import React from 'react'
import { Outlet } from 'react-router-dom'

const Logistics = () => {
    return (
        <>
            <div className='py-5 md:py-10 bg-white min-h-screen m-5'>
               <Outlet />
            </div>
        </>
    )
}

export default Logistics