import { Grid, Rating } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Classify from "./classification/Classify";
import BasicModal from "./AddProductModal";
import { add } from "../../../../../redux/SingleGoods";
import {
  useGetAllShippingAddressQuery,
  useGetAllVendorProductQuery,
} from "../../../../../redux/apiSlice";
import DummySkeleton from "../../../../../component/MarketPlace/goods/child/DummySkeleton";
import { addId } from "../../../../../redux/classifySlice";

export default function ProductIndex() {
  const [categories, setCategories] = useState();
  const [tab, setTab] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth);
  console.log(user.userInfo.id);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const [open4, setOpen4] = React.useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);

  //vendor Token
  const token = useSelector((state) => state.auth.userToken);

  // Data
  const { data, error, isLoading } = useGetAllVendorProductQuery(
    user.userInfo.id
  );
  console.log(data && data);
  const {
    data: shippingAdr,
    isLoading: shippingAdrLoading,
    error: shippingAdrError,
  } = useGetAllShippingAddressQuery();
  // console.log(shippingAdr && shippingAdr);

  // Methods
  const switchTab = (value) => {
    setTab((prev) => (prev === value ? prev : value));
  };

  function handleClassifyProduct(id) {
    console.log(id);
    dispatch(addId({ id: id }));
    navigate(`${id.id}`);
  }

  function handleOpenClassify(id) {
    // console.log("open classify modal", id);
    dispatch(addId({ id: id }));
    handleOpen2();
  }

  const getCategories = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}categories`);
    // console.log(res.data.data);
    setCategories(res.data.data);
  };

  // Route to address page
  function handleGotoLogisticsPage() {
    navigate("/dashboard/logistics");
  }

  const singleGoods = (data) => {
    // console.log(data);
    dispatch(add(data));
    navigate(`${data.id}`);
  };

  return (
    <>
      <div className="font-sans">
        <div className="grid grid-cols-1 gap-5 my-5 min-h-screen overflow-hidden">
          <div className="block w-full h-full bg-white shadow-lg rounded  px-5 py-5">
            <div className="flex justify-between items-center w-full mb-5 md:px-3">
              <h1 className="text-xl font-bold font-sans">Product List</h1>
              {/* <Link to="/dashboard/shop/add-product"> */}
              <button
                className="px-5 py-2.5 bg-brown-900 text-white rounded-lg"
                onClick={handleOpen}
              >
                Add Product(s)
              </button>
              {/* </Link> */}
            </div>

            <div className="flex items-center gap-5 mb-5">
              <button
                className={`border-b-2 px-3 py-2 hover:bg-brown-800 hover:text-white ${
                  tab === 1 ? "bg-brown-700 text-white" : ""
                } rounded-lg text-[14px]`}
                onClick={() => switchTab(1)}
              >
                Classified
              </button>
              <button
                className={`border-b-2 px-3 py-2 hover:bg-brown-800 hover:text-white ${
                  tab === 2 ? "bg-brown-700 text-white" : ""
                } rounded-lg text-[14px]`}
                onClick={() => switchTab(2)}
              >
                Non-Classified
              </button>
            </div>

            {/* Do a check if user has shipping Address */}
            {shippingAdr && shippingAdr.data.length === 0 ? (
              <div className="w-full mb-5 md:px-3 mt-10 bg-slate-100 p-3 rounded-lg space-y-2">
                <h1 className="text-lg font-bold font-sans">
                  No shipping address found!
                </h1>
                <p className="text-sm text-gray-500">
                  You need a shipping address to sell your products. Add a
                  shipping address
                </p>
                <button
                  className="px-5 py-2.5 bg-brown-900 text-white rounded-lg"
                  onClick={handleGotoLogisticsPage}
                >
                  Add Address
                </button>
              </div>
            ) : (
              <div>
                {tab === 1 ? (
                  <div>
                    {!data ? (
                      <DummySkeleton />
                    ) : (
                      <>
                        {data.data.length === 0 ? (
                          <div>You don't have a product yet!</div>
                        ) : (
                          <div className="grid md:grid-cols-4 grid-cols-2">
                            {data.data
                              .filter((data) => data.isClassified === true)
                              .map((product, i) => (
                                <div
                                  key={i}
                                  className="space-y-2  hover:border p-2 bg-gray-50 hover:border-brown-500 cursor-pointer"
                                >
                                  <img
                                    src={product.productImage}
                                    className="w-full h-40 object-cover transition duration-75 ease-in-out"
                                  />
                                  <div className="w-full">
                                    <p className="text-slate-700 text-xl font-bold font-sans">
                                      ${product.price.toLocaleString()}
                                    </p>
                                    <div className="my-2" />
                                    <p className="text-lg font-medium truncate">
                                      {product.name}
                                    </p>
                                    <div className="flex items-center space-x-2">
                                      <Rating
                                        value={product.ratings}
                                        text={`${product.numReviews} reviews`}
                                        size="small"
                                      />
                                      {/* review count */}
                                      <p className="text-xs text-gray-500">
                                        {product.ratings === 0
                                          ? "No reviews yet"
                                          : product.ratings}
                                      </p>
                                    </div>
                                    <div className="my-2" />

                                    <p className=" text-black text-sm truncate">
                                      {product.description}
                                    </p>
                                    {product.isClassified === false && (
                                      <p className="text-red-500 text-sm">
                                        Not Classifed
                                      </p>
                                    )}
                                    <div className="my-2" />
                                    <button
                                      className="px-5 py-2.5 text-brown-900 bg-white border-2 border-brown-900 w-full rounded-lg"
                                      onClick={() => singleGoods(product)}
                                    >
                                      View Product
                                    </button>
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div>
                    {data && data.data.length === 0 ? (
                      <div>You don't have a product yet!</div>
                    ) : (
                      <div className="grid md:grid-cols-4 grid-cols-2">
                        {data &&
                          data.data
                            .filter((data) => data.isClassified === false)
                            .map((product, i) => (
                              <div>
                                <Grid item xs={12} md={3} key={i}>
                                  <div
                                    className="space-y-2  hover:border p-2 bg-gray-50 hover:border-brown-500 cursor-pointer ]"
                                    // onClick={() => handleClassifyProduct(product)}
                                  >
                                    <img
                                      src={product.productImage}
                                      className="w-full h-40 object-cover hover:scale-[0.9] transition duration-75 ease-in-out"
                                    />
                                    <div className="space-y-2 font-bold">
                                      <p className="">{product.name}</p>
                                      <p className=" text-black">
                                        {product.description.substring(0, 20)}
                                        ...
                                      </p>
                                      <p className="font-bold text-red-500 ">
                                        ${product.price.toLocaleString()}
                                      </p>

                                      <div className="flex justify-between items-center">
                                        <div>
                                          <p className="text-[12px] text-slate-400">
                                            Classification Status:
                                          </p>
                                          <p className="text-red-500 text-[12px]">
                                            {String(product.isClassified)}
                                          </p>
                                        </div>
                                        <button
                                          className="px-2 py-1 bg-brown-900 text-white rounded-md text-[11px]"
                                          onClick={() =>
                                            handleOpenClassify(product.id)
                                          }
                                        >
                                          Re-classify
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </div>
                            ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <BasicModal
        open={open}
        setOpen={setOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
        categories={categories}
      />

      <Classify
        open2={open2}
        setOpen2={setOpen2}
        handleOpen2={handleOpen2}
        handleClose2={handleClose2}
      />
    </>
  );
}
