import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-step-progress-bar/styles.css";
import RegisterHeader from "../RegisterHeader";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  useGetListOfCountriesQuery,
  useSignupCustomerMutation,
} from "../../../../redux/apiSlice";
import { useDispatch } from "react-redux";
import { openSnackBar } from "../../../../redux/snackSlice";
import UserDetails from "../registration-pages/UserDetails"
import RenderSwitch from "../../registration/controller/RenderSwitch";
import Confirmation from "../registration-pages/Confirmation";
import { InfoRounded } from "@mui/icons-material";

function UserRegistration(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false)
  
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    password: "",
  });
  const [terms, setTerms] = useState(false)

  const {data: countryList} = useGetListOfCountriesQuery()

  // Formik
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    password: "",
    terms: false
  };

  const [signupCustomer, { isLoading }] = useSignupCustomerMutation();

  const signupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Enter your email address"),
    password: Yup.string().required("Enter your password").min(6, "Password must 6 character minimum"),
    country: Yup.string().required("Enter your phone number"),
    lastName: Yup.string().required("Enter Last name"),
    firstName: Yup.string().required("Enter First name"),
    terms: Yup.boolean(),
  });

  const togglePasswordView = (value) => {
    setVisible(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value)
    setDetails({ ...details, [name]: value });
  };

  const switchTerms = () => {
    setTerms(!terms)
  }
  const registerCustomer = async () => {
    console.log("initializing");
    setLoading(true)
    signupCustomer(details)
      .unwrap()
      .then((data) => {
        setLoading(false);
        navigate("/confirm-email", {
          state: {
            name: data.data.firstName,
            email: data.data.email,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(openSnackBar({ message: err.data.message }));
        setLoading(false);
      });
  };

  return (
    <div className="font-sans">
      <div className="">
        <div className="grid lg:grid-cols-5 grid-cols-1 mt-0 pt-0 h-screen">
          <div
            className="lg:col-span-3 bg-brown-900 bg-blend-overlay hidden lg:block w-full"
            style={{
              backgroundImage: `url("/images/red-pepper.png")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="flex flex-col justify-end pl-20 pb-40 w-3/4 h-full text-white">
              <h1 className="text-6xl font-bold mb-2">One market for all</h1>
              <p className="text-2xl font-light">
                The main objectives of the AfCFTA are to, inter alia, create a
                single continental market with free movement of goods and
                services
              </p>
            </div>
          </div>
          <div className="col-span-1 lg:col-span-2 pb-32">
            <RegisterHeader />
            <div className="flex flex-col justify-center items-center lg:mt-20 mt-10 lg:px-5 md:px-20 px-10">
              <Formik
                initialValues={initialValues}
                validationSchema={signupSchema}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true);
                  // console.log(values);
                  // try {
                  //   const res = await axios.post(
                  //     values
                  //   );
                  //   console.log(res.data);
                  //   actions.setSubmitting(false);
                  //   navigate("/confirm-email", {
                  //     state: {
                  //       name: res.data.data.firstName,
                  //       email: res.data.data.email,
                  //     },
                  //   });
                  // } catch (error) {
                  //   console.log(error);
                  //   actions.setSubmitting(false);
                  // }
                }}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form className=" w-full" id="" onChange={handleInputChange}>
                    {/* Form fields */}
                    <div className="md:px-10">
                      <div className="">
                        <h1 className="text-2xl font-bold lg:text3xl">
                          Create your account
                        </h1>
                        <h1 className="text-lg lg:text3xl text-gray-600">
                          Start enjoying all the benefits of Intra-African trade
                        </h1>
                        {/* <p className="text-xl text-slate-500 font-light">
                        Start selling on AfCFTA
                      </p> */}
                      </div>
                      <RenderSwitch 
                      registerCustomer={registerCustomer}
                      errors={errors}
                      touched={touched}
                      loading={loading}
                      handleInputChange={handleInputChange}
                      terms={terms}
                      >
                        <UserDetails errors={errors} touched={{touched}} togglePasswordView={togglePasswordView} visible={visible} countryList={countryList} label={"Personal Details"} />
                        <Confirmation label={"Confirmation"} errors={errors} switchTerms={switchTerms} terms={terms} />
                      </RenderSwitch>
                      {/* <button
                        type="button"
                        className="w-full py-4 px-5 bg-red-650 rounded-lg text-white bg-[#26220a] flex justify-center mt-5"
                        onClick={registerCustomer}
                      >
                        {loading ? (
                          <div className="flex justify-center w-full items-center">
                            <Oval />
                          </div>
                        ) : (
                          <p>Create Account</p>
                        )}
                      </button> */}
                      {errors.email ||
                      errors.password ||
                      errors.phoneNumber ||
                      errors.firstName ||
                      errors.lastName ? (
                        <div className="mt-10 hidden border bg-red-100 text-red-500 px-2 py-2 w-[300px] rounded-md">
                          <InfoRounded />
                          <p>
                            {errors.email ||
                              errors.password ||
                              errors.phoneNumber ||
                              errors.firstName ||
                              errors.lastName}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="mt-5">
                Already have an account?{" "}
                <span className="pl-2 font-bold text-brown-600">
                  {" "}
                  <Link to="/login">Log in</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserRegistration;
