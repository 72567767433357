import React from 'react';

function SectionNine(props) {
    return (
      <div className="w-full md:h-[500px] h-[650px] flex justify-center items-center -z-10">
        <div className="block space-y-10">
          <h3 className="mb-5 mt-5 font-bold md:px-40 px-0 text-center text-2xl">
            Partners and Associates
          </h3>
          <div className="block w-1/2 mx-auto md:w-full">
            <div className="grid md:grid-cols-3 grid-cols-1 gap-20 justify-items-center px-5 md:px-40">
              <div className="">
                <img
                  src="images/image2.png"
                  className=" object-contain"
                  alt="AFCFTA"
                />
              </div>
              <div className="w-full flex justify-center">
                <img
                  src="images/image 3.png"
                  className="object-contain"
                  alt="African Union"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default SectionNine;



<div className="container">
  
  <div className="grid md:grid-cols-3 grid-cols-1 gap-10 px-5 md:px-40">
    <div className="">
      <img src="images/image2.png" height={90} width={90} alt="AFCFTA" />
    </div>
    <div className="w-full flex justify-center">
      <img
        src="images/image 3.png"
        height={85}
        className="img-fluid"
        alt="African Union"
      />
    </div>
    <div className="w-full flex justify-center">
      <img src="images/Asset 2@4x-8 1.png" height={55} alt="African Union" />
    </div>
  </div>
</div>;