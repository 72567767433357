import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Header from '../utils/Header';
import Login from '../auth/login/Login';
import ForgotPassword from '../auth/forgot-password/ForgotPassword';
import Footer from '../utils/Footer';
import Dashboard from '../vendor/Dashboard';
import CompletedOnboardingStep from '../vendor/Dashboard/pages/dashboard/new_user/CompletedOnboardingStep';
import Content from '../vendor/Dashboard/pages/Content';
import RulesOfOrigin from '../regular-pages/about/RulesOfOrigin';
import WalletSummary from '../vendor/Dashboard/pages/wallet/WalletSummary';
import DisputeIndex from '../vendor/Dashboard/pages/disputes/DisputeIndex';
import SettingsIndex from '../vendor/Dashboard/pages/settings/SettingsIndex';
import ProfileSettings from '../vendor/Dashboard/pages/settings/ProfileSettings';
import SingleOrder from '../vendor/Dashboard/pages/orders/SingleOrder';
import ProductDetails from '../vendor/Dashboard/pages/product/child/ProductDetails';
import Success from '../auth/registration/registration-pages/Success';
import VendorHome from '../vendor/Dashboard/onboarding';
import SingleProduct from '../MarketPlace/goods/SingleGoodsDetails';
import Summary from '../MarketPlace/user/checkout/Summary/Summary';
import Services from '../vendor/Dashboard/pages/services/Services';
import Service from '../vendor/Dashboard/pages/orders/Service';
import SingleGoods from '../vendor/Dashboard/pages/product/SingleGoods';
import Logistics from '../vendor/Dashboard/pages/logistics/Logistics';

import Disputes from '../vendor/Dashboard/pages/disputes/disputes';
import Address from '../MarketPlace/user/address/Address';
import UserType from '../auth/registration/usertype/UserType';
import VendorRegistration from '../auth/registration/usertype/VendorRegistration';
import GeneralMarket from '../MarketPlace/GeneralMarket';
import SingleServiceDetail from '../MarketPlace/services/SingleServiceDetail';
import SingleServiceCategory from '../MarketPlace/services/SingleServiceCategory';
import GoodsCategory from '../MarketPlace/goods/GoodsCategory';
import SingleGoodsCategory from '../MarketPlace/goods/SingleGoodsCategory';
import AllGoodsCategory from '../MarketPlace/goods/AllGoodsCategory';
import ParentGoodsCategory from '../MarketPlace/goods/ParentGoodsCategory';
import AllServiceCategory from '../MarketPlace/services/AllServiceCategory';
import CreateOffer from '../MarketPlace/services/FixedOffer';
import Layout from '../MarketPlace/user/profile/sub-components/Layout';
import OrderIndex from '../MarketPlace/user/order/subcomponent/OrderIndex';
import SingelOrderDetails from '../MarketPlace/user/order/subcomponent/SingelOrderDetails';
import OfferHome from '../MarketPlace/user/offer/OfferHome';
import OfferIndex from '../MarketPlace/user/offer/subcomponent/OfferIndex';
import CheckoutHome from '../MarketPlace/user/checkout/CheckoutHome';
import ShippingDetail from '../MarketPlace/user/checkout/ShippingDetail';
import AddProductModal from '../vendor/Dashboard/pages/product/AddProductModal';
import SnackBar from '../utils/SnackBar';
import ServiceIndex from '../vendor/Dashboard/pages/services/ServiceIndex';
import ServiceDetail from '../vendor/Dashboard/pages/services/ServiceDetail';
import EditService from '../vendor/Dashboard/pages/services/EditService';
import ProductHome from '../vendor/Dashboard/pages/product/ProductHome';
import ProductIndex from '../vendor/Dashboard/pages/product/ProductIndex';
import CartHome from '../MarketPlace/user/cart/CartHome';
import AuthenticationBottomNav from '../auth/AuthenticationBottomNav';
import VendorOrder from '../vendor/Dashboard/pages/orders/VendorOrder';
import VendorOrderIndex from '../vendor/Dashboard/pages/orders/VendorOrderIndex';
import WalletHome from '../vendor/Dashboard/pages/wallet/WalletHome';
import SingleTransaction from '../vendor/Dashboard/pages/wallet/SingleTransaction';
import OfferPage from '../vendor/Dashboard/pages/offer/OfferHome';
import VendorOfferIndex from '../vendor/Dashboard/pages/offer/VendorOfferIndex';
import SingleVendorOfferDetails from '../vendor/Dashboard/pages/offer/SingleVendorOfferDetails';
import LogisticsIndex from '../vendor/Dashboard/pages/logistics/LogisticsIndex';
import FinalizeCheckout from '../MarketPlace/user/checkout/Summary/FinalizeCheckout';
import OfferCheckout from '../MarketPlace/user/checkout/offer/OfferCheckout';
import { useVerifyCurrentUserMutation } from '../../redux/apiSlice';
import AccountIndex from '../MarketPlace/user/profile/Account';
import { useDispatch, useSelector } from 'react-redux';
import OrderHome from '../MarketPlace/user/order/OrderHome';
import ResetPassword from '../auth/forgot-password/ResetPassword';
import ResetPasswordSuccess from '../auth/forgot-password/ResetPasswordSuccess';
import Business from '../vendor/Dashboard/onboarding/Business';
import Personal from '../vendor/Dashboard/onboarding/Personal';
import ShippingAddress from '../vendor/Dashboard/onboarding/ShippingAddress';
import Home from '../regular-pages/home/Index';
import AboutIndex from '../regular-pages/about/AboutHome';
import InfoGuide from '../regular-pages/about/InfoGuide';
import TransactionIndex from '../MarketPlace/user/transaction/TransactionIndex';
import TransactionList from '../MarketPlace/user/transaction/child/TransactionList';
import WithdrawalIndex from '../vendor/Dashboard/pages/wallet/child/WithdrawalIndex';
import SearchIndex from '../MarketPlace/search/SearchIndex';
import EmailConfirmation from '../auth/registration/others/EmailConfirmation';
import UserRegistration from '../auth/registration/usertype/UserRegistration';




function MainComponent(props) {



  const [cartItems, setCartItems] = useState("")
  const [token, setToken] = useState(null)
  const [error, setError] = useState("")
  
  
  const userAccess = useSelector(state => state.auth.tokenActive)
  const userInfo = useSelector((state) => state.auth.userInfo);
  // console.log(userInfo);
  const location = useLocation()
  const user = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  




  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `${token}`
  }



  // Effects
  useEffect(() => {
    window.scrollTo(0,0)
  }, [location.pathname]);

  // Check if user is visiting page for the first time, if not get a value from localStorage and take them to market page if property exist

  // useEffect(() => {
  //   const hasVisitedMarketplace = localStorage.getItem("visitedMarketplace");

  //   if (hasVisitedMarketplace) {
  //     navigate('/market');
  //     if (location.pathname === '/') {
  //       navigate('/')
  //     }
  //   } else if (!hasVisitedMarketplace) {
  //     localStorage.setItem("visitedMarketplace", JSON.stringify(true));
  //   }
  // }, [location.pathname])

  // Check the user status and return the state of authentication
  useEffect(() => {
    
    if (user.userToken !== undefined && user.userToken !== null) {
      const tokenToTrim = user.userToken.split("Bearer ", )[1]
      setToken(tokenToTrim)
    } else {
      setToken(null)
    }

    // handleVerifyUser()
    

    return () => {
      console.log("")
    }
  }, [])


  // Data
  const [verifyUser, {isLoading}] = useVerifyCurrentUserMutation()
  
  // Methods
    function handleVerifyUser () {
      // console.log(token);
      // verifyUser({"token": token}).unwrap()
      // .then(data => {
      //   dispatch(setTokenActive())
      //  })
      // .catch(err => {
      //   // dispatch(openSnackBar({message: "Seesion expired, Login!"}))
      //   dispatch(setTokenInActive())
      //   dispatch(logout())
      // })
    }
  
  

  return (
    <>
    <SnackBar />
    <Routes>
      {/* Snack */}
      
      {/* Home route */}
      <Route
        path="/"
        element={
          <>
            <Header />
            <Home />
            <Footer />
          </>
        }
      />

      {/* Other pages */}
      <Route path="about" element={<AboutIndex />} />
      <Route path="guides" element={<InfoGuide />} />
      <Route path="rules" element={<RulesOfOrigin />} />

      {/* Authentication section***********  */}
      <Route path="/select-user" element={<UserType />} />
      <Route path="/signup-buyer" element={<UserRegistration />} />
      <Route path="signup-vendor" element={<VendorRegistration />} />
      <Route path="/confirm-email" element={<EmailConfirmation />} />
      <Route path="/success" element={<Success />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/reset-success" element={<ResetPasswordSuccess />} />

      {/* Login */}
      <Route
        path="login"
        element={
          <>
            <Header />
            <Login />
          </>
        }
      />

      <Route path="/vendor/onboarding" element={<VendorHome />} />
      <Route path="/vendor/onboarding/personal" element={<Personal />} />
      <Route path="/vendor/onboarding/business" element={<Business />} />
      <Route path="/vendor/onboarding/shipping-address" element={<ShippingAddress />} />

      {/* Dashboard */}
      <Route path="/dashboard" element={<Dashboard />}>
        <Route path="/dashboard/" element={<Content />} />
        {/* New user | to see onboarding screens */}
        <Route path="onboarding" element={<CompletedOnboardingStep />} />

        {/* Returning user */}
        <Route path="user/:id" element={<h1>user dashboard</h1>} />

        {/* Goods Routes */}
        <Route path="shop" element={<ProductHome />}>
          <Route index element={<ProductIndex />} />
          <Route path=":id" element={<SingleGoods />} />

          <Route path="add-product" element={<AddProductModal />}>
            <Route path="add-product/" element={<ProductDetails />} />
          </Route>
        </Route>
        {/* Services route */}
        <Route path="services" element={<Services />} >
          <Route index element={<ServiceIndex />} />
          <Route path=':id' element={<ServiceDetail />} />
          <Route path=':id/update' element={<EditService />} />
        </Route>

        <Route path="disputes" element={<Disputes />} />
        
        <Route path="logistics" element={<Logistics />} >
          <Route index element={<LogisticsIndex />} />
        </Route>
        
        
        {/* Order */}
        <Route path="order" element={<VendorOrder />}>
          <Route index element={<VendorOrderIndex />} />
          <Route path=":id" element={<SingleOrder />} />
        </Route>
        <Route path="order/service" element={<Service />} />

        {/* Offer */}
        <Route path='offer' element={<OfferPage />}>
          <Route index element={<VendorOfferIndex />} />
          <Route path=':id' element={<SingleVendorOfferDetails />} />
        </Route>
        
        {/* Wallet */}
        <Route path="wallet" element={<WalletHome />}>
          <Route index element={<WalletSummary />} />
          <Route path=":id" element={<SingleTransaction />} />
          <Route path='withdraw' element={<WithdrawalIndex />}>

          </Route>
        </Route>
        
        {/* Disputes */}
        <Route path="disputes" element={<DisputeIndex />}></Route>
        
        {/* Settings */}
        <Route path="settings" element={<SettingsIndex />}>
          <Route index element={<ProfileSettings />} />
          {/* <Route path="notification" element={<NotificationSettings />} /> */}
          {/* <Route path="payment" element={<PaymentSettings />} /> */}
          {/* <Route path="security" element={<SecuritySettings />} /> */}
        </Route>
      </Route>



      {/* Market Routes */}
      {/* Goods Routes */}
      <Route path="market" element={<GeneralMarket />} />
      <Route path="/market/search" element={<SearchIndex />} />

      <Route path="/market/goods/category" element={<GoodsCategory />}>
        <Route path="/market/goods/category/" element={<AllGoodsCategory />} />
        <Route path='/market/goods/category/:id/' element={<ParentGoodsCategory />} />
        <Route path='/market/goods/category/:id/single' element={<SingleGoodsCategory />} />
      </Route>
      <Route path="/market/goods/category/:id" element={<SingleGoodsCategory />} />

      {/* <Route path="/market/goods/category/:id" element={<SingleGoodsCategory />} /> */}
      <Route path="/market/goods/:id" element={<SingleProduct />} />

      {/* Service Routes */}
      <Route path="/market/service/category" element={<AllServiceCategory />} />
      <Route path="/market/service/category/:id" element={<SingleServiceCategory />} />
      <Route path="/market/service/:id" element={<SingleServiceDetail />} />
      {/* <Route path="/service/hire" element={<Hire />} /> */}
      <Route path='/market/service/:id/create-offer' element={<CreateOffer />} />

      {/* Cart */}
      <Route path="/cart" element={<CartHome />} />
        <Route path='checkout-offer' element={<OfferCheckout />} />
      <Route path="/checkout" element={<CheckoutHome />} >
        <Route index element={<ShippingDetail />} />
        <Route path='summary' element={<Summary />} />
      </Route>

      <Route path='summary' element={<FinalizeCheckout />} />

      {/* <Route path="/profile" element={<Profile />} /> */}
      <Route path="/account" element={<Layout />}>
        <Route path='/account' element={<AccountIndex />} />
        {/* Order Page */}
      <Route path="order" element={<OrderHome />}>
        <Route index element={<OrderIndex />} />
        <Route path=":id" element={<SingelOrderDetails />} />
      </Route>
      <Route path="transaction" element={<TransactionIndex />}>
        <Route index element={<TransactionList />} />
        <Route path=':ref' element={""} />
      </Route>
      <Route path="offer" element={<OfferHome />}>
        <Route index element={<OfferIndex />} />
        {/* <Route path=':id' element={<SingleOfferDetails />} /> */}
      </Route>
      <Route path="address" element={<Address />} />
      </Route>
    </Routes>
    {!userAccess && location.pathname === "/market" ? <AuthenticationBottomNav />  : null}
    </>
  );
}



export default MainComponent;