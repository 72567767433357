import { useContext , createContext, useReducer } from "react";
import { vendorReducer } from "./vendorReducer";

export const VendorContext =  createContext()
const INITIAL_STATE ={ 
     vendorCategory:{
          brandAddress: "",
          brandName: "",
          country: "",
          description: "",
          email: "",
          firstName: "",
          lastName: "",
          password: "",
          phoneNumber: "",
          state: "",
          vendorCategory: "",
          resourceType:"goods"
     },
     user:{}
    
}
const VendorProvider = ({children}) =>{
     const [state,dispatch] = useReducer(vendorReducer,INITIAL_STATE)
     return (
          <VendorContext.Provider value={{state,dispatch}}>
               {children}  
          </VendorContext.Provider>  
     )

}

export default VendorProvider;