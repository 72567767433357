import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data:null,
    loading: false,
}

export const goodsReducer = createSlice({
    name: 'goods',
    initialState,
    reducers: {
        add: (state,action) => {
            state.data = action.payload
        },
        startLoading: (state) => {
            state.loading = true
        },
        stopLoading: (state) => {
            state.loading = false
        }
    },
})

// Action creators are generated for each case reducer function
export const {add, startLoading, stopLoading} = goodsReducer.actions

export default goodsReducer.reducer