import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link, useNavigate } from "react-router-dom";
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { VendorContext } from '../../../context/Vendor/VendorContext';
import { logout, login, loginStart, vendorAccess, userAccess } from '../../../redux/AuthSlice';
import { FlashOnRounded, FormatAlignLeftSharp } from '@material-ui/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
function Login(props) {
  // route navigator
  // const {state:token,dispatch} = useContext(AuthContext)
  const navigate = useNavigate()
  // const { dispatch } = useContext(VendorContext)
  const dispatch = useDispatch()
  // const redux = useSelector((state)=>state.auth)
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    email: '',
    password: ''
  })
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const togglePassword = (e) => {
    e.preventDefault()
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
    setShowPassword(!showPassword)
  }


  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   navigate("/dashboard")
  // }

  const login = async (e) => {

    e.preventDefault()
    setLoading(true)
    console.log('processing .....')
    dispatch(loginStart())
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}users/login`, { email: state.email, password: state.password })
      console.log(res)
      const token = await res?.headers?.authorization
      switch (res.data.data.userType) {
        case 'VENDOR':
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `${res?.headers?.authorization}`
          }
          const user = await axios.get(`${process.env.REACT_APP_BASE_URL}vendors/current`, { headers: headers })
          // console.log(user.data)
          dispatch(userAccess({userDetails: user?.data?.data, token: token, userType: "VENDOR"}))
          // console.log('vendor logged In successfullly')
          setLoading(false)
          // await localStorage.setItem("token", JSON.stringify(token))
          navigate('/dashboard')
          return;
        case 'CUSTOMER':
          const header = {
            'Content-Type': 'application/json',
            'Authorization': `${res?.headers?.authorization}`
          }
          const customer = await axios.get(`${process.env.REACT_APP_BASE_URL}customers/current`, { headers: header })
          // console.log(customer.data)
          dispatch(userAccess({userDetails: customer?.data?.data, token: token, userType: "CUSTOMER"}))
          // console.log('customer logged successfully')
          setLoading(false)
          // localStorage.setItem("token", JSON.stringify(token))
          navigate('/market')
          return;
        default:
          break
      }

      // console.log(res.headers)
      setLoading(false)
      

    } catch (error) {
      console.log(error)
      setLoading(false)
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }


  return (
    <div className="font-sans">
      <div className="flex justify-center md:items-center md:h-[95vh] h-[95vh] md:pt-0 pt-20 relative px-10 overflow-hidden">
        {/* floating vector */}
        <div className="absolute md:-bottom-40 md:-left-10 bottom-0 left-0">
          <div className="md:w-72 md:h-72 w-32 h-32">
            <img src={"/images/login-vector.svg"} className="" />
          </div>
        </div>
        <div className="block md:w-[360px] w-full">
          <h3 className="text-center font-bold text-xl mb-10">Login</h3>
          <form className="block w-full" onSubmit={login}>
            <div className="">
              <label className="">Email</label>
              <input
                type="text"
                name="email"
                onChange={handleChange}
                placeholder="yourname@gmail.com"
                className="px-2 py-2.5 w-full ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2 placeholder:text-slate-300"
                required
              />
            </div>
            <div className="mt-4">
              <div className="">
                <label className="text-left">
                  <div>Password</div>
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onChange={handleChange}
                    style={{ borderRight: "0px" }}
                    className="px-2 py-2.5 w-full ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                    required
                  />
                  <div className="absolute top-4 right-3">
                    <button className="btn " onClick={togglePassword}>
                      {showPassword ? (
                        <i className="fa fa-eye"></i>
                      ) : (
                        <i className="fa fa-eye-slash"></i>
                      )}
                    </button>
                  </div>
                </div>
                <small className="hidden">
                  <span>password should include atleast a number</span>
                </small>
                <p className="mt-4 text-right text-brown-500">
                  <Link to="/forgotpassword">Forgot Password?</Link>
                </p>
              </div>
            </div>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            {/* Submit Button */}
            <div className="">
              <button
                className={loading ? `text-brown-100 bg-brown-900 py-2.5 px-5 w-full my-2 rounded-md` : `text-brown-100 bg-brown-900 py-2.5 px-5 w-full my-2 rounded-md`}
                type='submit'
              >
                {loading ? (
                  <div className='flex items-center justify-center'>
                    <CircularProgress />
                  </div>
                ) : (
                  <p>Login</p>
                )}
              </button>
            </div>
            <div className="mt-3">
              <p className="text-slate-600">
                Don't have an account?{" "}
                <Link to="/select-user">
                  <span className="text-brown-700 font-bold">Signup</span>
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Login;