import { Grid, Skeleton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetGoodsCategoriesQuery } from "../../../redux/apiSlice";
import DummySkeleton from "./child/DummySkeleton";

export default function CategoryList() {


  const navigate = useNavigate()

  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(false);

  // fetching category details from slice
  const { data, isLoading, error } = useGetGoodsCategoriesQuery("product");

  if (isLoading) {
    
  }
  if (error) {
    
  }
  if (data) {
    
  }

  function routeCategory (data) {
    navigate(`/market/goods/category/${data.id}`, {
      state: {
        categoryName: data.name
      }
  })
}

  return (
    <div className="py-2 block rounded-md">
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-semibold text-brown-800 md:my-4 my-4">
          Categories
        </h1>
        <Link
          to="/market/goods/category"
          className="md:text-sm text-xs text-blue-700 cursor-pointer"
        >
          View all
        </Link>
      </div>
      {/* Web view */}
      <div>
        {!data ? (
          <div className="flex items-start space-x-5 overflow-x-scroll">
            {dummyValue.map((_, i) => (<>
              <Skeleton width={80} height={80} variant="circular" animation="wave" className=" flex-shrink-0" />
            </>) )}
          </div>
        ) : (
          <div className="flex items-start space-x-5 overflow-x-scroll">
            {data.data.map((data, i) => {
              return (
                <div className="flex-shrink-0">
                <Link
                  key={i}
                  to={`/market/goods/category/${data.id}`}
                  state={{
                    categoryName: data.name,
                  }}
                >
                  <div className="flex flex-col space-y-3 justify-center items-center flex-shrink-0" onClick={() => routeCategory()}>
                    <img
                      src={data.image}
                      className="w-20 h-20 md:w-28 md:h-28 rounded-full border-black flex-shrink-0"
                    />
                    <div className="">
                      <div className="ml-3">
                        <p className="capitalize font-black text-sm leading-tight w-20 text-center truncate">
                          {data.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/* <div className="hidden md:block">
        {!data ? (
          <>
            <div className="hidden md:block">
              <DummySkeleton />
            </div>{" "}
            <div className="flex items-start space-x-5 overflow-x-scroll">
              {data.data.slice(0, 12).map((data, i) => {
                <Skeleton
                  width={80}
                  height={80}
                  variant="circular"
                  animation="wave"
                />;
              })}
            </div>{" "}
          </>
        ) : (
          <>
            <div className="grid md:grid-cols-4 grid-cols-2 gap-1 w-full divide-x divide-y">
              {data.data.slice(0, 12).map((data, i) => {
                return (
                  <Link
                    key={i}
                    to={`/market/goods/category/${data.id}`}
                    state={{
                      categoryName: data.name,
                    }}
                  >
                    <div className="flex justify-between items-center bg-white md:h-20 h-14">
                      <img
                        src={data.image}
                        className="md:w-20 w-14 md:h-20 h-14 rounded-l-lg object-cover"
                      />
                      <div className="bg-white flex-1">
                        <div className="ml-3">
                          <p className="capitalize text-sm leading-tight">
                            {data.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
            <div className="flex items-start space-x-5 overflow-x-scroll">
              {data.data.slice(0, 12).map((data, i) => {
                return (
                  <Link
                    key={i}
                    to={`/market/goods/category/${data.id}`}
                    state={{
                      categoryName: data.name,
                    }}
                  >
                    <div className="flex flex-col space-y-3 justify-center items-center w-20">
                      <img
                        src={data.image}
                        className="w-20 h-20 rounded-xl border-[0.5px] border-black"
                      />
                      <div className="">
                        <div className="ml-3">
                          <p className="capitalize font-black text-sm leading-tight w-20 text-center truncate">
                            {data.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </>
        )}
      </div> */}
    </div>
  );
}

const dummyValue = [1, 2, 3, 4, 5,6,7,8,9,6,7,4];

{
  /* Mobile view */
}
{
  /* <div className='md:hidden block'>
      {!data ? <div className="flex items-start space-x-5 overflow-x-scroll">
          {data.data.slice(0, 12).map((data, i) => {
            <Skeleton width={80} height={80} variant="circular" animation="wave" />
          })}</div>  : (
        <div className="flex items-start space-x-5 overflow-x-scroll">
          {data.data.slice(0, 12).map((data, i) => {
            return (
              <Link key={i} to={`/market/goods/category/${data.id}`} state={{
                categoryName: data.name
              }} >
                <div className="flex flex-col space-y-3 justify-center items-center w-20">
                  <img
                    src={data.image}
                    className="w-20 h-20 rounded-xl border-[0.5px] border-black"
                  />
                  <div className="">
                    <div className="ml-3">
                      <p className="capitalize font-black text-sm leading-tight w-20 text-center truncate">{data.name}</p>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      )}
      </div> */
}
