import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import NoItemInShop from './ProductIndex';

export default function ProductHome () {
    return (
      <div className="w-full">
        <div>
          <Outlet />
        </div>
      </div>
    );
}
