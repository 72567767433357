import React from 'react';
import { IoLogoFacebook, IoLogoGithub, IoLogoInstagram, IoLogoTwitch, IoLogoTwitter } from 'react-icons/io';
import { Link } from 'react-router-dom';

function Footer(props) {
    return (
      <footer className="text-center flex flex-col justify-center  space-x-4 space-y-6 items-center">
        <div className="flex-col md:hidden items-start space-y-3 text-center text-white">
          <p className="list-inline-item ">
            <Link to="#">About</Link>
          </p>
          <p className="list-inline-item ">
            <Link to="#">Trades</Link>
          </p>
          <p className="list-inline-item ">
            <Link to="#">Careers</Link>
          </p>
          <p className="list-inline-item ">
            <Link to="#">Accessibility</Link>
          </p>
          <p className="list-inline-item ">
            <Link to="#">Partners</Link>
          </p>
        </div>
        <div className="md:flex items-start space-x-3 text-center text-white hidden">
          <p className="list-inline-item ">
            <Link to="#">About</Link>
          </p>
          <p className="list-inline-item ">
            <Link to="#">Trades</Link>
          </p>
          <p className="list-inline-item ">
            <Link to="#">Careers</Link>
          </p>
          <p className="list-inline-item ">
            <Link to="#">Accessibility</Link>
          </p>
          <p className="list-inline-item ">
            <Link to="#">Partners</Link>
          </p>
        </div>
        <div className="text-white">
          <div className="pt-3 text-white flex items-center space-x-5 md:space-x-10">
            <p className=" text-white first ">
              <Link to="#">
                <i class=""><IoLogoFacebook /></i>
              </Link>
            </p>
            <p className=" text-white ">
              <Link to="#">
                <i class=""><IoLogoTwitter /></i>
              </Link>
            </p>
            <p className=" text-white ">
              <Link to="#">
                <i class=""><IoLogoInstagram /></i>
              </Link>
            </p>
            <p className=" text-white ">
              <Link to="#">
                <i class=""><IoLogoGithub /> </i>
              </Link>
            </p>
          </div>
        </div>
        <div className="text-white text-sm md:text-lg copyright pt-3">
          {`© ${new Date()
            .getFullYear()
            .toString()} AFCFTA, Inc. All rights reserved`}
        </div>
      </footer>
    );
}

export default Footer;