



import React from 'react'
import { Link, Outlet } from 'react-router-dom';

export default function SingleTransaction() {
    return (
      
        <div className="">
          <p>Single transaction detail</p>    
        </div>
      
    );
}
