import { Button, Stack, Step, StepLabel, Stepper } from "@mui/material";
import { Form } from "formik";
import React, { useState } from "react";
import { Oval } from "react-loading-icons";

export default function FormStepper({
  children,
  loading,
  errors,
  handleInputChange,
  registerVendor,
}) {
  const stepsArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentStep = stepsArray[step];

  console.log(step < stepsArray.length - 1);

  return (
    <Form className="mt-5 mb-5" onChange={handleInputChange}
    autoComplete="off"
    >
      <Stepper alternativeLabel activeStep={step}>
        {stepsArray.map((step, i) => (
          <Step key={i} completed={step > i}>
            <StepLabel sx={{ color: "#ff2000" }}>{step.props.label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {currentStep}
      <Stack direction="row" spacing={2} sx={{ marginTop: 5 }}>
        {step !== 0 ? (
          <Button
            variant="outlined"
            onClick={() => {
              step > 0 ? setStep(step - 1) : setStep(0);
            }}
          >
            {step > 0 || step === stepsArray.length - 1 ? "Back" : null}
          </Button>
        ) : null}

        {step !== stepsArray.length - 1 ? (
          <Button
            variant="outlined"
            onClick={() => {
              step < stepsArray.length - 1
                ? setStep(step + 1)
                : setStep(stepsArray.length - 1);
            }}
          >
            Next
          </Button>
        ) : (
          <>
            <button
              type="button"
              onClick={registerVendor}
              className={`w-full py-3 px-5 bg-red-650 rounded-lg text-white bg-[#26220a] flex justify-center mt-5 ${
                errors.brandName ||
                errors.description ||
                errors.serviceType ||
                errors.vendorCategory
                  ? "hidden"
                  : null
              }`}
            >
              {loading ? (
                <div className="flex justify-center w-full items-center">
                  <Oval />
                </div>
              ) : (
                <p>Create Account</p>
              )}
            </button>
          </>
        )}
      </Stack>
    </Form>
  );
}
