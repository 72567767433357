import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function OrderList({ orders }) {
  const navigate = useNavigate();

  const gotoSingleOrder = (id) => {
    navigate(`/dashboard/order/${id}`);
  };

  console.log(orders);

  return (
    <div className="font-sans">
      <div className="grid grid-cols-1 gap-5 my-5 h-screen overflow-hidden">
        <div className="block w-full h-full bg-white shadow-lg rounded p-5">
          <div className="flex justify-between items-center w-full mb-10">
            <h1 className="text-xl font-bold font-sans">My Order(s)</h1>
            {/* <button className="px-5 py-2.5 bg-brown-900 text-white rounded-sm">
                Add Product(s)
              </button> */}
          </div>

          <div>
            <table className="table-auto w-full">
              <thead className="text-left">
                <tr className="bg-brown-800 text-white h-8 ">
                  <th>Order ID</th>
                  <th>Product Qty</th>
                  <th>Customer</th>

                  <th>Destination</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody className="text-left">
                {orders.map((product, i) => (
                  <tr
                    className="cursor-pointer text-slate-700 even:bg-slate-100 hover:bg-brown-100 "
                    onClick={() => gotoSingleOrder(product.id)}
                  >
                    <td className="py-2">{product.id}</td>
                    <td>{product.orderItems.length}</td>
                    <td>
                      {product.customer.firstName} {product.customer.lastName}
                    </td>

                    <td>{product.logistics.customerShippingAddress.country}</td>
                    <td
                      className={` ${
                        product.status === "Completed"
                          ? "text-green-600"
                          : "text-yellow-600"
                      }`}
                    >
                      {product.orderAcceptance.acceptanceStatus}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

const productList = [
  {
    order_id: "102345",
    product: "Ghana Weave Shirt",
    customer: "Alikan Hammed",
    date: "23/04/22",
    destination: "Kenya",
    status: "Ongoing",
  },
  {
    order_id: "102336",
    product: "Dudu Osun Black Soap",
    customer: "Benson H. Osy",
    date: "23/05/22",
    destination: "Uganda",
    status: "Completed",
  },
  {
    order_id: "102345",
    product: "Ghana Weave Shirt",
    customer: "Alikan Hammed",
    date: "23/04/22",
    destination: "Kenya",
    status: "Ongoing",
  },
  {
    order_id: "102336",
    product: "Aba Made Sneaker",
    customer: "Sarah Nkem",
    date: "02/05/22",
    destination: "Ehiopia",
    status: "Completed",
  },
  {
    order_id: "102345",
    product: "Ghana Weave Shirt",
    customer: "Alikan Hammed",
    date: "23/04/22",
    destination: "Kenya",
    status: "Ongoing",
  },
  {
    order_id: "102345",
    product: "Ghana Weave Shirt",
    customer: "Alikan Hammed",
    date: "23/04/22",
    destination: "Kenya",
    status: "Ongoing",
  },
];
