


import React, { useRef } from 'react'
import { IoIosAddCircle } from 'react-icons/io'

export default function ProductDetails() {


  const fileRef = useRef()

  // Handles all the product details states and sub-components
  const uploadFile = () => {
    console.log(fileRef.current);
  }

  return (
    <div className="">
      <p>Add Product</p>
      <hr className="my-2" />
      <form className="grid grid-cols-2 space-y-3 gap-5">
        <div className="flex flex-col col-span-2">
          <label>Product Name</label>
          <input
            type="text"
            className="px-3.5 py-2.5 w-full bg-brown-100 rounded-md placeholder-slate-500 focus:ring-2 ring-lime-500 outline-none"
            placeholder="African Soap Dudu Osun"
          />
        </div>
        <div className="flex flex-col col-span-2">
          <label>Description</label>
          <textarea
            rows={3}
            className="px-3.5 py-2.5 w-full bg-brown-100 rounded-md placeholder-slate-500 focus:ring-2 ring-lime-500 outline-none"
            placeholder="100% Cotton hand made..."
          />
        </div>
        <div className="flex flex-col">
          <label>Price</label>
          <input
            type="number"
            className="px-3.5 py-2.5 w-full bg-brown-100 rounded-md placeholder-slate-500 focus:ring-2 ring-lime-500 outline-none"
            placeholder="$200"
          />
        </div>
        <div className="flex flex-col">
          <label>Available Quantity</label>
          <input
            type="number"
            className="px-3.5 py-2.5 w-full bg-brown-100 rounded-md placeholder-slate-500 focus:ring-2 ring-lime-500 outline-none"
            placeholder="140"
          />
        </div>
        <div className="flex flex-col">
          <label>Condition</label>
          <select className="w-full bg-brown-100 py-2 rounded-md">
            <option>Used</option>
            <option>New</option>
          </select>
        </div>
        <div className="flex flex-col col-span-2">
          <label>Product Image</label>
          <div className="w-20 h-20 bg-blue-200 rounded-md flex justify-center items-center" ref={fileRef} onClick={uploadFile}>
            <IoIosAddCircle className="pointer-events-none" />
          </div>
        </div>
      </form>
    </div>
  );
}
