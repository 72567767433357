import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "./Hire.css";
import { TextareaAutosize, TextField } from "@mui/material";
import CreateOffer from "../FixedOffer";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../../redux/serviceSlice";
import { useCreateOfferMutation } from "../../../../redux/apiSlice";
import moment from "moment";
import { openSnackBar } from "../../../../redux/snackSlice";
import axios from "axios";
import { Oval } from "react-loading-icons";

export default function HireOffer({ open, serviceData, data }) {
  

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  console.log("data from pagent", data)

  const [opener, setOpener] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  const [offerDetails, setOfferDetails] = React.useState({
    description: "",
    serviceId: data.id,
    skills: "",
    budget: "",
    dueDate: "",
    duration: "",
    title: "",
    vendorId: data.vendor.id,
  });

  const user = useSelector((state) => state.auth);
  const offerState = useSelector((state) => state.service);
  const dispatch = useDispatch();
  const [createOffer, { isLoading, error }] = useCreateOfferMutation();

  // close modal on route change

  React.useEffect(() => {
    return () => {
      dispatch(closeModal());
      setLoading(false)
    };
  }, []);

  // Handle input changes
  function handleInputChange(e) {
    const { name, value } = e.target;
    setOfferDetails({ ...offerDetails, [name]: value });
    // console.log(name, ".....", value)
  }

  async function handleCreateOffer(e) {
    e.preventDefault();
    // console.log("Test sending offer")
    // check the user token exist
    const {
      description,
      serviceId,
      skills,
      budget,
      dueDate,
      duration,
      title,
      vendorId,
    } = offerDetails;

    const token = user.userToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };
    // console.log(offerDetails)

    try {
      setLoading(true)
      await createOffer({
        description: description,
        serviceId: serviceId,
        skills: skills,
        terms: {
          budget: budget,
          dueDate: moment(dueDate).format("DD-MM-yyyy"),
          duration: duration,
        },
        title: title,
        vendorId: vendorId,
      })
        .unwrap()
        .then((data) => {
          console.log(data)
          setLoading(false)
          dispatch(closeModal())
          dispatch(openSnackBar({ message: "Offer has been submitted" }));
        });
    } catch (err) {
      setLoading(false)
    }
  }

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <div className="">
      <div className="p-5 example w-[600px] relative">
        {/* Show while creating offer */}
        {/* <div className=' h-[800px] bg-[#fdfdfd9d] absolute left-0 right-0 mx-5 flex items-center justify-center'>
                        <h1>Creating Offer</h1>
                    </div> */}
        <div>
          <div className="py-1 px-4 flex space-x-3 items-center">
            <img src="/images/Hire Woman.png" />
            <div className="flex-1 px-6">
              <h2 className="font-bold">{data.vendor.firstName}</h2>
              <p className="text-xs text-gray-300">Service Vendor</p>
              <p className="text-gray-400 text-sm">{data.vendor.country}</p>
            </div>
          </div>
          <div className="py-4">
            <h1 className="text-xl font-bold">Send an Offer</h1>
            <form className="py-4" >
              <div className="py-4 space-y-2">
                <h3>Title</h3>
                <input
                  placeholder="A brief title of what you’re looking for"
                  className="w-full outline-none py-2.5 px-1.5 rounded-md border focus:ring-1 ring-amber-700"
                  value={offerDetails.title}
                  name="title"
                  onChange={handleInputChange}
                />
              </div>
              <div className="py-4 space-y-2">
                <h3>Description</h3>
                <TextareaAutosize
                  name="description"
                  value={offerDetails.description}
                  aria-label="empty textarea"
                  placeholder="Explain what the project is about?"
                  className="w-full border border-gray-200 bg-gray-100 px-1.5 py-2 rounded-md"
                  minRows={4}
                  onChange={handleInputChange}
                />
              </div>
              <div className="py-4 space-y-2">
                <h3>Skills</h3>
                <input
                  name="skills"
                  value={offerDetails.skills}
                  placeholder="BlockChain Developer"
                  className="w-full outline-none py-2.5 px-1.5 rounded-md border focus:ring-1 ring-amber-700"
                  onChange={handleInputChange}
                />
              </div>
              <div className="py-3 flex items-center justify-betweeen space-x-6 w-full">
                <div className="py-4 space-y-2">
                  <h3>Budget</h3>
                  <div className="flex items-center">
                    <div className="border p-2.5">$</div>
                    <input
                      name="budget"
                      placeholder="200"
                      value={offerDetails.budget}
                      className="w-1/2 outline-none py-2.5 px-1.5 border focus:ring-1 ring-amber-700"
                      onChange={handleInputChange}
                    />
                    <select className="py-2.5 border">
                      <option>per hr</option>
                      <option>One time</option>
                    </select>
                  </div>
                </div>
                <div className="py-4 space-y-2">
                  <h3>Duration</h3>
                  <div className="flex items-center">
                    <input
                      name="duration"
                      value={offerDetails.duration}
                      placeholder="10"
                      className="w-1/2 outline-none py-2.5 px-1.5 border focus:ring-1 ring-amber-700"
                      onChange={handleInputChange}
                    />
                    <select className="py-2.5 border">
                      <option>Days</option>
                      <option>Weeks</option>
                      <option>Months</option>
                    </select>
                  </div>
                </div>

                {/* <div className='py-4 space-y-2'>
                                        <h3>TimeLine</h3>
                                        <input  placeholder='10 days' className='w-full outline-none py-2.5 px-1.5 rounded-md border focus:ring-1 ring-amber-700' />
                                    </div> */}
              </div>
              <div className="py-4 space-y-2">
                <h3>Due Date</h3>
                <div className="flex items-center">
                  <input
                    name="dueDate"
                    type="date"
                    value={offerDetails.dueDate}
                    placeholder="10"
                    className="w-1/2 outline-none py-2.5 px-1.5 border focus:ring-1 ring-amber-700"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="py-4">
                
                  {loading ? <Oval stroke="#3c2b05" /> : <button
                  onClick={handleCreateOffer}
                  className="bg-brown-800 py-2 px-3 rounded-md text-white w-full"
                >Send Offer</button> }
                
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
