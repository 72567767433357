import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ErrorIcon from '@mui/icons-material/Error';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    minHeight: "70vh",
    borderRadius: 2,
    p: 6,
};

export default function SuccessModal({ open, handleClose, handleOpen, setOpen }) {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack spacing={2} className='w-full justify-center flex items-center'>
                        <div className='justify-center flex items-center'>
                            <div className='space-y-5 flex items-center justify-center flex-col'>
                                <img className='' src="/images/success.png" />
                                <h1 className='font-bold text-xl'>Classification Request Sent Successful</h1>
                                <div className='w-full bg-brown-200 p-3 flex justify-center items-center space-x-4'>
                                    <ErrorIcon className="text-xl text-brown-600" />
                                    <p>You will get a notification in your email when your product has been classified</p>
                                </div>
                            </div>
                        </div>
                    </Stack>
                    <div className='flex justify-center items-center p-4 mt-3'>
                        <Link to="/dashboard">
                            <p className='underline'>Go to Dashboard</p>
                        </Link>
                    </div>

                </Box>
            </Modal>
        </div>
    );
}
