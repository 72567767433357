import React, {useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Typical from 'react-typical'
const slide = [
    {
        image: 'images/image 20.png',
        heading: 'Thousands of business joining the AFCFTA one market',
        subHeading: 'Automobile, Agricultural, Fashion and every industry and business category comes together as one big African market',
        button:'Register as a vendor'
    },
    {
        image: 'images/image 27.png',
        heading: 'Boost sales and profit by connecting to the rest of the African market',
        subHeading: 'Leverage on a the digitized AFCFTA one Africa market to increase your business reach',
        button:'Register as a vendor'
    },
    {
        image: 'images/image 23.png',
        heading: 'Easy logistics process inline with custom procedure and guidelines',
        subHeading: 'Importing and Exporting of goods and services has been made easy and digitized for quicker transactions',
        button:'Register as a vendor'
    },

]


export const FirstSlide = ({ step, nextStep, prevStep }) => {
  

  const navigate = useNavigate();

  const gotoVendor = () => {
    navigate("/vendor");
  };



    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 items-center">
        <div className="col-span-1 text-center md:text-left md:px-10">
          <h3 className="text-2xl md:text-4xl  tracking-tight font-sans font-bold mb-3">
            <Typical
              steps={[
                "Thousands of business joining the AFCFTA one market!",
                200,
              ]}
              loop={Infinity}
              wrapper="h3"
            />
          </h3>
          <p className="md:text-2xl text-lg font-light">
            Automobile, Agricultural, Fashion and every industry and business
            category comes together as one big African market
          </p>
          <NavLink to="/vendor">
            <button className="px-10 py-2.5 w-full rounded bg-brown-600 my-4" onClick={gotoVendor}>
              Register as a vendor
              <span>
                <i class="fa fa-arrow-right ml-4"></i>
              </span>
            </button>
          </NavLink>
        </div>
        <div className="col-span-1">
          <div className="w-[350px] h-[250px] md:w-full md:h-full relative">
            <img
              src={"images/image 20.png"}
              className="rounded-lg object-cover"
              alt="first-slide"
            />
            <div className="text-center mt-3">
              <span>
                <button
                  style={{
                    height: "10px",
                    width: "10px",
                    border: "none",
                    cursor: "pointer",
                    borderRadius: "200%",
                    backgroundColor: `${step === 1 || 4 ? "#874F1B" : "white"}`,
                  }}
                  className="mr-2"
                  disabled={true}
                  onClick={nextStep}
                >
                  {/*  */}
                </button>

                <button
                  style={{
                    height: "10px",
                    width: "10px",
                    border: "none",
                    cursor: "pointer",
                    borderRadius: "200%",
                    backgroundColor: `${step === 2 ? "#874F1B" : "white"}`,
                  }}
                  className="mr-2"
                  onClick={nextStep}
                >
                  {/*  */}
                </button>
                <button
                  style={{
                    height: "10px",
                    width: "10px",
                    border: "none",
                    cursor: "pointer",
                    borderRadius: "200%",
                    backgroundColor: `${step === 3 ? "#874F1B" : "white"}`,
                  }}
                  className="mr-2"
                  onClick={nextStep}
                >
                  {/*  */}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
}
export const SecondSlide = ({ step, nextStep, prevStep }) => {
  
  
  const navigate = useNavigate();

  const gotoVendor = () => {
    navigate("/vendor");
  };

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 items-center ">
        <div className="col-span-1 text-center md:text-left md:px-10">
          <h3 className="text-2xl md:text-4xl  tracking-tight font-sans font-bold mb-3">
            <Typical
              steps={[
                "Boost sales and profit by connecting to the rest of the African market",
                200,
              ]}
              loop={Infinity}
              wrapper="h3"
            />
          </h3>
          <p className="md:text-2xl text-lg font-light">
            Leverage on a the digitized AFCFTA one Africa market to increase
            your business reach
          </p>
          <NavLink to="/vendor">
            <button
              className="px-10 py-2.5 w-full rounded bg-brown-600 my-4"
              onClick={gotoVendor}
            >
              Register as a vendor
              <span>
                <i class="fa fa-arrow-right ml-4"></i>
              </span>
            </button>
          </NavLink>
        </div>
        <div className="col-span-1">
          <div className="w-[350px] h-[250px] md:w-full md:h-full relative">
            <img
              src={"images/image 27.png"}
              className="rounded-lg object-cover"
              alt="second-slide"
            />
            <div className="text-center mt-3">
              <span>
                <button
                  style={{
                    height: "10px",
                    width: "10px",
                    border: "none",
                    cursor: "pointer",
                    borderRadius: "200%",
                    backgroundColor: `${step === 1 ? "#874F1B" : "white"}`,
                  }}
                  className="mr-2"
                  onClick={prevStep}
                >
                  {""}
                </button>

                <button
                  style={{
                    height: "10px",
                    width: "10px",
                    border: "none",
                    cursor: "pointer",
                    borderRadius: "200%",
                    backgroundColor: `${step === 2 ? "#874F1B" : "white"}`,
                  }}
                  disabled={true}
                  className="mr-2"
                  onClick={nextStep}
                >
                  {""}
                </button>

                <button
                  style={{
                    height: "10px",
                    width: "10px",
                    border: "none",
                    cursor: "pointer",
                    borderRadius: "200%",
                    backgroundColor: `${step === 3 ? "#874F1B" : "white"}`,
                  }}
                  className="mr-2"
                  onClick={nextStep}
                >
                  {""}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
}
export const ThirdSlide = ({ step, nextStep, prevStep }) => {
  

  
  const navigate = useNavigate();

  const gotoVendor = () => {
    navigate("/vendor");
  };

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 items-center">
        <div className="col-span-1 text-center md:text-left md:px-10">
          <h3 className="text-2xl md:text-4xl  tracking-tight font-sans font-bold mb-3">
            <Typical
              steps={[
                "Easy logistics process inline with custom procedure and guidelines",
                200,
              ]}
              loop={Infinity}
              wrapper="h3"
            />
          </h3>
          <p className="md:text-2xl text-lg font-light">
            Importing and Exporting of goods and services has been made easy and
            digitized for quicker transactions
          </p>
          <NavLink to="/vendor">
            <button
              className="px-10 py-2.5 w-full rounded bg-brown-600 my-4"
              onClick={gotoVendor}
            >
              Register as a vendor
              <span>
                <i class="fa fa-arrow-right ml-4"></i>
              </span>
            </button>
          </NavLink>
        </div>
        <div className="col-span-1">
          <div className="w-[350px] h-[250px] md:w-full md:h-full relative">
            <img
              src={"images/image 23.png"}
              className="rounded-lg object-cover"
              alt="third-slide"
            />
            <div className="text-center mt-3">
              <span>
                <button
                  style={{
                    height: "10px",
                    width: "10px",
                    border: "none",
                    cursor: "pointer",
                    borderRadius: "200%",
                    backgroundColor: `${step === 1 ? "#874F1B" : "white"}`,
                  }}
                  className="mr-2"
                  onClick={prevStep}
                >
                  {""}
                </button>
                <button
                  style={{
                    height: "10px",
                    width: "10px",
                    border: "none",
                    cursor: "pointer",
                    borderRadius: "200%",
                    backgroundColor: `${step === 2 ? "#874F1B" : "white"}`,
                  }}
                  className="mr-2"
                  onClick={prevStep}
                >
                  {""}
                </button>

                <button
                  style={{
                    height: "10px",
                    width: "10px",
                    border: "none",
                    cursor: "pointer",
                    borderRadius: "200%",
                    backgroundColor: `${step === 3 ? "#874F1B" : "white"}`,
                  }}
                  className="mr-2"
                  disabled={true}
                  onClick={nextStep}
                >
                  {""}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
}