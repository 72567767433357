







import React from "react";
import { Avatar, Divider, Grid, IconButton, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Email, WhatsApp } from "@material-ui/icons";

export default function VendorInfo({ vendorInfo }) {

  console.log(vendorInfo)

  return (
    <div>
      <Stack spacing={2}>
        <div className="bg-white shadow-sm py-4 rounded-lg">
          <div className="w-[95%] mx-auto py-2">
            <Stack spacing={3}>
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-2">
                  <h2 className="font-light text-sm">Vendor's Information</h2>
                  <IconButton>
                    {vendorInfo.verified && <VerifiedIcon className="text-blue-500" />}
                  </IconButton>
                </div>

                <IconButton>
                  {/* <EditIcon className="text-blue-500" /> */}
                </IconButton>
              </div>
              <div className="flex space-x-3">
                <Avatar
                  sx={{
                    width: 45,
                    height: 45,
                    backgroundColor: "#45361e",
                  }}
                >
                  {vendorInfo.profilePhoto ? vendorInfo.profilePhoto : "P"}
                </Avatar>
                <div className="space-y-1">
                  {/* <p className="text-sm font-medium">
                    Logo-Brand Identity | Brand Designer
                  </p> */}
                  <h2 className="font-bold">{vendorInfo.firstName}</h2>
                  <p className="text-sm text-slate-500">
                    {vendorInfo.state}
                  </p>
                </div>
              </div>
              {/* <Divider /> */}

              {vendorInfo.description === "" ? <p className="text-sm">{vendorInfo.description}</p> : null}

              <hr />
              <div className="flex items-center">
                <button className="py-3 w-full border border-brown-200 rounded-sm hover:bg-amber-800 hover:text-white">
                  <WhatsApp />
                </button>
                <button className="py-3 w-full border border-brown-200 rounded-sm hover:bg-amber-800 hover:text-white">
                  <Email />
                </button>
              </div>
            </Stack>
          </div>
        </div>

      </Stack>
    </div>
  );
}