import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';

function createData(name, calories, fat, carbs, protein, amount, creditcard) {
  return { name, calories, fat, carbs, protein, amount, creditcard };
}

const rows = [
  createData('1', '#159', "Emeke", "24/10/2020", 'Declined', "$3500", "paid"),
  createData('2', '#237', "Frank", "24/10/2021", "Declined", "$3500", "paid"),
  createData('3', '#262', "John", "24/10/2021", "Declined", "$3500", "paid"),
  createData('4', '#305', "Jude", "24/10/2021", "Declined", "$3500", "paid"),
  createData('5', '#356', "Joshua", "24/10/2022", "Declined", "$3500", "paid"),
];

export default function BasicTable() {
  const token = JSON.parse(localStorage.getItem("TOKEN"))
  const [transactions, setTransactions] = useState()
  const [loading, setLoading] = useState(false)
  const fetchTransactions = async () => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    }
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}transactions`, { headers: headers })
    console.log(response.data.data)
    setTransactions(response.data.data)
  }
  useEffect(() => {
    fetchTransactions()
  }, [])
  console.log(transactions)
  return (
    <>
      {transactions?.length !== 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>N / L</TableCell>
                <TableCell align="right">Order Id</TableCell>
                <TableCell align="center">Customer Name</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Credit Card</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.calories}</TableCell>
                  <TableCell align="center">{row.fat}</TableCell>
                  <TableCell align="center">{row.carbs}</TableCell>
                  <TableCell align="center">{row.protein}</TableCell>
                  <TableCell align="center">{row.amount}</TableCell>
                  <TableCell align="center">{row.creditcard}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {transactions?.length === 0 && (
        <div className="w-full flex items-center justify-center h-[40vh]">
          <h2 className="text-center text-2xl font-bolder">No Transactions</h2>
        </div>
      )}
    </>

  );
}
