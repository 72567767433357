


import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

export default function TransactionIndex() {
  

    
  
    return (
    <div>
        <Outlet />
    </div>
  )
}
