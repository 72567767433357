import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchTerm: "",
  searchResult: null,
  isSearching: false
};

export const searchReducer = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload.term;
    },
    setSearchResult: (state, action) => {
      state.searchResult = action.payload.result;
    },
    setIsSearching: (state, action) => {
        state.isSearching = action.payload
    } 
  },
});

export const { setSearchTerm, setSearchResult, setIsSearching } = searchReducer.actions;
export default searchReducer.reducer;
