



import { Avatar, Grid, IconButton } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import { useGetMoreServiceFromSameVendorQuery } from '../../../../redux/apiSlice';
import StarIcon from "@mui/icons-material/Star";

export default function MoreServiceFromVendor({ vendorId }) {


    const { data, isLoading, error } = useGetMoreServiceFromSameVendorQuery(vendorId)


    return (
        <div>
            {data && (
                <div className="flex space-x-7 overflow-x-scroll" container spacing={5}>
                    {data && data.data.map((service) => {
                        return (
                            <Grid
                                key={service.id}
                                item
                                xs={6}
                                md={3}
                                lg={3}
                                className="space-y-2"
                            >
                                <Link to={`/market/service/${service.id}`}>
                                    <div className="hover:border-brown-500 hover:border rounded-lg pb-2.5 w-[220px]">
                                        <div className="rounded-lg flex w-full">
                                            <img
                                                src={service.productImage}
                                                className="w-full h-36 rounded-t-lg cursor-pointer object-cover"
                                            />
                                        </div>
                                        <div className="flex flex-col w-[90%] mx-auto py-1">
                                            <div className="py-3 space-y-3">
                                                <div className="flex items-center space-x-2">
                                                    <Avatar sx={{ width: 35, height: 35 }}>P</Avatar>
                                                    <p className="text-sm">{service.vendor.firstName}</p>
                                                </div>
                                                <p className="text-black font-normal capitalize">
                                                    {service.category.name}
                                                </p>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <p className="text-red-800 font-bold">
                                                    ${service.price}
                                                </p>
                                                {service.ratings !== 0 && (
                                                    <div className="flex items-center space-x-1">
                                                        <IconButton>
                                                            <StarIcon className="text-yellow-500" />
                                                        </IconButton>

                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Grid>
                        );
                    })}
                </div>
            )}
        </div>
    )
}
