import React, { useState } from "react";
import Header from "../../../utils/Header";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Divider, IconButton } from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { openSnackBar } from "../../../../redux/snackSlice";

const EmailConfirmation = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const location = useLocation();
  const dispatch = useDispatch();
  // console.log(location)

  const data = {
    email: location.state.email,
  };

  async function handlerResendEmail() {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/password/resetResend`,
        data
      );
      console.log(res);
      setLoading(false);
      setMessage("Confirmation Email has been resent");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <>
      <div className="relative overflow-hidden h-screen">
        <Header />
        <div className="flex justify-center items-center mt-40 mx-10">
          <div className="flex justify-center flex-col items-center w-1/2 p-10 drop-shadow-md bg-white rounded-lg">
            <div className="flex justify-center items-center w-20 h-20">
            <img src="/success.png" alt="" />
            </div>
            <div className="my-2" />
            {/* <IconButton>
              <TaskAltIcon
                fontSize="large"
                className="text-brown-900 text-xl"
              />
            </IconButton> */}
            <h1 className="md:text-3xl text-2xl font-bold">
              Welcome {location.state.name || null}
            </h1>
            <h2 className="text-lg font-bold my-3">Check your email</h2>
            <div className="my-1" />
            <p className="text-center text-sm font-light">
              A confirmation link has successfully been sent to your inbox,
              check your inbox including spam folder to continue
            </p>
            <div className="mt-20 hidden">
              <button
                className="w-full py-4 px-5 bg-red-650 rounded-lg text-white bg-[#26220a] flex justify-center mt-5"
                onClick={handlerResendEmail}
              >
                Resend Confirmation Email
              </button>
            </div>

            <div className="mt-20">
              {message !== "" ? (
                <div className="mt-10 flex justify-center items-center border border-black bg-green-100 text-green-800 h-10">
                  {message}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0">
          <img src="/images/properties.png" />
        </div>
      </div>
    </>
  );
};

export default EmailConfirmation;
