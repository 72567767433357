import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CompleteProfile from './CompleteProfile';
import dashboardVector from '../../../../../src/assets/svg/dashboard-vector.svg'
import AccountSummary from './dashboard/returning_user/AccountSummary';
import IncomingOrder from './dashboard/returning_user/IncomingOrder';

import { useSelector } from 'react-redux';
import axios from 'axios'

function Content(props) {
  const [modal, setModal] = useState(false);
  const [order, setOrder] = useState('')
  const [products, setProducts] = useState()
  const [results, setResults] = useState()
  
  const toggleModal = () => {
    setModal(!modal)
  }
  const token = useSelector(state => state.auth.userToken)
  
  
  const { userInfo } = useSelector((state) => state.auth)
  
  
  return (
    <div className="font-sans mt-20">
      <div className="grid grid-cols-1 mt-5 px-10">
        <div className="block">
          <div
            className="h-[109.2px] w-full bg-brown-900 relative flex items-center rounded-xl"
            style={{
              backgroundImage: `url(${dashboardVector})`,
            }}
          >
            <div className="block m-5 reduce-margin">
              <div className="col-9">
                <h3 className="text-white font-bold text-xl">
                  Welcome, {`${userInfo?.firstName}`}
                </h3>
                <p className="text-slate-400 text-[14px]">Vendor</p>
              </div>
            </div>
          </div>
          {/* Dashboard content */}
          <div className="w-full mt-5">
            {
              <>
                <AccountSummary order={order} products={products} results={results} />
                <IncomingOrder />
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;


{/* <div className="row mt-5">
          <div className="col-12 col-md-5 big small-card">
            <Link to="#" onClick={toggleModal}>
              <div className="card  text-white bg-success">
                <div className="row">
                  <div className="col-10">
                    <b>Complete personal details </b>
                  </div>
                  <div className="col-2 text-right">
                    <span>
                      <i className="fa fa-check circle text-success"></i>
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-5 small small-card">
            <Link to="#" onClick={toggleModal}>
              <div className="card  text-white bg-success">
                <div className="row">
                  <div className="col-10">
                    <b>Complete personal details </b>
                  </div>
                  <div className="col-2 text-right">
                    <span>
                      <i className="fa fa-check circle text-success"></i>
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 col-md-5 small-card">
            <div className="card pl-3">Submit Official documents</div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 col-md-5 small-card">
            <div className="card pl-3">Update Business Information</div>
          </div>
        </div>
        <div className="row">
          <button
            className="btn btn-md ml-4 btn-save text-white"
            disabled={true}
          >
            Save Changes
          </button>
        </div>
        <CompleteProfile modal={modal} toggleModal={toggleModal} /> */}