import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextareaAutosize, TextField, Backdrop, Fade, IconButton, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { countryOfVendor, remaining } from '../../../../../../redux/classifySlice';
import CancelIcon from '@mui/icons-material/Cancel';
import Ingredient from './Ingredients';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import SuccessModal from './Success';
import { useClassifyManufacturerMutation } from '../../../../../../redux/apiSlice';
import { openSnackBar } from '../../../../../../redux/snackSlice';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  maxHeight: "90vh",
  overflowY: "scroll",
  borderRadius: 2,
  p: 6,
}



const Manufacturer = ({ open, setOpen, handleOpen, handleClose, countries }) => {
  const token = JSON.parse(localStorage.getItem("TOKEN"))
  const [origin, setOrigin] = useState('')
  const [name, setName] = React.useState()
  const [countrySourced, setCountry] = React.useState()
  const [percent, setPercent] = React.useState()
  const [opener, setOpener] = React.useState(false);
  
  
  const dispatch = useDispatch()
  
  const handleOpener = () => setOpener(true);
  const handleCloser = () => {
    setOpener(false)
    // setArray([''])

  }

  const notEnough = (data) => {
    let text = data
    const result = text.includes('100%')
    return result
  }

  
  // const [newState, setNewState] = useState(state)
  const [ingredient, setIngredient] = useState()
  const [array, setArray] = useState([])
  const [loading, setLoading] = useState(false)
  const [open5, setOpen5] = React.useState(false);
  const handleOpen5 = () => setOpen5(true);
  const handleClose5 = () => setOpen5(false);
  

  const ingredients = useSelector(state => state.classify)
  console.log(ingredients);
  

  // Data
  const [classifyManufacturer, {isLoading}] = useClassifyManufacturerMutation()


  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    console.log("processing......")
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    }

    const { constituents, countryOfVendor, natureOfVendor } = ingredients
    console.log({ constituents, countryOfVendor })

    try {
      classifyManufacturer({
        id: ingredients.id,
        data: {
          constituents: constituents,
          countryOfVendor: countryOfVendor
        }
      }).unwrap()
      .then(data =>{
        handleClose()
        dispatch(openSnackBar({message: "Classification request sent!"}))
      })
    } catch (err) {
      console.log(err);
    }

  }





  // console.log(state)

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll" }}
      >
        <Box sx={style}>
          <div className="w-[95%] mx-auto">
            <form onSubmit={handleSubmit}>
              <Stack direction="column" spacing={3}>
                <div className="w-2/5">
                  <div className="space-y-3">
                    <p>Your Country Of Origin</p>
                    {countries && (
                      <FormControl fullWidth>
                        <InputLabel id="country">Country</InputLabel>
                        <Select
                          labelId="country"
                          id="country"
                          value={origin}
                          label="Age"
                          required
                          onChange={(e) => {
                            setOrigin(e.target.value)
                            dispatch(countryOfVendor({country: e.target.value}))
                          }}
                        >
                          {countries.map((country) => (
                            <MenuItem value={country?.fullName}>{country?.fullName}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                  </div>
                </div>
                <div className="mt-3">
                  <div className="py-2">
                    <h2 className="text-xl font-bold">Add Raw Materials Information</h2>
                  </div>
                  <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                  {ingredients.constituents && ingredients.constituents.length !== 0 && (
                    <div>
                      {ingredients.constituents.map((constituents) => (
                        <div className='py-3'>
                          <div className="flex justify-betweeen items-center w-full">
                            <div className="space-y-3 flex-1">
                              <p>Name Of Ingredients</p>
                              <TextField className="w-4/5" id="outlined-basic" label="Ingredients" value={constituents.name} />
                            </div>
                            <div className="space-y-3 flex-1">
                              <p>Country Sourced</p>
                              <TextField className="w-4/5" id="outlined-basic" label="Country Sourced" variant="outlined" value={constituents.countrySourced} />
                            </div>
                            <div className="space-y-3 flex-1 text-center">
                              <p>% In Ingredient</p>
                              <TextField className="w-4/5" id="outlined-basic" label="Percentage" type="number" variant="outlined" value={constituents.percent} />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                </div>
                <div className="w-full  flex items-center">
                  <div className="py-2 flex items-center hover:bg-gray-50 cursor-pointer" onClick={handleOpener}>
                    <IconButton>
                      <AddCircleIcon className="text-[#5A3924]" />
                    </IconButton>
                    <p>Add Ingredients</p>
                  </div>
                </div>
                <div className='py-4'>
                  <button className={loading ? 'bg-[#5A3924] w-full text-white p-2 mt-6' : 'bg-[#5A3924] w-full text-white p-2 mt-6'}>
                    {isLoading ? (
                      <div className='flex items-center justify-center'>
                        <CircularProgress />
                      </div>
                    ) : (
                      <p>Proceed</p>
                    )}
                  </button>
                </div>
              </Stack>
            </form>
          </div>
        </Box>
      </Modal>
      <SuccessModal open={open5} handleOpen={handleOpen5} handleClose={handleClose5} setOpen5={setOpen5} />
      <Ingredient opener={opener} setOpener={setOpener} handleCloser={handleCloser} handleOpener={handleOpener} countries={countries} />
    </>
  )
}

export default Manufacturer