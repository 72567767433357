import { Stack } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { logout } from '../../../../../redux/AuthSlice'

const Sidebar = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return (
        <>
            <div className='bg-white mx-5'>
                <div className='py-4 px-5 justify-between'>
                    <Stack direction="column" spacing={1}>
                        <Link to="/account/">
                            <div className='w-full py-2 px-2 cursor-pointer hover:bg-gray-200 transition ease-in-out duration-200'>
                                <h2 className="text-sm">My Profile</h2>
                            </div>
                        </Link>
                        <Link to="order">
                            <div className='w-full py-2 px-2 cursor-pointer  hover:bg-gray-200 transition ease-in-out duration-200'>
                                <h2 className="text-sm">Orders</h2>
                            </div>
                        </Link>
                        <Link to="offer">
                            <div className='w-full py-2 px-2 cursor-pointer  hover:bg-gray-200 transition ease-in-out duration-200'>
                                <h2 className="text-sm">Offers</h2>
                            </div>
                        </Link>
                        <Link to="transaction">
                            <div className='w-full py-2 px-2 cursor-pointer  hover:bg-gray-200 transition ease-in-out duration-200'>
                                <h2 className="text-sm">Transactions</h2>
                            </div>
                        </Link>
                        <Link to="address">
                            <div className='w-full py-2 px-2 cursor-pointer  hover:bg-gray-200 transition ease-in-out duration-200'>
                                <h2 className="text-sm">Address Book</h2>
                            </div>
                        </Link>

                        {/* <div className='w-full py-2 px-2 cursor-pointer  hover:bg-gray-200 transition ease-in-out duration-200'>
                            <h2>Notifications</h2>
                        </div>
                        <div className='w-full py-2 px-2 cursor-pointer  hover:bg-gray-200 transition ease-in-out duration-200'>
                            <h2>Preferences</h2>
                        </div> */}
                    </Stack>
                    <button onClick={() => {
                        dispatch(logout())
                        navigate('/')
                        localStorage.removeItem('TOKEN')
                    }} 
                    className="bg-red-500 text-white mx-2 mt-5 px-3 py-1 rounded" 
                    >
                        <h2 className='font-bold'>Logout</h2>
                    </button>
                </div>
            </div>
        </>
    )
}

export default Sidebar