import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DisputeModal from './DisputeModal';

function createData(OrderId, Customer, Amount, Type, DisputeType) {
    return { OrderId, Customer, Amount, Type, DisputeType };
}

const rows = [
    createData('#133', "Charles", 6.0, "Payment", "27/03/20"),
    createData('124', "Charles", 9.0, "Payment", "27/03/20"),
    createData('312', "Charles", 16.0, "Payment", "27/03/20"),
    createData('#415', "Charles", 3.7, "Payment", "27/03/20"),
    createData('#445', "Charles", 16.0, "Payment", "27/03/20"),
];

export default function DisputeTable() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className='bg-gray-200'>
                        <TableRow>
                            <TableCell>OrderId</TableCell>
                            <TableCell align="center">Customer</TableCell>
                            <TableCell align="center"> Amount</TableCell>
                            <TableCell align="center"> Type</TableCell>
                            <TableCell align="center">DisputeType</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                onClick={() => { handleOpen() }}
                                className="cursor-pointer"
                            >
                                <TableCell component="th" scope="row">
                                    {row.OrderId}
                                </TableCell>
                                <TableCell align="center">{row.Customer}</TableCell>
                                <TableCell align="center">${row.Amount}</TableCell>
                                <TableCell align="center">{row.Type}</TableCell>
                                <TableCell align="center">{row.DisputeType}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <DisputeModal open={open} setOpen={setOpen} handleClose={handleClose} handleOpen={handleOpen} />
        </>
    );
}
