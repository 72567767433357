import React, { useRef, useState } from 'react'

import { Box, Button, Grid, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Header from '../../../utils/Header'



const UserType = () => {
    const [active, setActive] = useState(false)
    const [vendor, setVendor] = useState(false)
    const [user, setUser] = useState(false)
    const [link, setLink] = useState('')
    const navigate = useNavigate()


    const createVendorAccount = () => {
        setVendor(true)
        setLink('signup-vendor')
        console.log(link)
        setUser(false)
        setActive(true)
    }
    const createBuyerAccount = () => {
        setVendor(false)
        setUser(true)
        console.log(link)
        setActive(true)
        setLink('signup-buyer')
    }

    const reroute = () => {
        navigate(`/${link}`)
    }

    return (
        <>
            <Header />
            <div className='mx-auto py-20 min-h-[85vh]'>
                <div className='flex flex-col items-center space-y-2 text-center mx-10'>
                    <div>
                        <h1 className='md:text-4xl text-2xl font-bold'>How do you want to use AfCFTA platform?</h1>
                    </div>
                    <p className='text-gray-400'>You can buy items from other vendors, also you can put your products for sale</p>
                </div>
                <div className='flex justify-center items-center'>
                <div className='flex flex-col items-center md:w-3/5 mx-10'>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mt-10'>
                        <div>
                            <Box p={5} onClick={createBuyerAccount} className={`rounded-md  h-[300px] flex items-center ${user ? "cursor-pointer transition-all  duration-150 ease-in-out text-white hover:border-brown-900 border  bg-brown-900" : "cursor-pointer hover:border-brown-900 border bg-white"}`}>
                                <div className='text-center space-y-3'>
                                    <h1 className='text-2xl font-bold'>I want to Buy</h1>
                                    <p className='text-gray-300'>By registering as a buyer, you are required to signup before you add item to cart</p>
                                </div>
                            </Box>
                        </div>
                        
                            <Box borderRadius={2} p={5} onClick={createVendorAccount} className={ `h-[300px] flex items-center ${vendor ? "cursor-pointer transition-all rounded-md duration-150 ease-in-out text-white hover:border-brown-900 border bg-brown-900" : "cursor-pointer hover:border-brown-900 border bg-white"}`}>
                                <div className='text-center  space-y-3'>
                                    <h1 className='text-2xl font-bold'>I want to Sell</h1>
                                    <p className='text-gray-300'>By registering as a buyer, you are required to signup before you add item to cart</p>
                                </div>
                            </Box>
                        
                    </div>
                    <div className='w-full py-3 mt-5 text-center'>
                        <button onClick={reroute} className={active ? 'w-full md:w-[250px] text-white py-3.5 bg-brown-900 rounded-md cursor-pointer' : 'w-[250px] md:w-full text-white py-3.5 bg-brown-200 rounded-md cursor-pointer'} disabled={!active} variant='contained'>Continue</button>
                    </div>
                </div>

                </div>
            </div>
        </>
    )
}

export default UserType