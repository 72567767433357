import { Dialog, IconButton, Modal } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DeleteConfirmation from "../../../../../component/vendor/components/DeleteConfirmation";
import { useDeleteVendorProductMutation } from "../../../../../redux/apiSlice";
import { openSnackBar } from "../../../../../redux/snackSlice";
import EditProductModal from "./EditProductModal";

const SingleGoods = () => {
  const dispatch = useDispatch();
  const route = useParams();
  const navigate = useNavigate();

  const { data } = useSelector((state) => state.goods);
  const [images, setImages] = useState(data.productImage);
  const [deleteModal, setDeleteModal] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);

  const [deleteProduct, { isLoading: deleteProductLoading }] =
    useDeleteVendorProductMutation();

  function handleCloseDelete() {
    setDeleteModal(false);
  }

  function handleOpenDelete() {
    setDeleteModal(true);
  }

  function handleDeleteProduct() {
    try {
      deleteProduct(route.id)
        .unwrap()
        .then((data) => {
          handleCloseDelete();
          dispatch(openSnackBar({ message: "Product Deleted!" }));
          navigate(-1);
        });
    } catch (err) {
      console.log(err);
    }
  }

  function handleEditProduct() {
    console.log("");
    setEnableEdit(true);
  }

  return (
    <>
      <div className="min-h-screen border-t border-l border-brown-500">
        <div className="px-5 md:py-10 py-4">
          <div className="py-3">
            <div className="flex justify-between items-center">
              <Link to="/dashboard/shop">
                <div className="flex items-center space-x-3">
                  <IconButton>
                    <ArrowBackIcon />
                  </IconButton>
                  <h2 className="text-blue-400">Go back</h2>
                </div>
              </Link>

              <div className="flex space-x-3 items-center">
                <button
                  className="py-2 border bg-white border-brown-600 rounded-md px-3"
                  onClick={handleEditProduct}
                >
                  Edit Product
                </button>
                <button
                  className="py-2 border bg-red-600 text-white rounded-md px-3"
                  onClick={handleOpenDelete}
                >
                  Delete Product
                </button>
              </div>
            </div>
          </div>
          <div className="px-4">
            <div className="py-4">
              <h1 className="text-2xl font-bold">{data.name}</h1>
              <div className="py-4">
                <img src={images} className="h-[300px]" />
                <div className="py-6">
                  <div className="flex items-center space-x-5">
                    {data.screenshots.map((item) => (
                      <div
                        className="cursor-pointer bg-gray-200 p-2"
                        onClick={() => setImages(item.url)}
                      >
                        {console.log(item.url)}
                        <img
                          src={item.url}
                          className="w-20 h-24 object-cover"
                        />
                      </div>
                    ))}
                  </div>
                  <div>
                    <hr />
                  </div>
                </div>
                <div className="py-4">
                  <h1 className="font-bold">Product Description</h1>
                  <p className="py-4">{data.description}</p>
                  <div className="py-2">
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={deleteModal} onClose={handleCloseDelete}>
        <DeleteConfirmation
          message="Product"
          loading={deleteProductLoading}
          deleteFunction={handleDeleteProduct}
        />
      </Dialog>
      <Modal
        open={enableEdit}
        onClose={(e) => setEnableEdit(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditProductModal data={data} setEnableEdit={setEnableEdit} />
      </Modal>
      {/* <Dialog open={enableEdit} onclose={(e) => setEnableEdit(false)}>
      </Dialog> */}
    </>
  );
};
export default SingleGoods;
