import React, { useState } from "react";
import ServiceCategorySection from "./CategorySection";
import Footer from "../../utils/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useRef } from "react";
import StarIcon from "@mui/icons-material/Star";
import {
  useGetServiceByCategoryIdQuery,
} from "../../../redux/apiSlice";
import HeadMarket from "../child/HeadMarket";
import DummySkeleton from "../goods/child/DummySkeleton";
import { Avatar, Grid, IconButton } from "@mui/material";
import { ArrowBack, HourglassEmpty } from "@material-ui/icons";

const SingleServiceCategory = () => {
  const route = useParams();
  const mainRef = useRef(null);
  const [value, setValue] = React.useState(0);
  const [current, setCurrent] = useState();

  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setValue((prev) => (newValue === prev ? prev : newValue));
  };

  useEffect(() => {
    // mainRef.current.scrollIntoView();
  }, []);

  const { data, isLoading, error } = useGetServiceByCategoryIdQuery(route.id);
  // const { data, isLoading, error } = useGetAllServicesQuery();

  // Go back function
  function goBack() {
    navigate(-1)
  }

  console.log("services", data && data);

  return (
    <>
      {/* <MarketHeader /> */}
      <HeadMarket />
      <div className="p-20">
        <button className="flex items-center space-x-5" onClick={goBack}>
          <ArrowBack /> <p>Go Back</p>
        </button>
      
      {!data ? (
        <DummySkeleton />
      ) : (
        <div>
          {data && data.data.length === 0 ? (
            <div className="h-[50vh] flex justify-center items-center">
              <div className="flex flex-col items-center">
                <HourglassEmpty color="#efefef" style={{fontSize: 70, color: "#DFE4ED" }} />
              <h1 className="text-lg mt-5">No service in this category!</h1>
              </div>
            </div>
          ) : (
            <div
              className="grid grid-cols-2 md:grid-cols-4 gap-5"
              container
              spacing={5}
            >
              {data &&
                data.data.map((service) => {
                  return (
                    <Grid
                      key={service.id}
                      item
                      xs={6}
                      md={3}
                      lg={3}
                      className="space-y-2"
                    >
                      <Link to={`/market/service/${service.id}`}>
                        <div className="hover:border-brown-400 hover:drop-shadow rounded-lg pb-1.5">
                          <div className="rounded">
                            <img
                              src={service.productImage}
                              className="w-full h-36 rounded-t cursor-pointer object-cover"
                            />
                          </div>
                          <div className="flex flex-col w-[90%] mx-auto py-1">
                            <div className="py-1 space-y-3">
                              <div className="flex items-center space-x-2">
                                <Avatar sx={{ width: 35, height: 35 }}>
                                  P
                                </Avatar>
                                <p className="text-sm">
                                  {service.vendor.firstName}
                                </p>
                              </div>
                              <p className="text-black font-normal capitalize">
                                {service.category.name}
                              </p>
                            </div>
                            <div className="flex justify-between items-center">
                              <p className="text-red-800 font-bold">
                                ${service.price}
                              </p>
                              {service.ratings !== 0 && (
                                <div className="flex items-center space-x-1">
                                  <IconButton>
                                    <StarIcon className="text-yellow-500" />
                                  </IconButton>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </Grid>
                  );
                })}
            </div>
          )}
        </div>
      )}
      </div>
      {/* <div className="py-4">
        {
          <ServiceCategorySection
            data={data}
            link="/market/service/single-item"
          />
        }
      </div> */}

      <Footer />
    </>
  );
};

export default SingleServiceCategory;

const dummySubCategory = [
  "Product Design",
  "Graphic Design",
  "Iconography",
  "Video Editing",
  "Illustration",
];

{
  /* <>
        <div className="block">
          <div className="flex justify-between items-center">
            <h1 className="font-bold text-2xl my-5 capitalize">Category</h1>
          </div>
          {!data ? (
            <DummySkeleton />
          ) : (
            <div
              className="grid grid-cols-2 md:grid-cols-4 gap-5"
              container
              spacing={5}
            >
              {data &&
                data.data.map((service) => {
                  return (
                    <Grid
                      key={service.id}
                      item
                      xs={6}
                      md={3}
                      lg={3}
                      className="space-y-2"
                    >
                      <Link to={`/market/service/${service.id}`}>
                        <div className="hover:border-brown-400 hover:drop-shadow rounded-lg pb-1.5">
                          <div className="rounded">
                            <img
                              src={service.productImage}
                              className="w-full h-36 rounded-t cursor-pointer object-cover"
                            />
                          </div>
                          <div className="flex flex-col w-[90%] mx-auto py-1">
                            <div className="py-1 space-y-3">
                              <div className="flex items-center space-x-2">
                                <Avatar sx={{ width: 35, height: 35 }}>
                                  P
                                </Avatar>
                                <p className="text-sm">
                                  {service.vendor.firstName}
                                </p>
                              </div>
                              <p className="text-black font-normal capitalize">
                                {service.category.name}
                              </p>
                            </div>
                            <div className="flex justify-between items-center">
                              <p className="text-red-800 font-bold">
                                ${service.price}
                              </p>
                              {service.ratings !== 0 && (
                                <div className="flex items-center space-x-1">
                                  <IconButton>
                                    <StarIcon className="text-yellow-500" />
                                  </IconButton>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </Grid>
                  );
                })}
            </div>
          )}
        </div>
      </> */
}
