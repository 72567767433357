import React, { useEffect } from 'react'
import { useState } from 'react'
import { useGetAllCustomerOrderQuery } from '../../../../redux/apiSlice';
import { Link, Outlet } from 'react-router-dom';

export default function OrderHome() {

  const token = JSON.parse(localStorage.getItem("TOKEN"))
  const [order, setOrder] = useState()
  const [search, setSearch] = useState('');

  // const fetchOrders = async () => {
  //   const headers = {
  //     'Content-Type': 'application/json',
  //     'Authorization': `${token}`
  //   }
  // }
  
  const {data, isLoading, error} = useGetAllCustomerOrderQuery()
    



  useEffect(() => {
    // fetchOrders()
  }, [])
  const handleChange = e => {
    e.preventDefault()
    console.log(e)
    setSearch(e.target.value);
  };
  
  const filteredOrders = order?.filter(x =>
    x?.orderItems[0]?.product?.name?.toLowerCase().includes(search.toLowerCase())
  );


  return (
    <div className='w-full'>
         <Outlet />
    </div>
  )
  }