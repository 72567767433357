


import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { closeModal } from '../../../redux/serviceSlice'

export default function () {

    const navigate = useNavigate()
    const dispatch = useDispatch()


    const routeUser = (link) => {
        navigate(`/${link}`)
    }

    useEffect(() => {
      return () => {
       dispatch(closeModal())
      }
    }, [])
    

  return (
    <div className='p-10 flex justify-center items-center'>
        <div className="block space-y-2 sticky mt-10 w-[350px]">
            <div className='flex flex-col items-center'>
                <img src='' className='' />
                
                <h1 className='text-center font-light text-xl mb-3 leading-tight '>Log into your account to add services to your favourite list or create offers</h1>
            </div>
                <button className="py-4 w-full border rounded-md border-brown-900" onClick={() => routeUser("login")}>
                  Login
                </button>
                <button className="py-4 w-full text-white rounded-md bg-brown-900" onClick={() => routeUser("select-user")}>
                  Create account
                </button>
              </div>
    </div>
  )
}
