import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../../redux/AuthSlice";
import { Navbar } from "react-bootstrap";
import { IoMdLogOut } from "react-icons/io";

function Sidebar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [active, setActive] = useState(0);
  // const {dispatch,state} = useContext(VendorContext)

  // Click action
  const handleClickMenu = (id, link) => {
    // e.preventDefault()
    setActive(id);
    navigate(`/dashboard/${link}`);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className=""> 
      <div className="flex flex-col justify-between h-screen bg-white">
        

        
          {/* Project logo */}
          <div className="block">
          <div className="h-20 flex items-center justify-center">
            <Navbar.Brand href="/">
              <img
                src={"/images/logo.png"}
                height="50px"
                width="50px"
                alt="AFCFTA"
              />
            </Navbar.Brand>
          </div>
          <div className="m-3.5">
            <div className="flex flex-col justify-between space-y-3 w-full">
              {sideMenu.map((menu, i) => (
                <button
                  className={`px-3 py-2 rounded-md flex items-center space-x-5 ${
                    active === i
                      ? "text-brown-900 bg-brown-100 font-bold"
                      : "text-slate-400"
                  } flex items-center space-x-3.5 hover:bg-slate-100`}
                  onClick={() => handleClickMenu(i, menu.to)}
                >
                  <i className={`${menu.icon}`}></i>
                  <span className="text-[14px]">{menu.name}</span>
                </button>
              ))}
            </div>
          </div>

          </div>
        

        <div className="m-3 text-center overflow-hidden">
          <button
            className="px-3 py-2 rounded-md flex items-center space-x-5 bg-red-600 text-white"
            onClick={handleLogout}
          >
            <IoMdLogOut />
            <p>Logout</p>
          </button>
        </div>
      </div>
    </div>
  );
}
export default Sidebar;

const sideMenu = [
  {
    name: "Dashboard",
    to: "",
    icon: "fas fa-home-alt",
  },
  {
    name: "Goods",
    to: "shop",
    icon: "fas fa-map-marker-alt",
  },
  {
    name: "Services",
    to: "services",
    icon: "fas fa-map-marker-alt",
  },
  {
    name: "Order",
    to: "order",
    icon: "fas fa-chart-pie-alt",
  },
  {
    name: "Offer",
    to: "offer",
    icon: "fas fa-address-card",
  },
  {
    name: "Logistics",
    to: "logistics",
    icon: "fas fa-chart-pie-alt",
  },
  {
    name: "Wallet",
    to: "wallet",
    icon: "fas fa-credit-card",
  },
  // {
  //   name: "Disputes",
  //   to: "disputes",
  //   icon: "fas fa-bolt",
  // },
  {
    name: "Settings",
    to: "settings",
    icon: "fas fa-cog",
  },
];
