


import DummySkeleton from '../../../../../component/MarketPlace/goods/child/DummySkeleton';
import React from 'react'
import { Link } from 'react-router-dom'
import { useGetCustomerOffersQuery } from '../../../../../redux/apiSlice'
import EmptyOffer from './EmptyOffer';

export default function OfferIndex() {


    const {data, isLoading, error} = useGetCustomerOffersQuery()
    

  return (
    <div>
        <div className="">
        {!data ? (
          <DummySkeleton />
        ) : (
          <div className="">
            { data.data.length === 0 ? <EmptyOffer /> : <>
            {data.data.map((offer, i) => (
              <>
                {/* <Link to={`${offer.id}`}> */}
                  <div className="flex justify-between items-center bg-white cursor-pointer p-2 hover:bg-brown-100 hover:text-brown-800">
                    <div className="flex items-center space-x-5">
                      <div className="rounded w-[100px] h-[100px]">
                        <img
                          src={offer.service.productImage}
                          className="object-cover w-[100px] h-[100px] rounded-md"
                        />

                        {/* Some offer details */}
                      </div>
                      <div className="space-y-2">
                        <p className="text-xl text-slate-800">
                          {offer.service.name}
                          <span className="text-md text-slate-700">
                          {/* {offer.offerItems[0].quantity !== 0
                            ? offer.offerItems[0].quantity
                            : null} */}
                          </span>
                        </p>
                        <p className='text-slate-500 w-1/2 truncate'>{offer.service.description}</p>
                        <div className=' md:grid-cols-4 grid-cols-2 gap-5 w-[700px] hidden'>
                          <div>
                          <h1 className="text-xs text-slate-400">Name of Client</h1>
                          <p>{offer.customer.firstName}</p>
                          </div>
                          <div>
                          <h1 className="text-xs text-slate-400">Budget</h1>
                          <p>{offer.service.price}</p>
                          </div>
                          <div>
                          <h1 className="text-xs text-slate-400">Contract Period</h1>
                          <p>{offer.terms.duration}</p>
                          </div>
                          <div>
                          <h1 className="text-xs text-slate-400">Status</h1>
                          <p>{offer.offerStatus}</p>
                          </div>
                        </div>
                        {/* <h1 className="text-xs">
                          offer Status:{" "}
                          <span className="bg-green-600 text-white px-2 py-1 rounded-md">Active</span>
                        </h1> */}
                        
                      </div>
                    </div>

                    
                  </div>
                {/* </Link> */}
              </>
            ))}
            </>}
          </div>
        )}
      </div>
    </div>
  )
}
