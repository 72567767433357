

import React from 'react'
import { Link } from 'react-router-dom';

export default function InfoGuide() {
    return (
      <div>
        <div className="flex flex-col justify-center items-center h-screen">
          <p className="text-xl mb-10">Info Page Coming Soon</p>
          <Link to="/">
            <button className="w-[250px] hover:underline underline-offset-8">
              Go Back Home
            </button>
          </Link>
        </div>
      </div>
    );
}
