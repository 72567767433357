import { Dialog, Grid, IconButton, Skeleton, Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  useDeleteItemFromCartMutation,
  useGetAllCartItemQuery,
  useGetCustomerOffersQuery,
  useInitializeServiceOrderMutation,
  useUpdateCartMutation,
} from "../../../../../redux/apiSlice";
import { DeleteForever, InfoRounded } from "@material-ui/icons";
import { openSnackBar } from "../../../../../redux/snackSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addCheckoutItems,
  setCheckoutType,
} from "../../../../../redux/checkoutSlice";
import Oval from "react-loading-icons/dist/esm/components/oval";
import { data } from "jquery";
import { formatToCurrency } from "../../../../utils/currencyFormatter";
import {
  decrementQuantity,
  getUserCart,
  incrementQuantity,
  removeItem,
  updateCart,
  updateTotal,
} from "../../../../../redux/cartSlice";
import {
  resetCurrentOffer,
  setCurrentOffer,
} from "../../../../../redux/offerSlice";
import GoodsCartItem from "./GoodsCartItem";
import EmptyCart from "./EmptyCart";

const CartItems = () => {
  // component state
  const [quantity, setQuantity] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [key, setKey] = useState(false);
  const [goods, setGoods] = useState(true);
  const [services, setServices] = useState(false);
  const [checkoutOffer, setCheckoutOffer] = useState(null);

  // Use hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Cart item from the state
  const cartItems = useSelector((state) => state.cart.cart);
  const offerItems = useSelector((state) => state.offer.offerList);
  const totalPrice = useSelector((state) => state.cart.totalPrice);
  const cartStatus = useSelector((state) => state.cart.loading);
  const userToken = useSelector((state) => state.auth.userToken);
  const currentOffer = useSelector((state) => state.offer.currentOffer);
  const [refresh, setRefresh] = useState(false);

  // effect to get updated price **** Fix later
  useEffect(() => {
    // fetch total price from state on add Quantity
    dispatch(getUserCart());
    const timer = setTimeout(() => {
      setRefresh(false);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [key, refresh]);

  // Data
  const [initializeOfferPayment] = useInitializeServiceOrderMutation();
  const { data: offerList, isLoading, error } = useGetCustomerOffersQuery();
  const [removeItemFromCart, { isLoading: cartItemDeleteLoading }] =
    useDeleteItemFromCartMutation();
  const { data: cartData } = useGetAllCartItemQuery();
  const [updateCart, { isLoading: updateCartLoading }] =
    useUpdateCartMutation();

  useEffect(() => {
    if (userToken === null) {
      navigate("/market");
    }
  }, []);

  useEffect(() => {
    console.log("");
    return () => {
      dispatch(resetCurrentOffer());
    };
  }, []);

  // Methods
  const onGoods = () => {
    setGoods(true);
    setServices(false);
  };
  const onServices = () => {
    setServices(true);
    setGoods(false);
  };

  const deleteItemFromCart = (id, productId) => {

    try {
      removeItemFromCart(id)
        .unwrap()
        .then((data) => {
          dispatch(openSnackBar({ message: "Item removed from cart!" }));
          setOpenDeleteDialog(false);
        });
    } catch (err) {
      console.error(err);
      setOpenDeleteDialog(false);
    }
  };

  const handleCheckout = (cartItems, totalPrice) => {
    dispatch(
      addCheckoutItems({ cartItems: cartItems, cartTotalPrice: totalPrice })
    );
    navigate("/checkout");
    dispatch(updateTotal());
  };

  const addQuantity = (productId, number, cartId) => {
    updateCart({
      data: {
        productId: productId,
        quantity: number,
      },
      cartId: cartId,
    })
      .unwrap()
      .then((data) => {
        console.log("added product", data);
      });

    // dispatch(
    //   updateCart({
    //     data: {
    //       productId: productId,
    //       quantity: number,
    //     },
    //     cartId: cartId,
    //     token: userToken,
    //     type: "increase",
    //   })
    // );
  };

  const removeQuantity = (productId, number, cartId) => {
    // const value = Math.max(1, number - 1)
    console.log(productId, number);
    const value = number - 1;
    if (value < 1) {
      handleOpenDeleteDialog();
    } else {
      updateCart({
        data: {
          productId: productId,
          quantity: value,
        },
        cartId: cartId,
      })
        .unwrap()
        .then((data) => {
          console.log("removed product", data);
        });
      // dispatch(
      //   updateCart({
      //     data: {
      //       productId: productId,
      //       quantity: value,
      //     },
      //     cartId: cartId,
      //     token: userToken,
      //     type: "decrease",
      //   })
      // );
      setKey(true);
    }
  };

  // Dialog method
  function handleCloseDeleteDialog(productId) {
    setOpenDeleteDialog(false);
    // dispatch(incrementQuantity({ id: productId }));
  }

  function handleOpenDeleteDialog() {
    setOpenDeleteDialog(true);
  }

  // Offer methods

  function handleSetOffer(value, i) {
    setCheckoutOffer(i);
    dispatch(setCurrentOffer(value));
  }

  // function handleServicePayment() {
  //   // Inintiate service payment
  //   navigate("/checkout-offer");
  // }

  // methods
  function handleServicePayment(offerId, serviceId) {
    console.log(offerId, serviceId);
    try {
      initializeOfferPayment({
        offerId: offerId,
        serviceId: serviceId,
      })
        .unwrap()
        .then((data) => {
          console.log(data);
          dispatch(setCheckoutType({ type: "services" }));
          window.location.replace(data.data.webLink);
        });
    } catch (err) {
      console.log(err);
    }
  }

  console.log("Offer list", offerList);

  return (
    <div className="md:p-6 p-5">
      <h2 className="text-xl mb-2 text-slate-400">Cart</h2>
      <div className="flex items-center space-x-1">
        <button
          onClick={onGoods}
          className={` relative
            ${
              goods
                ? "border bg-brown-900 text-white py-2 px-10 "
                : "border-brown-900 py-2 px-10 border text-black"
            }
          `}
        >
          Goods
          <span className="w-[18px] h-[18px] rounded-lg bg-red-600 text-white absolute right-5 top-2 flex items-center justify-center">
            <p className="text-[8px]">
              {!cartData ? (
                <Skeleton />
              ) : (
                <>
                  {cartData !== undefined && cartItems === {}
                    ? null
                    : cartData.data.cartItems.length > 0
                    ? cartData.data.cartItems.length
                    : 0}
                </>
              )}
            </p>
          </span>
        </button>
        <button
          onClick={onServices}
          className={` relative
            ${
              services
                ? "bg-brown-900 text-white py-2 px-10 "
                : "border-brown-900 py-2 px-10 text-black border"
            }
          `}
        >
          Services
          <span className="w-[18px] h-[18px] rounded-lg bg-red-600 text-white absolute right-5 top-2 flex items-center justify-center">
            <p className="text-[8px]">
              {offerList !== undefined &&
              offerList.data.filter((item) => item.offerStatus === "ACCEPTED")
                .length !== 0
                ? offerList.data.length
                : 0}
            </p>
          </span>
        </button>
      </div>

      {goods ? (
        <>
          {!cartData ? (
            <div className="flex flex-col justify-center items-center">
              <div className="p-2 rounded-full min-h-[700px]">
                <Oval stroke="#fcbca2" />
              </div>
            </div>
          ) : (
            <div>
              <GoodsCartItem
                openDeleteDialog={openDeleteDialog}
                handleCloseDeleteDialog={handleCloseDeleteDialog}
                cartItems={cartData}
                removeQuantity={removeQuantity}
                addQuantity={addQuantity}
                deleteItemFromCart={deleteItemFromCart}
                handleCheckout={handleCheckout}
                totalPrice={totalPrice}
                cartItemDeleteLoading={cartItemDeleteLoading}
                cartStatus={cartStatus}
              />
            </div>
          )}
        </>
      ) : null}

      {/* Services */}
      {services ? (
        <>
          {cartStatus ? (
            <div className="flex flex-col justify-center items-center">
              <div className="p-2 rounded-full min-h-[700px]">
                <Oval stroke="#fcbca2" />
              </div>
            </div>
          ) : (
            <div>
              <>
                <div className="mx-auto py-2 mb-20">
                  <hr />

                  <div className="mt-2">
                    {offerList && offerList.data.length === 0 ? (
                      <>
                        <EmptyCart />
                      </>
                    ) : (
                      <div className="grid md:grid-cols-12 grid-cols-1 md:gap-10 mb-5 mt-2">
                        <div className="md:col-span-4 col-span-1">
                          {/* Cart items */}

                          <div className="space-y-5 overflow-y-scroll h-[500px]">
                            {offerList ? (
                              offerList.data.filter(
                                (item) => item.offerStatus === "ACCEPTED"
                              ).length === 0 ? (
                                <div>
                                  <Stack
                                    spacing={0}
                                    className="mt-5 bg-gray-100 p-5"
                                  >
                                    <h2 className="font-bold md:text-xl">
                                      Your offers are yet to be approved by the
                                      vendor!
                                    </h2>
                                    <p className="font-light text-gray-500">
                                      You will see approved offers once the
                                      vendor approved it
                                    </p>
                                  </Stack>
                                </div>
                              ) : (
                                <>
                                  <p className="my-3 text-xl">
                                    List of accepted offers
                                  </p>
                                  {offerList
                                    ? offerList.data
                                        .filter(
                                          (item) =>
                                            item.offerStatus === "ACCEPTED"
                                        )
                                        .map((offer, i) => (
                                          <div
                                            key={i}
                                            className={` flex justify-between  border border-slate-100 rounded-md w-full p-2 ${
                                              checkoutOffer === i
                                                ? "bg-brown-900 text-white"
                                                : "bg-slate-50 hover:bg-slate-100"
                                            } `}
                                            onClick={() =>
                                              handleSetOffer(offer, i)
                                            }
                                          >
                                            <div className="flex space-x-5">
                                              <div className="w-16 h-16 relative">
                                                <img
                                                  src={
                                                    offer.service.productImage
                                                  }
                                                  alt=""
                                                  className="object-cover overflow-hidden h-full rounded-md"
                                                />
                                              </div>
                                              <div>
                                                <h1 className="text-base font-medium">
                                                  {offer.description}
                                                </h1>
                                                <p className="text-lg font-bold">
                                                  {formatToCurrency(
                                                    offer.service.price
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                            <div></div>
                                          </div>
                                        ))
                                    : null}
                                </>
                              )
                            ) : null}
                            {/*  */}
                          </div>
                        </div>
                        <div className=" col-span-8">
                          {currentOffer?.service ===
                          undefined ? //     Select one of the offers to complete the payment //   <p className=""> //   /> //     className="text-brown-600" //     fontSize="large" //   <InfoRounded // <div className="p-5 border border-slate-100 bg-slate-50 rounded-md flex items-center space-x-3">
                          //     process
                          //   </p>
                          // </div>
                          null : (
                            <div className="border p-3 rounded-md w-full space-y-5 overflow-y-scroll h-[70vh]">
                              <div className="space-y-2">
                                <div className="w-full h-40 relative">
                                  <img
                                    src={currentOffer.service.productImage}
                                    alt=""
                                    className="object-cover overflow-hidden w-full h-full rounded-md"
                                  />
                                </div>
                                <hr />
                                <h1 className="text-[20px] text-slate-500">
                                  {currentOffer.description}
                                </h1>
                                <p className="font-bold font-serifextra text-[20px]">
                                  {formatToCurrency(currentOffer.service.price)}
                                </p>
                              </div>
                              <button
                                className="bg-[#2E99EC] py-3 px-5 text-white rounded-md"
                                onClick={() =>
                                  handleServicePayment(
                                    currentOffer.id,
                                    currentOffer.service.id
                                  )
                                }
                              >
                                Proceed to Checkout
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            </div>
          )}
        </>
      ) : null}
      {/* Payment dialog */}
    </div>
  );
};

export default CartItems;

export function DeleteCartItem({
  removeFromCart,
  cartId,
  cartItemDeleteLoading,
  cancelAction,
  productId,
}) {
  return (
    <div className="min-w-[350px] min-h-[250px] bg-white rounded-md flex justify-center items-center">
      {cartItemDeleteLoading ? (
        <>
          <div className="min-w-[250px] md:min-h-[250px] rounded-md md:py-5 py-10 flex flex-col items-center justify-center">
            <Oval />
          </div>
        </>
      ) : (
        <div className="min-w-[250px] md:min-h-[250px] rounded-md md:py-5 py-10 flex flex-col items-center justify-center">
          <h1 className="text-[25px] text-center px-10">
            Do you want to delete Item from cart?
          </h1>
          <div className="flex items-center md:space-x-5 space-x-2 mt-5">
            <button
              className="bg-blue-200  px-5 py-3 rounded-md text-[14px] font-bold text-blue-500"
              onClick={() => cancelAction(productId)}
            >
              Cancel
            </button>
            <button
              className="text-white px-5 py-3 rounded-md text-[14px] font-bold bg-red-600 min-w-[200px]"
              onClick={() => removeFromCart(cartId)}
            >
              Yes, Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

{
  /* <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/> */
}
