


import React from 'react'
import { Link } from 'react-router-dom';
import Header from '../../utils/Header';
import vectorBg from "../../../assets/svg/dashboard-vector.svg"

export default function AboutHome() {
    return (
      <div>
        <Header />
        <section
          className={`md:px-40 px-10 bg-brown-900 h-40 text-white flex items-center overflow-hidden relative`}
          style={{
            backgroundImage: `url(${vectorBg})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "0% 50%",
            backgroundSize: "110%"
          }}
        >
          <div className="absolute">
            
          </div>

          <h1 className="md:text-5xl text-2xl md:text-left">About AfCFTA</h1>
        </section>
        <section className="md:px-40 md:py-0 px-10 py-20 items-center grid grid-cols-1 md:grid-cols-2 md:h-[650px] overflow-hidden">
          <div className="block md:space-y-4 text-center md:text-left">
            <h1 className="md:text-4xl text-2xl font-bold mb-4">
              From concept towards reality
            </h1>
            <p>
              The Africann Continental Free Trade (AfCFTA) is an ambitious
              undertaking that aims to eventually bring together 55 African
              countries with a combines 1.3 billion people to create the world’s
              largest trade area, as measured bt number of Member States, Its
              objective is to create and integrated market for the trade and
              services, as well as the free movement of people
            </p>
          </div>
          <div className="">
            <img src="" />
          </div>
        </section>
      </div>
    );
}
