import React, { useState } from "react";
import { IoIosCart, IoIosFunnel } from "react-icons/io";
import { useGetAllVendorOfferQuery } from "../../../../../redux/apiSlice";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { formatToCurrency } from "../../../../../component/utils/currencyFormatter";

export default function VendorOfferIndex() {
  // hooks
  const navigate = useNavigate();

  // State
  const [type, setType] = useState("all");

  // data
  const { data, error } = useGetAllVendorOfferQuery();
  console.log(data && data);

  // Methods
  function handleChangeType(value) {
    setType((prev) => (prev === value ? prev : value));
  }

  function handleOpenSingleOffer(id, details) {
    console.log("single offer clicked", id);
    navigate(`${id}`, {
      state: {
        serviceDetail: details,
      },
    });
  }

  return (
    <div className="flex flex-col">
      {/* <div className="flex items-center mx-10 mb-2 mt-10 space-x-2">
        <button
          className={`px-5 py-2 rounded-sm w-[150px] border border-brown-800 ${
            type === "goods" && "bg-brown-900 text-white"
          } `}
          onClick={() => handleChangeType("goods")}
        >
          Goods
        </button>
        <button
          className={`px-5 py-2 rounded-sm w-[150px] border border-brown-800 ${
            type === "service" && "bg-brown-900 text-white"
          }`}
          onClick={() => handleChangeType("service")}
        >
          Services
        </button>
      </div> */}
      <div className="bg-white min-h-[500px] rounded-md mx-10 p-10">
        {/* Search filter */}
        <div className="flex">
          <input
            type="search"
            placeholder="Search"
            className="py-1.5 px-2 border-[0.7px] w-1/2  border-brown-800 outline-none"
          />
          <button className="px-5 py-2 rounded-sm border-[0.7px] border-brown-800 flex items-center space-x-2 rounded-r-lg hover:bg-amber-900 hover:text-white">
            {" "}
            <span>
              <IoIosFunnel />
            </span>{" "}
            <p>Filter</p>{" "}
          </button>
        </div>

        {/* Filter tab */}
        <div className="flex items-center mt-10 space-x-2">
          <button
            className={`px-5 py-2 rounded-sm border-b-2 text-[14px] ${
              type === "all" && "border-brown-800 "
            } `}
            onClick={() => handleChangeType("all")}
          >
            All
          </button>
          <button
            className={`px-5 py-2 rounded-sm border-b-2 text-[14px] ${
              type === "accepted" && "border-brown-800 "
            }`}
            onClick={() => handleChangeType("accepted")}
          >
            In progress
          </button>
          <button
            className={`px-5 py-2 rounded-sm border-b-2 text-[14px] ${
              type === "completed" && "border-brown-800 "
            }`}
            onClick={() => handleChangeType("completed")}
          >
            Completed
          </button>
        </div>

        {/* Goods or Service */}

        <div className="mt-2">
          {type === "all" && (
            <>
              <div className="flex flex-col space-y-5 mt-5">
                {data && data.data.length === 0 ? (
                  <div className="flex justify-center items-center w-full h-full">
                    <div className="block text-center">
                      <IoIosCart className="text-7xl text-slate-200 mx-auto" />
                      <h4 className="text-slate-400">
                        You don't have any offer for now
                      </h4>
                      <p className="text-slate-300">
                        Add services to the market
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    {data &&
                      data.data.map((row, index) => (
                        <div
                          className="w-full border rounded-md min-h-[30px] p-5 group hover:bg-brown-100 cursor-pointer"
                          onClick={() => handleOpenSingleOffer(row.id, row)}
                        >
                          <h1 className="text-xl font-bold ">{row.title}</h1>
                          <p className="text-sm text-slate-500 mb-2 ">
                            {row.description}
                          </p>
                          <div className="grid md:grid-cols-4 grid-cols-2 gap-5 ">
                            <div className="p-1 rounded-sm">
                              <div className="text-[12px]  text-slate-400  ">
                                Name of Client
                              </div>
                              <p className="text-[15px] font-bold">
                                {row.customer.firstName}
                              </p>
                            </div>
                            <div className="p-1 rounded-sm">
                              <div className="text-[12px]  text-slate-400 ">
                                Budget
                              </div>
                              <p className="text-[15px] font-bold">
                                {formatToCurrency(row.terms.budget)}
                              </p>
                            </div>
                            <div className="p-1 rounded-sm">
                              <div className="text-[12px]  text-slate-400 ">
                                Due Date
                              </div>
                              <p className="text-[15px] font-bold">
                                {moment(row.terms.dueDate).format(
                                  "dddd DD MMM, YYYY"
                                )}
                              </p>
                            </div>
                            <div className="p-1 rounded-sm">
                              <div className="text-[12px]  text-slate-400 ">
                                Status
                              </div>
                              <button
                                className={`text-[15px] font-bold px-5 py-1.5 rounded-md ${
                                  row.offerStatus === "PENDING"
                                    ? "bg-blue-100 text-blue-600"
                                    : row.offerStatus === "ACCEPTED"
                                    ? "text-yellow-700 bg-yellow-100"
                                    : "bg-green-100 text-green-700"
                                } `}
                              >
                                {row.offerStatus}
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </>
                )}
              </div>
            </>
          )}

          {/* in progress */}
          {type === "accepted" && (
            <>
              <div className="flex flex-col space-y-5 mt-5">
                {data &&
                data.data.filter((item) => item.offerStatus === "ACCEPTED")
                  .length === 0 ? (
                  <div className="flex justify-center items-center w-full h-full">
                    <div className="block text-center">
                      <IoIosCart className="text-7xl text-slate-200 mx-auto" />
                      <h4 className="text-slate-400">
                        You don't have any ongoing offer for now
                      </h4>
                      <p className="text-slate-300">
                        Add services to the market
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    {data &&
                      data.data
                        .filter((item) => item.offerStatus === "ACCEPTED")
                        .map((row, index) => (
                          <div
                            className="w-full border rounded-md min-h-[30px] p-5 group hover:bg-brown-100 cursor-pointer"
                            onClick={() => handleOpenSingleOffer(row.id, row)}
                          >
                            <h1 className="text-xl font-bold ">{row.title}</h1>
                            <p className="text-sm text-slate-500 mb-2 ">
                              {row.description}
                            </p>
                            <div className="grid md:grid-cols-4 grid-cols-2 gap-5 ">
                              <div className="p-1 rounded-sm">
                                <div className="text-[12px]  text-slate-400  ">
                                  Name of Client
                                </div>
                                <p className="text-[15px] font-bold">
                                  {row.customer.firstName}
                                </p>
                              </div>
                              <div className="p-1 rounded-sm">
                                <div className="text-[12px]  text-slate-400 ">
                                  Budget
                                </div>
                                <p className="text-[15px] font-bold">
                                  {formatToCurrency(row.terms.budget)}
                                </p>
                              </div>
                              <div className="p-1 rounded-sm">
                                <div className="text-[12px]  text-slate-400 ">
                                  Due Date
                                </div>
                                <p className="text-[15px] font-bold">
                                  {moment(row.terms.dueDate).format(
                                    "dddd DD MMM, YYYY"
                                  )}
                                </p>
                              </div>
                              <div className="p-1 rounded-sm">
                                <div className="text-[12px]  text-slate-400 ">
                                  Status
                                </div>
                                <button className={`text-[15px] font-bold px-5 py-1.5 rounded-md ${
                                  row.offerStatus === "PENDING"
                                    ? "bg-blue-100 text-blue-600"
                                    : row.offerStatus === "ACCEPTED"
                                    ? "text-yellow-700 bg-yellow-100"
                                    : "bg-green-100 text-green-700"
                                }`}>
                                  {row.offerStatus}
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                  </>
                )}
              </div>
            </>
          )}

          {/* completed */}
          {type === "completed" && (
            <>
              <div className="flex flex-col space-y-5 mt-5">
                {data &&
                data.data.filter((item) => item.offerStatus === "COMPLETED")
                  .length === 0 ? (
                  <div className="flex justify-center items-center w-full h-full">
                    <div className="block text-center">
                      <IoIosCart className="text-7xl text-slate-200 mx-auto" />
                      <h4 className="text-slate-400">
                        You don't have any completed offer yet
                      </h4>
                      <p className="text-slate-300">
                        Add services to the market
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    {data &&
                      data.data
                        .filter((item) => item.offerStatus === "COMPLETED")
                        .map((row, index) => (
                          <div
                            className="w-full border rounded-md min-h-[30px] p-5 group hover:bg-brown-100 cursor-pointer"
                            onClick={() => handleOpenSingleOffer(row.id, row)}
                          >
                            <h1 className="text-xl font-bold ">{row.title}</h1>
                            <p className="text-sm text-slate-500 mb-2 ">
                              {row.description}
                            </p>
                            <div className="grid md:grid-cols-4 grid-cols-2 gap-5 ">
                              <div className="p-1 rounded-sm">
                                <div className="text-[12px]  text-slate-400  ">
                                  Name of Client
                                </div>
                                <p className="text-[15px] font-bold">
                                  {row.customer.firstName}
                                </p>
                              </div>
                              <div className="p-1 rounded-sm">
                                <div className="text-[12px]  text-slate-400 ">
                                  Budget
                                </div>
                                <p className="text-[15px] font-bold">
                                  {formatToCurrency(row.terms.budget)}
                                </p>
                              </div>
                              <div className="p-1 rounded-sm">
                                <div className="text-[12px]  text-slate-400 ">
                                  Due Date
                                </div>
                                <p className="text-[15px] font-bold">
                                  {moment(row.terms.dueDate).format(
                                    "dddd DD MMM, YYYY"
                                  )}
                                </p>
                              </div>
                              <div className="p-1 rounded-sm">
                                <div className="text-[12px]  text-slate-400 ">
                                  Status
                                </div>
                                <button className={`text-[15px] font-bold px-5 py-1.5 rounded-md ${
                                  row.offerStatus === "PENDING"
                                    ? "bg-blue-100 text-blue-600"
                                    : row.offerStatus === "ACCEPTED"
                                    ? "text-yellow-700 bg-yellow-100"
                                    : "bg-green-100 text-green-700"
                                }`}>
                                  {row.offerStatus}
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

//  <>

//               <div className="flex justify-center items-center w-full h-full">
//                 <div className="block text-center">
//                   <IoIosCart className="text-7xl text-slate-200 mx-auto" />
//                   <h4 className="text-slate-400">
//                     You don't have any offer for now
//                   </h4>
//                   <p className="text-slate-300">Add services to the market</p>
//                 </div>
//               </div>
//             </>
