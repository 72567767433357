import React, { useState } from "react";
import { formatToCurrency } from "../../../../utils/currencyFormatter";
import { Dialog, IconButton, Skeleton, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { DeleteCartItem } from "./CartItems";
import { useCancelCheckoutMutation } from "../../../../../redux/apiSlice";
import { Oval } from "react-loading-icons";

export default function GoodsCartItem({
  cartItems,
  removeQuantity,
  addQuantity,
  deleteItemFromCart,
  handleCheckout,
  openDeleteDialog,
  handleCloseDeleteDialog,
  cartItemDeleteLoading,
  cartStatus,
}) {
  
  const [cancelCheckout, {isLoading: cancelCheckoutLoading }] = useCancelCheckoutMutation()

  function handleCancelCheckout() {
    cancelCheckout().unwrap().then(data => console.log(data)).catch(err => console.log(err))
  }

  return (
    <div>
      <div className="mx-auto py-2 mb-20">
        <hr />
        {cartStatus ? (
          <Skeleton />
        ) : (
          <div className="mt-4">
            {cartItems.data.cartItems.length === 0 ? (
              <>
                <div className=" mx-auto my-5 p-10 bg-white">
                  <div className="flex flex-col justify-center items-center h-[400px]">
                    <Stack spacing={6}>
                      <div className="p-2 rounded-full">
                        <img
                          src="/images/empty-cart.png"
                          className="object-cover w-20"
                        />
                      </div>
                    </Stack>
                    <Stack spacing={2} className="mt-5 text-center">
                      <h2 className="font-bold md:text-xl text-slate-400">
                        You don’t have anything in your cart yet!
                      </h2>
                    </Stack>
                    <div className="mt-4">
                      <Link to="/market">
                        <p className="underline underline-offset-4 text-brown-800 mt-5">
                          Start Shopping
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="grid md:grid-cols-12 grid-cols-1 md:gap-20">
                <div className="md:col-span-8 col-span-1">
                  <div className="space-y-5">
                    {/* Cart items */}
                    <div className="">
                      {cartItems.data.cartItems.map((data, index) => (
                        <div key={index}>
                          <div className="md:flex hidden items-center justify-between space-x-4 md:p-5">
                            <div className="">
                              <img
                                src={data.product.productImage}
                                className="md:h-28 h-16 object-cover overflow-hidden rounded-md"
                              />
                            </div>
                            <div className="space-y-1.5 md:flex-1">
                              <h2 className="font-bold md:text-[14px]">
                                {data.product.name}
                              </h2>
                              <h3 className="text-gray-400 text-[12px]">
                                Condition:
                                <span className="font-bold text-black">
                                  {" "}
                                  New
                                </span>
                              </h3>
                              <h3 className="text-gray-400 text-[12px]">
                                HS-Code :
                                <span className="font-bold text-black">
                                  {" "}
                                  {data.product.hscode}
                                </span>
                              </h3>
                            </div>
                            <div className="font-bold md:flex-1 text-center text-[17px]">
                              ${data.product.price.toLocaleString()}
                            </div>
                            <div className="space-y-2 md:flex-1">
                              <h2 className="text-[12px] text-slate-500 hidden md:block">
                                Quantity
                              </h2>
                              <div className="flex space-x-3">
                                <div className="flex md:space-x-4 items-center">
                                  <button
                                    disabled={
                                      data.quantity === 0 ? true : false
                                    }
                                    className="px-3 py-1 bg-brown-700 text-white text-center rounded-md cursor-pointer"
                                    onClick={() =>
                                      removeQuantity(
                                        data.product.id,
                                        data.quantity,
                                        data.id
                                      )
                                    }
                                  >
                                    -
                                  </button>
                                  <h2 className="">{data.quantity}</h2>
                                  <button
                                    disabled={
                                      data.quantity ===
                                      data.product.quantityInStock
                                        ? true
                                        : false
                                    }
                                    onClick={() =>
                                      addQuantity(
                                        data.product.id,
                                        data.quantity ===
                                          data.product.quantityInStock
                                          ? data.product.quantityInStock
                                          : data.quantity + 1,
                                        data.id
                                      )
                                    }
                                    className="px-3 py-1 bg-brown-700 disabled:bg-slate-300 text-white text-center rounded-md cursor-pointer"
                                  >
                                    +
                                  </button>
                                </div>
                                <IconButton
                                  onClick={() =>
                                    deleteItemFromCart(data.id, data.product.id)
                                  }
                                >
                                  <RestoreFromTrashIcon className="text-red-700" />
                                </IconButton>

                                <Dialog
                                  open={openDeleteDialog}
                                  onClose={handleCloseDeleteDialog}
                                >
                                  <DeleteCartItem
                                    removeFromCart={deleteItemFromCart}
                                    cartId={data.id}
                                    cartItemDeleteLoading={
                                      cartItemDeleteLoading
                                    }
                                    cancelAction={handleCloseDeleteDialog}
                                    productId={data.product.id}
                                  />
                                </Dialog>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="border drop-shadow-md rounded-sm col-span-4 mt-10 md:mt-0">
                  <div className="p-5">
                    <h2 className="font-bold text-lg">Cart Summary</h2>
                    <Stack direction="column" spacing={3}>
                      <div className="flex justify-between w-full items-center">
                        <h2></h2>
                      </div>
                      <div className="flex justify-between w-full items-center">
                        <h2>VAT</h2>
                        <h2>$0.00</h2>
                      </div>
                      <div className="flex justify-between w-full items-center">
                        <h2>Sub Total</h2>
                        <h2 className="font-bold text-lg">
                          {formatToCurrency(cartItems.data.totalPrice)}
                        </h2>
                      </div>
                    </Stack>

                    <div className="mt-4">
                      {cartItems.data.checkingOut ? <div className="">
                        <h1>You did not complete your previous checkout process</h1>
                        <div className="flex items-center">
                          {/* <button className="py-2 px-5 bg-brown-600 text-white" >Yes Continue Last Checkout</button> */}
                          <button className="py-1.5 px-5 rounded-md bg-red-600 text-white" onClick={handleCancelCheckout} >{cancelCheckoutLoading ? <Oval /> : "Cancel Checkout"}</button>
                          </div>
                      </div> : (
                        <button
                          className="bg-[#2E99EC] w-full py-3 text-white rounded-md"
                          onClick={() =>
                            handleCheckout(
                              cartItems.cartItems,
                              cartItems.totalPrice
                            )
                          }
                        >
                          Checkout
                        </button>
                      )}
                    </div>

                    <div className="mt-2">
                      <p className="text-[10px] text-slate-500">
                        You will be paying with using dollar currency, click{" "}
                        <a
                          className="underline underline-offset-4"
                          href="/rules"
                        >
                          here
                        </a>{" "}
                        to learn more
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
