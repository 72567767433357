


import { Skeleton } from '@mui/material'

import React from 'react'
import { MdArrowBack } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { useGetSingleCustomerOrderQuery } from '../../../../../redux/apiSlice'

export default function SingelOrderDetails() {


    const route = useParams()
    const navigate = useNavigate()


    const {data, isLoading, error} = useGetSingleCustomerOrderQuery(route.id)


    function goBack () {
        navigate("/account/order")
    }



  return (
    <div className='overflow-y-scroll h-screen'>
        <div className='flex items-center space-x-5 cursor-pointer' onClick={goBack}>
            <MdArrowBack />
            <p>Go back</p>
        </div>

        {/* status */}
        <div className='flex justify-between items-center mt-10'>
            <p>Order ID</p>
            <div className='flex items-center space-x-3'>
                <p className='text-lg italic'>Status</p>
                <button className="bg-green-600 text-white px-2 py-1 rounded-md">Active</button>
            </div>
        </div>
        <hr className='my-2' />

        <div className='my-5 h-[10px] bg-cyan-600 rounded-md'>


        </div>
        <hr className='my-2' />

        <div className="py-3 mt-4  divide-y-2">
                                      {!data ? <Skeleton variant='rectangular' animation="wave" sx={{height: 180, width: "100%"}} /> : <div>{data.data.orderItems.map((order, index) => (
                                            <div key={index} className="flex justify-between items-center py-5 bg-white cursor-pointer p-2 hover:bg-amber-50">
                                                <img src={order.product.productImage} className="h-32 rounded-sm w-32 object-contain" />
                                                <p className='flex-1 text-center'>{order.product.name}</p>
                                                <p className='flex-1 text-center font-bold'>${order.product.price}</p>
                                                <p className='flex-1 text-center font-bold'>x{order.quantity}</p>
                                                <p className='flex-1 text-sm'>{order.product.description}</p>
                                            </div>
                                        ))}
                                        </div>}

                                    </div>
        
    </div>
  )
}
