import { Skeleton, Stack } from "@mui/material";
import axios from "axios";
import HeadMarket from "../../../../component/MarketPlace/child/HeadMarket";
import { formatToCurrency } from "../../../../component/utils/currencyFormatter";
import Footer from "../../../../component/utils/Footer";
import React, { useState } from "react";
import { useEffect } from "react";
import { Oval } from "react-loading-icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  useGetAllCartItemQuery,
  useGetCheckoutInfoMutation,
  useVerifyCurrentUserMutation,
} from "../../../../redux/apiSlice";
import { setCheckoutType } from "../../../../redux/checkoutSlice";
import { openSnackBar } from "../../../../redux/snackSlice";
import {
  FlutterWaveButton,
  closePaymentModal,
  useFlutterwave,
} from "flutterwave-react-v3";

export default function CheckoutHome() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [token, setToken] = useState(null);
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState("");
  const [shippingFee, setShippingFee] = useState(null);
  const [paymentLink, setPaymentLink] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.auth);
  const checkOutInfo = useSelector((state) => state.checkout);
  const address = useSelector((state) => state.checkout.checkoutAddress);

  const VAT = 0;
  const shippingCost = 0;

  // Data
  const [
    checkoutInfo,
    { isLoading: checkoutLoading, isError, error: checkoutError },
  ] = useGetCheckoutInfoMutation();
  const { data: cartData } = useGetAllCartItemQuery();

  // Effects

  useEffect(() => {
    if (user.userToken !== undefined && user.userToken !== null) {
      const tokenToTrim = user.userToken.split("Bearer ")[1];
      setToken(tokenToTrim);
    } else {
      setToken(null);
    }

    // handleVerifyUser()

    return () => {
      console.log("");
    };
  }, []);

  // confirm current user is logged and authenticated with their token
  const [verifyUser, { isLoading }] = useVerifyCurrentUserMutation();

  function handleVerifyUser() {
    verifyUser({ token: token })
      .unwrap()
      .then((data) => {
        setVerified(true);
      })
      .catch((err) => dispatch(openSnackBar({ message: "Session expired" })));
  }

  // Methods

  function stepChanger(value, type) {
    setStep((prev) => (value === prev ? prev : value));
  }

  const navigateBack = () => {
    navigate(-1);
  };

  const requestCheckoutInfo = () => {
    //   getCheckoutInfo().unwrap()
    // .then(data => {
    //   console.log(data);
    // })
    // .catch(e => console.log(e))
  };

  function getCheckoutInfo() {
    try {
      setLoading(true);
      console.log(address)
      checkoutInfo({
        shippingAddress: {
          country: address.nationality.alpha2Abbr,
          phoneNumber: address.phoneNumber,
          address: address.address,
          city: address.city,
          contactName: address.contactName,
          postalCode: address.postalCode,
          state: address.state,
          companyName: address.companyName,
          // country: "GH",
          // phoneNumber: "Accra Ghana",
          // address: "Base56 Street, Ac",
          // city: "Accra",
          // contactName: "Huler Man",
          // postalCode: "10923",
          // state: "Accra",
          // companyName: "Comp Name",
        },
      })
        .unwrap()
        .then((data) => {
          console.log(data);
          setShippingFee(data.data.deliveryFee);
          setPaymentLink(data.data.charge.webLink);
          setConfirmed(true);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  }

  function initiateCheckoutPayment() {
    // navigate(paymentLink)
    // dispatch(setCheckoutType({ type: "goods" }));
    window.location.replace(paymentLink);
  }

  console.log(cartData && cartData);

  return (
    <div>
      <HeadMarket />

      {/* <div className="h-20 bg-slate-200 p-5 flex items-center justify-center">
          <div className="flex justify-between w-full">
            <p>Back to cart</p>
            <p>Logistics</p>
          </div>
        </div> */}

      <div className="grid md:grid-cols-12 grid-cols-1 gap-5">
        <div className="md:col-span-8 col-span-1">
          <div className="py-4 rounded-xl m-5 shadow">
            <div className="flex mx-auto items-center justify-between">
              <h2
                className="font-bold cursor-pointer text-sm"
                onClick={navigateBack}
              >
                {"< Back To Cart"}
              </h2>

              {/* <h2 className="font-bold text-sm" onClick={nextStep}></h2> */}
            </div>
          </div>
          <Outlet />
        </div>
        {/* Summary of cost */}
        <div className="md:col-span-4 col-span-1">
          <div className="p-5 m-5 rounded-md sticky top-40 space-y-5 border border-slate-200">
            <div className="bg-amber-800 p-2 rounded-sm">
              <h2 className=" text-amber-300 font-bold">Cart Summary</h2>
            </div>
            <div className="block space-y-2">
              <h2 className="font-bold text-[20px] mt-4">Items</h2>
              <div className="flex justify-between w-full items-center text-[14px] font-sans">
                <div className="space-y-2">
                  <h2 className="text-slate-600">Price</h2>
                  <h2 className="text-slate-600">VAT</h2>
                  <hr />
                  <h2 className="text-black">Sub-Total</h2>
                </div>
                <div className="space-y-2 font-bold">
                  {!cartData ? (
                    <Skeleton />
                  ) : (
                    <h2>{formatToCurrency(cartData && cartData.data.totalPrice)}</h2>
                  )}
                  <h2>0</h2>
                  <hr />
                  {!cartData ? (
                    <Skeleton />
                  ) : (
                    <h2>{formatToCurrency(cartData && cartData.data.totalPrice)}</h2>
                  )}
                </div>
              </div>
              <h2 className="font-bold text-[20px] pt-4">Delivery</h2>
              <div className="flex justify-between w-full text-[14px] font-sans">
                <div className="space-y-2 ">
                  <h2>Shipping Fee</h2>
                  <p className="text-[8px] text-slate-400">
                    Shipping Tax inclusive
                  </p>
                </div>
                <div className="space-y-2 font-bold">
                  {shippingFee === "" ? (
                    <Skeleton height={10} width={10} />
                  ) : (
                    <h2>{shippingFee && shippingFee}</h2>
                  )}
                </div>
              </div>

              <div className="flex justify-between w-full items-center">
                <div className="space-y-2">
                  <h2 className="font-bold text-[20px] pt-5">Total</h2>
                </div>
                <div className="space-y-2">
                  <h1 className="font-bold text-[20px] pt-5">
                    {formatToCurrency(
                      cartData && cartData.data.totalPrice + VAT + shippingFee
                    )}
                  </h1>
                  {/* <h2>${total.toLocaleString()}</h2> */}
                </div>
              </div>
            </div>
            <div className=" mt-7 space-y-2">
              {confirmed ? (
                <button
                  className={
                    "bg-[#205fdd] w-full py-3 text-white rounded-md flex justify-center items-center space-x-5"
                  }
                  onClick={initiateCheckoutPayment}
                >
                  Pay Now
                </button>
              ) : (
                <button
                  className={
                    "bg-[#8dc6f2] w-full py-3 text-white rounded-md flex justify-center items-center space-x-5"
                  }
                  onClick={getCheckoutInfo}
                >
                  {checkoutLoading ? <Oval /> : "Proceed to pay"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Payment Confirmation modal */}

      <Footer />
    </div>
  );
}
