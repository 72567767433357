import React, { useContext, useEffect, useRef, useState } from "react";
import { Navbar, NavItem } from "react-bootstrap";
import { Badge, Button, IconButton, InputBase, TextField } from "@mui/material";
import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import {
  IoMdArrowForward,
  IoMdArrowRoundUp,
  IoMdClose,
  IoMdMenu,
} from "react-icons/io";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { CartContext } from "../../context/CartContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { logout } from "../../redux/AuthSlice";
import { DoorFront } from "@mui/icons-material";

function Header(props) {
  //  const {currentUser} = useSelector(state => state.auth.userInfo)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opened = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location.pathname)

  const handleChangeCurrent = (menu) => {
    // setCurrent(menu)
    // console.log(menu)
  };

  // const location = route.asPath.split("/")[1]

  // States *******************************************
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(null);
  const [showBtn, setShowBtn] = useState(false);
  const [currentHref, setCurrentHref] = useState("");
  const [fixBar, setFixBar] = useState(false);

  // refs ******************************************
  const dropDownMenuRef = useRef();
  const toggleRef = useRef();

  const userTokenSession = useSelector((state) => state.auth.tokenActive);
  const userType = useSelector((state) => state.auth.userType);
  const user = useSelector((state) => state.auth);
  console.log(user);

  // Methods ****************************************
  const handleMenuToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleScrollToTop = (e) => {
    window.scrollTo({
      top: 10,
      behavior: "smooth",
    });
    setCurrentHref("");
  };

  // useEffects *******************************************
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (
        dropDownMenuRef.current &&
        !dropDownMenuRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    });
    return () => {
      document.removeEventListener("click", () => {
        // console.log("Hmm")
      });
    };
  }, []);

  useEffect(() => {
    // if (document.body.scrollTop > 500) {
    //     console.log("I scrolled for", )
    // }
    document.addEventListener("scroll", () => {
      return window.scrollY > 600 ? setShowBtn(true) : setShowBtn(false);
    });
    return () => {
      document.removeEventListener("scroll", () => {
        console.log("removed");
      });
    };
  });

  useEffect(() => {
    document.addEventListener("scroll", () => {
      return window.scrollY > 150 ? setFixBar(true) : setFixBar(false);
    });
    return () => {
      document.removeEventListener("scroll", () => {
        console.log("ssm");
      });
    };
  });

  // Close menu on button click
  const closeMenu = (link) => {
    navigate(`/${link}`);
    setOpen(false);
    console.log("routing to", link);
  };

  return (
    <>
      <div className="sticky top-0 w-full z-50">
        <div
          className={`w-full flex items-center justify-between md:px-20 h-20 shadow-md bg-white md:bg-none ${
            fixBar ? "fixed" : null
          }`}
        >
          {/* logo */}
          <div className="hidden md:block">
            <a href="/">
              <img src={"images/logo.png"} alt="" />
            </a>
          </div>
          <div className="md:hidden px-5">
            <img
              src={"images/logo.png"}
              layout="intrinsic"
              width={50}
              height={50}
              alt=""
            />
          </div>

          {/* Web menu */}
          <div className="hidden md:block">
            <ul className="flex space-x-5 font-sans text-base">
              {menuItem.map((menu, i) => (
                <NavLink
                  to={menu.to}
                  key={i}
                  className={`hover:underline hover:decoration-blue-400 underline-offset-8 ${
                    navigate.pathname === menu.to
                      ? "text-brown-500"
                      : "text-brown-900"
                  } `}
                  onClick={() => setCurrentHref(menu.to)}
                >
                  <p className={`font-bold `}>{menu.name}</p>
                </NavLink>
              ))}
            </ul>
          </div>
          <div>
            {userTokenSession ? (
              <>
                {userType === 'VENDOR' ? <Button
                            startIcon={<DoorFront sx={{fontSize: 20}} />}
                            sx={{ borderRadius: "50px", color: "white" }}
                            variant="contained"
                            className="bg-brown-800 p-3 px-6 rounded-full text-white"
                            onClick={() => navigate('/dashboard')}
                          >
                            Dashboard
                          </Button> : <div className="hidden md:flex items-center space-x-4">
                  <NavItem>
                    <div className="space-x-3 flex items-center">
                      {/* <AccountCircleIcon className='text-blue-400'/> */}
                      <h2 onClick={handleClick} className="cursor-pointer"></h2>
                      <Button
                        id="demo-positioned-button"
                        aria-controls={
                          opened ? "demo-positioned-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={opened ? "true" : undefined}
                        onClick={handleClick}
                      ></Button>
                      <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={opened}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Link to="/account">
                          <MenuItem onClick={handleClose}>Profile</MenuItem>
                        </Link>

                        <Link to="/account">
                          <MenuItem onClick={handleClose}>My account</MenuItem>
                        </Link>

                        <MenuItem
                          onClick={() => {
                            dispatch(logout());
                            navigate("/");
                            localStorage.removeItem("TOKEN");
                          }}
                        >
                          Logout
                        </MenuItem>
                      </Menu>
                    </div>
                  </NavItem>
                  <NavItem>
                    <div>
                      <Link to={`/cart`}>
                        <Badge className="text-red-700 text-2xl">
                          <Button
                            startIcon={<ShoppingCartIcon sx={{fontSize: 20}} />}
                            sx={{ borderRadius: "50px", color: "white" }}
                            variant="contained"
                            className="bg-brown-600 p-3 px-6 rounded-full text-white"
                          >
                            Cart
                          </Button>
                        </Badge>
                      </Link>
                    </div>
                  </NavItem>
                </div>}
              </>
            ) : (
              <>
                <div className="hidden md:flex space-x-2 items-center">
                  <NavItem>
                    <NavLink className="" to="/login">
                      <button className="px-5 py-[9px] min-w-[150px] border border-brown-600 rounded-sm">
                        Login
                      </button>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <button className="px-5 py-2.5 min-w-[150px] bg-brown-900 text-white rounded-sm">
                      <NavLink className="" to="/select-user">
                        Create an Account
                      </NavLink>
                    </button>
                  </NavItem>
                </div>
              </>
            )}
          </div>

          {/* mobile menu */}
          <div ref={dropDownMenuRef} className="md:hidden">
            <div className="pr-5" ref={toggleRef}>
              {open ? (
                <div
                  className="flex justify-center items-center p-3 text-brown-500 rounded-full"
                  onClick={handleMenuToggle}
                >
                  <IoMdClose className="w-8 h-8 transition-all ease-in-out delay-300 duration-500 pointer-events-none" />
                </div>
              ) : (
                <div
                  className="flex justify-center items-center p-3 text-brown-900 rounded-full"
                  onClick={handleMenuToggle}
                >
                  <IoMdMenu className="w-8 h-8 transition-all ease-in-out delay-300 duration-500 pointer-events-none" />
                </div>
              )}
            </div>
            {/* DROPDOWN */}
            <div className="absolute left-0 top-20">
              <div
                className={`md:hidden bg-brown-900  w-[100vw] filter drop-shadow-sm transition-transform delay-700 ease-out duration-1000 rounded-b-3xl ${
                  open ? "border-b-1" : null
                } relative`}
                // ref={dropDownMenuRef}
              >
                {open && (
                  <div className="absolute top-36 -right-5 -z-[1] ">
                    <div className="w-32 h-32">
                      <img
                        src={"images/Mask group.svg"}
                        className=" object-cover opacity-50"
                      />
                    </div>
                  </div>
                )}

                {open && (
                  <div
                    className={`block space-y-5 py-10 px-7 rounded-b-xl list-none ${
                      open ? "text-brown-100" : "text-slate-700"
                    }`}
                  >
                    <div className="space-y-5">
                      {menuItem.map((item, i) => (
                        <li
                          key={i}
                          className="listItem text-2xl"
                          onClick={(e) => setOpen(false)}
                        >
                          {/* <span>
                          <IoMdArrowForward />
                        </span> */}
                          <a href={`${item.to}`}>{item.name}</a>
                        </li>
                      ))}
                    </div>
                    <div
                      className="space-y-2 flex flex-col"
                      // onClick={closeMenu}
                    >
                      <Link to="/select-user">
                        <button
                          className="px-5 py-2.5 bg-brown-600 text-white rounded-lg w-full"
                          onClick={closeMenu}
                        >
                          Become a Vendor
                        </button>
                      </Link>
                      <Link to="/login">
                        <button
                          className="px-5 py-2.5 bg-white text-brown-900 rounded-lg w-full"
                          onClick={closeMenu}
                          c
                        >
                          Login
                        </button>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Floating back to top button */}
        <div className="fixed bottom-10 right-10">
          {showBtn && (
            <div
              className="flex justify-center items-center p-3 bg-slate-600 rounded-full text-white"
              onClick={handleScrollToTop}
            >
              <IoMdArrowRoundUp className="w-8 h-8 transition-all ease-in-out delay-300 duration-500 pointer-events-none" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Header;

const menuItem = [
  {
    name: "Home",
    to: "/",
  },
  {
    name: "Market",
    to: "/market",
  },
  {
    name: "About",
    to: "/about",
  },
  {
    name: "Trade Guides",
    to: "/rules",
  },
];
