export const IngredientReducer = (state,action)=>{
    switch (action.type) {
        case "ADDID":
            const newState = {...state,id:action.payload}
            return newState  
        case 'NATURE':
            const prevState = {...state,natureOfVendor:action.payload}   
            return prevState 
        case 'COUNTRY':
            const  country = {...state,countryOfVendor:action.payload}  
            return country
        case 'CONSTITUTE':
            const iterable ={...state,constituents:[...state.constituents,action.payload]}
            return iterable
        case 'REMOVE':
            // const remove = {...state,constituents:[]} 
            // const remove = state.constituents.pop()     
            let anotherState = {...state}
            anotherState.constituents.pop()
            return anotherState
        case "REMOVEALL":
            let another = {...state,constituents:[]}
            return another

        default:
            break; 
    }
}