import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Grid,
  Stack,
  TextareaAutosize,
  TextField,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import "./modal.css";
import { useState } from "react";
import {
  useGetGoodsCategoriesQuery,
  useVendorUpdateProductMutation,
} from "../../../../../redux/apiSlice";
import Oval from "react-loading-icons/dist/esm/components/oval";
import { useNavigate, useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  // border: '1px solid #000',
  boxShadow: 24,
  borderRadius: 4,
  p: 3,
  maxHeight: "90vh",
  overflowY: "scroll",
};

export default function EditProductModal({ data, setEnableEdit }) {
  const [name, setName] = React.useState(data.name);
  const [description, setDescription] = React.useState(data.description);
  const [image, setImage] = React.useState(data.image);
  const [quantityInStock, setQuantityInStock] = React.useState(
    data.quantityInStock
  );
  const [snapShots, setSnapShot] = React.useState([data.screenShots]);
  const [price, setPrice] = React.useState(data.price);
  const [category, setCategory] = React.useState(data.category);
  const [weight, setWeight] = React.useState(data.weight);
  const [width, setWidth] = useState(data.width);
  const [height, setHeight] = useState(data.height);
  const [length, setLength] = useState(data.length);

  const token = useSelector((state) => state.auth.userToken);
  const [loading, setLoading] = React.useState(false);
  const [id, setID] = React.useState("");
  const inputRef = React.useRef();
  const [open2, setOpen2] = React.useState(false);

  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  // Hooks
  const dispatch = useDispatch();
  const multipart = React.useRef();
  const route = useParams();
  const navigate = useNavigate()

  function handleChange(e) {
        console.log(e.target.files[0]);
        setImage(e.target.files[0]);
    }

  const handleMultipart = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();
      setSnapShot((prev) => [...prev, newImage]);
    }
    console.log(snapShots);
  };

  const handleUpload = async (e) => {
    e.persist();
    setSnapShot((prev) => [...prev, e.target.files[0]]);
  };
  console.log(snapShots);

  const notEnough = (data) => {
    let text = data;
    const result = text.includes("varying(255)");
    return result;
  };

  const filter = (category) => {
    const array = categories.filter((cat) => cat.name === category);
    console.log(array);

    console.log(array[0].id);
    setID(array[0].id);
    // return
  };
  snapShots.map((x, index) => console.log({ x, index }));

  // Data query
  const {
    data: categories,
    isLoading: categoryLoading,
    isError,
    error,
  } = useGetGoodsCategoriesQuery();

  // Mutation method
  const [updateProduct, { isLoading }] = useVendorUpdateProductMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("processing......");

    setLoading(true);

    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("description", description);
    formdata.append("photo", image);
    formdata.append("price", price);
    formdata.append("quantityInStock", quantityInStock);
    formdata.append("weight", weight);
    formdata.append("width", width);
    formdata.append("height", height);
    formdata.append("length", length);
    // snapShots.forEach((snap, index) =>
    //   formdata.append(`screenshots[${index}]`, snap)
    // );

    for (var pair of formdata.entries()) {
      console.log(pair[0] + " - " + pair[1]);
    }

    try {
      updateProduct({ 
        data: formdata,
        id: route.id })
        .unwrap()
        .then((data) => {
          setImage(null);
          console.log(data)
          setEnableEdit(false)
          navigate(-1)
          //   dispatch(addId({ id: data.data.id }));
        });
    } catch (err) {
      console.log(err);
    }
  };

//   console.log(route, data.id);

  return (
    <div>
      <Box sx={style} className="example">
        <div className="mx-auto">
          <Stack spacing={1}>
            <h1 className="font-bold text-[20px]">Add Product</h1>
            <hr />
            <div className="divide-y"></div>
            <form className="space-y-2" onSubmit={handleSubmit}>
              <div>
                <label className="text-left">
                  Product Name <span className="text-red-700 font-bold">*</span>
                </label>
                <TextField
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  placeholder="Black Soap 56g"
                  variant="outlined"
                  className="form-control p-2 w-full rounded-md  border-2 border-gray-200"
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-5">
                <label className="text-left">
                  Product description{" "}
                  <span className="text-red-700 font-bold">*</span>
                </label>
                <TextareaAutosize
                  minRows={5}
                  variant="outlined"
                  type="text"
                  id="description"
                  name="description"
                  value={description}
                  placeholder="Turkey first grade jeans ,very neat and clean."
                  className="form-control p-2 w-full rounded-md  border-2 border-gray-400"
                  required
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div className="grid grid-cols-2 gap-3">
                <div>
                  <label>
                    Price <span className="text-red-700 font-bold">*</span>
                  </label>
                  <TextField
                    id="price"
                    name="price"
                    value={price}
                    placeholder="1,000"
                    variant="outlined"
                    className="form-control p-2 w-full rounded-md  border-2 border-gray-200"
                    required
                    onChange={(e) => setPrice(e.target.value)}
                    // value={price}
                    type="number"
                  />
                </div>
                <div>
                  <label className="text-left">
                    Product Quantity{" "}
                    <span className="text-red-700 font-bold">*</span>
                  </label>
                  <TextField
                    id="quantityInStock"
                    name="quantityInStock"
                    value={quantityInStock}
                    placeholder="10"
                    variant="outlined"
                    type="number"
                    className="form-control p-1 w-full rounded-md  border-2 border-gray-200"
                    required
                    onChange={(e) => setQuantityInStock(e.target.value)}
                  />
                </div>

                {/* <div>
                  <label className="text-left">
                    Category <span className="text-red-700 font-bold">*</span>
                  </label>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Categories
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={category}
                      label="Categories"
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                    >
                      {categories ? (
                        categories.data.map((category) => (
                          <MenuItem value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem className="flex items-center space-x-2">
                          <Oval stroke="#efedfd" height={"1em"} />
                          <p className="text-[10px]">Loading Categories</p>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div> */}
              </div>

              {/* Dimension and measurements */}
              <div className="grid grid-cols-3 gap-3">
                <div className="">
                  <label className="text-[13px]">Width (cm)</label>
                  <input
                    type="number"
                    name="weight"
                    value={width}
                    className="w-full py-2 px-2 border outline-none mt-1 focus-within:ring-2 rounded-sm"
                    onChange={(e) => setWidth(e.target.value)}
                  />
                </div>
                <div className="">
                  <label className="text-[13px]">Length (cm)</label>
                  <input
                    type="number"
                    name="weight"
                    value={length}
                    className="w-full py-2 px-2 border outline-none mt-1 focus-within:ring-2 rounded-sm"
                    onChange={(e) => setLength(e.target.value)}
                  />
                </div>
                <div className="">
                  <label className="text-[13px]">Height (cm)</label>
                  <input
                    type="number"
                    name="weight"
                    value={height}
                    className="w-full py-2 px-2 border outline-none mt-1 focus-within:ring-2 rounded-sm"
                    onChange={(e) => setHeight(e.target.value)}
                  />
                </div>
              </div>

              {/* weight */}
              <div className="">
                <label className="text-[13px]">Weight (kg)</label>
                <input
                  type="number"
                  name="weight"
                  value={weight}
                  className="w-full py-2 px-2 border outline-none mt-1 focus-within:ring-2 rounded-sm"
                  onChange={(e) => setWeight(e.target.value)}
                />
              </div>

              <div className="space-y-3">
                <h2>
                  Product Image{" "}
                  <span className="text-red-700 font-bold">*</span>
                </h2>

                {!image && (
                  <div
                    className="flex items-center space-x-4"
                    onClick={() => inputRef.current.click()}
                  >
                    <h2>Choose From File</h2>
                    <span className="bg-blue-100 font-bold p-2 cursor-pointer rounded-md text-blue-500">
                      Upload
                    </span>
                  </div>
                )}

                {image && (
                  <div>
                    <img
                      src={URL.createObjectURL(image)}
                      className="w-[20%] h-[20%]"
                    />
                    <div className="space-y-4 py-2">
                      <p>{image?.name}</p>
                      {snapShots && (
                        <Grid container spacing={2}>
                          {snapShots.map((snap) => (
                            <Grid item xs={4} md={4}>
                              <div className="space-y-2 flex justify-center items-center flex-col">
                                <img
                                  src={snap ? URL.createObjectURL(snap) : null}
                                  className="w-2/5 h-[8]"
                                />
                                <p className="text-center text-sm">
                                  {snap ? snap.name : ""}
                                </p>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                      <div className=" flex items-center space-x-4">
                        <button
                          onClick={() => {
                            setImage();
                            setSnapShot([]);
                          }}
                          className="border border-[#1E323F] text-[#1E323F] rounded-md py-2 px-4"
                        >
                          Delete
                        </button>
                        <button
                          className="bg-[#1E323F] py-2 px-4 rounded-md text-white"
                          onClick={(e) => {
                            e.preventDefault();
                            inputRef.current.click();
                          }}
                        >
                          Replace
                        </button>
                        <input
                          type="file"
                          className="hidden"
                          ref={multipart}
                          onChange={handleUpload}
                        />
                        <button
                          className="bg-[#1E323F] py-2 px-4 rounded-md text-white"
                          onClick={(e) => {
                            e.preventDefault();
                            multipart.current.click();
                          }}
                        >
                          Add SnapShots
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <input
                  type="file"
                  className="hidden"
                  ref={inputRef}
                  onChange={handleChange}
                  required
                />
              </div>
              <button
                variant="contained"
                disabled={
                  name && description && quantityInStock && price ? false : true
                }
                className={
                  loading
                    ? `py-2 bg-brown-900 text-white w-full mt-4`
                    : `py-2 bg-brown-900 text-white w-full mt-4`
                }
              >
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <p>Save Changes</p>
                )}
              </button>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </form>
          </Stack>
        </div>
      </Box>
    </div>
  );
}
