import { createSlice } from "@reduxjs/toolkit"



const initialState = {
    updateData: {},
    success: false,
    deleteModal: false,
    addService: false
}


export const serviceReducer = createSlice({
    name: 'serviceReducer',
    initialState,
    reducers: {
        openModal: (state) => {
            state.modal = true
        },
        closeModal: (state) => {
            state.modal = false
        },
        addDataToUpdate: (state, action) => {
            state.updateData = action.payload
        },
        openServiceDelete: (state) => {
            state.deleteModal = true
        },
        closeServiceDelete: (state) => {
            state.deleteModal = false
        },
        openAddService: (state) => {
            state.addService = true
        },
        closeAddService: (state) => {
            state.addService = false
        }
    }
})

export const {openModal, closeModal, addDataToUpdate, openServiceDelete, closeServiceDelete, openAddService, closeAddService} = serviceReducer.actions

export default serviceReducer.reducer