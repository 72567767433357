import { Dialog } from "@mui/material";
import { formatToCurrency } from "../../../../../component/utils/currencyFormatter";
import moment from "moment";
import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Oval } from "react-loading-icons";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useVendorAcceptOfferMutation,
  useVendorRejectOfferMutation,
} from "../../../../../redux/apiSlice";
import { openSnackBar } from "../../../../../redux/snackSlice";

export default function SingleVendorOfferDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location);

  const [type, setType] = useState("all");
  const [modal, setModal] = useState(false);

  const serviceDetail = location.state.serviceDetail || {};

  // mutations
  const [acceptOffer, { isLoading: acceptOfferLoading }] =
    useVendorAcceptOfferMutation();
  const [declineOffer, { isLoading: declineOfferLoading }] =
    useVendorRejectOfferMutation();

  // Methods
  function handleCloseModal() {
    setModal(false);
    console.log("close modal");
  }

  function handleAcceptOffer() {
    setModal(true)
    acceptOffer(serviceDetail.id)
      .unwrap()
      .then((data) => {
        console.log(data);
        setModal(false)
        dispatch(openSnackBar({ message: "Offer Accepted!" }));
        navigate(-1);
      });
    console.log("accept offer");
  }

  function handleDeclineOffer() {
    console.log("decline");
    declineOffer(serviceDetail.id)
      .unwrap()
      .then((data) => {
        console.log(data);
        setModal(false)
        dispatch(openSnackBar({ message: "Offer has been declined!" }));
        navigate(-1);
      });
  }

  return (
    <div>
      <div className="bg-white min-h-[500px] rounded-md m-10 p-10">
        <div className="flex items-center my-2">
          <IoIosArrowBack />
          <p>Go Back</p>
        </div>
        <hr />
        <h1 className="mt-5 text-[20px] font-bold">{serviceDetail.title}</h1>
        <p className="mt-2 text-slate-500 font-light">
          {serviceDetail.description}
        </p>

        <hr className="my-2" />
        <div className="grid md:grid-cols-4 grid-cols-2 gap-5 group-hover:text-white">
          <div className="p-1 rounded-sm">
            <div className="text-[12px] group-hover:text-white text-brown-600 mb-2 ">
              Name of Client
            </div>
            <p className="text-[15px] font-bold">
              {serviceDetail.customer.firstName}
            </p>
          </div>
          <div className="p-1 rounded-sm">
            <div className="text-[12px] group-hover:text-white text-brown-600 mb-2">
              Budget
            </div>
            <p className="text-[15px] font-bold">
              {formatToCurrency(serviceDetail.terms.budget)}
            </p>
          </div>
          <div className="p-1 rounded-sm">
            <div className="text-[12px] group-hover:text-white text-brown-600 mb-2">
              Due Date
            </div>
            <p className="text-[15px] font-bold">
              {moment(serviceDetail.terms.dueDate).format("dddd DD MMM, YYYY")}
            </p>
          </div>
          <div className="p-1 rounded-sm">
            <div className="text-[12px] group-hover:text-white text-brown-600 mb-2">
              Status
            </div>
            <button className={`text-[15px] font-bold px-5 py-1.5 rounded-md ${serviceDetail.offerStatus === 'PENDING' ? 'bg-blue-100 text-blue-600' : serviceDetail.offerStatus === 'ACCEPTED' ? 'text-yellow-700 bg-yellow-100' : "bg-green-100 text-green-700" }`}>{serviceDetail.offerStatus}</button>
          </div>
        </div>

        {/* Accept or Reject Offer */}
        <div className="border w-full flex justify-between items-center p-5 mt-10 rounded-md drop-shadow-sm">
          <p>Will you like to approve this offer?</p>
          <div className="flex items-center">
            <button
              className={`px-5 py-2.5 bg-green-600 text-white rounded-lg border text-[14px] w-[150px] ${
                type === "goods" && "border-brown-800 "
              } `}
              onClick={() => handleAcceptOffer("all")}
            >
              Approve
            </button>
            <button
              className={`px-5 py-2.5 rounded-lg text-red-600 font-bold text-[14px] w-[150px] ${
                type === "service" && "border-brown-800 "
              }`}
              // onClick={() => handleChangeType("pending")}
            >
              Decline
            </button>
          </div>
        </div>
        {/* Modals popup */}
        <Dialog open={acceptOfferLoading || declineOfferLoading} onClose={handleCloseModal}>
          <div className="w-[250px] h-[250px] rounded-lg flex justify-center items-center">
            <Oval stroke="#ffb33d" />
          </div>
        </Dialog>
      </div>
    </div>
  );
}
