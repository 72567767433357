



import { IconButton } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { useGetSimilarProductsByIdQuery, useNewArrivalsQuery } from '../../../../redux/apiSlice'
import DummySkeleton from './DummySkeleton'
import StarIcon from '@mui/icons-material/Star';

export default function SimilarProducts({id}) {


    const {data, isLoading, error} = useGetSimilarProductsByIdQuery(id)

    // console.logs(data && data)

  return (
    <div className='mt-10'>
        {!data ? <DummySkeleton /> : (
            <div>

{data.length === 0 ? null : (
    
              <>
              <h1 className='text-xl font-semibold text-brown-800 my-4'>Similar products</h1>
              <div className="grid md:grid-cols-5 grid-cols-2 gap-5 mb-20">{
                data.data.map((detail, i) => {
                  return (
                    <div
                      key={detail.id}


                      className="space-y-2 block"

                    >
                      <Link to={`/market/goods/${detail.id}`}>
                        <div className="hover:border-brown-500 hover:border hover:drop-shadow-md bg-white rounded-sm  pb-2.5">
                          <div className="rounded-lg flex w-full">
                            <img
                              src={detail.productImage}
                              className="w-full h-40 rounded-t cursor-pointer object-cover object-top border "
                            />
                          </div>
                          <div className="flex flex-col w-[90%] mx-auto py-2">
                            <p className="text-black capitalize truncate tracking-tight">
                              {detail.name}
                            </p>
                            <p className="text-xs text-gray-400">{detail.hscode}</p>

                            <div className="flex justify-between items-center">
                              <p className="text-red-700 font-semibold">
                                ${detail.price}
                              </p>
                              {detail.ratings !== 0 && (
                                <div className="flex items-center space-x-1">
                                  <IconButton>
                                    <StarIcon className="text-yellow-500" />
                                  </IconButton>
                                  <p className="font-bold">{detail.ratings}</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                }
                )
              }</div></>

            )}
            </div>
        )}
    </div>
  )
}
