import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Grid, TextareaAutosize } from "@mui/material";
import {
  useChangePasswordMutation,
  useGetListOfCountriesQuery,
} from "../../../../redux/apiSlice";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { Oval } from "react-loading-icons";
import { ToastContainer, toast } from "react-toastify";

export default function AccountIndex() {
  const { userInfo } = useSelector((state) => state.auth);
  const currentUser = userInfo ? userInfo : {};
  const [userData, setUserData] = useState({
    address: currentUser.address,
    age: currentUser.age,
    city: currentUser.city,
    country: currentUser.country,
    firstName: currentUser.firstName,
    gender: currentUser.gender,
    lastName: currentUser.lastName,
    phoneNumber: currentUser.phoneNumber,
    state: currentUser.state,
  });
  const [status, setStatus] = useState(null);
  const [visible, setVisible] = useState(false);
  const [lengthCheck, setLengthCheck] = useState(false);
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  // Effect
  useEffect(() => {
    if (password.newPassword === "" || password.confirmPassword === "") {
      // If both fields are empty, don't show any message
      setStatus(null);
      setLengthCheck(false);
    } else if (password.newPassword === password.confirmPassword) {
      if (password.newPassword.length < 6) {
        setStatus(false);
        setLengthCheck(true);
      } else {
        setStatus(true);
        setLengthCheck(false);
      }
    } else {
      // If passwords don't match, set status to false
      setStatus(false);
      setLengthCheck(false);
    }
  }, [password.newPassword, password.confirmPassword]);

  function onInputChangeHandler(e) {
    const { name, value } = e.target;
    // console.log(name, value);
    setPassword({ ...password, [name]: value });
  }

  function showPasswordHandler(value) {
    setVisible(value);
  }

  const { data: countryList } = useGetListOfCountriesQuery();
  const [changePassword, { isLoading }] = useChangePasswordMutation();

  function handleInputChange(e) {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  }

  const handleSubmitChange = (e) => {
    e.preventDefault();

    changePassword({
      newPassword: password.newPassword,
      oldPassword: password.oldPassword,
    })
      .unwrap()
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err.data.message);
        toast.error(err.data.message)
      });
  };

  console.log(status);

  return (
    <div className="bg-gray-100 min-h-screen space-y-5 mb-40">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col space-y-5">
        <div className="flex justify-between items-center mb-10">
          <h1 className="font-bold">My Profile</h1>
          <button className="px-3 py-2 rounded-md border-brown-700 border">
            Edit Profile
          </button>
        </div>
        <div className="grid grid-cols-2 w-[500px]">
          <p className="text-gray-600 font-bold">First Name</p>
          <p className="text-gray-600 font-bold">{userInfo.firstName}</p>
        </div>
        <div className="grid grid-cols-2 w-[500px]">
          <p className="text-gray-600 font-bold">Last Name</p>
          <p className="text-gray-600 font-bold">{userInfo.lastName}</p>
        </div>
        <div className="grid grid-cols-2 w-[500px]">
          <p className="text-gray-600 font-bold">Phone Number</p>
          <p className="text-gray-800">{userInfo.phoneNumber}</p>
        </div>
        <div className="grid grid-cols-2 w-[500px] mt-4 sm:mt-0">
          <p className="text-gray-600 font-bold">Email</p>
          <p className="text-gray-800">{userInfo.email}</p>
        </div>

        <div className="grid grid-cols-2 w-[500px]">
          <p className="text-gray-600 font-bold">Address</p>
          <p className="text-gray-800">{userInfo.address}</p>
        </div>
        <div className="grid grid-cols-2 w-[500px] mt-4 sm:mt-0">
          <p className="text-gray-600 font-bold">City</p>
          <p className="text-gray-800">{userInfo.city}</p>
        </div>
        <div className="grid grid-cols-2 w-[500px] mt-4 sm:mt-0">
          <p className="text-gray-600 font-bold">State</p>
          <p className="text-gray-800">{userInfo.state}</p>
        </div>
        <div className="grid grid-cols-2 w-[500px] mt-4 sm:mt-0">
          <p className="text-gray-600 font-bold">Country</p>
          <p className="text-gray-800">{userInfo.country}</p>
        </div>
      </div>

      {/* Change Password */}
      <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col space-y-5">
        <form>
          <Grid item xs={12} md={6}>
            <div className="col-6">
              <label className="text-sm">Current Password</label>
              <input
                type={visible ? "text" : "password"}
                id="oldPassword"
                name="oldPassword"
                placeholder="*****"
                value={password.oldPassword}
                className="px-2 py-2.5 w-full ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                required
                onChange={onInputChangeHandler}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="col-6">
              <label className="text-sm">New Password</label>
              <div className="relative">
                <div className="absolute top-3 right-3">
                  {visible ? (
                    <IoIosEyeOff
                      fontSize={23}
                      onClick={() => showPasswordHandler(false)}
                    />
                  ) : (
                    <IoIosEye
                      fontSize={23}
                      onClick={() => showPasswordHandler(true)}
                    />
                  )}
                </div>
                <input
                  type={visible ? "text" : "password"}
                  id="newPassword"
                  name="newPassword"
                  value={password.newPassword}
                  placeholder="*********"
                  className="px-2 py-2.5 w-full ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                  required
                  onChange={onInputChangeHandler}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="col-6">
              <label className="text-sm">Retype New Password</label>
              <input
                type={visible ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                value={password.confirmPassword}
                placeholder="*********"
                className="px-2 py-2.5 w-full ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                required
                onChange={onInputChangeHandler}
              />
            </div>
            {password.newPassword.length < 6
              ? "Password should be 6 character mminimum"
              : ""}
            <div
              className={`  my-2 ${
                status === null
                  ? "hidden"
                  : status
                  ? " text-green-600"
                  : "text-red-600"
              }`}
            >
              <p>
                {status === null
                  ? ""
                  : status
                  ? "Password matches"
                  : "Passwords do not match"}
              </p>
            </div>
          </Grid>
          <div className="w-full py-6 flex justify-end">
            <button
              className="bg-brown-700 font-bold py-2 px-4 text-white rounded-md"
              onClick={handleSubmitChange}
            >
              {isLoading ? <Oval /> : "Update Password"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

// <div className="space-y-5">
//   <div className="bg-white p-5">
//     <h2 className="font-bold">My Profile</h2>

//     <div className="flex flex-col justify-center mt-10">
//       <div className="w-24 h-24 bg-slate-600 rounded-full">
//         <img src="" className="object-cover" />
//       </div>
//       <h4 className="text-2xl font-bold mt-3">{currentUser.firstName}</h4>
//       <p className="text-base">{currentUser.category}</p>
//     </div>
//     <hr className="my-3" />

//     <div className="mx-auto">
//       <form className="block font-sans" onChange={handleInputChange}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} md={6}>
//             <div className="">
//               <label className="text-sm">First Name</label>

//               <input
//                 type="text"
//                 id="firstname"
//                 name="firstName"
//                 placeholder={currentUser.firstName}
//                 value={currentUser.firstName}
//                 className="px-2 py-2.5 w-full text-gray-700 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
//                 required
//               />
//             </div>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <div className="col-6">
//               <label className="text-sm"> Last Name</label>
//               <input
//                 type="text"
//                 id="lastname"
//                 name="lastName"
//                 placeholder={currentUser.lastName}
//                 value={currentUser.lastName}
//                 className="px-2 py-2.5 w-full text-gray-700 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
//                 required
//               />
//             </div>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <div className="">
//               <label className="text-sm">
//                 <div>Email</div>
//               </label>
//               <input
//                 type="text"
//                 id="email"
//                 name="email"
//                 placeholder={currentUser.email}
//                 value={currentUser.email}
//                 className="px-2 py-2.5 w-full text-gray-300 focus:ring-[2px] rounded outline-none mt-2"
//                 required
//                 readonly
//               />
//             </div>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <div className="col-6">
//               <label className="text-sm">Phone Number</label>
//               <input
//                 type="text"
//                 id="phoneNumber"
//                 name="phoneNumber"
//                 placeholder={currentUser.phoneNumber}
//                 value={currentUser.phoneNumber}
//                 className="px-2 py-2.5 w-full text-gray-300 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
//                 required
//                 readonly
//               />
//             </div>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             {/* <div
//               name="country"
//               as="select"
//               className={`w-full px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
//                 !countryList ? "hidden" : "block"
//               }`}
//             >
//               {countryList
//                 ? countryList.data.map((country, i) => (
//                     <option key={i} value={country.alpha3Abbr}>
//                       {country.fullName}
//                     </option>
//                   ))
//                 : null}
//             </div> */}
//             <div className="">
//               <label className="text-sm">
//                 <div>Nationality</div>
//               </label>
//               <input
//                 type="text"
//                 id="country"
//                 name="country"
//                 placeholder={currentUser?.businessInfo?.nationality}
//                 value={currentUser?.businessInfo?.nationality}
//                 className="px-2 py-2.5 w-full text-gray-300 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
//                 required
//                 readonly
//               />
//             </div>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <div className="col-6">
//               <label className="text-sm"> State</label>
//               <input
//                 type="text"
//                 id="state"
//                 name="state"
//                 placeholder={currentUser.state}
//                 value={currentUser.state}
//                 className="px-2 py-2.5 w-full text-gray-300 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
//                 required
//               />
//             </div>
//           </Grid>
//         </Grid>
//         <div className="py-6 flex">
//           <button className="bg-blue-300 font-white font-bold py-2 px-4 text-white rounded-md">
//             Save
//           </button>
//         </div>
//       </form>
//     </div>
//   </div>
//   <div className="bg-white py-5">
//     <div className="mx-auto w-[95%]">
//       <div>
//         <h1 className="text-lg font-bold">Update Password</h1>
//       </div>
//       <div className="py-5">
//         <Grid container spacing={2}>
// <Grid item xs={12} md={6}>
//   <div className="col-6">
//     <label className="text-sm">Current Password</label>
//     <input
//       type="password"
//       id="lastname"
//       name="lastName"
//       placeholder="*****"
//       className="px-2 py-2.5 w-full text-gray-300 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
//       required
//       readonly
//     />
//   </div>
// </Grid>
//   <Grid item xs={12} md={6}></Grid>
//   <Grid item xs={12} md={6}>
//     <div className="col-6">
//       <label className="text-sm">New Password</label>
//       <input
//         type="password"
//         id="lastname"
//         name="lastName"
//         placeholder="*********"
//         className="px-2 py-2.5 w-full text-gray-300 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
//         required
//         readonly
//       />
//     </div>
//   </Grid>
//   <Grid item xs={12} md={6}>
//     <div className="col-6">
//       <label className="text-sm">Retype New Password</label>
//       <input
//         type="password"
//         id="lastname"
//         name="lastName"
//         placeholder="*********"
//         className="px-2 py-2.5 w-full text-gray-300 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
//         required
//         readonly
//       />
//     </div>
//   </Grid>
//   <div className="w-full py-6 flex justify-end">
//     <button className="bg-blue-300 font-white font-bold py-2 px-4 text-white rounded-md">
//       Save
//     </button>
//   </div>
// </Grid>
//       </div>
//     </div>
//   </div>
// </div>
