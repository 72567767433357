import { Grid, Skeleton } from '@mui/material'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGetGoodsCategoriesQuery, useSingleGoodsCategoryQuery } from '../../../redux/apiSlice'
import Cover from '../child/Cover'
import GoodsSubCategoryProducts from './child/GoodsSubCategoryProducts'
import Main from './child/Main'
import Sidebar from './child/Sidebar'
import CategoryList from './GoodsCategoryList'

export default function AllGoodsCategory() {



  const route = useParams()

  const { data, isLoading, error } = useGetGoodsCategoriesQuery()
  

  return (
    <>
      <div className='mt-8'>
        <h1 className='text-3xl font-semibold'>Discover Categories</h1>
        <div className="grid md:grid-cols-4 grid-cols-2  gap-5 mt-7">
          {!data ? (
            <>
              {testNum.map((category, i) => (

                <div key={i} className="flex justify-between items-center rounded-md drop-shadow  h-20">
                  <Skeleton sx={{ height: 80 }} animation="wave" variant="rectangular" />
                  <div className=" flex-1">
                    <div className="ml-3">
                      <Skeleton sx={{ height: 20 }} animation="wave" variant="rectangular" />
                      <Skeleton sx={{ height: 10 }} animation="wave" variant="rectangular" />
                    </div>
                  </div>
                </div>

              ))}
            </>
          ) : (
            <>
              {data.data.map((category, i) => (
                <Link key={i} to={`/market/goods/category/${category.id}`} state={{
                  categoryName: category.name
                }}>
                  <div className="flex justify-between items-center rounded-md drop-shadow bg-white h-20">
                    <img
                      src={category.image}
                      className="w-20 h-20 shadow-md rounded-l-lg object-cover"
                    />
                    <div className="bg-white flex-1">
                      <div className="ml-3">
                        <p className="capitalize text-xs">{category.name}</p>
                        <p className="text-gray-300 text-[9px]">
                          {category.productCount > 1
                            ? category.productCount + ` products`
                            : category.productCount + ` product`}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  )
}
const testNum = [1, 2, 3, 4, 5, 6, 7, 8]