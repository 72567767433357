import {
  Dialog,
  Grid,
  IconButton,
  Skeleton,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddressModal from "./AddressModal";
import {
  useDeleteShippingAddressMutation,
  useGetAllShippingAddressQuery,
} from "../../../../redux/apiSlice";
import { MdDelete } from "react-icons/md";
import { useTheme } from "@emotion/react";
import Oval from "react-loading-icons/dist/esm/components/oval";

const Address = () => {
  const [info, setInfo] = useState();
  const [open, setOpen] = React.useState(false);
  const [hovered, setHovered] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  // set hovered
  function pickAddressToDelete(value) {
    setHovered((prev) => (value === prev ? prev : value));
  }

  // Data
  const { data, isLoading, error } = useGetAllShippingAddressQuery();

  const [deleteAddress, { isLoading: deleteAddressLoading }] =
    useDeleteShippingAddressMutation();

  // Methods
  function closeDeleteDialog() {
    setDeleteDialog(false);
  }

  function openDeleteDialog() {
    setDeleteDialog(true);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleOpenSnack() {
    setOpenSnack(true);
  }

  function handleCloseSnack() {
    setOpenSnack(false);
  }

  function handleDeleteAddress(id) {
    setDeleteLoading(true);
    deleteAddress(id)
      .unwrap()
      .then((data) => {
        setDeleteLoading(false);

        setDeleteDialog(false);
        handleOpenSnack();
      });
  }

  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={4000}
        onClose={handleCloseSnack}
        message="Address Deleted"
      />
      <div className="space-y-5">
        <div className="flex justify-between items-center p-5 rounded-md bg-white">
          <h2 className=" font-bold">My Address {info?.length}</h2>
          <button
            className="bg-brown-700 py-2 px-3 rounded-md text-white"
            onClick={() => handleClickOpen()}
          >
            Add New Address
          </button>
        </div>

        <div className="">
          {!data ? (
            <>
              <Skeleton />
            </>
          ) : (
            <>
              {data.data.length === 0 ? (
                <div>
                  <p>You have no address yet!</p>
                  <p className="text-xs text-slate-400">
                    Add new shipping address by clicking the button above
                  </p>
                </div>
              ) : (
                <div>
                  <div className="space-y-2">
                    {data.data.map((address, index) => (
                      <div
                        className="block bg-white rounded-md p-5"
                        key={index}
                        onMouseEnter={() => pickAddressToDelete(index)}
                      >
                        <div className="">
                          <div className="flex items-center justify-between">
                            <h1 className="font-light text-slate-500 text-[18px]">
                              {address.contactName}
                            </h1>
                            {hovered === index && (
                              <>
                                <button
                                  className="px-3 py-1.5 text-white bg-red-600 flex items-center space-x-1 rounded-md"
                                  onClick={openDeleteDialog}
                                >
                                  <MdDelete /> <span>Delete</span>
                                </button>
                                {/* Delete Dialog */}
                                <Dialog
                                  // fullScreen={fullScreen}
                                  open={deleteDialog}
                                  onClose={closeDeleteDialog}
                                  aria-labelledby="responsive-dialog-title"
                                >
                                  {deleteLoading ? (
                                    <>
                                      <div className="min-w-[250px] md:min-h-[250px] rounded-md md:py-5 py-10 flex flex-col items-center justify-center">
                                        <Oval />
                                      </div>
                                    </>
                                  ) : (
                                    <div className="min-w-[250px] md:min-h-[250px] rounded-md md:py-5 py-10 flex flex-col items-center justify-center">
                                      <h1 className="text-[25px] text-center px-10">
                                        Are you sure you want to delete this
                                        address?
                                      </h1>
                                      <div className="flex items-center md:space-x-5 space-x-2 mt-5">
                                        <button className="bg-blue-200  px-5 py-3 rounded-md text-[14px] font-bold text-blue-500">
                                          Cancel
                                        </button>
                                        <button
                                          className="text-white px-5 py-3 rounded-md text-[14px] font-bold bg-red-600 min-w-[200px]"
                                          onClick={() =>
                                            handleDeleteAddress(address.id)
                                          }
                                        >
                                          Yes, Delete
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </Dialog>
                              </>
                            )}
                          </div>
                          <h1 className="text-lg font-bold">
                            {address.address}
                          </h1>
                          <div className="flex items-center justify-between">
                            <p className="text-[14px] text-slate-600 mt-3.5">
                              {address.phoneNumber}
                            </p>
                            <div>
                              {address.default && (
                                <div className="flex justify-end">
                                  <button className="bg-blue-200 px-2 rounded-md text-[14px] font-bold text-blue-500">
                                    Default
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <AddressModal
        open={open}
        setOpen={setOpen}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
    </>
  );
};

export default Address;

{
  /* <div className='border border-gray-200 rounded-md'>
                                            <div className='py-3 px-3 space-y-2'>
                                                <div className='flex justify-between items-center'>
                                                    <h1 className="text-xl">{info.Address}</h1>
                                                    <IconButton>
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                </div>

                                                <p className='font-bold'>{info.address}</p>
                                                <p className='text-sm text-gray-300'>{info?.phoneNumber}</p>
                                                {index === 0 && (
                                                    <div className="flex justify-end">
                                                        <button className='bg-blue-200 px-2 rounded-md font-bold text-blue-500'>Default</button>
                                                    </div>
                                                )}
                                            </div>
                                        </div> */
}
