import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ToastContainer } from "react-toastify";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Backdrop,
  Fade,
  IconButton,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import {
  useAddNewServiceMutation,
  useGetServiceCategoriesQuery,
} from "../../../../../redux/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  // border: '1px solid #000',
  boxShadow: 24,
  borderRadius: 4,
  p: 3,
  maxHeight: "90vh",
  overflowY: "scroll",
};

export default function AddNewService({
  open,
  handleClose,
  setOpen,
  handleOpen,
}) {
  const [loading, setLoading] = useState(false);
  const [part, setPart] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [specialization, setSpecialization] = useState(null);
  const [tags, setTags] = useState("");
  const [price, setPrice] = useState("");
  const [projectUrl, setUrl] = useState("");
  const [image, setImage] = useState(null);

  //   data

  const token = useSelector((state) => state.auth.userToken);
  const { data: categoryList, isLoading } = useGetServiceCategoriesQuery();
  //   console.log(categoryList && categoryList);

  const [addNewService, { isLoading: newServiceLoading }] =
    useAddNewServiceMutation();

  //   Hook
  const dispatch = useDispatch;
  const imageRef = React.useRef();

  //   Methods
  function handleUploadImage() {
    imageRef.current.click();
  }

  function updateCoverImage(e) {
    setImage(e.target.files[0]);

  }

  // Method to set all field state to ""

  const handleReset = () => {
    setTitle("");
    setDescription("");
    setCategory("");
    setSpecialization("");
    setTags("");
    setPrice("");
    setUrl("");
    setImage(null);
  }

  const handleSubmitService = async (e) => {
    e.preventDefault();
    setLoading(true)

    const formData = new FormData();
    formData.append("name", title);
    formData.append("description", description);
    formData.append("image", image);
    formData.append("price", price);
    formData.append("maxPrice", price);
    // formData.append("specialization", specialization)
    formData.append("categoryId", category);
    formData.append("tags", tags);
    formData.append("projectUrl", "https://testurl.com");
    // snapShots.forEach((snap, index) =>
    //   formData.append(`screenshots[${index}]`, snap)
    // )

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `${token}`,
    };

    for (var pair of formData.entries()) {
      console.log(pair[0] + " - " + pair[1]);
    }
    console.log(token);

    try {
      addNewService(formData)
        .unwrap()
        .then((data) => {
          console.log(data);
          setLoading(false)
          handleReset()
          handleClose();

        })
        .catch((err) => {
          console.log("Erro", err);
          setPart(false);
          setLoading(false)
        });
    } catch (err) {
      console.log("eeror", err);
      setPart(false);
      setLoading(false);
    }
  };

  // console.log("Part is", part);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="example">
          <div className="w-[90%] mx-auto">
            <Stack spacing={2}>
              {!part && (
                <div>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Add Services(1/2)
                  </Typography>
                  <div>
                    <div className="py-3">
                      <div className="divide-y w-[50%]  border-brown-700 border"></div>
                    </div>
                    <form className="space-y-2">
                      <div>
                        <label className="text-left">
                          <div className="mb-3">
                            What service do you offer?
                            <span className="text-red-700 font-bold">*</span>
                          </div>
                        </label>
                        <TextField
                          type="text"
                          id="title"
                          name="title"
                          placeholder="Title of project"
                          variant="outlined"
                          className="form-control px-2 w-full rounded-md  border-2 border-gray-200"
                          required
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="text-left">
                          <div className="mb-3">
                            Describe the nature of the service{" "}
                            <span className="text-red-700 font-bold">*</span>
                          </div>
                        </label>
                        <TextareaAutosize
                          minRows={5}
                          variant="outlined"
                          type="text"
                          id="description"
                          name="description"
                          placeholder="Brief description of project"
                          className="form-control p-2 w-full rounded-md  border-2 border-gray-400"
                          required
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                      <div className="">
                        <FormControl className="w-1/2">
                          <InputLabel id="demo-simple-select-label">
                            Category
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={category}
                            label="Category"
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            {categoryList &&
                              categoryList.data.map((cat, i) => (
                                <MenuItem key={i} value={cat.id}>
                                  {cat.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <Grid container spacing={3}>
                          {/* <Grid item xs={12} md={6}>
                            <label className="text-left">
                              <div className="mb-3">
                                Category{" "}
                                <span className="text-red-700 font-bold">
                                  *
                                </span>
                              </div>
                            </label>
                            <TextField
                              id="lastname"
                              name="lastname"
                              placeholder="Design/Creative"
                              variant="outlined"
                              className="form-control p-2 w-full rounded-md  border-2 border-gray-200"
                              required
                              // onChange={(e) => setPrice(e.target.value)}
                              // value={price}
                              onChange={(e) => setCategory(e.target.value)}
                              type="text"
                            />
                          </Grid> */}
                          <Grid item xs={12} md={6} className="pt-5 hidden">
                            <label className="text-left">
                              <div className="mb-3 ">
                                Specialization{" "}
                                <span className="text-red-700 font-bold">
                                  *
                                </span>
                              </div>
                            </label>
                            <TextField
                              id="specialization"
                              name="specialization"
                              placeholder=""
                              variant="outlined"
                              type=""
                              className="form-control p-2 w-full rounded-md  border-2 border-gray-200"
                              required
                              onChange={(e) =>
                                setSpecialization(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <label className="text-left">
                              <div className="mb-3 flex items-center justify-between">
                                <p> Special tags</p>
                                <p className="text-gray-300">8 tags min</p>
                              </div>
                            </label>
                            <TextField
                              id="tags"
                              name="tags"
                              placeholder=""
                              variant="outlined"
                              type="text"
                              className="form-control p-2 w-full rounded-md  border-2 border-gray-200"
                              required
                              onChange={(e) => setTags(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <label className="text-left">
                              <div className="mb-3 ">
                                Opening Price{" "}
                                <span className="text-red-700 font-bold">
                                  *
                                </span>
                              </div>
                            </label>
                            <TextField
                              id="lastname"
                              name="lastname"
                              placeholder=""
                              variant="outlined"
                              type="number"
                              className="form-control p-2 w-full rounded-md  border-2 border-gray-200"
                              required
                              onChange={(e) => setPrice(e.target.value)}
                            />
                          </Grid>
                          {/* <Grid item xs={12} md={6}>
                                           </Grid> */}
                        </Grid>
                      </div>
                      <div className="space-y-3"></div>
                      <button
                        variant="contained"
                        onClick={() => {
                          setPart(true);
                        }}
                        className={
                          tags &&
                          price &&
                          // specialization &&
                          description &&
                          category &&
                          title
                            ? `py-2 bg-brown-900 text-white w-full mt-4 rounded-md`
                            : `py-2 bg-blue-100 text-white w-full mt-4`
                        }
                      >
                        {loading ? (
                          <div className="flex items-center justify-center">
                            <CircularProgress />
                          </div>
                        ) : (
                          <p>Proceed</p>
                        )}
                      </button>
                      <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                    </form>
                  </div>
                </div>
              )}
              {part && (
                <div>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Add Services(2/2)
                  </Typography>
                  <div>
                    <div className="py-3">
                      <div className="divide-y w-[100%]  border-brown-700 border"></div>
                    </div>
                    <Stack spacing={1}>
                      <div className="py-3">
                        <h2>
                          Service Upload <span className="text-red-500">*</span>{" "}
                        </h2>
                      </div>
                      <h2>Upload up to 5 images, with max size 10MB each</h2>
                      <h2>
                        High resolution images (jpg, png){" "}
                        <span className="text-red-500">*</span>
                      </h2>
                      <div className="py-4">
                        <div
                          className="h-[150px] w-[150px] rounded-lg hover:bg-slate-50 flex flex-col justify-center items-center border border-gray-200 cursor-pointer"
                          ref={imageRef}
                          onClick={handleUploadImage}
                        >
                          <IconButton>
                            <PhotoLibraryIcon className="text-blue-500 pointer-events-none" />
                          </IconButton>
                          <p className="text-slate-300 text-center text-[13px]">
                            Click to upload image for service
                          </p>
                        </div>
                        <input
                          hidden
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          ref={imageRef}
                          onChange={updateCoverImage}
                        />
                      </div>
                      <div className="py-4 space-y-4 mt-3">
                        <p className="text-lg font-bold">Project Url</p>
                        <div className="h-3/5">
                          <TextField
                            variant="outlined"
                            className="w-full"
                            onChange={(e) => setUrl(e.target.value)}
                          />
                        </div>
                      </div>
                    </Stack>
                    <div className="py-4">
                      <button
                        variant="contained"
                        onClick={handleSubmitService}
                        className={`py-2 bg-brown-900 text-white w-full mt-4 rounded-md`}
                      >
                        {loading ? (
                          <div className="flex items-center justify-center">
                            <CircularProgress />
                          </div>
                        ) : (
                          <p>Save Changes</p>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </Stack>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
