import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../utils/Header";
import { useResetpasswordMutation } from "../../../redux/apiSlice";
import { Oval } from "react-loading-icons";

export default function ResetPassword() {
  const [details, setDetails] = useState({
    password: "",
    confirmPassword: "",
    code: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [status, setStatus] = useState(null);
  const [lengthCheck, setLengthCheck] = useState(false)

  const location = useLocation();
  const navigate = useNavigate()

  // Effect
  useEffect(() => {
    if (details.password === '' || details.confirmPassword === '') {
      // If both fields are empty, don't show any message
      setStatus(null);
    } else if (details.password === details.confirmPassword) {
      if (details.password.length < 6) {
        setLengthCheck(true)
      }
      // If passwords match, set status to true
      setStatus(true);
    } else {
      // If passwords don't match, set status to false
      setStatus(false);
      setLengthCheck(false)
    }
  }, [details.password, details.confirmPassword]);
  
  const [resetPassword, { isLoading }] = useResetpasswordMutation();

  const togglePassword = (e) => {
    e.preventDefault()
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const handleSubmitResetPassword = (e) => {
    e.preventDefault()
    const {code, password, confirmPassword} = details
    const {email} = location.state
    console.log(email, code, password)
    if (password === confirmPassword) {
      resetPassword({
        code: code,
        email: email,
        password: password,
      }).unwrap()
      .then(data => {
          console.log(data)
          navigate('/reset-success')
      })
      .catch(error => {
          console.log(error)
      })
    }
    
  };

  return (
    <>
      <Header />

      <div className="flex flex-col justify-center items-center md:h-[95vh] h-[95vh] md:pt-0 pt-20 relative px-10 overflow-hidden">
        <div className="my-5">
        <h3 className="text-center font-bold text-2xl mb-10">
              Reset Password
            </h3>
            <p className="text-gray-500 mb-6 text-center hidden">
              A code has been sent to your email, enter it below and your new password to reset your password
            </p>
        </div>
        <form className="mt-4 ">
          <div className="w-[350px] md:w-[450px] space-y-5">
            <div className="relative">
              <label className="text-left text-gray-400">
                <div>Code</div>
              </label>
              <input
                type="number"
                maxLength={4}
                minLength={4}
                name="code"
                value={details.code}
                onChange={handleChange}
                style={{ borderRight: "0px" }}
                className="px-2 py-2.5 w-full ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                required
              />
            </div>
            <div className="">
              <label className="text-left text-gray-400">
                <div>Password</div>
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={details.password}
                  onChange={handleChange}
                  style={{ borderRight: "0px" }}
                  className="px-2 py-2.5 w-full ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                  required
                />
                <div className="absolute top-4 right-3">
                  <button className="btn " onClick={togglePassword}>
                    {showPassword ? (
                      <i className="fa fa-eye"></i>
                    ) : (
                      <i className="fa fa-eye-slash"></i>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="relative">
              <label className="text-left text-gray-400">
                <div>Confirm Password</div>
              </label>
              <input
                type={showPassword ? "text" : "password"}
                name="confirmPassword"
                value={details.confirmPassword}
                onChange={handleChange}
                style={{ borderRight: "0px" }}
                className="px-2 py-2.5 w-full ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                required
              />
            </div>
            <small className="">
              <div
              className={`  my-2 ${
                status === null
                  ? "hidden"
                  : status
                  ? " text-green-600"
                  : "text-red-600"
              }`}
            >
              <p>
                {status === null
                  ? ""
                  : status
                  ? "Passwords match"
                  : "Passwords do not match"}
              </p>
            </div>
            </small>
          </div>
          <div className="">
                <button
                  className={`text-brown-100 bg-brown-900 py-2.5 px-5 w-full my-2 rounded-md`}
                  onClick={handleSubmitResetPassword}
                >
                  {isLoading ? <Oval /> : "Reset My Password"}
                </button>
              </div>
        </form>
      </div>
    </>
  );
}
