import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from "@mui/material";
import { Field } from "formik";
// import { Button, ButtonGroup } from '@chakra-ui/react'

function Confirmation({ switchTerms, errors, terms }) {
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // console.log(terms)

  return (
    <>
      <div className="mb-5 mt-32">
        <p>
          By clicking the confirm button below, you agree to our{" "}
          <Link to="/terms">Terms and Conditions</Link> and{" "}
          <Link to="/privacy">Privacy Policy</Link>
        </p>
      </div>

      {/* <input
        type="checkbox"
        name="terms"
        checked={terms}
        onChange={() => switchTerms()} 
        required
      /> */}
      {/* <small className="ml-3">
        I agree to <Link to="#">terms of conditions</Link> and{" "}
        <Link to="#">privacy policy</Link>{" "}
      </small> */}
    </>
  );
}

export default Confirmation;
