import React from 'react'
import overlayBackground from "../../../assets/svg/about-background-overlay.svg";
import styleVector from "../../../assets/svg/dashboard-vector.svg"
import Footer from '../../utils/Footer';
import Header from '../../utils/Header';

export default function RulesOfOrigin() {
    return (
      <div>
        <Header />
        <section
          className={`md:px-40 px-10 bg-brown-800 bg-blend-overlay h-80 text-white flex md:justify-start justify-center items-center relative overflow-hidden`}
                style={{
            backgroundImage: `url(${styleVector})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "100% 100%",
                    backgroundSize: "cover",
            }}
            >
                
          <h1 className="md:text-5xl text-2xl md:text-left text-center">
            Rules of Origin Guide
          </h1>
        </section>
        <section className="md:px-40 md:py-0 px-10 py-20 items-center grid grid-cols-1 md:grid-cols-2 md:h-[650px] overflow-hidden">
          <div className="block md:space-y-4 text-center md:text-left">
            <h1 className="md:text-4xl text-2xl font-bold mb-4">
              Roles of Rules of Origin in a preferential trade area
            </h1>
            <p>
              Rules of Origin (RoO) are legal provisions that are used to
              determine the nationality of a product in the context of
              internatoinal trade. A distinction can be made between
              non-preferential and preferential RoO:
            </p>
            <p className="my-5">
              Non- preferential RoO involves national laws used to allocate
              origin to traded goods for purposes such as trade statistics,
              trade remedies, labelling requirements and variations other
              purposes, while preferential RoO are the rregulations contained in
              the agrrements of preferential trade areas between two or more
              countries (such as those forming part of the AfCFTA), and which
              prescribe the minimum amount of precessing and other criteria that
              must be compiled with in order to make a determination on the
              preferential trade status of a product
            </p>
          </div>
          <div className="">
            <img src="" />
          </div>
        </section>
        <section className="h-[450px] bg-brown-200 text-brown-800 flex justify-center items-center relative overflow-hidden">
          {/* side overlay */}
          <div className="absolute right-0 top-16 hidden md:flex">
            <img src={overlayBackground} />
          </div>
          <h1 className="md:text-3xl text-2xl font-bold md:w-1/2 w-full text-center px-10 md:px-0">
            Each product that is traded under the AfCFTA scheme must meet the
            relevant criteria set by the AfCFTA RoO
          </h1>
        </section>
        <section className="md:h-screen py-20 md:py-0 flex flex-col justify-center md:px-40 px-10 overflow-hidden">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="block md:space-y-4 text-center md:text-left">
              <h1 className="md:text-4xl text-2xl font-bold mb-4">
                Rules of origin that govern intra-African Trade
              </h1>
              <p>
                The AfCFTA recognises existing regional economic communities
                (RECs) and these continue to remain in place and function as
                before; in other words, the AfCFTA does not abolish or replace
                these. For any trade that takes place among countries that are
                party to such an arrangement, the pre-existing tariff regimes
                and associated regional Rules of Origin provisions and
                associated preferences will continue to apply. The AfCFTA
                therefore adds a preferential trade regime for trade between
                countries where trade previously took place on a standard MFN
                basis, in other words, without any special preferences being
                available. The RECs in turn are seen as the building blocks of
                the AfCFTA
              </p>
            </div>
            <div className=""></div>
          </div>
          <div className="block mt-10">
            <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
              <div className="hover:shadow-md p-5 rounded-md bg-brown-100">
                <h1 className="text-lg font-bold">
                  Trade withing existing RECs / Free Trade Area (e.g SADC,
                  COMESA, ECOWAS, ECCAS)
                </h1>
                <p>
                  Established RoO and tarrifs of these RECs will continue to
                  apply to intra-regional trade
                </p>
              </div>
              <div className="hover:shadow-md p-5 rounded-md bg-brown-100">
                <h1 className="text-lg font-bold">
                  Trade between countries not in an established REC relationship
                  (e.g South Africa-Kenya, Ghana-Ethiopia etc)
                </h1>
                <p>
                  New AfCFTA RoO and tariffs apply,as negotiated (in tarrif
                  lines and RoO, where agreed and implemented)
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="flex flex-col justify-center md:px-40 px-10 py-40 bg-brown-900 overflow-hidden">
          <h1 className="md:text-4xl text-2xl font-bold text-white text-center mb-10">
            The Status of the RoO negotiations
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10 items-center">
            <div className="hover:shadow-md p-5 rounded-md bg-brown-100">
              <h3 className="text-lg font-bold">
                Trade under the AfCFTA was set to begin on 1 January 2021
              </h3>
            </div>
            <div className="hover:shadow-md p-5 rounded-md bg-brown-100">
              <h3 className="text-lg font-bold">
                Impacts countries that did not previously trade with each other
                on preferential terms
              </h3>
            </div>
            <div className="hover:shadow-md p-5 rounded-md bg-brown-100">
              <h3 className="text-lg font-bold">
                Negotiations on RoO and tariff schedules are ongoing
              </h3>
            </div>
          </div>
        </section>
        <section className="flex flex-col justify-center px-10 md:px-40 py-10 overflow-hidden">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="block space-y-4 text-center md:text-left">
              <p>
                A decision was made by the heads of State of the African Union
                in December 2020 that trade under the AfCFTA should officially
                begin on 1 January 2021, between AU members that have become
                State Parties to the AfCFTA and previously traded with one
                another on standard MFN terms. This would apply goods for which
                RoO have been agreed and at tarrifs provided for in the
                respective tarrif offers made by the State Parties, provided
                that these offers are compliant with the agreed modalitiesss for
                the tarrif negotiations
              </p>
            </div>
          </div>
        </section>
        <section className="flex flex-col justify-center px-10 md:px-40 md:py-40 py-20 bg-brown-900 overflow-hidden">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <div className="hover:shadow-md p-10 rounded-md bg-brown-100">
              <h3 className="text-lg md:text-2xl font-bold font-sans">
                Rule of Origin Outstanding General Provisions
              </h3>
              <ul>
                <li>
                  <b>Definitions:</b> Clarity around the concept of ‘value
                  added’ and how it will apply in the RoO
                </li>
                <li>
                  <b>Treatment of Goods produced in special economic zones:</b>
                  Export processing zones often involve special competitive
                  benefits for producers
                </li>
                <li>
                  <b>Value tolerance (de minimis) provisions:</b>{" "}
                  Commonprovision not currently included; value tolerance
                  provision provide a small measure of leeway to products allow
                  for minor deviations from the standard RoO
                </li>
                <li>
                  <b>Treatment of Goods produced in special economic zones:</b>{" "}
                  Export processing zones often involve special benefits for
                  produces
                </li>
                <li>
                  <b>Absorption (roll-up) principle:</b> Common provision that
                  allows on-originating materials contained in intermediate
                  products to be considered ‘originating’ when calculating local
                  content once trasnformed into a different product
                </li>
              </ul>
            </div>
            <div className="hover:shadow-md p-10 rounded-md bg-brown-100">
              <h3 className="text-lg md:text-2xl font-bold font-sans">
                Rule of Origin Outstanding Sector rules
              </h3>
              <ul className="listing">
                <li>
                  <b>Wheat Flour</b>
                </li>
                <li>
                  <b>Certain fish products </b>(snoek, parts of prepared fish
                </li>
                <li>
                  <b>Certain dairy products </b>(butermilk, cheese)
                </li>
                <li>Certain animal or</li>
                <li>
                  <b>vegetable fats</b> (sunflower oil, etc)
                </li>
                <li>Sugar</li>
                <li>
                  <b>Certain Juices</b> (‘other’ and ‘mixed’ juices)
                </li>
                <li>
                  <b>Certain tobacco products</b> (Cigar other tobacco products)
                </li>
                <li>
                  <b>Articles of Leather</b> (incl. Travel Goods, Handbags)
                </li>
                <li>
                  <b>Textile fabrics</b> (most fabric, and some yarns)
                </li>
                <li>Clothing</li>
                <li>
                  <b>Household Textiles</b> (most tarrif lines outstanding)
                </li>
                <li>Motor</li>
                <li>vehicles and Parts</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="md:px-40 px-10 flex flex-col justify-center items-center md:h-[650px] overflow-hidden mb-20">
          <div className="block space-y-4 w-3/4">
            <h1 className="md:text-4xl text-2xl font-bold my-10 text-center md:text-left">
              Key Steps on how to determine Origin status of a product as a
              trader
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
              <div>
                <ul className="listing">
                  <li>
                    <b>What is the export destination?</b> If it is part of the
                    same REC, then those RoO (not AfCFTA RoO) apply
                  </li>
                  <li>
                    <b>Know the correct HS code for the product</b>
                  </li>
                  <li>
                    <b>Consult the product-specific RoO</b>
                  </li>
                  <li>
                    <b>Consult the general RoO provisions</b>
                  </li>
                  <div>
                    <ul className="ml-7 text-slate-600">
                      <li>
                        <b>Is the product made from local inputs only?</b>{" "}
                        [Wholy obtained provisions]
                      </li>
                      <li>
                        <b>
                          Have inputs been sourced from other AfCFTA Member
                          States?
                        </b>
                        [Cummulation / Info Procedures]
                      </li>
                      <li>
                        <b>
                          oes all the local processing combined go beyond
                          ‘Insufficient Operations’?
                        </b>{" "}
                        [Art. 7]
                      </li>
                    </ul>
                  </div>
                </ul>
              </div>
              <div>
                <h3 className="">
                  The AfCFTA Rule of Origin are included as Annex II to the
                  AfCFTA agreement [Annex I covers the import tarrif
                  concessions]. Parts I and II of Annex II contain the general
                  provisions, including provisions such as the criteria for
                  ‘Wholly obtained’, cumulation, origin declarations,
                  simple/insufficient processing, key definitions, and others.
                </h3>
                <h4 className="my-2.5 text-xl font-bold font-sansextra">
                  The RoO Annex also contains a number of Appendixes
                </h4>
                <ul>
                  <li>
                    <b>Appendix I:</b> A sample copy of the certificate of
                    Origin
                  </li>
                  <li>
                    <b>Appedix II:</b> The text of the origin (Self) declaration
                    (for shipments by approved exporters)
                  </li>
                  <li>
                    <b>Appendix III:</b> A sample AfCFTA supplier declaration
                    (to cover regional imputs)
                  </li>
                  <li>
                    <b>Appendix IV:</b> The AfCFTA product-specific RoO criteria
                  </li>
                </ul>
              </div>
            </div>
          </div>
            </section>
            <Footer />
      </div>
    );
}
