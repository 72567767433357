import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { theme } from './theme'
import VendorProvider from './context/Vendor/VendorContext';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import IngredientProvider from './context/IngedientContext';
ReactDOM.render(

    <BrowserRouter>
        
            <IngredientProvider>
                <VendorProvider>
                    <ThemeProvider theme={theme}>
                        <Provider store={store}>
                            <PersistGate loading={null} persistor={persistor}>
                                <App />
                            </PersistGate>
                        </Provider>
                    </ThemeProvider>
                </VendorProvider>
            </IngredientProvider>
        
    </BrowserRouter>


    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();  
