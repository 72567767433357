import React, { useRef, useState } from "react";
import moment from "moment";
import { Oval } from "react-loading-icons";
import { useEffect } from "react";
import { Dialog, Modal } from "@mui/material";
import {
  LocalizationProvider,
  MobileTimePicker,
  StaticTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useStartOrderShipmentMutation } from "../../../../../../redux/apiSlice";
import { useParams } from "react-router-dom";
import AddPickupAddress from "./AddPickupAddress";

const DateNow = ({handleCloseShipmentModal}) => {
  const [selectedDate, setSelectedDate] = useState(moment().add(1, "day"));
  const [description, setDescription] = useState("");
  const [openPickupAddress, setOpenPickupAddress] = useState("");
  const [selectedTime, setSelectedTime] = useState(dayjs());
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const [openTime, setOpenTime] = useState(false);
  const [openPickupDate, setOpenPickupDate] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [pickupRequest, setPickupRequest] = useState({
    address: "",
    city: "",
    country: "",
    phoneNumber: "",
    postalCode: "",
    state: "",
    contactName: "",
    companyName: "",
    specialInstructions: "",
  });


  const defaultValues = {
    address: '',
    city: '',
    country: '',
    phoneNumber: '',
    postalCode: '',
    state: '',
    contactName: '',
    companyName: '',
    specialInstructions: ''
  };

  const route = useParams();

  const [startShipment, { isLoading: startShippingLoading }] =
    useStartOrderShipmentMutation();

  const isWeekday = (date) => {
    const day = moment(date).format("dddd");
    return day !== "Saturday" && day !== "Sunday";
  };

  const generateDates = () => {
    const today = moment();
    const availableDates = [];

    for (let i = 1; i < 15; i++) {
      const date = moment(today).add(i, "days");
      if (isWeekday(date)) {
        availableDates.push(date);
      }
    }

    setDates(availableDates);
  };

  // ref
  const clockRef = useRef();

  // effect
  useEffect(() => {
    generateDates();

    return () => {
      console.log("clean up");
    };
  }, []);

  //   Mehtods
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPickupRequest({ ...pickupRequest, [name]: value });
  };
  const resetPickupRequest = (e) => {
    e.preventDefault();
    setPickupRequest(defaultValues);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleTimeChange = (newValue) => {
    setSelectedTime(newValue);
  };

  //   click clock
  function clickOpenTime(e) {
    e.preventDefault()
    clockRef.current.click();
  }

  //   Pickup
  function handlePickupAddress(e) {
    e.preventDefault();
    setOpenAddress(true);
  }
  function handleOpenTime(e) {
    e.preventDefault();
    setOpenTime(true);
  }
  function handleOpenDate(e) {
    e.preventDefault();
    setOpenPickupDate(true);
  }

  function handleCloseAddress(e) {
    e.preventDefault();
    setOpenAddress(false);
  }
  function handleCloseTime() {
    setOpenTime(false);
  }
  function handleCloseDate() {
    setOpenPickupDate(false);
  }

  //   start shipment
  function handleStartShipment(e) {
    e.preventDefault();
    console.log(route.id);
    setLoading(true)
    const date = moment(selectedDate).format("YYYY-MM-DD");
    const time = dayjs(selectedTime).format("HH:mm:ss");
    const plannedDate = `${date}T${time}`;
    const {address, city, companyName, contactName, country, phoneNumber, postalCode, specialInstructions, state} = pickupRequest
   

    let request = {
        description: description,
        plannedShippingDate: plannedDate
      };
      
      if (pickupRequest && Object.keys(pickupRequest).length > 0) {
        request.pickupRequest = {
          address: pickupRequest.address,
          city: pickupRequest.city,
          companyName: pickupRequest.companyName,
          contactName: pickupRequest.contactName,
          country: pickupRequest.country,
          phoneNumber: pickupRequest.phoneNumber,
          postalCode: pickupRequest.postalCode,
          specialInstructions: pickupRequest.specialInstructions,
          state: pickupRequest.state,
        };
      }
      
      startShipment({
        data: request,
        orderId: route.id
      })
        .unwrap()
        .then((data) => {
          console.log(data);
          setAnchorEl(null);
          setLoading(false)
          handleCloseShipmentModal()
        })
        .catch((err) => {
            console.log(err);
            
            setAnchorEl(null);
            setLoading(false)
        });
      
  }

//   console.log(clockRef);

  return (
    <div>
      <form className="flex flex-col space-y-3 border p-3 rounded-sm">
        {/* Shipping Date */}
        <div className="grid grid-cols-2 items-center gap-3 p-2 border-2 rounded-md">
          <div className="col-span-1 ">
            <button
              onClick={handleOpenDate}
              className="mb-2 mt-2 px-5 py-2 text-lg font-bold  text-white bg-brown-800 rounded-md"
            >
              Select Date
            </button>
          </div>
          <div className="flex justify-center items-center">
            <p className="text-lg font-bold font-sans">
              {selectedDate
                ? `${selectedDate.format("dddd MMMM D")}`
                : "Select shipping Date"}
            </p>
          </div>
        </div>
        <hr className="my-3" />
        {/* Shipping Time */}
        <div className="grid grid-cols-2 items-center gap-3 mt-5 p-2 border-2 rounded-md">
          <div className=" cursor-pointer">
          <button
              onClick={clickOpenTime}
              className="mb-2 mt-2 px-5 py-2 text-lg font-bold  text-white bg-brown-800 rounded-md"
            >
              Select Time
            </button>
          </div>
          <div className="flex justify-center items-center">
            <p className="text-lg font-bold font-sans">
              {selectedDate
                ? `${selectedTime.format("hh:mm A")}`
                : "Select shipping Date"}
            </p>
            <div className="hidden">
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <MobileTimePicker
            ref={clockRef}
            className="text-lg font-bold font-sans bg-brown-100 text-brown-700"
            value={selectedTime}
            onChange={(value) => handleTimeChange(value)}
            />
          </LocalizationProvider>
            </div>
          </div>
          
        </div>
        <hr className="my-5" />
        <h1 className="mb-2 text-lg font-bold">
          Enter description for the shipment
        </h1>
        <textarea
          rows={2}
          name="description"
          value={description}
          className="p-3 outline-none border-2"
          onChange={(e) => setDescription(e.target.value)}
        />

        <hr className="my-5" />
        {/* Pickup Address */}
        {pickupRequest.address === "" ? (
          <button
            onClick={handlePickupAddress}
            className="mb-2 mt-7 text-lg font-bold cursor-pointer underline-offset-4 text-blue-800 py-3 rounded-md bg-blue-100 border-blue-800"
          >
            Add Pickup Request
          </button>
        ) : (
          <button
            className="mb-2 mt-7 text-lg font-bold cursor-pointer underline-offset-4 text-red-600 py-3 rounded-md bg-blue-100 border-blue-800"
            onClick={resetPickupRequest}
          >
            Remove Pickup Request
          </button>
        )}

        {pickupRequest.address !== "" ? (
          <div className="text-blue-800 bg-blue-100 border-blue-800 p-2 rounded-md">
            {pickupRequest.address}
          </div>
        ) : null}
        <hr className="my-5" />
        <button
          type="button"
          onClick={handleStartShipment}
          className="bg-brown-800 text-white py-3 rounded-md flex justify-center items-center"
        >
          {loading ? <Oval fontSize={20} /> : "Start Shipment"}
        </button>
      </form>
      <Dialog open={openAddress} onClose={handleCloseAddress}>
        <AddPickupAddress
          handleInputChange={handleInputChange}
          handleCloseAddress={handleCloseAddress}
        />
      </Dialog>
      <Dialog open={openPickupDate} onClose={handleCloseDate}>
        <div className="p-5">
          <h1 className="my-2 text-lg font-bold">Available Pickup Dates</h1>
          <div className="grid grid-cols-4 gap-2 w-[450px]">
            {dates.map((date, i) => (
              <div
                key={i}
                className={`flex justify-center items-center  cursor-pointer border-2 h-10 rounded-md  ${
                  selectedDate.format("YYYY-MM-DD") ===
                  date.format("YYYY-MM-DD")
                    ? "bg-brown-600 text-white hover:bg-brown-700"
                    : "hover:bg-gray-100"
                }`}
                onClick={() => handleDateChange(date)}
              >
                {date.format("ddd MMM D")}
              </div>
            ))}
          </div>
          <div className="my-3">
            <button
              className="bg-brown-800 text-white py-3 rounded-md flex justify-center items-center w-full"
              onClick={handleCloseDate}
            >
              Use Selected Date
            </button>
          </div>
        </div>
      </Dialog>
      {/* <Dialog open={openTime} onClose={handleCloseTime}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        </LocalizationProvider>
      </Dialog> */}
    </div>
  );
};

export default DateNow;
