import React, {useState, useRef, useEffect} from 'react';
import RenderSlide from './RenderSlide';
import skewBackground from "../../../../assets/svg/skew-background.svg"
import twinVector from "../../../../assets/images/twin-ribbon.png"

function SectionSeven(props) {
  const [step, setStep] = useState(1)
  const intervalId = useRef()
  const nextStep = () => {
    setStep(step + 1);
        
  }
  const prevStep = () => {
    setStep(step - 1);
  }
  useEffect(() => {
    // return () => clearInterval(interval)
    if (intervalId.current != null) {
      return
    }
    intervalId.current = setInterval(() => {
      setStep(prevStep => {
        if (prevStep + 1 == 4) {
          return 0
        }
        return prevStep + 1
      })
    }, 5000)
          
    return () => {
      clearInterval(intervalId.current)
    }
  })
  return (
    <div
      className="flex justify-center items-center relative h-[1200px]"
      style={{
        backgroundImage: `url(${skewBackground})`,
        backgroundRepeat: "no-repeat",
        // backgroundPosition: "0% 0%",
        backgroundSize: "cover",
      }}
    >
      {/* twin vector */}
      <div className="absolute md:top-[90px] top-[155px] left-0">
        <img src={twinVector} className="w-[110px] md:w-full" />
      </div>

      {/* <div className="absolute md:-bottom-[40px] lg:-bottom-[10px] -bottom-[10px] right-0">
        <img src={twinVector} className="w-[110px] md:w-full rotate-180" />
      </div> */}

      <div className="flex justify-center items-center md:px-52 px-5 text-white mb-5">
        <RenderSlide step={step} prevStep={prevStep} nextStep={nextStep} />
      </div>
    </div>
  );
}

export default SectionSeven;