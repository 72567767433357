
export const vendorReducer = (state,action)=>{
       switch(action.type){
        case "ADD":
            return {...state,vendorCategory:action.payload}

        case "LOGIN":
            return {...state,user:action.payload}
       }

}