import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data:null
}

export const orderReducer = createSlice({
    name: 'order',
    initialState,
    reducers: {
        add: (state,action) => {
            state.data = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {add} = orderReducer.actions

export default orderReducer.reducer