import { Field } from "formik";
import React, { useState } from "react";
import { useGetListOfCountriesQuery } from "../../../../redux/apiSlice";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";

export default function AgencyInfo({ errors, touched, isSubmitting }) {
  const [visible, setVisible] = useState(false);

  const { data: countryList } = useGetListOfCountriesQuery();
  // const countryList = data && data !== undefined && data.data;
  // console.log("Countrylist", countryList);

  const togglePasswordView = (value) => {
    setVisible(value);
  };

  return (
    <div className="px-2">
      <div className="mt-10 space-y-5">
        <div>
          <label className="text-zinc-400 text-lg" htmlFor="brandName">
            Enter your business name
          </label>
          <Field
            name="brandName"
            type="text"
            className={`w-full mb-5 px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
              errors.brandName && touched.brandName ? "bg-[#ffebeb]" : null
            }`}
            placeholder="Merry Integrated Services"
          />
        </div>
        <div>
          <label className="text-zinc-400 text-lg" htmlFor="description">
            Describe your business
          </label>
          <Field
            name="description"
            as="textarea"
            className={`w-full mb-5 px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
              errors.description && touched.description ? "bg-[#ffebeb]" : null
            }`}
            placeholder="Describe your business"
            rows="4"
          />
        </div>

        <div>
          <label className="text-zinc-400 text-lg" htmlFor="brandAddress">
            Business Address
          </label>
          <Field
            name="brandAddress"
            type="text"
            className={`w-full mb-5 px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
              errors.brandAddress && touched.brandAddress ? "bg-[#ffebeb]" : null
            }`}
            placeholder="Cheery Road 3, NH 34 Str, Accra"
          />
        </div>

        <div className="hidden">
          <label className="text-zinc-400 text-lg" htmlFor="resourceType">
            What will you be selling
          </label>
          <Field
            name="resourceType"
            as="select"
            className={`w-full mb-5 px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 `}
          >
            <option value="" selected disabled hidden>
              Select your primary
            </option>
            <option value="goods">Goods</option>
            <option value="services">Services</option>
          </Field>
        </div>
        <div>
          <label className="text-zinc-400 text-lg" htmlFor="serviceType">
            What is your type of business?
          </label>
          <Field
            name="serviceType"
            type="text"
            className={`w-full mb-5 px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0`}
            as="select"
          >
            <option value="" selected disabled hidden>
              Select business type
            </option>
            <option value="individual">Individuals</option>
            <option value="agency">Agency</option>
          </Field>
        </div>
        <div>
          <label className="text-zinc-400 text-lg" htmlFor="vendorClass">
            What is the size of your business?
          </label>
          <Field
            name="vendorCategory"
            type="text"
            className={`w-full mb-5 px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
              errors.vendroCategory && touched.vendroCategory ? "bg-[#ffebeb]" : null
            }`}
            as="select"
          >
            <option value="" selected disabled hidden>
              Select vendor size
            </option>
            <option value="minimist">Small Scale Vendor</option>
            <option value="highend">Highend Vendor</option>
          </Field>
        </div>
      </div>
    </div>
  );
}
