import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    goods: true,
    services: false
}

export const marketReducer = createSlice({
    name: 'ingredient',
    initialState,
    reducers: {
        toggleGoods: (state,action) => {
            state.goods = true
            state.services = false
        },
        toggleServices: (state,action) => {
            state.goods = false
            state.services = true
        },
    },
})

// Action creators are generated for each case reducer function
export const {toggleGoods, toggleServices} = marketReducer.actions

export default marketReducer.reducer