import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import '../../MarketPlace/services/child/Hire.css'
import { TextareaAutosize, TextField } from '@mui/material';
export default function CreateOffer({ open, setOpen, handleClickOpen, handleClose }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    // const [isActiveProfile, setIsActiveProfile] = React.useState(true)
    // const [isActiveWork, setIsActiveWork] = React.useState(false)

    // const handleProfile = () => {
    //     setIsActiveProfile(true)
    //     setIsActiveWork(false)
    // }
    // const handleWork = () => {
    //     setIsActiveWork(true)
    //     setIsActiveProfile(false)
    // }
    return (
        <div className='overflow-hidden'>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className='example'
            >
                <DialogContent className='py-3 px-1 example w-[600px]' >
                    <DialogContentText>
                        {/* <div className='py-2 px-4 flex space-x-3 items-center'>
                            <img src="/images/Hire Woman.png" />
                            <div className='flex-1 px-6'>
                                <h2 className='font-bold'>Joan Grace</h2>
                                <p className='text-sm text-gray-300'>Architect and Survey Expert</p>
                                <p className='text-gray-400 text-sm'>South Africa</p>
                            </div>

                        </div> */}
                        <div className='py-4'>
                            <h1 className='text-xl font-bold'>Create an Offer</h1>
                            <div className='py-4 border border-gray-100'>
                                <div className='py-4 space-y-2'>
                                    <h3>Job Details</h3>
                                    <TextField placeholder='A brief title of what you’re looking for' className='w-full outline-none' />
                                </div>
                                <div className='py-4 space-y-2'>
                                    <h3>Description</h3>
                                    <TextareaAutosize
                                        aria-label="empty textarea"
                                        placeholder="Describe the job details"
                                        className="w-full border border-gray-200 bg-gray-100 px-4"
                                        minRows={8}
                                    />
                                </div>
                                <div className='py-4 space-y-2'>
                                    <h3>Skills</h3>
                                    <TextField placeholder='BlockChain Developer' className='w-full outline-none' />
                                </div>
                                <div>
                                    <h2 className='text-xl text-black'>Terms</h2>
                                </div>
                                <div className='py-3 flex items-center justify-betweeen space-x-6 w-full'>
                                    <div className='py-4 space-y-2'>
                                        <h3>How Long will it take ?</h3>
                                        <TextField placeholder='Describe the job details' className='w-full outline-none' />
                                    </div>
                                    <div className='py-4 space-y-2'>
                                        <h3>Date</h3>
                                        <TextField placeholder='10 days' className='w-full outline-none' />
                                    </div>
                                </div>
                                <div className='py-4 flex items-center space-x-5'>
                                    <button className='bg-brown-800 py-2 px-3 rounded-md text-white '>Send offer </button>
                                    <button className=' py-2 px-3 rounded-md border border-gray-100 bg-gray-200 '>Cancel</button>
                                </div>
                            </div>
                        </div>


                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div >
    );
}
