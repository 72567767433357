import React, {useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

const Styles = styled.div`
        input{
            background-color: white
        }
        button{
            height: 48px;
        }
        label div{
            color: #1E323F !important;
            font-size: 14px !important
        }
        `

function CompleteProfile({modal, toggleModal}) {
    const [state, setState] = useState({ 
          email:'', 
          firstName:'',
          lastName:'',
          phoneNumber:'',
          residentialAddress:'',
          state:'',
          nationality:'',
    })
    const [isLoading, setIsLoading] = useState(false)
    
    const handleChange = (e)=>{
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = (e)=>{
        e.preventDefault();
        setIsLoading(true)
        alert(JSON.stringify(state))
    }
    return (
        <Styles>

            <div className="row big">
                <div className="col-12">
                    <Modal isOpen= {modal} 
                    style={{borderRadius:'30px'}}
                    toggle={toggleModal}>
                        <ModalHeader  className='modal-header'>
                            Update personal details
                        </ModalHeader>
                        <ModalBody>
                            <form  
                            className="needs-validation"
                            onSubmit={(val) => {handleSubmit(val)}}>
                                <div className="form-group row mt-3">
                                    <div className="col-md-6 col-12">
                                        <label className='sb'>
                                            <div>
                                                First Name
                                            </div>
                                        </label>
                                        <input type="text" id="firstname" name="firstName"
                                            placeholder="Fasakin"   
                                            onChange={handleChange}
                                            className="form-control" required
                                            />
                                    </div>
                                    <div className="col-md-6"col-12>
                                        <label className='sd'> Last Name</label> 
                                        <input type="text" 
                                            id="lastname" name="lastName"
                                            placeholder="Oluwaseyi" onChange={handleChange}
                                            className="form-control" required/>
                                    </div>
                                </div>
                                <div className="form-group row mt-3">
                                    <div className="col-md-6 col-12">
                                        <label className='sb'>
                                            <div>
                                                Email
                                            </div>
                                        </label>
                                        <input type="text" id="email" name="email"  
                                            onChange={handleChange}
                                            placeholder="fasakinoluwaseyi4@gmail.com"
                                            className="form-control" required
                                            />
                                    </div>
                                    <div className="col-md-6"col-12>
                                        <label className='sd'> Phone Number</label> 
                                        <input type="text" id="phoneNumber" name="phoneNumber"
                                            onChange={handleChange}
                                            placeholder="+237 123 456 7890"
                                            className="form-control" required/>
                                    </div>
                                </div>
                                <div className="form-group row mt-3">
                                    <div className="col-12">
                                        <label className='sd'> Residential Address</label> 
                                        <input type="text"
                                            name="residentialAddress"
                                            onChange={handleChange}
                                            className="form-control" required/>
                                    </div>
                                </div>
                                <div className="form-group row mt-3">
                                    <div className="col-md-6 col-12">
                                        <label className='sb'>
                                            <div>
                                                state
                                            </div>
                                        </label>
                                        <input type="text"  name="state"  
                                            onChange={handleChange}
                                            className="form-control" required
                                            />
                                    </div>
                                    <div className="col-md-6"col-12>
                                        <label className='sd'> Nationality</label> 
                                        <input type="text"  name="nationality"
                                            onChange={handleChange}
                                            className="form-control" required/>
                                    </div>
                                </div>
                                <div className="form-group row mt-4">
                                    <div className="col-12 pb-4 ">
                                        <button 
                                        className="btn btn-md btn-primary w-100" >Save Changes</button>
                                    </div>
                                </div>
                            </form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </Styles>
    );
}

export default CompleteProfile;