import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import SelectAddressModal from "./SelectAddressModal";
import {
  useAddNewShippingAddressMutation,
  useGetAllShippingAddressQuery,
} from "../../../../redux/apiSlice";
import Oval from "react-loading-icons/dist/esm/components/oval";
import { CloseRounded, InfoTwoTone } from "@material-ui/icons";
import {
  closeNewAddressModal,
  openNewAddressModal,
} from "../../../../redux/checkoutSlice";

const ShippingDetail = () => {
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState();
  const [country, setCountry] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [state, setState] = React.useState("");
  const [contactName, setContactName] = React.useState("");

  //   Regular states
  const [checked, setChecked] = React.useState(false);
  const [changeDefault, setChangeDefault] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hovered, setHovered] = useState(null);
  const [selected, setSelected] = useState(false);

  const dispatch = useDispatch();
  const token = JSON.parse(localStorage.getItem("TOKEN"));
  const openAddressModal = useSelector(
    (state) => state.checkout.newAddressModal
  );
  const currentAddress = useSelector((state) => state.checkout.checkoutAddress);
  const [info, setInfo] = useState();
  const addressForShipping = useSelector(
    (state) => state.checkout.checkoutAddress
  );

  // New address modal controller
  const handleClickOpen = () => {
    dispatch(openNewAddressModal());
  };
  const handleClose = () => {
    dispatch(closeNewAddressModal());
  };
  const navigate = useNavigate();
  // const { state: cartItems } = useContext(CartContext);

  // const [total, setTotal] = useState(
  //   cartItems
  //     ? cartItems.reduce(
  //         (prev, current) => (prev += current.product.price * current.quantity),
  //         0
  //       )
  //     : 0
  // );

  // Data fetch and mutations ***********************************************************************
  const {
    data: shippingAddress,
    isLoading,
    error,
  } = useGetAllShippingAddressQuery();
  // console.log(shippingAddress && shippingAddress);

  //   add new address
  const [addNewAddress, { isLoading: addNewAddressLoading }] =
    useAddNewShippingAddressMutation();

  const handleSubmit = async (e) => {
    
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };
    e.preventDefault();
    setLoading(true);

    try {
      addNewAddress({
        address: address,
        city: city,
        companyName: "Testoil Plc",
        contactName: contactName,
        country: country,
        phoneNumber: phoneNumber,
        postalCode: postalCode,
        state: state,
      })
        .unwrap()
        .then((data) => {
          
          if (data.status === "true") {
            closeLoadingDialog();
          }
        });
    } catch (err) {
      
    }
  };

  // Methords *************************************************

  const handleChange = (event) => {
    setChecked(event.target.checked);
    
  };

  function selectAddress(value) {
    setHovered((prev) => (value === prev ? prev : value));
  }

  function deselectHover() {
    setHovered(null);
  }

  function handleSelectAddress(address, i) {
    setAddress(address);
    setSelected(true);
  }

  //   const submit = (e) => {
  //     e.preventDefault();
  //     dispatch(add(info));
  //     navigate("/logistics");
  //   };

  function handleChangeAddress() {
    setChangeDefault(true);
  }

  function closeNewAddressForm() {
    setChangeDefault(false);
  }

  function ChangeAddress() {}
  // navigate to logistics
  function routeToLogistics() {
    // dispatch(setCheckoutAddress(address));
    navigate("summary");
  }

  function closeLoadingDialog() {
    setLoading(false);
  }

  // clg
  

  return (
    <>
      <div className="mx-5">
        <div className="mt-5">
          <div>
            <div className="mt-3 py-2 bg-white w-full">
              <div className="mx-auto rounded-md mt-5">
                <div className="flex justify-between">
                  <h2 className="font-bold text-xl">
                    Select your shipping address
                  </h2>
                  <div
                    className="flex items-center cursor-pointer px-3 py-2 rounded-md border hover:bg-brown-800 hover:text-white text-brown-700 text-[14px] mt-2.5 whitespace-nowrap"
                    onClick={handleClickOpen}
                  >
                    <h3 className=" font-bold md:text-[17px] text-[14px]">
                      Change Address
                    </h3>
                  </div>
                </div>
                <div className="py-4 mt-4">
                  {/* Current address */}
                  {changeDefault && (
                    <div className="flex items-center space-x-3">
                      {/* <p className="text-[14px]">Current Address</p> */}
                      <button
                        className="px-4 py-2.5 rounded-md bg-blue-400 text-white text-[10px]"
                        onClick={handleChangeAddress}
                      >
                        Add New Address
                      </button>
                    </div>
                  )}

                  {!shippingAddress ? (
                    <div className="flex justify-center items-center min-h-[200px]">
                      <div className="flex flex-col items-center">
                        <Oval fill="#fcb200" stroke="#ff4bc2" />
                        <h1 className="mt-5 text-zinc-400">
                          Fetching your shipping address{" "}
                        </h1>
                      </div>
                    </div>
                  ) : (
                    <div className="block " key={1}>
                      {shippingAddress.data.length === 0 ? (
                        <div className="border p-5 rounded-md">
                          <p>You don't have any address yet!</p>
                          <p className="text-xs text-slate-400">
                            Add new shipping address
                          </p>
                          <button
                            className="px-3 py-2 rounded-md bg-brown-800 text-white text-[14px] mt-2.5"
                            onClick={handleClickOpen}
                          >
                            {" "}
                            <span className="mr-2.5">
                              <AddCircleIcon />
                            </span>
                            Add Shipping Address
                          </button>
                        </div>
                      ) : (
                        <div className="space-y-3">
                          <div className="mb-2 flex space-x-2 items-center p-2 border border-slate-300 rounded-md bg-slate-100">
                            <InfoTwoTone fontSize="large" className="" />
                            <div className="">
                              <p className="text-[13px] text-slate-500">
                                Your default address will be used for delivery
                              </p>
                              <p className="text-[10px] text-slate-400">
                                You can change the address by selecting from the
                                list or adding a new address
                              </p>
                            </div>
                          </div>
                          {/* {shippingAddress.data.filter(address => address.default === true).map((item, i) => ( */}
                          {currentAddress.contactName === undefined ? null : (
                            <div
                              // key={}
                              className={`cursor-pointer ${
                                selected
                                  ? "bg-brown-700 text-white hover:bg-brown-600"
                                  : "hover:bg-slate-100"
                              } group border-2 mt-3 rounded-md p-3`}
                              onMouseEnter={() => selectAddress()}
                              onMouseLeave={deselectHover}
                              onClick={() =>
                                handleSelectAddress(currentAddress)
                              }
                            >
                              <h1
                                className={`font-light text-[16px] ${
                                  selected
                                    ? "group-hover:text-white"
                                    : "text-slate-600"
                                }`}
                              >
                                {currentAddress.contactName}
                              </h1>
                              <h1
                                className={`text-lg font-bold ${
                                  selected
                                    ? "group-hover:text-white"
                                    : "text-slate-600"
                                }`}
                              >
                                {currentAddress.address}
                              </h1>
                              <div className="flex items-center justify-between">
                                <p
                                  className={`text-[14px] mt-2 ${
                                    selected
                                      ? "group-hover:text-white"
                                      : "text-slate-600"
                                  } `}
                                >
                                  {currentAddress.phoneNumber}
                                </p>
                                {currentAddress.default ? (
                                  <>
                                    <div className="flex justify-end">
                                      <button className="bg-blue-200 px-2 rounded-md text-[14px] font-bold text-blue-500">
                                        Default
                                      </button>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          )}
                          {/* ))} */}
                        </div>
                      )}
                    </div>
                  )}

                  {/* Change shipping address */}
                </div>
                {shippingAddress && shippingAddress.data.length === 0 ? (
                  <></>
                ) : (
                  <div>
                    {!shippingAddress ? null : (
                      <div className="flex justify-between">
                        <div> </div>
                        {changeDefault ? null : (
                          <div className="py-4">
                            {selected ? (
                              <button
                                className="px-3 py-2 rounded-md bg-brown-800 text-white text-[14px] mt-2.5"
                                onClick={() =>
                                  routeToLogistics(shippingAddress.data[0])
                                }
                              >
                                Confirm Address
                              </button>
                            ) : null}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      <SelectAddressModal
        open={openAddressModal}
        // setOpen={}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
    </>
  );
};

export default ShippingDetail;

// {changeDefault ? (
//   <form>
//     {loading ? (
//       <Dialog
//         open={loading}
//         onClose={closeLoadingDialog}
//         aria-labelledby="responsive-dialog-title"
//       >
//         <div className="w-[350px] h-[200px] rounded-md bg-white flex justify-center items-center">
//           <div className="flex flex-col items-center space-y-3">
//             <Oval fill="#fcbfc4" stroke="#fb4230" />
//             <h1 className="font-bold text-[18px]">
//               Adding Shipping Address
//             </h1>
//           </div>
//         </div>
//       </Dialog>
//     ) : (
//       <div className="mt-10">

//         <div className="flex justify-between">
//           <div> </div>
//           {!changeDefault ? null : (
//             <div className="py-4">
//               <button
//                 className="bg-brown-700 rounded-md py-3 px-4 text-white w-full"
//                 onClick={closeNewAddressForm}
//               >
//                 <span>
//                   <CloseRounded />
//                 </span>
//                 Close
//               </button>
//             </div>
//           )}
//         </div>

//         <Grid container spacing={2}>
//           <Grid item xs={12} md={12}>
//             <div className="py-2">
//               <h3 className="text-[14px] text-slate-600">
//                 Name
//               </h3>
//             </div>
//             <TextField
//               placeholder="Enter Name"
//               value={
//                 checked ? info?.contactName : contactName
//               }
//               className="w-full"
//               onChange={(e) => setContactName(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} md={12}>
//             <div className="py-2">
//               <h3 className="text-[14px] text-slate-600">
//                 Country
//               </h3>
//             </div>
//             <TextField
//               placeholder="Country"
//               value={checked ? info?.country : country}
//               className="w-full"
//               onChange={(e) => setCountry(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <div className="py-2">
//               <h3 className="text-[14px] text-slate-600">
//                 State
//               </h3>
//             </div>
//             <TextField
//               placeholder="Select State"
//               value={checked ? info?.state : state}
//               className="w-full"
//               onChange={(e) => setState(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <div className="py-2">
//               <h3 className="text-[14px] text-slate-600">
//                 City
//               </h3>
//             </div>
//             <TextField
//               placeholder="City"
//               value={checked ? info?.city : city}
//               className="w-full"
//               onChange={(e) => setCity(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <div className="py-2">
//               <h3 className="text-[14px] text-slate-600">
//                 Address
//               </h3>
//             </div>
//             <TextField
//               placeholder="Enter Address"
//               value={checked ? info?.address : address}
//               className="w-full"
//               onChange={(e) => setAddress(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <div className="py-2">
//               <h3 className="text-[14px] text-slate-600">
//                 Postal Code
//               </h3>
//             </div>
//             <TextField
//               placeholder="Enter Name"
//               value={checked ? info?.postalCode : postalCode}
//               className="w-full"
//               onChange={(e) => setPostalCode(e.target.value)}
//             />
//           </Grid>
//           <Grid item xs={12} md={12}>
//             <div className="py-2">
//               <h3 className="text-[14px] text-slate-600">
//                 Phone Number
//               </h3>
//             </div>
//             <TextField
//               placeholder="Phone Number"
//               value={
//                 checked ? info?.phoneNumber : phoneNumber
//               }
//               className="w-full"
//               onChange={(e) => setPhoneNumber(e.target.value)}
//             />
//           </Grid>
//         </Grid>
//         <div className="py-4 flex justify-between items-center">
//           <h2 className="text-brown-700">Select Default</h2>
//           <Switch
//             checked={checked}
//             onChange={handleChange}
//             inputProps={{ "aria-label": "controlled" }}
//           />
//         </div>
//         <div className="py-4">
//           <button
//             className="bg-brown-700 rounded-md py-3 px-4 text-white w-full"
//             onClick={handleSubmit}
//           >
//             Confirm Logistics
//           </button>
//         </div>
//       </div>
//     )}
//   </form>
// ) : null}
