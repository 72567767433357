import { IconButton } from '@mui/material'
import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
const Service = () => {
    return (
        <div className='bg-white min-h-screen px-5 py-10'>
            <div className="w-[90%] mx-auto">
                <div>
                    <Link to="/dashboard/order">
                        <div className="space-x-2 flex items-center">
                            <IconButton>
                                <ArrowBackIcon />
                            </IconButton>
                            <h2 className='font-bold'>Go Back</h2>
                        </div>
                    </Link>

                </div>
                <div className="mt-3">
                    <hr className='w-[90%]' />
                </div>
                <div className='w-[90%] mx-auto py-3'>
                    <div className='space-y-3'>
                        <h1 className="font-bold">UX Designer For CRM Application & Web Sites</h1>
                        <h3 className='font-bold'>Project overview</h3>
                        <p>We are looking for an experienced UX designer with attention to detail, creativity, and empathy towards the end user.
                            We are building a small CRM web application and need a rockstar UX designer to make the experience amazing. Additionally, we have a few follow up web design projects that we would like to tackle after and more to come!
                            Primary Responsibilities for this position include:
                        </p>
                        <p>- Find creative ways to solve UX problems (e.g. usability, findability)</p>
                        <p>- Understand product specifications and user psychology</p>
                        <p>- Develop wireframes and prototypes around customer needs</p>
                        <p>- Communicate design ideas and prototypes to developers</p>

                    </div>
                    <div className='py-3 space-2'>
                        <h2 className='font-bold'>MileStone</h2>
                        <div className='w-3/5 py-3'>
                            <div className='flex justify-between items-center'>
                                <h2 className="text-brown-700">Duration</h2>
                                <h2 className="text-brown-700">Due Time</h2>
                                <h2 className="text-brown-700">Budget</h2>
                            </div>
                        </div>
                        <div className='w-3/5'>
                            <div className='flex justify-between items-center'>
                                <h2 className="text-brown-700">16 weeks</h2>
                                <h2 className="text-brown-700">September 18th 2021 </h2>
                                <h2 className="text-brown-700">$ 6,000</h2>
                            </div>
                        </div>

                    </div>
                    <div className='w-full border border-gray-400 py-4'>
                        <div className='w-[90%] mx-auto'>
                            <div className='flex justify-between items-center'>
                                <h2>Will you like to approve this offer?</h2>
                                <div className='space-x-4'>
                                    <button className='bg-brown-800 py-3 px-6 text-white rounded-lg'>Approve</button>
                                    <button className='py-3 px-6'>Decline</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service