



import { Skeleton } from '@mui/material';
import React from 'react'

export default function DummySkeleton() {
  return (
    <div className="grid md:grid-cols-5 grid-cols-2 gap-5">{dummyNumber.slice(0, 5).map((item, i) => (

        <div className="hover:border-brown-500 hover:border">
          <div className="rounded-lg flex justify-center py-2">
            <Skeleton variant="rectangular" width={210} height={118} className="rounded-md" />
          </div>
          <div className="flex items-center flex-col">
            <Skeleton animation="wave" height={10} width="80%" />
            <Skeleton animation="wave" height={10} width="80%" />
            <Skeleton animation="wave" height={10} width="80%" />
          </div>
        </div>

      ))}</div>
  )
}

const dummyNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9];
