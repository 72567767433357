import React from 'react';
import heroImage from "../../../../assets/images/hero-image.png"
import hadnDrawing from "../../../../assets/images/hero-handdraw-vector.png"
import { Link, NavLink, useNavigate } from 'react-router-dom';
import {motion} from 'framer-motion'
function Jumbotron(props) {

  const navigate = useNavigate()


  const gotoVendor = () => {
    navigate("/select-user")
  }

  const gotoMarket = () => {
    navigate("/market")
  }

    return (
      <div className="">
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-10 gap-5 mx-auto px-3 md:px-40 overflow-hidden bg-brown-500 py-5 h-screen">
          <div className="w-full flex items-center md:h-[80vh] h-[40vh] order-last md:order-first">
            <div className="block">
              <div className="relative">
                <motion.h1 
                initial={{y:'-100vh'}}
                 animate={{y:'0vh'}}
                 transition={{type:'spring',stiffness:100,duration:1.0}}
                className="text-white md:text-4xl text-2xl text-center md:text-left font-bold font-sans my-2">
                  Intra-African market Connectivity, Collaboration and Trade.
                </motion.h1>
                {/* Hand drawing */}
                <div className="absolute top-8 md:left-0 left-10 w-[180px]">
                  <img src={hadnDrawing} className="object-contain" />  
                </div>
              </div>

              <p className="text-lg text-center md:text-left leading-tight text-brown-900 font-normal my-4">
                Join the ecosystem that unlocks human capital in Africa, Buy or
                sell Goods and services across the global market
              </p>
              <div className="grid md:grid-cols-2 items-center gap-2 mt-1.5 md:w-3/4 w-full">
                <button
                  className="py-3.5 rounded-md bg-white text-brown-900 text-sm flex items-center justify-center"
                  onClick={gotoMarket}
                >
                  Explore Market
                  <span>
                    <i class="fa fa-arrow-right ml-3"></i>
                  </span>
                </button>

                <button
                  className="py-3.5 rounded-md bg-brown-800 text-white text-sm flex items-center justify-center"
                  onClick={gotoVendor}
                >
                  <p>Create an account</p>
                  <span>
                    <i class=" ml-2 mr-2 fa fa-arrow-right"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center md:h-[80vh]">
            <div className="block">
              <div className="flex justify-center md:justify-end">
                <div className="md:w-4/5 w-[230px]">
                  <img
                    src={heroImage}
                    alt="aftcta-map"
                    className="object-contain"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Jumbotron;