import { Divider, Grid, IconButton, Skeleton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StarIcon from '@mui/icons-material/Star';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar, Ally, Autoplay } from 'swiper';

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'




export default function CategorySection({ data, name }) {
  const [categories, setCategories] = useState([])
  const token = JSON.parse(localStorage.getItem("TOKEN"))

  


  return (
    <div className="py-5 block">
      {!data ? null : data.data === undefined ? null : (<>

        <div className="flex justify-between items-center">
          <h1 className="font-semibold text-xl my-7">
            {name}
          </h1>
          <div className="flex items-center space-x-1 md:hidden">
            <IconButton>
              <ArrowBackIosIcon fontSize="small" className="text-blue-400" />
            </IconButton>
            <IconButton>
              <ArrowForwardIosIcon fontSize="small" className="text-blue-400" />
            </IconButton>
          </div>
        </div>
      </>)}




      <div>
        {!data ? (<div className="grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 grid-cols-2 gap-5">
          {dummyNumber.slice(0, 5).map((item, i) => (

            <div className="hover:border-brown-500 hover:border">
              <div className="rounded-lg flex justify-center py-2">
                <Skeleton variant="rectangular" width={210} height={118} />
              </div>
              <div className="flex items-center flex-col">
                <Skeleton animation="wave" height={10} width="80%" />
                <Skeleton animation="wave" height={10} width="80%" />
                <Skeleton animation="wave" height={10} width="80%" />
              </div>
            </div>

          ))}


        </div>) : (
          <div className="grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5  gap-5">
            {data.length === 0 ? null : (
              <>{
                // data.map((detail, i) => {
                //   return (
                //     <div
                //       key={detail.id}


                //       className="space-y-2 block"

                //     >
                //       <Link to={`/market/goods/${detail.id}`}>
                //         <div className="hover:border-brown-500 hover:border hover:drop-shadow-md bg-white rounded-lg border-gray-300 pb-2.5">
                //           <div className="rounded-lg flex w-full">
                //             <img
                //               src={detail.productImage}
                //               className="w-full h-32 rounded-t-lg cursor-pointer object-cover object-top border border-brown-200"
                //             />
                //           </div>
                //           <div className="flex flex-col w-[90%] mx-auto py-2">
                //             <p className="text-black capitalize truncate tracking-tight">
                //               {detail.name}
                //             </p>
                //             <p className="text-xs text-gray-400">{detail.hscode}</p>

                //             <div className="flex justify-between items-center">
                //               <p className="text-red-700 font-semibold">
                //                 ${detail.price}
                //               </p>
                //               {detail.ratings !== 0 && (
                //                 <div className="flex items-center space-x-1">
                //                   <IconButton>
                //                     <StarIcon className="text-yellow-500" />
                //                   </IconButton>
                //                   <p className="font-bold">{detail.ratings}</p>
                //                 </div>
                //               )}
                //             </div>
                //           </div>
                //         </div>
                //       </Link>
                //     </div>
                //   );
                // }
                // )
              }</>
            )}
          </div>
        )}
      </div>

    </div>
  );
}


const dummyNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9];











