


import React from 'react'
import { IoIosInformationCircleOutline } from 'react-icons/io';

export default function DisputeIndex() {
    return (
      <div className="font-sans">
        <div className="grid grid-cols-1 gap-5 my-5 h-screen overflow-hidden">
          <div className="block w-full h-full bg-white shadow-lg rounded  px-5 py-5 mx-5">
            <div className="flex justify-between items-center w-full">
              <h1 className="text-xl font-bold font-sans">My Dispute(s)</h1>
              {/* <button className="px-5 py-2.5 bg-brown-900 text-white rounded-sm">
                Goto Order
              </button> */}
            </div>

            <div className="flex justify-center items-center w-full h-full">
              <div className="block text-center">
                <IoIosInformationCircleOutline className="text-7xl text-slate-200 mx-auto" />
                <h4 className="text-slate-400">
                  You don't have any dispute
                </h4>
                <p className="text-slate-300">Add products to your store</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
