


import React from 'react'
import { Link } from 'react-router-dom'

export default function RulesOfOriginSection() {
    return (
      <div className="mb-0 bg-brown-100 h-screen relative justify-center flex items-center ">
        {/* <div className="grid grid-cols-1 md:grid-cols-2 px-40"> */}
          <div className="space-y-3 text-center md:w-1/2 w-full px-10 md:px-0">
            <h2 className="md:text-5xl text-2xl font-sans tracking-tight font-bold">
              The AFCFTA Rules of Origin
            </h2>
            <p className="md:text-xl text-base">
              Discover the legal provisions that are used to determine the
              nationality of a product in the context of inter-country trade on AfCFTA
            </p>
            <Link to="/rules">
              <button className="px-5 py-2.5 md:w-52 w-full bg-brown-300 text-brown-900 rounded-md my-8">
                Learn more
              </button>
            </Link>
          </div>
        {/* </div> */}
      </div>
    );
}
