


import React from 'react'
import { Link } from 'react-router-dom'
import { useGetProductByCategoryIdQuery, useGetSingleProductDetailsQuery } from '../../../../redux/apiSlice'
import { formatToCurrency } from '../../../utils/currencyFormatter'
import { Rating } from '@mui/material'

export default function GoodsSubCategoryParent({ name, id, products }) {

    const { data, isLoading, error } = useGetProductByCategoryIdQuery(id)

    return (
        <div className="space-y-3 my-5">
            <div className='grid md:grid-cols-5 grid-cols-2 gap-2'>
                {products !== undefined && products.data.slice(0, 5).map((detail, i) => (
                    <Link to={`/market/goods/${detail.id}`} >
                        {/* <div className="" key={i}>
                            <div className='w-full bg-slate-100'>
                                <img src={item.productImage} />
                            </div>
                            <h1 className="text-xl font-medium mt-2 capitalize">{item.name}</h1>
                            <p className="text-red-500">{item.price}</p>
                            <p className="text-slate-200 text-xs">{item.hscode}</p>
                        </div> */}

                        <div key={i} className=" hover:bg-[#f8f8f8] bg-white  pb-2.5 cursor-pointer">
                          <div className="rounded-lg flex w-full">
                            <img
                              src={detail.productImage}
                              className="w-full h-40 rounded-t hover:border-[0.2px]  object-cover object-top"
                            />
                          </div>
                          <div className="flex flex-col w-[90%] mx-auto py-1.5">
                            <p className="text-lg font-medium truncate">
                              {detail.name}
                            </p>
                            <div className="flex space-x-2 items-center">
                              <Rating
                                name="read-only"
                                value={detail.ratings}
                                precision={0.5}
                                size="small"
                                readOnly
                                className=""
                              />
                              <p className="text-xs text-gray-400 text-[10px] mt-[0.5px]">
                                ({detail.ratingsCount})
                              </p>
                            </div>
                            <div className="my-0.5" />
                            <p className="text-xs text-gray-400 text-[10px]">
                              {detail.hscode}
                            </p>

                            <div className="my-0.5" />
                            <p className="text-slate-700 text-lg font-bold font-sans">
                              {formatToCurrency(detail.price)}
                            </p>
                          </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    )
}


const tryNum = [1, 2, 3, 4, 5, 6, 7, 8]