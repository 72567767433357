import React from 'react'
import { Outlet } from 'react-router-dom'
import SettingsMenu from './child/SettingsMenu'

export default function SettingsIndex() {
    return (
        <div className="grid bg-gray-100 min-h-screen grid-cols-1 md:grid-cols-4 gap-5">
            <div className="md:col-span-1">
                <SettingsMenu />
            </div>
            <div className="md:col-span-3 col-span-1 overflow-hidden">
                <Outlet />
            </div>
        </div>
    )
}
