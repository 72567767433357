import { Grid, IconButton, Skeleton } from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { add } from "../../../../../../redux/Order";
import { useGetAllVendorOrderQuery } from "../../../../../../redux/apiSlice";
import { HourglassEmpty } from "@mui/icons-material";
import { Oval } from "react-loading-icons";

export default function IncomingOrder() {
  const token = JSON.parse(localStorage.getItem("TOKEN"));
  const [orders, setOrders] = useState();
  const [loading, setLoading] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filter = (id) => {
    // const order = orders.find((order)=>order.order.id==id)
  };

  // Data
  const {
    data: vendorOrder,
    error,
    isLoading,
  } = useGetAllVendorOrderQuery(userInfo.id);
  console.log(vendorOrder && vendorOrder);
  // console.log(error && error);

  return (
    <div className="font-sans py-6">
      <div className="py-4">
        <h2 className="underline text-sm text-slate-400">Upcoming Orders</h2>
        <div className="py-2 mt-2 bg-slate-100"></div>
      </div>

      <div className="space-y-5">
        <div spacing={2} className="bg-white flex flex-col py-5">
          <div className="p-5">
            <p className="">Goods</p>
          </div>
          <div className="grid grid-cols-4 gap-5">
            {!vendorOrder ? (
              <div>
                <Oval />
              </div>
            ) : (
              <>
                {vendorOrder.data.length === 0 ? (
                  <div className="w-full rounded-md flex justify-center">
                    <div className="flex flex-col items-center justify-center">
                      <HourglassEmpty className="text-[#9a9a9a] mb-2" />
                      <p className="text-[#9a9a9a]">
                        You don't have any order yet!
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    {vendorOrder.data.slice(0, 6).map((order, i) => (
                      <Grid key={i} item xs={12} md={4}>
                        <div className="bg-white rounded-lg shadow p-4">
                          <div className="w-full  block  rounded justify-between px-5 py-5">
                            <div className="space-y-3">
                              <h2 className="font-bold text-xl">
                                Order #{order.id}
                              </h2>
                              <div className="flex items-center justify-between text-sm">
                                <p>Customer</p>
                                <p className="font-bold">{order.customer.firstName}</p>
                              </div>
                              <div className="flex items-center justify-between text-sm">
                                <p>Quantity</p>
                                <p className="font-bold">
                                  {order.orderItems.length}{" "}
                                  {order.orderItems.length > 1
                                    ? "items"
                                    : "item"}
                                </p>
                              </div>
                              <div className="flex items-center justify-between text-sm">
                                <p className="text-gray-600">Status:</p>
                                <p
                                  className={`text-${
                                    order.orderAcceptance.acceptanceStatus ===
                                    "ACCEPTED"
                                      ? "green"
                                      : "red"
                                  }-600 font-bold`}
                                >
                                  {order.orderAcceptance.acceptanceStatus}
                                </p>
                              </div>

                              <div className="">
                                {/* <div className="flex items-center justify-between mb-4"></div> */}
                                <div className="flex items-center justify-between text-sm">
                                  <p className="text-gray-600">Date:</p>
                                  <p className="text-gray-800 font-bold">
                                    {new Date(order.orderAcceptance.acceptanceDate).toDateString()}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="mt-5">
                              <Link to={`/dashboard/order/${order.id}`}>
                              <button
                                className="py-1.5 px-4 bg-brown-800 text-white rounded-lg"
                              >
                                View Order
                              </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {/* service */}
        <Grid container spacing={2} className="bg-white ">
          <div className="p-5">
            <p className="">Services</p>
          </div>
          {(!orders && (
            <div className="w-full h-[200px] rounded-md flex justify-center">
              <div className="flex flex-col items-center justify-center">
                <HourglassEmpty className="text-[#9a9a9a] mb-2" />
                <p className="text-[#9a9a9a]">You don't have any order yet!</p>
              </div>
            </div>
          )) ||
            orders.slice(-3).map((order, i) => (
              <Grid key={i} item xs={12} md={4}>
                <div>
                  <div className="w-full  block  rounded justify-between px-5 py-5">
                    <div className="space-y-3">
                      <h2 className="font-bold text-xl">
                        Order #{order.order.id}
                      </h2>
                      <p>
                        {" "}
                        {order.orderItems.length}{" "}
                        {order.orderItems.length > 0 ? "items" : "item"}
                      </p>
                    </div>

                    <div className="mt-5">
                      {/* <Link to="/dashboard/order/filled"> */}
                      <button
                        onClick={() => {
                          console.log(
                            orders.find((x) => x.order.id === order.order.id)
                          );
                          dispatch(
                            add(
                              orders.find((x) => x.order.id === order.order.id)
                            )
                          );
                          navigate("/dashboard/single-order");
                        }}
                        className="w-full py-3 bg-brown-800 text-white rounded-lg"
                      >
                        View Order
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
      </div>
    </div>
  );
}

const incomingOrderValues = [
  {
    product_name: "Dudu-Osun African Black Soap",
    customer_name: "Alvan Emmanuel",
    quantity: "150 Cartons",
    country: "Cameroun",
  },
];
