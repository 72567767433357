import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data:null
}

export const customerReducer = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        add: (state,action) => {
            state.data = action.payload    
        }
    },
})

// Action creators are generated for each case reducer function
export const {add} = customerReducer.actions

export default customerReducer.reducer