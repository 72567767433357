


import { Skeleton } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { useGetServiceCategoriesQuery } from '../../../redux/apiSlice'
import MarketSlider from '../child/MarketSlider'
import HeadMarket from '../child/HeadMarket'

export default function AllServiceCategory() {


    const { data, isLoading, error } = useGetServiceCategoriesQuery()

    console.log(data && data)


    return (
        <div >
            <HeadMarket />
            <div className='mx-5 md:mx-10'>
                <MarketSlider />


                {/* Category sections */}
                <div className=''>
                    {!data ? (
                        <>
                            <Skeleton className="text-2xl font-semibold" />
                            <div className='grid md:grid-cols-5 grid-cols-2 gap-5'>
                                {testNum.map((_, i) => (
                                    <div className='space-y-3'>
                                        <Skeleton variant="rectangular" animation="pulse" sx={{ height: 100, width: "100%" }} />
                                        <Skeleton variant="rectangular" animation="pulse" sx={{ height: 30, width: "100%" }} />
                                        <Skeleton variant="rectangular" animation="pulse" sx={{ height: 20, width: "100%" }} />
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <div className=''>

                            {data && data.data.map((category, p_index) => (
                                <div className="divide-y mt-10">
                                    <h1 className='text-2xl font-semibold font-sans my-3'>{category.name}</h1>
                                    <div className='grid grid-cols-2 md:grid-cols-5 gap-5'>
                                        {category.categories.map((subCategory, i) => (
                                            <Link to={`/market/service/category/${data.data[p_index].id}`}>
                                                <div className='w-full h-32 bg-amber-700 rounded-md'>
                                                    <img src={subCategory.image || 'https://picsum.photos/seed/picsum/200/300'} className="object-cover w-full h-full rounded-md" />
                                                </div>
                                                <h1 className='truncate overflow-ellipsis my-1.5'>{subCategory.name}</h1>
                                                {/* <p>Other Info</p> */}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}

const testNum = [1, 2, 3, 4, 5, 6]
