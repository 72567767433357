import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { marketSlice } from "./apiSlice";

export const getUserCart = createAsyncThunk(
  "cart/getCartItem",
  async ({ token }, thunkAPI) => {
    const state = thunkAPI.getState()
    const userToken = state.auth.userToken
    const res = axios
      .get(`${process.env.REACT_APP_BASE_URL}carts`, {
        headers: {
          Authorization: `${userToken}`,
        },
      })
      .then((data) => {
        // console.log("cart data from backend",data.data);
        return data.data
      });
    return res;
  }
);

export const updateCart = createAsyncThunk(
  "cart/updateItem",
  async ({ data, cartId, token, type }, thunkAPI) => {
    const res = axios
      .put(`${process.env.REACT_APP_BASE_URL}carts/update/${cartId}`, data, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((data) => {
        // thunkAPI.dispatch(marketSlice.endpoints.getAllCartItem.initiate())
        // thunkAPI.dispatch()
        console.log(data?.data);
       return data.data
      });
    return res;
  }
);

const initialState = {
  cart: [],
  totalPrice: 0,
  loading: false,
  updateStatus: false,
};

export const cartSlice = createSlice({
  name: "cartState",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      console.log("I'm receiving", action.payload);
      const itemInCart = state.cart.cartItems.find(
        (item) => item.id === action.payload.id
      );
      if (itemInCart) {
        itemInCart.quantity++;
      } else {
        state.cart.cartitems.push({ ...action.payload });
      }
      // total cart
      
    },
    incrementQuantity: (state, action) => {
      const item = state.cart.cartItems.find(
        (item) => item.product.id === action.payload.id
      );
      // console.log(item.quantity);
      item.quantity++;
      
    },
    decrementQuantity: (state, action) => {
      const item = state.cart.cartItems.find(
        (item) => item.product.id === action.payload.id
      );
      if (item.quantity > 0) {
        item.quantity--;
      } else if (item.quantity === 1) {
        item.quantity = 1;
      }
      
    },
    removeItem: (state, action) => {
      const removeItem = state.cart.cartItems.filter(
        (item) => item.product.id === action.payload.id
      );
      state.cart.cartItems = removeItem;
      
    },
    updateTotal: (state) => {
      state.totalPrice  = state.cart.cartItems.reduce((prev, current) => (prev + current.product.price * current.quantity), 0)
    },
    // addTotal: (state) => {
    //   state.totalPrice += 100
    // },
    

  },
  extraReducers: {
    [getUserCart.pending]: (state) => {
      state.loading = true;
    },
    [getUserCart.fulfilled]: (state, action) => {
      state.loading = false;
      state.cart = action.payload.data;
      state.totalPrice = action.payload.data.cartItems.reduce((prev, current) => (prev + current.product.price * current.quantity), 0)
      
    },
    [getUserCart.rejected]: (state) => {
      state.loading = false;
    },
    // Update cart
    [updateCart.pending]: (state) => {
      state.updateStatus = true;
    },
    [updateCart.fulfilled]: (state, action) => {
      state.updateStatus = false;
      const itemInCart = state.cart.cartItems.find(
        (item) => item.id === action.payload.data.id
      );
      itemInCart.quantity = action.payload.data.quantity;
      state.totalPrice  = state.cart.cartItems.reduce((prev, current) => (prev + current.product.price * current.quantity), 0)
      
      
    },
    [updateCart.rejected]: (state) => {
      state.updateStatus = false;
    },
  },
});

export const { addToCart, incrementQuantity, decrementQuantity, removeItem, addTotal, updateTotal } =
  cartSlice.actions;

export default cartSlice.reducer;
