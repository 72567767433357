import { Box, Grid, Stack, IconButton, Select, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  useGetListOfCountriesQuery,
  useUpdateVendorDetailsMutation,
} from "../../../../redux/apiSlice";
import { Oval } from "react-loading-icons";

const Personal = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(30);
  const currentUser = useSelector((state) => state.auth);
  // console.log(currentUser.userInfo)
  const { data: countryList } = useGetListOfCountriesQuery();
  const [updateVendor, {isLoading}] = useUpdateVendorDetailsMutation();

  const [state, setState] = useState({
    email: currentUser.userInfo.email,
    firstname: currentUser.userInfo.firstName,
    lastname: currentUser.userInfo.lastName,
    phoneNumber: currentUser.phoneNumber,
    address: "",
    country: "",
    state: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { firstname, lastname, brandname, phoneNumber, country, address } =
      state;
    console.log("processing ......");
    navigate("/vendor/onboarding/business");
    updateVendor({
      address: address,
      // brandAddress: "string",
      // brandName: "string",
      // country: country,
      firstName: firstname,
      lastName: lastname,
      phoneNumber: phoneNumber,
      // state: state,
    })
      .unwrap()
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="p-2 w-4/5 mx-auto flex justify-between items-center">
        {/* <Link to="/vendor/onboarding/business">
        </Link> */}
        <Link to="/">
          <img src="/images/logo.png" />
        </Link>
        <Link to="/vendor/onboarding/business">
          <IconButton className="cursor-pointer">
            <NavigateNextIcon className="text-xl" />
          </IconButton>
        </Link>
      </div>
      <div className="w-[55%] mx-auto">
        <Stack spacing={3}>
          <h2 className="text-3xl">Complete Personal Details</h2>
          <p>
            Please fill the form below to receive a quote for your project. Feel
            free to add as much detail as needed.
          </p>
          {/* <Stepperr/> */}
          <Box p={4} className="rounded-md shadow-md">
            <div className="mx-auto w-full">
              <form  className="w-full">
                <Grid
                  container
                  spacing={4}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={12} md={6}>
                    <label>
                      <div className="mb-3">First Name</div>
                    </label>
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      placeholder="Enter First Name"
                      value={state.firstname}
                      className="form-control p-2 w-full  rounded-md bg-gray-100 border-gray-50"
                      required
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label className="text-left">
                      <div className="mb-3">Last Name</div>
                    </label>
                    <input
                      type="text"
                      id="lastname"
                      name="lastname"
                      placeholder="Enter last name"
                      value={state.lastname}
                      className="form-control p-2 w-full rounded-md bg-gray-100 border-2 border-gray-50"
                      required
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label className="text-left">
                      <div className="mb-3">Nationality</div>
                    </label>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={state.country}
                    name="country"
                    label="country"
                    onChange={handleInputChange}
                  >
                    {countryList &&
                      countryList.data.map((cat, i) => (
                        <MenuItem key={i} value={cat.alpha3Abbr}>
                          {cat.fullName}
                        </MenuItem>
                      ))}
                  </Select>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <label>
                      <div className='mb-3'>
                        Email Address
                      </div>
                    </label>
                    <input type="text" id="firstname" name="uniben2018@gmail.com"
                      placeholder="Mbenko"
                      value={state.email}
                      className="form-control p-2 w-full  rounded-md bg-gray-100 border-gray-50" required
                    />
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <label className="text-left">
                      <div className="mb-3">Phone Number</div>
                    </label>
                    <input
                      type="text"
                      id="lastname"
                      name="phoneNumber"
                      placeholder="Enter phone number"
                      value={state.phoneNumber}
                      className="form-control p-2 w-full  rounded-md bg-gray-100 border-2 border-gray-50"
                      required
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label className="text-left">
                      <div className="mb-3">State</div>
                    </label>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      placeholder="State"
                      value={state.state}
                      className="form-control p-2 w-full  rounded-md bg-gray-100 border-2 border-gray-50"
                      required
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <label className="text-left">
                      <div className="mb-3">Address</div>
                    </label>
                    <input
                      type="text"
                      id="lastname"
                      name="address"
                      placeholder="Enter your address"
                      value={state.address}
                      className="form-control p-2 rounded-md px-2 bg-gray-100 border-2 border-gray-50 w-full"
                      required
                      onChange={handleInputChange}
                    />
                  </Grid>
                  
                  <Grid item xs={12} md={12}>
                    <button
                      type="submit"
                      className="enable-button btn-lg w-full p-2 text-white"
                      onClick={handleSubmit}
                    >
                      {isLoading ? <Oval /> : "Save Changes"}
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Box>
        </Stack>
      </div>
    </>
  );
};
export default Personal;
