import React, { useState } from "react";
import { Field } from "formik";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";

export default function UserDetails({
  errors,
  touched,
  togglePasswordView,
  visible,
  countryList,
}) {
  return (
    <div>
      <div className="space-y-5">
        <Field
          name="firstName"
          type="text"
          className={`w-full px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
            errors.firstName && touched.firstName ? "bg-[#ffebeb]" : null
          }`}
          placeholder="Firstname"
        />
        <Field
          name="lastName"
          type="text"
          className={`w-full px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
            errors.lastName && touched.lastName ? "bg-[#ffebeb]" : null
          }`}
          placeholder="Lastname"
        />

        <Field
          name="country"
          as="select"
          className={`w-full px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
            errors.country && touched.country ? "bg-[#ffebeb]" : null
          }`}
        >
          <option value="" selected disabled hidden>
            Select country
          </option>
          {countryList
            ? countryList.data.map((country, i) => (
                <option key={i} value={country.alpha3Abbr}>
                  {country.fullName}
                </option>
              ))
            : null}
        </Field>
        <Field
          name="email"
          type="email"
          className={`w-full px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
            errors.email && touched.email ? "bg-[#fae6e6]" : null
          }`}
          placeholder="Email address"
        />
        <div className="relative w-full">
          <Field
            name="password"
            type={visible ? "text" : "password"}
            className={`w-full px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
              errors.password && touched.password ? "bg-[#f6e4e4]" : null
            }`}
            placeholder="Enter Password"
          />
          <div
            className="absolute right-3 top-3 cursor-pointer"
            // onClick={() => setVisible(!visible)}
          >
            {visible ? (
              <IoIosEyeOff
                style={{ fontSize: 25 }}
                onClick={() => togglePasswordView(false)}
              />
            ) : (
              <IoIosEye
                style={{ fontSize: 25 }}
                onClick={() => togglePasswordView(true)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
