

import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  CircularProgress,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  IconButton,
  Box,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { useAddNewShippingAddressMutation, useGetListOfCountriesQuery } from "../../../../redux/apiSlice";
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function ShippingAddress() {


    const [loading, setLoading] = React.useState(false);
  const token = JSON.parse(localStorage.getItem("TOKEN"));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState();
  const [country, setCountry] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [state, setState] = React.useState("");
  const [contactName, setContactName] = React.useState("");

//   hooks
const navigate = useNavigate()


  const { data: countryData, error: countryDataError } =
    useGetListOfCountriesQuery();
  const [addNewAddress, { isLoading }] = useAddNewShippingAddressMutation();

  const handleSubmit = async (e) => {
    console.log("processing");
    e.preventDefault();
    setLoading(true);

    try {
      addNewAddress({
        address: address,
        city: city,
        companyName: "Test company Name",
        contactName: contactName,
        country: country,
        phoneNumber: phoneNumber,
        postalCode: postalCode,
        state: state,
      })
        .unwrap()
        .then((data) => {
          console.log(data);
          setLoading(false);
          navigate("/dashboard/")
        });
    } catch (err) {
      console.log(err);
    }
  };



  return (
    <div>
        <div className="p-2 w-4/5 mx-auto flex justify-between items-center">
        <Link to="/vendor/onboarding">
          <IconButton className="cursor-pointer">
            <ChevronLeftSharpIcon className="text-xl" />
          </IconButton>
        </Link>
        <Link to="/dashboard/">
          <IconButton className="cursor-pointer">
            <NavigateNextIcon className="text-xl" />
          </IconButton>
        </Link>
        {/* <IconButton className='cursor-pointer'>
          <ArrowForwardIosIcon />
        </IconButton> */}
      </div>

      <div className="w-[55%] mx-auto py-4">
        <Stack spacing={3}>
          <h2 className="text-3xl">Add Shipping Information</h2>
          <p>
            Please fill the form below to add a shipping address. This will be the address logistics company will use to pickup any goods you want to ship to the customer.
          </p>
          <Box>
          <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <div className="py-2">
                    <h3>Contact Name</h3>
                  </div>
                  <TextField
                    placeholder="Enter Name"
                    required
                    className="w-full"
                    onChange={(e) => setContactName(e.target.value)}
                  />
                </Grid>

                <FormControl className="w-full">
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={country}
                    label="Category"
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    {countryData &&
                      countryData.data.map((cat, i) => (
                        <MenuItem key={i} value={cat.alpha3Abbr}>
                          {cat.fullName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Grid item xs={12} md={6}>
                  <div className="py-2">
                    <h3>State</h3>
                  </div>
                  <TextField
                    placeholder="Select State"
                    required
                    className="w-full"
                    onChange={(e) => setState(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="py-2">
                    <h3>City</h3>
                  </div>
                  <TextField
                    placeholder="City"
                    required
                    className="w-full"
                    onChange={(e) => setCity(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="py-2">
                    <h3>Address</h3>
                  </div>
                  <TextField
                    placeholder="Enter Address"
                    required
                    className="w-full"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="py-2">
                    <h3>Postal Code</h3>
                  </div>
                  <TextField
                    placeholder="Enter Postal Code"
                    required
                    className="w-full"
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className="py-2">
                    <h3>Phone Number</h3>
                  </div>
                  <TextField
                    placeholder="Phone Number"
                    required
                    className="w-full"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Grid>
              </Grid>
              <div className="py-4">
                <button className="bg-brown-700 rounded-md py-4 px-4 text-white">
                  {" "}
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <p>Confirm Address</p>
                  )}
                </button>
              </div>
            </form>
          </Box>
        </Stack>
        </div>
    </div>
  )
}
