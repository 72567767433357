import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { data } from "jquery";

export const marketSlice = createApi({
  reducerPath: "api",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const userToken = getState().auth.userToken;
      if (userToken !== null) {
        headers.set("Authorization", `${userToken}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    "Market",
    "Cart",
    "Categories",
    "Offer",
    "Order",
    "Checkout",
    "Shipping",
    "Service",
    "Products",
    'Vendor-Order',
    'Vendor-Offer',
    'VendorWallet',
    'VendorTransactions',
    'UserTransactions',
    'VendorSingleOrder',
    'Transaction'
  ],
  endpoints: (builder) => ({
    // Customers endpoints

    // goods categories
    getGoodsCategories: builder.query({
      query: (type) => `/categories?type=product`,
    }),
    getSubCategories: builder.query({
      query: (id) => `categories/${id}/subcategories`,
    }),
    singleGoodsCategory: builder.query({
      query: (id) => `categories/${id}`,
    }),

    //   product
    topSellingProduct: builder.query({
      query: () => `/products/topSelling`,
    }),
    newArrivals: builder.query({
      query: () => `/products/newArrivals`,
    }),
    popularProducts: builder.query({
      query: () => `/products/popular`,
    }),
    recommendedProducts: builder.query({
      query: () => `/products/recommended`,
    }),

    //Single Product details
    getSingleProductDetails: builder.query({
      query: (id) => `products/${id}`,
    }),
    getProductByCategoryId: builder.query({
      query: (categoryId) => `products/categories/${categoryId}`,
    }),
    getSimilarProductsById: builder.query({
      query: (id) => `/products/similar/${id}`,
    }),

    // service categories
    getServiceCategories: builder.query({
      query: () => `/categories?type=service`,
    }),

    getServiceByCategoryId: builder.query({
      query: (categoryId) => `/services/categories/${categoryId}`,
    }),
    getServiceWithServiceType: builder.query({
      query: () => `categories/withProducts?type=service`,
    }),
    getAllServices: builder.query({
      query: () => `/services`,
      providesTags: ['Service']
    }),
    getSingleService: builder.query({
      query: (id) => `/services/${id}`,
      providesTags: ['Service']
    }),
    getMoreServiceFromSameVendor: builder.query({
      query: (id) => `services/vendors/${id}`,
    }),

    // Offer
    // Service Offers
    getCustomerOffers: builder.query({
      query: () => `/offers/customers`,
    }),
    createOffer: builder.mutation({
      query: (data) => ({
        url: `/offers/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Service"]
    }),

    userCancelOffer: builder.mutation({
      query: (offerId) => ({
        url: `/offers/cancel/${offerId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Offer']
    }),
    userDeleteOffer: builder.mutation({
      query: (offerId) => ({
        url: `/offers/delete/${offerId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Offer']
    }),

    initializeServiceOrder: builder.mutation({
      query: (data) => ({
        url: `/orders/services/initialize`,
        method: 'POST',
        body: data
      })
    }),

    completeServiceOrder: builder.mutation({
      query: (data) => ({
        url: `/orders/services/create`,
        method: 'POST',
        body: data
      })
    }),

    // Order
    getAllCustomerOrder: builder.query({
      query: () => `/orders`,
    }),
    getSingleCustomerOrder: builder.query({
      query: (id) => `/orders/${id}`,
    }),




    // Shipping Address
    addNewShippingAddress: builder.mutation({
      query: (data) => ({
        url: "users/shipping_addresses/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Shipping"],
    }),
    getAllShippingAddress: builder.query({
      query: () => `/users/shipping_addresses/all`,
      providesTags: ["Shipping"],
    }),
    setDefaultShippingAddress: builder.mutation({
      query: (id) => ({
        url: `/users/shipping_addresses/default/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["Shipping"],
    }),
    deleteShippingAddress: builder.mutation({
      query: (id) => ({
        url: `/users/shipping_addresses/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Shipping"],
    }),
    updateShippingAddress: builder.mutation({
      query: (data, id) => ({
        url: `/users/shipping_addresses/update/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Shipping"],
    }),

    // Transactions
    getAllTransaction: builder.query({
      query: () => `/transactions`,
      providesTags: ['Transaction']
    }),

    // single transaction
    getSingleTransaction: builder.query({
      query: (reference) => `/transactions/reference/${reference}`,
      providesTags: ['Transaction']
    }),

    // Add to cart
    getAllCartItem: builder.query({
      query: () => `/carts`,
      providesTags: ["Cart"],
      keepUnusedDataFor: 5,
    }),
    addProductToCart: builder.mutation({
      query: (data) => ({
        url: "/carts/addToCart",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Cart"],
    }),
    clearAllCartItems: builder.mutation({
      query: () => ({
        url: `/carts/clear`,
        method: "POST",
      }),
      invalidatesTags: ["Cart"],
    }),
    deleteItemFromCart: builder.mutation({
      query: (id) => ({
        url: `/carts/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Cart"],
    }),
    updateCart: builder.mutation({
      query: ({data, cartId}) => ({
        url: `/carts/update/${cartId}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Cart']
    }),

    // Checkout

    getCheckoutInfo: builder.mutation({
      query: (data) => ({
        url: `/carts/checkout`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Cart"],
    }),
    completeCheckout: builder.mutation({
      query: (ref) => ({
        url: `/carts/completeCheckout`,
        method: 'POST',
        body: ref
      }),
      invalidatesTags: ['Cart']
    }),
    cancelCheckout: builder.mutation({
      query: () => ({
        url: `/carts/cancelCheckout`,
        method: "POST"
      }),
      invalidatesTags: ['Cart']
    }),
    

    // General Query &&  Mutations
    // Get user details
    verifyCurrentUser: builder.mutation({
      query: (token) => ({
        url: `/users/verify_token`,
        method: 'POST',
        body: token
      })
    }),

    signupCustomer: builder.mutation({
      query: (data) => ({
        url: `/customers/signup`,
        method: 'POST',
        body: data
      })
    }),
    
    
    // Vendors endpoints

    signupVendor: builder.mutation({
      query: (data) => ({
        url: `/vendors/signup`,
        method: 'POST',
        body: data
      }),
      
    }),    

    // Dashboard
    dashBoardData: builder.query({
      query: () => `/vendors/dashboard`,
      providesTags: ["Dashboard"],
    }),

    // Products
    getAllVendorProduct: builder.query({
      query: (id) => `/products/vendors/${id}`,
      providesTags: ['Products']
    }),
    vendorAddNewProduct: builder.mutation({
      query: (data) => ({
        url: `/vendors/products/create`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ['Products']
    }),
    vendorUpdateProduct: builder.mutation({
      query: ({data, id}) => ({
        url: `/vendors/products/update/${id}`,
        method: "PATCH",
        body: data
      }),
      invalidatesTags: ['Products']
    }),
    deleteVendorProduct: builder.mutation({
      query: (id) => ({
        url: `/products/delete/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Products']
    }),

    // Classify product
    classifyManufacturer: builder.mutation({
      query: ({id, data}) => ({
        url: `/products/classifications/manufacturer/${id}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Products']
    }),
    classifyRetailer: builder.mutation({
      query: ({id, data}) => ({
        url: `/products/classifications/retailer/${id}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Products']
    }),

    // Service

    getAllServiceForVendor: builder.query({
      query: () => `/vendors/services/all`,
      providesTags: ['Service']
    }),
    addNewService: builder.mutation({
      query: (data) => ({
        url: `/vendors/services/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    updateServiceDetail: builder.mutation({
      query: ({id, data}) => ({
        url: `/vendors/services/update/${id}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['Service']
    }),
    deleteSingleService: builder.mutation({
      query: (productId) => ({
        url: `/vendors/services/delete/${productId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Service']
    }),


    // Good Order
    getAllVendorOrder: builder.query({
      query: () => `/vendors/vendor_orders/all`,
      providesTags: ['Vendor-Order'] 
    }),
    getSingleVendorOrder: builder.query({
      query: (id) => `/vendors/vendor_orders/${id}`,
      providesTags: ['VendorSingleOrder']
    }),
    acceptVendorOrder: builder.mutation({
      query: (orderId) => ({
        url: `/vendors/vendor_orders/accept/${orderId}`,
        method: 'POST'
      }),
      providesTags: ['Vendor-Order', 'VendorSingleOrder'] 
    }),
    startOrderShipment: builder.mutation({
      query: ({data, orderId}) => ({
        url: `/vendors/vendor_orders/${orderId}/shipments/start`,
        method: 'POST',
        body: data
      })
    }),

    // Services Offer
    getAllVendorOffer: builder.query({
      query: () => `/offers/vendors`,
      providesTags: ['Vendor-Offer']
    }),

    vendorAcceptOffer: builder.mutation({
      query: (offerId) => ({
        url: `/offers/accept/${offerId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Vendor-Offer']
    }),

    vendorRejectOffer: builder.mutation({
      query: (offerId) => ({
        url: `/offers/reject/${offerId}`,
        method: 'POST'
      }),
      invalidatesTags: ['Vendor-Offer']
    }),


    
    // Wallet
    getVendorWalletSummary: builder.query({
      query: () => `/wallets/vendors/summary`,
      providesTags: ['VendorWallet']
    }),
    getAllTransactions: builder.query({
      query: () => `/wallets/transactions`,
      providesTags: ["UserTransactions"]
    }),

    initiateWithdrawal: builder.mutation({
      query: (data) => ({
        url: `/wallets/vendors/withdraw`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['VendorWallet']
    }),
    

    // General endpoints
    getListOfCountries: builder.query({
      query: () => `/countries`,
    }),


    // Settings Endpoint
    changePassword: builder.mutation({
      query: (data) => ({
        url: `/users/password/change`,
        method: 'POST',
        body: data
      })
    }),
    forgetPassword: builder.mutation({
      query: (email) => ({
        url: `/users/password/reset`,
        method: 'POST',
        body: email
      })
    }),
    resetpassword: builder.mutation({
      query: (data) => ({
        url: `/users/password/resetChange`,
        method: "POST",
        body: data
      })
    }),
    updateVendorDetails: builder.mutation({
      query: (data) => ({
        url: `/vendors`,
        method: 'PATCH',
        body: data
      })
    }),
    addVendorBusinessInfo: builder.mutation({
      query: (data) => ({
        url: `/vendors/business/addInfo`,
        method: 'POST',
        body: data
      })
    }),
    updateVendorBusiness: builder.mutation({
      query: (data) => ({
        url: `/vendors/business/updateInfo`,
        method: 'PUT',
        body: data
      })
    }),

  }),
});

export const {
  
  
  // General
  useGetListOfCountriesQuery,
  useVerifyCurrentUserMutation,
  useChangePasswordMutation,
  useForgetPasswordMutation,
  useResetpasswordMutation,


  // Queries*********************************

  // Users_________________________________
  useGetGoodsCategoriesQuery,
  useGetSubCategoriesQuery,
  useSingleGoodsCategoryQuery,
  useTopSellingProductQuery,
  useNewArrivalsQuery,
  useGetSingleProductDetailsQuery,
  useGetProductByCategoryIdQuery,
  useGetSimilarProductsByIdQuery,
  useGetServiceCategoriesQuery,
  useGetServiceByCategoryIdQuery,
  useGetAllServicesQuery,
  useGetServiceWithServiceTypeQuery,
  useGetSingleServiceQuery,
  useGetMoreServiceFromSameVendorQuery,
  usePopularProductsQuery,
  useRecommendedProductsQuery,
  useGetAllCustomerOrderQuery,
  useGetSingleCustomerOrderQuery,
  useGetCustomerOffersQuery,
  useGetAllShippingAddressQuery,
  useGetAllCartItemQuery,
  useGetAllTransactionQuery,
  useGetSingleTransactionQuery,
  
  
  // Vendors_____________________________
  useDashBoardDataQuery,
  useGetAllServiceForVendorQuery,
  useGetAllVendorProductQuery,
  useGetAllVendorOrderQuery,
  useGetSingleVendorOrderQuery,
  useStartOrderShipmentMutation,
  useGetVendorWalletSummaryQuery,
  useGetAllTransactionsQuery,
  useGetAllVendorOfferQuery,
  
  // Mutations******************************
  // Users_____________________________
  useCreateOfferMutation,
  useAddNewShippingAddressMutation,
  useSetDefaultShippingAddressMutation,
  useDeleteShippingAddressMutation,
  useUpdateShippingAddressMutation,
  useAddProductToCartMutation,
  useDeleteItemFromCartMutation,
  useClearAllCartItemsMutation,
  useUpdateCartMutation,
  useGetCheckoutInfoMutation,
  useCompleteCheckoutMutation,
  useCancelCheckoutMutation,
  useUserCancelOfferMutation,
  useUserDeleteOfferMutation,
  useInitializeServiceOrderMutation,
  useCompleteServiceOrderMutation,
  useSignupCustomerMutation,
  
  // Vendors____________________________
  useAddNewServiceMutation,
  useUpdateServiceDetailMutation,
  useDeleteSingleServiceMutation,
  useVendorAddNewProductMutation,
  useVendorUpdateProductMutation,
  useClassifyManufacturerMutation,
  useClassifyRetailerMutation,
  useDeleteVendorProductMutation,
  useVendorAcceptOfferMutation,
  useAcceptVendorOrderMutation,
  useVendorRejectOfferMutation,
  useUpdateVendorDetailsMutation,
  useSignupVendorMutation,
  useAddVendorBusinessInfoMutation,
  useUpdateVendorBusinessMutation,
  useInitiateWithdrawalMutation
  

} = marketSlice;
