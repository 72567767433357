import { createContext, useReducer } from "react";
import { IngredientReducer } from "./IngredientReducer";



export const IngredientContext = createContext();

const INITIAL_STATE = {
    id: "",
    constituents: [],
    countryOfVendor: "",
    natureOfVendor: ""
}

const IngredientProvider = ({ children }) => {
    const [state, dispatch] = useReducer(IngredientReducer,INITIAL_STATE)
    return (
        <IngredientContext.Provider value={{ dispatch, state }}>
            {children}
        </IngredientContext.Provider>
    )
}

export default IngredientProvider;