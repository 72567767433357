import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {useGetCheckoutInfoMutation} from '../../../../../redux/apiSlice'

const Summary = () => {
  // Get user details from state
  const user = useSelector((state) => state.auth.userInfo);
  const userCheck = useSelector((state) => state.auth.userToken);

  // Address details
  const address = useSelector((state) => state.checkout.checkoutAddress);
  console.log(address);

  //  data
  const [checkoutInfo, {isLoading, isError, error}] = useGetCheckoutInfoMutation()
  

  return (
    <>
      <div className="m-5">
        <div className="p-10 space-y-5">
          <h1 className="font-bold text-xl bg-slate-50 p-5">
            Checkout Summary
          </h1>
          {/* Personal information */}
          <div className="bg-slate-50 p-5">
            <p className="font-bold text-xl mb-3">Contact Information</p>
            <div className="grid md:grid-cols-2 grid-cols-2 gap-y-5">
              <p className="text-[14px] font-sans">FirstName</p>
              <p className="text-[14px] font-sans font-bold">
                {user.firstName}
              </p>
              <p className="text-[14px] font-sans">LastName</p>
              <p className="text-[14px] font-sans font-bold">{user.lastName}</p>
              <p className="text-[14px] font-sans">Nationality</p>
              <p className="text-[14px] font-sans font-bold">
                {user.nationality.fullName}
              </p>
              <p className="text-[14px] font-sans">phoneNumber</p>
              <p className="text-[14px] font-sans font-bold">
                {user.phoneNumber}
              </p>
              <p className="text-[14px] font-sans">Email Address</p>
              <p className="text-[14px] font-sans font-bold">{user.email}</p>
            </div>
          </div>
          {/* Address Information */}
          <div className="bg-slate-50 p-5">
            <p className="font-bold text-xl mb-3">Address Information</p>
            <div className="">
              <h1 className="font-light text-slate-500 text-[16px]">
                {address.contactName}
              </h1>
              <h1 className="text-lg font-bold">{address.address}</h1>
              <div className="flex items-center justify-between">
                <p className="text-[14px] text-slate-600 mt-2">
                  {address.phoneNumber}
                </p>
                

                <div className="flex justify-end">
                  {address.default && (
                    <button className="bg-green-200 px-2 border border-green-400 rounded-sm text-[14px] font-bold text-green-600">
                      Default
                    </button>
                  )}
                </div>
              </div>
              <h1 className="font-light text-slate-500 text-[16px]">
                {address.postalCode}
              </h1>
              <h1 className="font-light text-slate-500 text-[16px]">
                {address.state}
              </h1>
              <h1 className="font-light text-slate-500 text-[16px]">
                {address.country}
              </h1>
              <h1 className="font-light text-slate-500 text-[16px]">
                {address.city}
              </h1>
              
            </div>
          </div>

          {/* Logistics Company information */}
          <div className="bg-slate-50 p-5">
            <p className="font-bold text-xl mb-3">Logistics Information</p>
            <div className="">
              <div className="mx-auto space-y-3">
                <div className="w-full py-3 flex items-center space-x-3 hover:bg-gray-100 transition ease-in-out duration-200 cursor-pointer">
                  <div className="">
                    <Link to="">
                      <img
                        src="/images/DHL.png"
                        className="object-cover w-12 h-12"
                      />
                    </Link>
                  </div>
                  <div>
                    <h2 className="text-lg text-slate-700">DHL Freight Co.</h2>
                    <p></p>
                  </div>
                  {/* <span className='bg-[#3D2314] py-2 px-2 text-white rounded-md' onClick={routeToInsurance}>Calculate Estimate</span> */}
                </div>

                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
