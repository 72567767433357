import React from "react";
import { useGetSingleTransactionQuery } from "../../../../../redux/apiSlice";
import { useLocation, useParams } from "react-router-dom";
import { Oval } from "react-loading-icons";

export default function SingleUserTransaction() {
  const route = useLocation();
  // console.log(route.search.replace('?ref=', ''))

  const reference = route.search.replace("?ref=", "");

  const { data } = useGetSingleTransactionQuery(reference);
  console.log(data && data);

  return (
    <>
      {!data ? (
        <div className="flex justify-center items-center bg-white rounded-md p-4">
          <Oval stroke="#bcbde3" />
        </div>
      ) : (
        <div className="bg-white rounded-md p-4 w-full">
          <div className="text-lg font-semibold mb-4">
            {data.data.description}
          </div>
          <div className="flex items-center justify-between mb-4">
            <div className="text-gray-500">Payment Status:</div>
            <div
              className={`text-${
                data.data.status === "PENDING" ? "yellow" : "green"
              }-600 font-semibold`}
            >
              {data.data.status}
            </div>
          </div>
          <div className="flex items-center justify-between mb-4">
            <div className="text-gray-500">Amount:</div>
            <div className="font-semibold">${data.data.charge.amount}</div>
          </div>
          <div className="flex items-center justify-between mb-4">
            <div className="text-gray-500">Payment Type:</div>
            <div className="font-semibold">{data.data.type}</div>
          </div>
          <div className="flex items-center justify-between mb-4">
            <div className="text-gray-500">Created On:</div>
            <div>{new Date(data.data.createdOnDate).toLocaleString()}</div>
          </div>
          <div className="flex items-center justify-between mb-4">
            <div className="text-gray-500">Expiration Date:</div>
            <div>{new Date(data.data.expirationDate).toLocaleString()}</div>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-gray-500">Reference:</div>
            <div className="font-semibold">{data.data.charge.reference}</div>
          </div>
        </div>
      )}
    </>
  );
}
