import { Stack } from "@mui/material";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomizedInputBase from "./InputField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import OrderTable from "./OrderTable";
import { Grid, Skeleton } from "@mui/material";
import { useGetAllVendorOrderQuery } from "../../../../../redux/apiSlice";
import { Oval } from "react-loading-icons";
import ServiceOrder from "./ServiceOrder";
import OrderList from "./OrderList";

export default function VendorOrderIndex() {
  const token = JSON.parse(localStorage.getItem("TOKEN"));
  const [orders, setOrders] = useState();
  const [loading, setLoading] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);
  const [goods, setGoods] = useState(true);
  const [services, setServices] = useState(false);

  // fetch data
  const { data: orderList, error, isLoading } = useGetAllVendorOrderQuery();

  const onGoods = () => {
    setGoods(true);
    setServices(false);
  };
  const onServices = () => {
    setServices(true);
    setGoods(false);
  };

  console.log(orderList && orderList);

  return (
    <div>
      {/* {isLoading && (
        <div className='mx-auto md:px-10 px-5'>
          <div className="w-full h-[100vh]">
            <Skeleton className='w-full h-[40vh]' height={450} />
          </div>
        </div>

      )} */}
      {!orderList ? (
        <div className="w-full h-[400px] flex justify-center ">
          <Oval stroke="#fbf200" />
        </div>
      ) : (
        <>
          {orderList.data.length === 0 ? (
            <div className="w-full md:px-10 px-5">
              <div className="flex w-full">
                <div className="mt-5 py-5">
                  <h2 className="text-2xl font-bold">My Orders</h2>
                </div>
              </div>
              <div className="w-full min-h-[full] flex justify-center items-center">
                <div>
                  <Stack spacing={3}>
                    <img src="/images/OrderGroup.png" className="object-fit" />
                    <div className="spacing-y-4 text-center">
                      <p className="text-gray-500">You don’t have order yet!</p>
                      <p className="text-gray-400">
                        When someone makes an order it’ll appear here
                      </p>
                    </div>
                  </Stack>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="py-10 md:px-10 px-5">
                <div>
                  {/* <h2 className="text-xl font-bold">My Orders</h2> */}
                </div>
                <div className="py-5 flex justify-center items-center">
                  <div className="flex items-center space-x-1">
                    <button
                      onClick={onGoods}
                      className={
                        goods
                          ? `border bg-brown-900 text-white py-2 px-10  rounded-md`
                          : `border-brown-900 py-2 px-10 border text-black rounded-md`
                      }
                    >
                      Goods
                    </button>
                    <button
                      onClick={onServices}
                      className={
                        services
                          ? `bg-brown-900 text-white py-2 px-10  rounded-md`
                          : `border-brown-900 py-2 px-10 text-black rounded-md border`
                      }
                    >
                      Services
                    </button>
                  </div>
                </div>
                <div className="p-2 mt-3">
                  <div className="w-[90%] mx-auto">
                    <div className="w-[95%] hidden space-x-3 items-center mx-auto  p-6 rounded-lg">
                      <CustomizedInputBase />
                      <button className="bg-white py-3 px-4 rounded-md">
                        <div className="flex space-x-1 items-center">
                          <FilterAltIcon className="text-gray-300" />
                          <p>Filter</p>
                        </div>
                      </button>
                    </div>
                    {goods && (
                      <div className="py-4 mx-auto overflow-y-scroll h-screen">
                        <OrderList orders={orderList && orderList.data} />
                      </div>
                    )}
                    {services && (
                      <div className="py-4 mx-auto overflow-y-scroll h-screen">
                        <ServiceOrder orders={orderList && orderList.data} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
