import { Box, CircularProgress, Grid, MenuItem, Select, Stack } from "@mui/material";
import axios from "axios";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { IconButton } from "@mui/material";
import Personal from "./Personal";
import { Link } from "react-router-dom";
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import styles from "./business.module.css";
import { useAddVendorBusinessInfoMutation, useGetListOfCountriesQuery, useUpdateVendorBusinessMutation } from "../../../../redux/apiSlice";
const Business = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.userInfo);
  console.log(currentUser)
  const token = JSON.parse(localStorage.getItem("TOKEN"));
  const [brandName, setBrandName] = useState(currentUser.businessInfo.brandName);
  const [businessPhoneNo, setBusinessPhoneNo] = useState(currentUser.businessInfo.businessPhoneNo);
  const [companyEmail, setCompanyEmail] = useState(currentUser.businessInfo.companyEmail || "");
  const [nationality, setNationality] = useState(currentUser.businessInfo.nationality);
  const [officeAddress, setOfficeAdrress] = useState(currentUser.businessInfo.officeAddress);
  const [state, setState] = useState(currentUser.businessInfo.state);
  const [companyLogo, setCompanyLogo] = useState(currentUser.businessInfo.companyLogoUrl);
  const [taxIdentificationNo, setTaxIdentificationNo] = useState(currentUser.businessInfo.taxIdentificationNo);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState("");
  const fileInputRef = useRef();
  const onChange = (e) => {
    setCompanyLogo(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  // const [addVendorBusinessInfo] = useAddVendorBusinessInfoMutation();
  const [addVendorBusinessInfo] = useUpdateVendorBusinessMutation()
  const { data: countryList } = useGetListOfCountriesQuery();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(token);
    console.log("processing ..........");

    const formdata = new FormData();
    formdata.append("brandName", brandName);
    formdata.append("businessPhoneNo", businessPhoneNo);
    formdata.append("state", state);
    formdata.append("officeAddress", officeAddress);
    formdata.append("nationality", nationality);
    formdata.append("companyEmail", companyEmail);
    formdata.append("companyLogo", companyLogo);
    formdata.append("taxIdentificationNo", taxIdentificationNo)

    addVendorBusinessInfo(formdata)
      .unwrap()
      .then((data) => {
        console.log("Done.....");
        console.log(state);
        setBrandName("");
        setBusinessPhoneNo("");
        setCompanyEmail("");
        setNationality("");
        setOfficeAdrress("");
        setState("");
        toast.success("Profile Updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
        setTimeout(() => {
          navigate("/vendor/onboarding/shipping-address");
        }, 1000);
      })
      .catch(error => {
        console.log(error)
      setLoading(false)
      })
    
  };
  return (
    <>
      <div className="p-2 w-4/5 mx-auto flex justify-between items-center">
        <Link to="/vendor/onboarding">
          <IconButton className="cursor-pointer">
            <ChevronLeftSharpIcon className="text-xl" />
          </IconButton>
        </Link>
        <Link to="/vendor/onboarding/shipping-address">
          <IconButton className="cursor-pointer">
            <NavigateNextIcon className="text-xl" />
          </IconButton>
        </Link>
        {/* <IconButton className='cursor-pointer'>
          <ArrowForwardIosIcon />
        </IconButton> */}
      </div>

      <div className="w-[55%] mx-auto py-4">
        <Stack spacing={3}>
          <h2 className="text-3xl">Update Business Information</h2>
          <p>
            Please fill the form below to receive a quote for your project. Feel
            free to add as much detail as needed.
          </p>
          <Box p={4} className="rounded-md shadow-md">
            <div className="mx-auto w-full">
              <div className="flex items-center justify-between w-full space-x-2 py-4">
                {/* <p className='text-[17px]'>Choose from file</p>
                <h3 className='font-bold text-yellow-600 bg-yellow-50 p-1 cursor-pointer'>Upload</h3> */}
                <input
                  type="file"
                  id="file"
                  style={{ display: "none" }}
                  onChange={onChange}
                  ref={fileInputRef}
                  accept="image/*"
                />
                {!companyLogo && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      fileInputRef.current.click();
                    }}
                    className="cursor-pointer bg-brown-500 text-white py-2 px-5"
                  >
                    Upload logo
                  </button>
                )}
                {companyLogo && (
                  <div className="py-4 flex items-center space-x-4">
                    <img
                      src={URL.createObjectURL(companyLogo)}
                      className="w-[20%] h-[20%]"
                      alt="Image Preview"
                    />
                    <div className="space-y-4 py-2">
                      <p>{companyLogo?.name}</p>
                      <div className=" flex items-center space-x-4">
                        <button
                          onClick={() => {
                            setCompanyLogo();
                          }}
                          className="border border-[#1E323F] text-[#1E323F] rounded-md py-2 px-4"
                        >
                          Delete
                        </button>
                        <button
                          className="bg-[#1E323F] py-2 px-4 rounded-md text-white"
                          onClick={(e) => {
                            e.preventDefault();
                            fileInputRef.current.click();
                          }}
                        >
                          Replace
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <form className="w-full" onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={4}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={12} md={12}>
                    <label className="text-left">
                      <div className="mb-3">Business Name</div>
                    </label>
                    <input
                      type="text"
                      id="brandName"
                      name="brandName"
                      value={brandName}
                      placeholder={''}
                      onChange={(e) => setBrandName(e.target.value)}
                      className="form-control p-2 rounded-md px-2 bg-gray-100 border-2 border-gray-50 w-full"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label>
                      <div className="mb-3">Business Email Address</div>
                    </label>
                    <input
                      type="text"
                      id="companyEmail"
                      name="companyEmail"
                      value={companyEmail}
                      placeholder={currentUser.businessInfo.companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                      className="form-control p-2 w-full  rounded-md bg-gray-100 border-gray-50"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label className="text-left">
                      <div className="mb-3">Business Phone Number</div>
                    </label>
                    <input
                      type="text"
                      id="businessPhoneNo"
                      name="businessPhoneNo"
                      value={businessPhoneNo}
                      placeholder={currentUser.businessInfo.businessPhoneNo}
                      onChange={(e) => setBusinessPhoneNo(e.target.value)}
                      className="form-control p-2 w-full rounded-md bg-gray-100 border-2 border-gray-50"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <label>
                      <div className="mb-3">Other Address</div>
                    </label>
                    <input
                      type="text"
                      id=" officeAddress"
                      name="officeAddress"
                      value={officeAddress}
                      placeholder={currentUser.businessInfo.officeAddress}
                      onChange={(e) => setOfficeAdrress(e.target.value)}
                      className="form-control p-2 w-full  rounded-md bg-gray-100 border-gray-50"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label className="text-left">
                      <div className="mb-3">Tax Identification Number</div>
                    </label>
                    <input
                      type="text"
                      id="tax"
                      name="taxIdentificationNo"
                      value={taxIdentificationNo}
                      placeholder="TIN339229399"
                      // onChange={handleChange}
                      onChange={(e) => setTaxIdentificationNo(e.target.value)}
                      className="form-control p-2 w-full  rounded-md bg-gray-100 border-2 border-gray-50"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>

                  <Grid item xs={12} md={6}>
                    <label>
                      <div className="mb-3">State</div>
                    </label>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      value={state}
                      placeholder={currentUser.state}
                      onChange={(e) => setState(e.target.value)}
                      className="form-control p-2 w-full  rounded-md bg-gray-100 border-gray-50"
                      required
                    />
                  </Grid>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                  <Grid item xs={12} md={6}>
                    <label className="text-left">
                      <div className="mb-3">Nationality</div>
                    </label>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={nationality}
                    name="nationality"
                    label="nationality"
                    onChange={e => setNationality(e.target.value)}
                    className="w-32"
                  >
                    {countryList &&
                      countryList.data.map((cat, i) => (
                        <MenuItem key={i} value={cat.alpha3Abbr}>
                          {cat.fullName}
                        </MenuItem>
                      ))}
                  </Select>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <button
                      type="submit"
                      disabled={
                        brandName &&
                        businessPhoneNo &&
                        companyEmail &&
                        nationality &&
                        officeAddress &&
                        state
                          ? false
                          : true
                      }
                      className={
                        brandName &&
                        businessPhoneNo &&
                        companyEmail &&
                        nationality &&
                        officeAddress &&
                        state
                          ? `btn-lg w-full bg-brown-700 p-2 text-white`
                          : ` btn-lg w-full bg-brown-200 p-2 text-white`
                      }
                    >
                      {loading ? (
                        <div className="flex items-center justify-center">
                          <CircularProgress />
                        </div>
                      ) : (
                        <p>Save Changes</p>
                      )}
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Box>
        </Stack>
      </div>
    </>
  );
};

export default Business;
