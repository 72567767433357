

import React from 'react'
import { Link } from 'react-router-dom'

export default function AuthenticationBottomNav() {
  return (
    <div className=''>

    <div className='fixed bottom-0 w-full md:h-16 h-20 bg-white border-t-2 border-brown-800 hidden md:flex space-y-1 md:space-y-0 justify-center items-center space-x-5'>
        <h1 className='md:text-[14px] text-[14px] text-center md:text-left'>Don't miss out on the offers on the AfCFTA market</h1>
        <div className='flex space-x-3'>
            <Link to="/login"><button className='px-5 py-2 min-w-[150px] md:min-w-[150px] border border-brown-600 md:text-[14px] text-[12px]  rounded-sm'>Login</button></Link>
            <Link to="select-user"><button className='px-5 py-2 min-w-[150px] md:min-w-[150px] bg-brown-800 md:text-[14px] text-[12px]  border-brown-800 text-white rounded-sm'>Create account</button></Link>
        </div>
    </div>

    <div className='fixed bottom-0 w-full md:h-16 h-20 bg-white border-t-2 border-brown-800 md:hidden flex flex-col space-y-1 md:space-y-0 justify-center items-center space-x-5'>
        <h1 className='md:text-[14px] text-[14px] text-center md:text-left'>Don't miss out on the offers on the AfCFTA market</h1>
        <div className='flex space-x-3'>
            <Link to="/login"><button className='px-5 py-2 min-w-[150px] md:min-w-[150px] border border-brown-600 md:text-[14px] text-[12px]  rounded-sm'>Login</button></Link>
            <Link to="select-user"><button className='px-5 py-2 min-w-[150px] md:min-w-[150px] bg-brown-800 md:text-[14px] text-[12px]  border-brown-800 text-white rounded-sm'>Create account</button></Link>
        </div>
    </div>
    </div>
  )
}
