import { createSlice } from "@reduxjs/toolkit"





const initialState = {
    open: false,
    message: "",
    timeout: 2000
}


export const snackReducer = createSlice({
    name: "snackbar" ,
    initialState,
    reducers: {
        openSnackBar: (state, action) => {
            state.open = true
            state.message = action.payload.message
            
        },
        closeSnackbar: (state) => {
            state.open = false
            state.message = ""
        }
    }
})

export const {openSnackBar, closeSnackbar} = snackReducer.actions

export default snackReducer.reducer