import { Skeleton } from "@mui/material";
import HeadMarket from "../../../../../component/MarketPlace/child/HeadMarket";
import { formatToCurrency } from "../../../../../component/utils/currencyFormatter";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInitializeServiceOrderMutation } from "../../../../../redux/apiSlice";
import { setCheckoutType } from "../../../../../redux/checkoutSlice";
import { resetCurrentOffer } from "../../../../../redux/offerSlice";

export default function OfferCheckout() {
  const dispatch = useDispatch();

  // state
  const currentOffer = useSelector((state) => state.offer.currentOffer);
  console.log(currentOffer);

  // Effect
  useEffect(() => {
    return () => {
      dispatch(resetCurrentOffer());
    };
  }, []);

  // Data
  const [initializeOfferPayment, { isLoading }] =
    useInitializeServiceOrderMutation();

  // methods
  function handleServicePayment(offerId, serviceId) {
    console.log(offerId, serviceId);
    try {
      initializeOfferPayment({
        offerId: offerId,
        serviceId: serviceId,
      })
        .unwrap()
        .then((data) => {
          console.log(data);
          dispatch(setCheckoutType({ type: "services" }));
          window.location.replace(data.data.webLink);
        });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      <HeadMarket />
      <div className="grid md:grid-cols-12 grid-cols-1 md:gap-10 mb-5 mt-2">
        <div className="md:col-span-3"></div>
        <div className="md:col-span-6 col-span-1">
          {currentOffer?.service === undefined ? (
            <Skeleton />
          ) : (
            <div className="border p-3 rounded-md w-full space-y-5">
              <div className="space-y-2">
                <div className="w-full h-40 relative">
                  <img
                    src={currentOffer.service.productImage}
                    alt=""
                    className="object-cover overflow-hidden w-full h-full rounded-md"
                  />
                </div>
                <hr />
                <h1 className="text-[20px] text-slate-500">
                  {currentOffer.description}
                </h1>
                <p className="font-bold font-serifextra text-[20px]">
                  {formatToCurrency(currentOffer.service.price)}
                </p>
              </div>

              {/* User details */}
              <h1>Your Information</h1>
              <div className="grid grid-cols-2"></div>
              <button
                className="bg-[#2E99EC] w-full py-3 px-3 text-white rounded-md"
                onClick={() =>
                  handleServicePayment(currentOffer.id, currentOffer.service.id)
                }
              >
                Pay Now
              </button>
            </div>
          )}
        </div>
        <div className="md:col-span-3"></div>
      </div>
    </div>
  );
}
