import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ToastContainer } from "react-toastify";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Backdrop,
  Fade,
  IconButton,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { useSelector } from "react-redux";
import {
  useGetServiceCategoriesQuery,
  useUpdateServiceDetailMutation,
} from "../../../../../redux/apiSlice";
import { useNavigate, useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  // border: '1px solid #000',
  boxShadow: 24,
  borderRadius: 4,
  p: 3,
  maxHeight: "90vh",
  overflowY: "scroll",
};

export default function EditService() {
  // state
  const serviceDetail = useSelector((state) => state.service.updateData);
  // console.log(serviceDetail)

  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);

  const [serviceDetailEdit, setServiceDetailEdit] = useState({
    title: serviceDetail.name,
    description: serviceDetail.description,
    // category: serviceDetail.category.id,
    // specialization: serviceDetail.specialization,
    price: serviceDetail.price,
    // projectUrl: serviceDetail.projectUrl,
  });
  const [tags, setTags] = useState(serviceDetail.tags);
  const [tagInput, setTagInput] = useState("");
  const [productImage, setProductImage] = useState(serviceDetail.productImage);

  // Ref
  const imageRef = React.useRef();
  const navigate = useNavigate();
  const route = useParams();

  // Data
  // const {data: categoryList, isLoading} = useGetServiceCategoriesQuery()
  const [updateService, { isLoading }] = useUpdateServiceDetailMutation();

  // Methods
  const handleClose = () => {};

  const selectImageFile = () => {
    imageRef.current.click();
  };

  function updateCoverImage(e) {
    setProductImage(e.target.files[0]);
    setChanged(true);
  }

  function handleChangeInput(e) {
    const { name, value } = e.target;
    setServiceDetailEdit({ ...serviceDetailEdit, [name]: value });
  }

  function changeTagInput(e) {
    setTagInput(e.target.value);
  }

  function handleKeydownPushTag(e) {
    const { key } = e;
    const trimmedInput = tagInput.trim();

    if (key === "," && tagInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      setTags((prev) => [...prev, trimmedInput]);
      setTagInput("");
    }
  }

  // submit update
  function submitServiceUpdate(e) {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", serviceDetailEdit.title);
    formData.append("description", serviceDetailEdit.description);
    formData.append("maxPrice", serviceDetailEdit.price);
    formData.append("price", serviceDetailEdit.price);
    if (changed) {
      formData.append("photo", productImage);
    }

    for (var pair of formData.entries()) {
      console.log(pair[0] + " - " + pair[1]);
    }

    // console.log(formData);

    try {
      updateService({ id: route.id, data: formData })
        .unwrap()
        .then((data) => {
          console.log(data);
          navigate(-1);
        });
    } catch (err) {
      console.log("Error", err);
    }
  }

  // console.log(serviceDetailEdit)
  // console.log(tags)

  return (
    <div>
      <div className="w-[90%] mx-auto">
        <Stack spacing={2}>
          {
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Edit Service details
              </Typography>
              <div>
                <div className="py-3">
                  <div className="divide-y w-[50%]  border-brown-700 border"></div>
                </div>
                <form className="space-y-2">
                  <div>
                    <label className="text-left">
                      <div className="mb-3">
                        Name
                        <span className="text-red-700 font-bold">*</span>
                      </div>
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={serviceDetailEdit.title}
                      className="form-control px-2 py-2.5 w-full rounded-md  border border-gray-200"
                      required
                      onChange={handleChangeInput}
                    />
                  </div>
                  <div>
                    <label className="text-left">
                      <div className="mb-3">
                        Description
                        <span className="text-red-700 font-bold">*</span>
                      </div>
                    </label>
                    <TextareaAutosize
                      minRows={5}
                      variant="outlined"
                      type="text"
                      id="lastname"
                      name="description"
                      value={serviceDetailEdit.description}
                      className="form-control p-2 w-full rounded-md  border-2 border-gray-400"
                      required
                      onChange={handleChangeInput}
                    />
                  </div>
                  <div>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <label className="text-left">
                          <div className="mb-3 ">
                            Opening Price{" "}
                            <span className="text-red-700 font-bold">*</span>
                          </div>
                        </label>
                        <input
                          id="lastname"
                          name="price"
                          type="number"
                          value={serviceDetailEdit.price}
                          className="form-control p-2 w-full rounded-md  border-2 border-gray-200"
                          required
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <label className="text-left">
                          <div className="mb-3">
                            Category
                            <span className="text-red-700 font-bold">*</span>
                          </div>
                        </label>
                        <input
                          id="category"
                          name="category"
                          variant="outlined"
                          value={serviceDetailEdit.category}
                          className="form-control p-2 w-full rounded-md outline-none"
                          readOnly
                          onChange={handleChangeInput}
                          type="text"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <label className="text-left">
                          <div className="mb-3 ">
                            Specialization{" "}
                            <span className="text-red-700 font-bold">*</span>
                          </div>
                        </label>
                        <input
                          id="lastname"
                          name="specialization"
                          type="text"
                          value={serviceDetailEdit.specialization}
                          className="form-control p-2 w-full rounded-md outline-none"
                          required
                          readOnly
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <label className="text-left">
                          <div className="mb-3 flex items-center justify-between">
                            <p> Special tags</p>
                            <p className="text-gray-300">8 tags min</p>
                          </div>
                        </label>
                        {/* <input
                          id="lastname"
                          name="tags"
                          type="text"
                          className="form-control p-2 w-full rounded-md  border-2 border-gray-200"
                          required
                          value={tagInput}
                          onChange={changeTagInput}
                          onKeyDown={handleKeydownPushTag}
                        /> */}
                        <div
                          className="flex items-center gap-3
                         flex-wrap mt-5"
                        >
                          {tags.length > 0 &&
                            tags.map((tag, i) => (
                              <div
                                key={i}
                                className="border border-brown-400 px-3 py-2"
                              >
                                <p>{tag.tagName}</p>
                              </div>
                            ))}
                        </div>
                      </Grid>
                      {/* <Grid item xs={12} md={6}>
                                           </Grid> */}
                    </Grid>
                  </div>
                  <div className="space-y-3"></div>
                </form>
              </div>
            </div>
          }

          <div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
            ></Typography>
            <div>
              <div className="py-3">
                <div className="divide-y w-[100%]  border-brown-700 border"></div>
              </div>
              <Stack spacing={1}>
                <div className="py-3">
                  <h2 className="font-bold text-[16px]">
                    Change cover image <span className="text-red-500">*</span>{" "}
                  </h2>
                </div>
                <h2 className="text-[14px] text-slate-500">
                  Upload up to 5 images, with max size 10MB each
                </h2>
                <h2 className="text-[14px] text-slate-500">
                  High resolution images (jpg, png){" "}
                  <span className="text-red-500">*</span>
                </h2>
                <div className="py-4 flex items-end gap-5">
                  <div className="h-[25vh] flex justify-center items-center w-2/5 border border-gray-200  overflow-clip">
                    <input
                      hidden
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      ref={imageRef}
                      onChange={updateCoverImage}
                    />
                    <img
                      src={
                        !changed
                          ? productImage
                          : URL.createObjectURL(productImage)
                      }
                      className="object-cover"
                    />
                  </div>
                  <button
                    className="px-4 py-2 bg-slate-200 border-slate-500 rounded-md"
                    onClick={selectImageFile}
                  >
                    Change picture
                  </button>
                </div>
                <div className="py-4 space-y-4 mt-3">
                  <p className="text-lg font-bold">Project Url</p>
                  <div className="h-3/5">
                    <input
                      variant="outlined"
                      className="w-full"
                      onChange={handleChangeInput}
                    />
                  </div>
                </div>
              </Stack>
              <div className="py-4">
                <button
                  variant="contained"
                  className={`flex justify-center items-center
                    ${
                      1
                        ? "py-2 bg-brown-900 text-white w-full mt-4 rounded-md"
                        : "py-2 bg-blue-100 text-white w-full mt-4"
                    }
                  `}
                  onClick={submitServiceUpdate}
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <p>Update Changes</p>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Stack>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
