

import { Stack } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export default function EmptyCart() {
  return (
    <div>
        <div className=" mx-auto my-5 p-10 bg-white">
                          <div className="flex flex-col justify-center items-center h-[400px]">
                            <Stack spacing={6}>
                              <div className="p-2 rounded-full">
                                <img
                                  src="/images/empty-cart.png"
                                  className="object-cover w-20"
                                />
                              </div>
                            </Stack>
                            <Stack spacing={2} className="mt-5 text-center">
                              <h2 className="font-bold md:text-xl text-slate-400">
                                You have not added any service offer yet!
                              </h2>
                            </Stack>
                            <div className="mt-4">
                              <Link to="/market">
                                <p className="underline underline-offset-4 text-brown-800 mt-5">
                                  Create service offers
                                </p>
                              </Link>
                            </div>
                          </div>
                        </div>
    </div>
  )
}
