import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Link } from "react-router-dom";
import { useGetListOfCountriesQuery } from "../../../../redux/apiSlice";

const Sidebar = ({ categoryData }) => {
  const [age, setAge] = React.useState("");
  const [country, setCountry] = useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  // Country data
  const { data: countryList, error } = useGetListOfCountriesQuery();

  console.log("Category list", categoryData)

  return (
    <>
      <div className="bg-white  rounded-md hidden md:block">
        <div className="p-5">
          <h2 className="font-light text-[14px] text-slate-500 mb-5">Filter Products</h2>
          <div className="space-y-5">
            {/* Country */}
            <FormControl className="w-full hidden">
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={country}
                label="Select country"
                onChange={(e) => setCountry(e.target.value)}
              >
                {countryList &&
                  countryList.data.map((cat, i) => (
                    <MenuItem key={i} value={cat.fullName}>
                      {cat.fullName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {/* Price */}
            <div>
              <h2 className="font-light text-[14px]">Price</h2>
              <div className="flex justify-between space-x-4">
                <TextField placeholder="min" variant="outlined" />
                <TextField placeholder="max" variant="outlined" />
              </div>
            </div>

            <div>
              <h2 className="font-light text-[14px]">Category</h2>
              <div className="space-y-5 mt-5">
                {categoryData &&
                  categoryData.length > 0 &&
                  categoryData.map((list) => (
                    <Link
                      to={`/market/goods/category/${list.id}`}
                      state={{
                        categoryName: list.name,
                      }}
                    >
                      <div className="p-1.5 rounded hover:bg-amber-200 hover:text-brown-800 border border-gray-100 my-2">
                        <h1 className="font-medium font-sans">{list.name}</h1>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
