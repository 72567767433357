




import React from 'react'
import Oval from 'react-loading-icons/dist/esm/components/oval'

export default function DeleteConfirmation({loading, deleteFunction, message}) {
  return (
    <div>
        {loading ? (
                                    <>
                                    <div className="min-w-[250px] md:min-h-[250px] rounded-md md:py-5 py-10 flex flex-col items-center justify-center">
                                      <Oval stroke='#fcb240' />
                                    </div>
                                    </>
                                  ) : (
                                    <div className="min-w-[250px] md:min-h-[250px] rounded-md md:py-5 py-10 flex flex-col items-center justify-center">
                                      <h1 className="text-[25px] text-center px-10">
                                        Are you sure you want to delete this
                                        {" "}{message}?
                                      </h1>
                                      <div className="flex items-center md:space-x-5 space-x-2 mt-5">
                                        <button className="bg-blue-200  px-5 py-3 rounded-md text-[14px] font-bold text-blue-500">
                                          Cancel
                                        </button>
                                        <button
                                          className="text-white px-5 py-3 rounded-md text-[14px] font-bold bg-red-600 min-w-[200px]"
                                          onClick={deleteFunction}
                                        >
                                          Yes, Delete
                                        </button>
                                      </div>
                                    </div>
                                  )}
    </div>
  )
}
