import { Stack } from '@mui/material';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';




const Cover = () => {

const navigate = useNavigate()
    const gotoGuide = () => {
        navigate("/rules")
    }

    return (
        <>
            <div className={`bg-gradient-to-tr from-[#2b0948] to-[#ce653b] p-1 flex items-center relative rounded-lg my-10 h-[450px]`}>
                <div className='mx-auto flex p-5 items-center'>
                    <div className='space-y-3'>
                        <h2 className='md:text-4xl text-[20px] leading-tight font-bold text-white text-center'>Join the largest African market</h2>
                        <h2 className='text-white font-extralight text-center md:text-xl text-xs'>Start selling to customers from other countries, make more sales</h2>
                        <div className="mt-3 flex justify-center">
                            <button className='text-white text-sm rounded-md py-2.5 px-2 w-[200px] mx-auto bg-[#ff8800]'>
                        <Link to="/select-user">
                                Create account
                        </Link>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='absolute top-0 right-0 hidden'>
                    <img src="/images/king.png" className='h-[100px]' />
                </div>

            </div>

            <div className='mt-8 hidden  md:px-10 px-5 py-5 bg-gray-100 rounded-md shadow-sm mb-5'>
                <div className='flex items-center justify-between'>
                    <div className=''>
                        <h1 className='md:text-2xl text-xl font-bold'>Understand AfCFTA Rule of Origin Guideline</h1>
                        <h6 className='font-light leading-tight md:text-lg text-xs text-slate-500 md:w-3/4'>Learn how AFCFTA Rule of Origin can affect your goods & services across the African market</h6>
                        
                    </div>
                    <div>
                        <img src="/images/globe.png" className='md:w-36 w-36' />
                    </div>
                </div>
                    <button className='py-2 px-3 rounded mt-5 bg-brown-600 text-white w-full md:w-1/2' onClick={gotoGuide}>Learn more</button>
            </div>
        </>
    )
}

export default Cover;