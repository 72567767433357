

import { Dialog, Grid, Skeleton } from '@mui/material';
import DummySkeleton from '../../../../../component/MarketPlace/goods/child/DummySkeleton';
import DeleteConfirmation from '../../../../../component/vendor/components/DeleteConfirmation';
import React, { useState } from 'react'
import { MdArrowBack } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteSingleServiceMutation, useGetSingleServiceQuery } from '../../../../../redux/apiSlice';
import { addDataToUpdate, closeServiceDelete, openServiceDelete } from '../../../../../redux/serviceSlice';
import { openSnackBar } from '../../../../../redux/snackSlice';

export default function ServiceDetail() {

  // hooks
  const route = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // state
  const deleteDialog = useSelector(state => state.service.deleteModal)
  // console.log(deleteDialog.deleteModal);

  // Data
  const {data, error, isLoading} = useGetSingleServiceQuery(route.id)

  const [deleteSingleService, {isLoading: deleteLoading}] = useDeleteSingleServiceMutation()


  // Methods
  function handleOpenEditModal () {
    console.log("Edit service");
  }

  function handleCloseDialog() {
    dispatch(closeServiceDelete())
  }

  function gotoEditPage (serviceInfo) {
    dispatch(addDataToUpdate(serviceInfo))
    navigate(`update`)

  }

  function deleteService () {
    dispatch(openServiceDelete())
  }

  function handleDeleteFunction () {
    
    try {
      deleteSingleService(route.id).unwrap()
      .then(data => {
        console.log(data)
        dispatch(closeServiceDelete())
        dispatch(openSnackBar({message: "Item has been deleted!"}))
        navigate(-1)
      })
    } catch (err) {
      console.log(err);
    }
  }

  // logs
  console.log(data && data);

  return (
    <div>
      <div className='flex items-center mx-4 cursor-pointer space-x-2 mb-5' onClick={() => navigate(-1)}>
        <MdArrowBack />
        <p>Go back</p>
      </div>
      {!data ? (
        <div className="py-8 px-4">
          <div className="">
            <div className="grid grid-cols-3 gap-5">
              <div className="col-span-2">
                <Skeleton
                  variant="rectangular"
                  animation="pulse"
                  sx={{ height: 400, borderRadius: 3 }}
                />
              </div>
              <div className="space-y-5">
                <Skeleton
                  variant="rectangular"
                  animation="pulse"
                  sx={{ height: 80, borderRadius: 3 }}
                />
                <Skeleton
                  variant="rectangular"
                  animation="pulse"
                  sx={{ height: 150, borderRadius: 3 }}
                />
                <div className="block space-y-2 sticky mt-10">
                  <div
                    className="py-4 w-full"
                    // onClick={handleOpenSaveServiceModal}
                  >
                    <Skeleton />
                  </div>
                  <div
                    className="py-4 w-full"
                    // onClick={handleOpenOfferModal}
                  >
                    <Skeleton />
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-2 mt-5">
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ height: 20, width: 200 }}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ height: 10 }}
              />
            </div>
          </div>
          <div className="mt-10">
            <DummySkeleton />
          </div>
        </div>
      ) : (
        <>
          <div className="py-2">
            <div className="mx-auto">
              <div className="grid md:grid-cols-3 grid-cols-1">
                <div className="col-span-2">
                  <div className="py-1 md:px-5">
                    <h2 className="text-2xl font-bold">
                      {data && data.data.name}
                    </h2>
                    <div>
                      <Grid
                        container
                        justifyContent=""
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item xs={12} md={12}>
                          <div className="py-4">
                            <img
                              src={data.data.productImage || ""}
                              className="w-full min-h-[400px] max-h-[350px] rounded-md object-cover"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="mt-3">
                      <hr className="" />
                    </div>
                    <div className="py-4 space-y-4">
                      <h2 className="font-bold">Project Details</h2>
                      <p>{data && data.data.description}</p>
                      <p className="text-blue-400">
                        {data && data.data.projectUrl}
                      </p>
                    </div>
                    <div className="mt-3">
                      <hr className="" />
                    </div>

                    <div className="py-3 mt-3">
                      <h2 className="font-bold">Tags</h2>

                      <div className="flex flex-wrap items-center gap-3 py-3">
                        {data &&
                          data.data.tags.map((item, i) => (
                            <button
                              key={i}
                              className="py-2 px-3 whitespace-nowrap border border-gray-200 rounded-lg hover:bg-brown-900 hover:text-white"
                            >
                              {item.tagName}
                            </button>
                          ))}
                      </div>
                    </div>
                    <div className="py-3 mt-3 w-full">
                      <h2 className="font-bold">Reviews</h2>
                      <div className="flex justify-center items-center">
                        <div className="py-8">
                          <h2 className="text-gray-400">
                            You have no reviews yet
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* User card */}
                <div className="md:mx-10">
                  
                  <div className="block space-y-2 sticky mt-10">
                    {/* <button className="py-4 w-full border rounded-md border-brown-900">
                      Save work
                    </button> */}
                    <button
                      className="py-4 w-full text-white rounded-md bg-brown-900"
                      onClick={() => gotoEditPage (data.data) }
                    >
                      Edit
                    </button>
                    <button
                      className="py-4 w-full text-white rounded-md bg-red-600"
                      onClick={() => deleteService (data.data.id) }
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </>
      )}
      {/* <CreateOffer open={modal.modal}  /> */}
      {/* Create a dialog that first check if the user is currently authenticated, if yes, show create offer, otherwise will show the login/signup pop-up */}
        <Dialog
          // fullScreen={fullScreen}
          open={deleteDialog}
          onClose={handleCloseDialog}
          aria-labelledby="responsive-dialog-title"
          className="example"
          >
          <DeleteConfirmation message={"service"} loading={deleteLoading} deleteFunction={handleDeleteFunction} />
        </Dialog>
    </div>
  )
}
