import React, { useState } from "react";
import { formatToCurrency } from "../../../../../utils/currencyFormatter";
import axios from "axios";
import {
  useGetVendorWalletSummaryQuery,
  useInitiateWithdrawalMutation,
} from "../../../../../../redux/apiSlice";
import { Grid, Skeleton, TextField } from "@mui/material";
import { Oval } from "react-loading-icons";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { ArrowBackIos } from "@material-ui/icons";

export default function WithdrawalIndex() {
  // const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [fullfilled, setFullfilled] = useState(false);
  const [formData, setFormData] = useState({
    amount: "",
    accountName: "",
    accountNumber: "",
    bankCode: "",
    bankName: "",
  });

  const navigate = useNavigate();
  const { data: balance, error } = useGetVendorWalletSummaryQuery();
  const [withdrawFunds] = useInitiateWithdrawalMutation();

  // Methods
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  function handleGoBack() {
    navigate(-1)
  }

  const handleWithdrawal = async (e) => {
    e.preventDefault();
    const {accountName, accountNumber, bankCode, bankName, amount} = formData
    setLoading(true);
    withdrawFunds({
      account: {
        accountName: accountName,
        accountNumber: accountNumber,
        bankCode: bankCode,
        bankName: bankName,
      },
      amount: amount,
    })
      .unwrap()
      .then((data) => {
        console.log(data);
        setLoading(false);
        setFullfilled(true);
        setTimeout(() => {
          navigate("/dashboard/wallet");
        }, 5000);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  

  return (
    <div>

      {!fullfilled ? (
        <div className="p-20">
          <div className="flex items-center cursor-pointer" onClick={handleGoBack}> <ArrowBackIos /> <p>Go Back</p></div>
          <div className="mt-5 w-[500px] bg-white p-5">
          <h1 className="text-2xl font-bold">Withdrawal</h1>
            <div className="flex justify-end">
              <p className="my-2 text-lg">
                Available Balance:{" "}
                {!balance ? (
                  <Skeleton />
                ) : (
                  <span className="font-bold text-xl">
                    {formatToCurrency(balance?.data.available.amount)}
                  </span>
                )}
              </p>
            </div>
            <form className="flex flex-col space-y-3 border p-3 rounded-sm bg-gray-100">
              {/* <div className="my-5 hidden">
                <input
                  type="number"
                  name="amount"
                  value={amount}
                  className="py-3 px-2 outline-none border-[0.4px]"
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div> */}

                  <h1>Withdrawal Amount</h1>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Amount"
                    fullWidth
                    variant="outlined"
                    name="amount"
                    value={formData.amount}
                    onChange={handleInputChange}
                    className="bg-white"
                  />
                </Grid>
                </Grid>
                <hr className="my-7" />
                <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Account Name"
                    fullWidth
                    variant="outlined"
                    name="accountName"
                    value={formData.accountName}
                    onChange={handleInputChange}
                    className="bg-white"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Account Number"
                    fullWidth
                    variant="outlined"
                    name="accountNumber"
                    value={formData.accountNumber}
                    onChange={handleInputChange}
                    className="bg-white"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Bank Code"
                    fullWidth
                    variant="outlined"
                    name="bankCode"
                    value={formData.bankCode}
                    onChange={handleInputChange}
                    className="bg-white"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Bank Name"
                    fullWidth
                    variant="outlined"
                    name="bankName"
                    value={formData.bankName}
                    onChange={handleInputChange}
                    className="bg-white"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    className="bg-brown-800 text-white py-3 w-full rounded-md flex justify-center items-center"
                    onClick={handleWithdrawal}
                  >
                    {loading ? <Oval fontSize={20} /> : "Withdraw"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      ) : (
        <div className="p-20 flex flex-col space-y-5 items-center">
          <h1 className="text-3xl font-bold">Withdrawal Successfull</h1>
          <p>
            Your withdrawal has been submitted, you will get a notification soon
          </p>
          <button className="underline"> You will be redirected in 5s</button>
        </div>
      )}
    </div>
  );
}
