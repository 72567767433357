


import { Skeleton } from '@mui/material'
import React from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useGetSingleProductDetailsQuery } from '../../../redux/apiSlice'

export default function SingleGoodsCategory() {

    const navigate = useNavigate()

    const routeBack = () => {
        navigate(-1)
    }

    const route = useParams()
    

    const { data, isLoading, error } = useGetSingleProductDetailsQuery(route.id)

    if (isLoading) {

    }

    if (error) {
        
    }

    if (data) {
        
    }

    return (
        <div>
            <div className="m-5">
                <button className="" onClick={routeBack}>Go back to Categories</button>
            </div>
            <div className="m-5">
                <h1 className='text-xl font-bold'>{!data ? <Skeleton sx={{ height: 20, width: 120 }} animation="wave" variant="rectangular" /> : `Category Name`} </h1>
            </div>
            {!data ? (
                <div className='grid grid-cols-5 gap-2 p-5'>
                    {tryNum.slice(0, 5).map((item, i) => (

                        <div className="space-y-2" key={i}>
                            <Skeleton sx={{ height: 100, width: "100%" }} animation="wave" variant='rectangular' className='w-full h-40' />
                            <Skeleton sx={{ height: 10, width: 70 }} animation="wave" variant="rectangular" />
                            <Skeleton sx={{ height: 10, width: 50 }} animation="wave" variant="rectangular" />
                            <Skeleton sx={{ height: 10, width: 30 }} animation="wave" variant="rectangular" />
                        </div>

                    ))}
                </div>
            ) : (
                <div className='grid grid-cols-5 gap-2 p-5'>
                    {tryNum.slice(0, 5).map((item, i) => (
                        <Link to="/market/goods/2">
                            <div className="" key={i}>
                                <div className='w-full h-40 bg-blue-500' />
                                <h1>Name of product</h1>
                                <p>Other details</p>
                                <p>Price</p>
                            </div>
                        </Link>
                    ))}
                </div>)}
        </div>
    )
}

const tryNum = [1, 2, 3, 4, 5, 6, 7, 8]