import { Grid, Rating, Skeleton, Snackbar } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  useAddProductToCartMutation,
  useGetAllCartItemQuery,
  useGetSimilarProductsByIdQuery,
  useGetSingleProductDetailsQuery,
  useUpdateCartMutation,
} from "../../../redux/apiSlice";
import MarketFooter from "../child/MarketFooter";
import VendorInfo from "../child/VendorInfo";
import SimilarProducts from "./child/SimilarProducts";
import HeadMarket from "../child/HeadMarket";
import {
  AddBoxOutlined,
  PlusOneOutlined,
  ShoppingBasket,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { openSnackBar } from "../../../redux/snackSlice";
import AuthenticationBottomNav from "../../auth/AuthenticationBottomNav";

const SingleProduct = () => {
  const [image, setImage] = useState();
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [fromCartQuantity, setFromCartQuantity] = useState();
  const userAccess = useSelector((state) => state.auth.tokenActive);

  const mainRef = useRef();
  const location = useLocation();
  const route = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth);

  // Fetched
  const {
    data: productDetail,
    isLoading,
    error,
  } = useGetSingleProductDetailsQuery(route.id);

  if (productDetail) {
  }

  const {
    data: similarProduct,
    isLoading: similarLoading,
    error: similarError,
  } = useGetSimilarProductsByIdQuery(route.id);

  const [addProductToCart, { isLoading: addToCartLoading }] =
    useAddProductToCartMutation();

  const {
    data: cartItems,
    error: cartError,
    isLoading: cartLoading,
  } = useGetAllCartItemQuery();

  // update cart details
  const [updateCart, { isLoading: cartUpdateLoading }] =
    useUpdateCartMutation();
  // if (cartItems) {
  //   dispatch(addToCart())
  // }

  // Methods for cart

  const AddItemToCart = async (productId) => {
    if (user.userInfo === null) {
      dispatch(openSnackBar({ message: "You are not logged!" }));
    } else {
      const productId = productDetail && productDetail.data.id;
      const cartData = cartItems && cartItems.data;
      const itemInCart = cartData.cartItems.find(
        (item) => item.product.id === productId
      );

      if (itemInCart) {
        const itemCheck =
          itemInCart.quantity === productDetail.data.quantityInStock;
        if (itemCheck) {
          dispatch(
            openSnackBar({ message: "Maximum number of Item in Stock!" })
          );
        } else {
          updateCart({
            data: {
              productId: route.id,
              quantity: quantity,
            },
            cartId: itemInCart.id,
          })
            .unwrap()
            .then((data) => {
              dispatch(openSnackBar({ message: "Cart updated" }));
            });
        }
      } else {
        try {
          addProductToCart({
            productId: route.id,
            quantity: quantity,
          })
            .unwrap()
            .then((data) => {
              dispatch(openSnackBar({ message: "Item added to cart!" }));
            });
        } catch (err) {}
      }
    }
  };

  function changeImage(img) {
    // setImage(img)
    setImage(img);
  }

  //   sub methods
  function handleCloseSnack() {
    setOpen(false);
  }

  const incrementQuantity = (availableQuantity) => {
    setQuantity((prev) =>
      prev === availableQuantity ? availableQuantity : prev + 1
    );
  };

  const decrementQuantity = () => {
    setQuantity((prev) => (prev === 1 ? 1 : prev - 1));
  };

  // Cart checks
  const productId = productDetail && productDetail.data.id;
  console.log(cartItems && cartItems);
  const productExist =
    cartItems &&
    cartItems.data.cartItems.find((item) => item.product.id === productId);

  return (
    <>
      <HeadMarket />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message="Note archived"
        // action={action}
      />
      <div className="md:mx-10 mx-2.5" ref={mainRef}>
        <div className="w-full">
          <div className=" grid md:grid-cols-12 grid-cols-1 gap-5 mt-12">
            {!productDetail ? (
              <>
                <div className=" col-span-5">
                  <Skeleton
                    sx={{ height: 400 }}
                    animation="wave"
                    variant="rectangular"
                  />
                </div>
                <div className="col-span-4 space-y-3">
                  <Skeleton
                    sx={{ height: 30 }}
                    animation="wave"
                    variant="rectangular"
                  />
                  <Skeleton
                    sx={{ height: 30, width: "70%" }}
                    animation="wave"
                    variant="rectangular"
                  />
                  <Skeleton
                    sx={{ height: 30, width: "40%" }}
                    animation="wave"
                    variant="rectangular"
                  />
                </div>
                <div className="col-span-3 ">
                  <div className="p-2.5 rounded bg-slate-100 space-y-3">
                    <Skeleton
                      sx={{ height: 30 }}
                      animation="wave"
                      variant="rectangular"
                    />
                    <Skeleton
                      sx={{ height: 10, width: "70%" }}
                      animation="wave"
                      variant="rectangular"
                    />
                    <Skeleton
                      sx={{ height: 10, width: "40%" }}
                      animation="wave"
                      variant="rectangular"
                    />
                    <Skeleton
                      sx={{ height: 10, width: "40%" }}
                      animation="wave"
                      variant="rectangular"
                    />
                    <Skeleton
                      sx={{ height: 10, width: "40%" }}
                      animation="wave"
                      variant="rectangular"
                    />
                    <Skeleton
                      sx={{ height: 10, width: "40%" }}
                      animation="wave"
                      variant="rectangular"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className=" col-span-4">
                  <div className="border-[0.5px] w-full md:max-h-[450px] min-h-[320px] flex-shrink-0">
                    <img
                      src={!image ? productDetail.data.productImage : image}
                      className="object-cover overflow-hidden w-full h-[450px] rounded-sm"
                    />
                  </div>
                  <div className="grid grid-cols-5 gap-3 w-full">
                    {productDetail.data.screenshots && (
                      <div className="flex w-full mx-auto items-center space-x-5 py-2">
                        {/* Initial image */}
                        <div
                          onClick={() =>
                            changeImage(productDetail.data.productImage)
                          }
                          className="flex-shrink-0"
                        >
                          <div className="p-2 bg-gray-200 flex-shrink-0 hover:border-brown-900 hover:border hover:scale-2 cursor-pointer rounded">
                            <img
                              src={productDetail.data.productImage}
                              className="w-full h-[50px] object-cover flex-shrink-0"
                            />
                          </div>
                        </div>
                        {/* Other snapshots */}
                        {productDetail.data.screenshots
                          .slice(0, 3)
                          .map((x, index) => (
                            <div
                              key={index}
                              onClick={() => changeImage(x.url)}
                              className="flex-shrink-0"
                            >
                              <div className="p-2 bg-gray-200 flex-shrink-0 hover:border-brown-900 hover:border hover:scale-2 cursor-pointer rounded">
                                <img
                                  src={x.url}
                                  className="w-full h-[50px] object-cover "
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-span-5 space-y-3 px-5">
                  <h1 className="text-3xl font-semibold">
                    {productDetail.data.name}
                  </h1>
                  <button className="border rounded px-3 py-2">
                    <p className="text-[12px]">
                      {productDetail.data.quantityInStock}{" "}
                      <span className="text-[10px] text-slate-400">
                        in stock
                      </span>
                    </p>
                  </button>

                  {/* <h2 className='text-gray-400 text-[20px] font-semibold'>{productDetail.data.id}</h2> */}
                  <div>
                    <Rating
                      name="read-only"
                      defaultValue={productDetail.data.ratings}
                      precision={0.5}
                      readOnly
                    />
                  </div>

                  <h2 className="text-gray-400 text-[14px] font-semibold">
                    {productDetail.data.hscode}
                  </h2>

                  <h1 className="text-[25px] leading-none font-bold text-red-600 mt-5">
                    ${productDetail.data.price.toLocaleString()}
                  </h1>
                  <hr className="my-1.5" />
                  <div className="p-2 rounded-md bg-amber-50 flex space-x-3">
                    <div className="bg-amber-500 rounded-sm w-3">
                      {/* <MdPlusOne /> */}
                    </div>
                    <div>
                      {/* <h1 className='text-brown-700 text-ellipsis text-sm'>AfCFTA Status</h1> */}
                      <h1 className="text-brown-700 text-sm">
                        Product is tradable under the AfCFTA provision
                      </h1>
                    </div>
                  </div>
                  <div className="my-5">
                    <div className="flex items-center space-x-3">
                      <p className="text-sm font-light">Quantity</p>
                      <div className="flex items-center justify-between my-5">
                        <button
                          disabled={cartUpdateLoading ? true : false}
                          className="text-brown-700 border border-brown-800 rounded-md w-10 h-10"
                          onClick={decrementQuantity}
                        >
                          -
                        </button>
                        <div className="flex justify-center items-center w-12">
                          {quantity}
                        </div>

                        <button
                          disabled={
                            quantity === productDetail.data.quantityInStock
                              ? true
                              : false || cartUpdateLoading
                              ? true
                              : false
                          }
                          className={`text-brown-700 border border-brown-800 rounded-md w-10 h-10 disabled:border-slate-200 disabled:text-slate-200`}
                          onClick={() =>
                            incrementQuantity(
                              productDetail.data.quantityInStock
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>

                    {/* {itemInCart === undefined ? null : ( */}
                    <button
                      disabled={productExist !== undefined ? true : false}
                      className="py-4 px-10 bg-[#5A3924] text-white rounded-md font-bold disabled:bg-slate-400 disabled:text-slate-500 flex items-center"
                      onClick={() => AddItemToCart(productDetail.data.id)}
                    >
                      <span className="mx-2.5">
                        <AddBoxOutlined />
                      </span>
                      <p>Add to Cart</p>
                    </button>
                    {/* )} */}
                  </div>
                </div>
                <div className="col-span-3">
                  <div className="">
                    <VendorInfo vendorDetail={productDetail.data.vendor} />
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Product details */}
          <div className="my-5 md:mx-0 mx-5">
            <hr className="my-5" />
            <h1 className="text-2xl font-semibold py-4 ">Product Details</h1>
            {!productDetail ? (
              <Skeleton
                animation="wave"
                height={10}
                style={{ marginBottom: 6 }}
              />
            ) : (
              <p className="text-lg font-sans">
                {productDetail.data.description}
              </p>
            )}
          </div>

          {/* Smlar items */}
          <div className="mb-20">
            <SimilarProducts id={route.id} />
          </div>
        </div>
      </div>
      <MarketFooter />
      {!userAccess && <AuthenticationBottomNav />}
    </>
  );
};
export default SingleProduct;
