


import React from 'react'
import { Outlet } from 'react-router-dom'

export default function VendorOrder() {



  return (
    <div className='m-5'>
        {/* <h1>Order Information</h1> */}
        <Outlet />
    </div>
  )
}
