import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import Header from '../../../utils/Header';

function Success(props) {
    const [showPassword, setShowPassword] = useState(false);
    const [state, setState] = useState({
        fullname: '',   
        email: '',
        confirmpassword: '',
        newpassword: ''
    })
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const togglePassword = (e) => {
        e.preventDefault()
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        setShowPassword(!showPassword)
    }
    const handleSubmit = ()=>{
        console.log('submitted successfully')
    }
    return (  
        <>   
            <Header />
            <div className="font-sans">
                <div className="flex justify-center md:items-center md:h-[95vh] h-[95vh] md:pt-0 pt-20 relative px-10 overflow-hidden">
                    {/* floating vector */}
                    <div className="absolute md:-bottom-40 md:-left-10 bottom-0 left-0"> 
                        <div className="md:w-72 md:h-72 w-32 h-32">
                            <img src={"/images/login-vector.svg"} className="" />
                        </div>
                    </div>
                    <div className="flex flex-col items-center md:w-[320px] w-full md:mb-8">
                           <img src='/images/check-circle.png'  className='items-center justify-self-center'/>                    
                        <h3 className="text-center font-bold text-xl mb-10">Check Your Email</h3>
                        <p className='text-gray-500 mb-6 text-center'>A password reset link has successfully been sent to your inbox, check your inbox including spam folder to continue</p>
                    </div>
                </div>
            </div>  
        </>
    );
}

export default Success;