import { createSlice } from '@reduxjs/toolkit'

const initialState =[]

export const ingredients = createSlice({
    name: 'ingredient',
    initialState,
    reducers: {
        add: (state,action) => {
            state.push(action.payload)
        },
        remove: (state,action) => {
            const newState = []
            state = newState
        },
    },
})

// Action creators are generated for each case reducer function
export const { add,remove} = ingredients.actions

export default ingredients.reducer