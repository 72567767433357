import { Field } from "formik";
import React, { useState } from "react";
import { useGetListOfCountriesQuery } from "../../../../redux/apiSlice";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";

export default function PersonalDetails({ errors, touched }) {
  const [visible, setVisible] = useState(false);

  const { data: countryList } = useGetListOfCountriesQuery();

  const togglePasswordView = (value) => {
    setVisible(value);
  };

  return (
    <div>
      <div className="space-y-5 mt-10">
        <div>
          <label className="text-zinc-400 text-base" htmlFor="description">
            Firstname
          </label>
          <input
            name="firstName"
            type="text"
            className={`w-full px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
              errors.firstName && touched.firstName ? "bg-[#ffebeb]" : null
            }`}
            placeholder="First Name"
            // autocomplete="off"
          />
        </div>
        <div>
          <label className="text-zinc-400 text-base" htmlFor="description">
            Lastname
          </label>
          <input
            name="lastName"
            type="text"
            className={`w-full px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
              errors.lastName && touched.lastName ? "bg-[#ffebeb]" : null
            }`}
            placeholder="Last Name"
          />
        </div>

        <div>
          <label className="text-zinc-400 text-base" htmlFor="description">
            Phone Number
          </label>
          <Field
            name="phoneNumber"
            type="text"
            className={`w-full px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
              errors.phoneNumber && touched.phoneNumber ? "bg-[#ffebeb]" : null
            }`}
            placeholder="Enter phone number"
          />
        </div>

        <div>
          <label className="text-zinc-400 text-base" htmlFor="description">
            Country
          </label>
          {/* ${!countryList ? "hidden" : "block"} */}
          <Field
            name="country"
            as="select"
            className={`w-full px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
              errors.country && touched.country ? "bg-[#ffebeb]" : null
            }`}
          >
            <option value="" selected disabled hidden>
              Select country
            </option>
            {countryList
              ? countryList.data.map((country, i) => (
                  <option key={i} value={country.alpha3Abbr}>
                    {country.fullName}
                  </option>
                ))
              : null}
          </Field>
        </div>
        <div>
          <label className="text-zinc-400 text-base" htmlFor="description">
            State
          </label>
          <Field
            name="state"
            type="text"
            className={`w-full px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
              errors.state && touched.state ? "bg-[#ffebeb]" : null
            }`}
            placeholder="State"
          />
        </div>
        <div>
          <label className="text-zinc-400 text-base" htmlFor="description">
            Email
          </label>
          <Field
            name="email"
            type="email"
            className={`w-full px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
              errors.email && touched.email ? "bg-[#ffebeb]" : null
            }`}
            placeholder="Enter email address"
          />
        </div>

        <div className="relative w-full">
          <label className="text-zinc-400 text-base" htmlFor="description">
            Password
          </label>
          <Field
            name="password"
            type={visible ? "text" : "password"}
            className={`w-full px-3.5 py-3 bg-zinc-100 border focus:border-opacity-0 focus:outline-none focus:ring-2 ring-red-650 border-b focus:border-b-0 ${
              errors.password && touched.password ? "bg-[#f7e7e7]" : null
            }`}
            placeholder="********"
          />
          <div
            className="absolute right-3 top-10 cursor-pointer"
            // onClick={() => setVisible(!visible)}
          >
            {visible ? (
              <IoIosEyeOff
                style={{ fontSize: 25 }}
                onClick={() => togglePasswordView(false)}
              />
            ) : (
              <IoIosEye
                style={{ fontSize: 25 }}
                onClick={() => togglePasswordView(true)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
