import React, { useContext, useEffect, useState } from 'react';
import RulesOfOriginSection from "./sections/RulesOfOriginSection"
import Jumbotron from './sections/Jumbotron';
import SectionFive from './sections/SectionFive';
import SectionFour from './sections/SectionFour';
import SectionNine from './sections/SectionNine';
import SectionOne from './sections/SectionOne';
import SectionSeven from './sections/SectionSeven';
import SectionSix from './sections/SectionSix';
import SectionThree from './sections/SectionThree';
import { CartContext } from '../../../context/CartContext';
import axios from 'axios';

function Home(props) {

    return (
        <div>
            <Jumbotron/>
            <SectionOne />
            <RulesOfOriginSection />
            <SectionThree/>
            <SectionFive/>
            <SectionSix/>
            <SectionSeven/>
            <SectionNine/>
        </div>
    );
}

export default Home;