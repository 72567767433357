import React, { useState, useEffect } from "react";
import { useHistory, Link, useNavigate } from "react-router-dom";
import Header from "../../utils/Header";
import { useForgetPasswordMutation } from "../../../redux/apiSlice";
import { Oval } from "react-loading-icons";

function ForgotPassword(props) {
  const [forgetPassword, { isLoading }] = useForgetPasswordMutation();

  const [showPassword, setShowPassword] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const handleChange = (e) => {
    setUserEmail(e.target.value);
  };

  const navigate = useNavigate()

  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(userEmail);
    forgetPassword({
      email: userEmail,
    })
      .unwrap()
      .then((data) => {
        console.log(data);
        navigate("/reset-password", {
            state: {
                email: userEmail
            }
        })
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Header />
      <div className="font-sans">
        <div className="flex justify-center md:items-center md:h-[95vh] h-[95vh] md:pt-0 pt-20 relative px-10 overflow-hidden">
          {/* floating vector */}
          <div className="absolute md:-bottom-40 md:-left-10 bottom-0 left-0">
            <div className="md:w-72 md:h-72 w-32 h-32">
              <img src={"/images/login-vector.svg"} className="" />
            </div>
          </div>
          <div className="block md:w-[420px] w-full md:mb-8">
            <h3 className="text-center font-bold text-xl mb-10">
              Forgot Password
            </h3>
            <p className="text-gray-500 mb-6 text-center">
              Enter the email connected to your account and we will send a link
              to your inbox to reset your password
            </p>
            <form className="block w-full" onSubmit={handleSubmit}>
              <div className="">
                <label className="">Email</label>
                <input
                  type="text"
                  name="email"
                  value={userEmail}
                  onChange={handleChange}
                  placeholder="yourname@gmail.com"
                  className="px-2 py-2.5 w-full ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2 placeholder:text-slate-300"
                  required
                />
              </div>
              <div className="">
                <button
                  className={`text-brown-100 bg-brown-900 py-2.5 px-5 w-full my-2 rounded-md`}
                  onClick={handleSubmit}
                >
                  {isLoading ? <Oval /> : "Reset My Password"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
