import React, { useState, useRef, useEffect } from "react";
import { IoMdHome } from "react-icons/io";
import moment from "moment";

import { NavItem } from "react-bootstrap";
import { Badge, Button, IconButton, InputBase, TextField } from "@mui/material";
import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import {
  IoMdArrowRoundUp,
  IoMdClose,
  IoMdMenu,
} from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { logout } from "../../../redux/AuthSlice";
import {
  MdArchive,
  MdInfo,
  MdShopTwo,
} from "react-icons/md";
import { toggleGoods, toggleServices } from "../../../redux/market";
import { DoorFront } from "@mui/icons-material";
import SearchInit from "../search/child/SearchInit";

export default function HeadMarket() {
  // updated methods
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opened = Boolean(anchorEl);

  const dispatch = useDispatch();

  const userTokenSession = useSelector((state) => state.auth.tokenActive);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const userType = useSelector((state) => state.auth.userType);
  

  const details = useSelector((state) => state.market);
  const navigate = useNavigate();
  const location = useLocation();

  const handleChangeCurrent = (menu) => {
    // setCurrent(menu)
    // console.log(menu);
  };

  // const location = route.asPath.split("/")[1]

  // States *******************************************
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(null);
  const [showBtn, setShowBtn] = useState(false);
  const [currentHref, setCurrentHref] = useState("");
  const [fixBar, setFixBar] = useState(false);

  // refs ******************************************
  const dropDownMenuRef = useRef();
  const toggleRef = useRef();

  // Methods ****************************************
  const handleMenuToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleScrollToTop = (e) => {
    window.scrollTo({
      top: 10,
      behavior: "smooth",
    });
    setCurrentHref("");
  };

  // useEffects *******************************************
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (
        dropDownMenuRef.current &&
        !dropDownMenuRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    });
    return () => {
      document.removeEventListener("click", () => {
        // console.log("Hmm");
      });
    };
  }, []);

  useEffect(() => {
    // if (document.body.scrollTop > 500) {
    //     console.log("I scrolled for", )
    // }
    document.addEventListener("scroll", () => {
      return window.scrollY > 600 ? setShowBtn(true) : setShowBtn(false);
    });
    return () => {
      document.removeEventListener("scroll", () => {
        // console.log("removed");
      });
    };
  });

  useEffect(() => {
    document.addEventListener("scroll", () => {
      return window.scrollY > 150 ? setFixBar(true) : setFixBar(false);
    });
    return () => {
      document.removeEventListener("scroll", () => {
        // console.log("ssm");
      });
    };
  });

  // Close menu on button click
  const closeMenu = (link) => {
    navigate(`/${link}`);
    setOpen(false);
    // console.log("routing to", link);
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  // console.log(anchorEl);

  const handleGotoRoute = () => {
    // console.log("Menu")
  };

  // open Notification
  const handleOpenNotification = () => {};

  function handleLogout() {
    dispatch(logout());
    navigate("/");
    // localStorage.removeItem("TOKEN");
  }

  function handleToggleGoods() {
    if (location.pathname !== "/market") {
      navigate("/market");
    }
    dispatch(toggleGoods());
  }

  function handleToggleServices() {
    if (location.pathname !== "/market") {
      navigate("/market");
    }
    dispatch(toggleServices());
  }

  function handleCloseAndRoute () {
    setOpen(false)
    navigate('/cart')
  }

  // ************************************* fetch cart details
  // const { data: cartItem, isLoading, error } = useGetAllCartItemQuery();
  const cartItem = useSelector((state) => state.cart.cart);
  // console.log(cartItem);

  // Cart summary from context
  // console.log(currentUser);

  return (
    <>
      <div className="sticky top-0 w-full z-50">
        {/* Upper layer */}
        <div className="w-full flex justify-between items-center bg-brown-600 text-white py-2 px-5 overflow-hidden">
          <div>
            <div className="flex items-center">
              <p className="text-sm">{moment().format("ddd Do MMM, YYYY")}</p>
            </div>
          </div>
          {/* Web menu */}
          <div className="hidden md:block">
            <ul className="flex space-x-5 font-sans text-base">
              {menuItem.map((menu, i) => (
                <NavLink
                  to={menu.link}
                  key={i}
                  className={`hover:underline hover:decoration-blue-400 underline-offset-8 ${
                    location.pathname === menu.link
                      ? "text-brown-800"
                      : "text-white"
                  } `}
                  onClick={() => setCurrentHref(menu.link)}
                >
                  <p className="font-bold text-sm">{menu.name}</p>
                </NavLink>
              ))}
            </ul>
          </div>
        </div>

        {/*  second section */}
        <div
          className={`w-full flex items-center justify-between md:px-5 px-3 h-20 shadow-md bg-white md:bg-none ${
            fixBar ? "fixed top-0" : null
          }`}
        >
          {/* logo */}
          <div className="flex items-center md:space-x-5 space-x-4">
            <div className="hidden md:block">
              <Link to="/">
                <img src={"images/logo.png"} alt="" className="w-10" />
              </Link>
            </div>
            <div className="md:hidden">
              <Link to="/">
                <img
                  src={"images/logo.png"}
                  layout="intrinsic"
                  width={50}
                  height={50}
                  alt=""
                  className="w-10"
                />
              </Link>
            </div>
            <div className="flex items-center md:space-x-5 space-x-2">
              <p
                onClick={handleToggleGoods}
                className={`text-sm ${
                  details.goods
                    ? "text-brown-900 border-b-2 transition ease-in-out border-brown-900 font-bold cursor-pointer"
                    : "text-gray-400 cursor-pointer transition ease-in-out"
                }`}
              >
                Goods
              </p>
              <p
                onClick={handleToggleServices}
                className={`text-sm ${
                  details.services
                    ? "text-brown-900 border-b-2 transition ease-in-out border-brown-900 font-bold cursor-pointer"
                    : "text-gray-400 cursor-pointer transition ease-in-out"
                }`}
              >
                Services
              </p>
            </div>
          </div>

          <div className="">
            <SearchInit />
          </div>

          <div>
            {!userTokenSession ? (
              <>
                <div className="hidden md:flex space-x-2 items-center">
                  <NavItem>
                    <NavLink className="" to="/login">
                      <button className="px-5 py-[9px] min-w-[150px] border border-brown-600 rounded-sm text-[14px]">
                        Login
                      </button>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <button className="px-5 py-2.5 min-w-[150px] bg-brown-900 text-white rounded-sm text-[14px]">
                      <NavLink className="" to="/select-user">
                        Create an Account
                      </NavLink>
                    </button>
                  </NavItem>
                </div>
              </>
            ) : (
              <>
                {userType === "VENDOR" ? <Button
                            startIcon={<DoorFront sx={{fontSize: 20}} />}
                            sx={{ borderRadius: "50px", color: "white" }}
                            variant="contained"
                            className="bg-brown-800 p-3 px-6 rounded-full text-white"
                            onClick={() => navigate('/dashboard')}
                          >
                            Dashboard
                          </Button> : <div className="hidden md:flex items-center space-x-2.5">
                  <NavItem>
                    <div className="space-x-3 flex items-center">
                      <Button
                        id="demo-positioned-button"
                        aria-controls={
                          opened ? "demo-positioned-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={opened ? "true" : undefined}
                        onClick={handleOpenMenu}
                      >
                        <AccountCircleIcon
                          className="text-brown-800"
                          sx={{ fontSize: 40 }}
                        />
                      </Button>
                      {/* Floating menu */}
                      <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                      >
                        <Link to="/account">
                          <MenuItem onClick={handleGotoRoute}>
                            My account
                          </MenuItem>
                        </Link>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                      </Menu>
                    </div>
                  </NavItem>
                  <NavItem>
                    <div>
                      <Link to={`/cart`}>
                        <div className="bg-brown-200 py-2 px-4 rounded-full text-amber-900 flex items-center space-x-2 relative">
                          <ShoppingCartIcon />
                          <p>Cart</p>
                          {/* <div className="w-4 h-4 bg-red-500 rounded-full absolute right-3 flex justify-center items-center">
                            <p className="text-[8px]">
                              {!cartItem
                                ? null
                                : cartItem.cartItems.length === 0
                                ? 0
                                : cartItem.cartItems.length}
                            </p>
                          </div> */}
                        </div>
                      </Link>
                    </div>
                  </NavItem>
                </div>}
              </>
            )}
          </div>

          {/* mobile menu */}
          <div
            ref={dropDownMenuRef}
            className="md:hidden flex items-center space-x-2"
          >
            {userTokenSession && (
              <>
                <div>
                  <Link to={`/cart`}>
                    <div className="py-2 px-2 rounded-full text-brown-900 flex items-center space-x-3">
                      <ShoppingCartIcon sx={{ fontSize: 25 }} />
                      {/* <div className="w-4 h-4 bg-red-500 rounded-full flex justify-center items-center">
                        <p className="text-[8px]">
                          {!cartItem
                        ? null
                        : cartItem.cartItems.length === 0
                        ? 0
                        : cartItem.cartItems.length}
                        </p>
                      </div> */}
                    </div>
                  </Link>
                </div>
              </>
            )}

            {/* Mobile menu hamburger/toggle */}
            <div className="" ref={toggleRef}>
              {open ? (
                <div
                  className="flex justify-center items-center text-brown-500 rounded-full"
                  onClick={handleMenuToggle}
                >
                  <IoMdClose className="w-8 h-8 transition-all ease-in-out delay-300 duration-500 pointer-events-none" />
                </div>
              ) : (
                <div
                  className="flex justify-center items-center text-brown-900 rounded-full"
                  onClick={handleMenuToggle}
                >
                  <IoMdMenu className="w-8 h-8 transition-all ease-in-out delay-300 duration-500 pointer-events-none" />
                </div>
              )}
            </div>

            {/* DROPDOWN */}
            <div className="absolute left-0 top-28">
              <div
                className={`md:hidden bg-white  w-[100vw] filter drop-shadow-sm transition-transform delay-700 ease-out duration-1000 rounded-b-3xl ${
                  open ? "border-b-1" : null
                } relative`}
              >
                {open && (
                  <div className="absolute top-28 -right-5 -z-[1] ">
                    <div className="w-32 h-32"></div>
                  </div>
                )}

                {open && (
                  <div
                    className={`block space-y-5 py-10 px-7 rounded-b-xl list-none ${
                      open ? "text-brown-900" : "text-slate-700"
                    }`}
                  >
                    <div className="space-y-3">
                      {menuItem.map((item, i) => (
                        <li
                          key={i}
                          className=""
                          onClick={(e) => setOpen(false)}
                        >
                          {/* <span>
                      <IoMdArrowForward />
                    </span> */}
                          <a
                            href={`${item.link}`}
                            className="text-[16px] font-bold flex items-center space-x-2"
                          >
                            <p>{item.icon}</p> <p>{item.name}</p>
                          </a>
                        </li>
                      ))}
                    </div>

                    <hr className="my-1 border-brown-200" />

                    {/* ************************ */}
                    {userTokenSession ? (
                      <div className="" onClick={handleCloseAndRoute}>
                        <div className="bg-brown-900 py-2 px-2 w-1/3 rounded-full text-amber-400 flex items-center justify-center space-x-3 relative">
                          <ShoppingCartIcon sx={{ fontSize: 15 }} />
                          <p className="text-[12px]">Cart</p>
                          {/* <div className="w-4 h-4 bg-red-500 rounded-full flex justify-center items-center">
                            <p className="text-[8px]">
                              {!cartItem
                        ? null
                        : cartItem.cartItems.length === 0
                        ? 0
                        : cartItem.cartItems.length}
                            </p>
                          </div> */}
                        </div>
                      </div>
                    ) : (
                      <div
                        className="space-x-2 flex items-center"
                        // onClick={closeMenu}
                      >
                        <button
                          className="px-5 py-2 border-2 border-amber-400 text-brown-900 rounded-lg w-full text-[14px]"
                          onClick={() => closeMenu("login")}
                          c
                        >
                          Login
                        </button>
                        <button
                          className="px-5 py-2 bg-brown-600 text-white rounded-lg w-full text-[14px]"
                          onClick={() => closeMenu("select-user")}
                        >
                          Create account
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Floating back to top button */}
        <div className="fixed bottom-10 right-10">
          {showBtn && (
            <div
              className="flex justify-center items-center p-3 bg-slate-600 rounded-full text-white"
              onClick={handleScrollToTop}
            >
              <IoMdArrowRoundUp className="w-8 h-8 transition-all ease-in-out delay-300 duration-500 pointer-events-none" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const menuItem = [
  {
    name: "Home",
    link: "/",
    icon: <IoMdHome />,
  },
  {
    name: "Market",
    link: "/market",
    icon: <MdShopTwo />,
  },
  {
    name: "About",
    link: "/about",
    icon: <MdInfo />,
  },
  {
    name: "Trade Guides",
    link: "/rules",
    icon: <MdArchive />,
  },
];

// <div className='flex items-center space-x-4'>
// <p onClick={() => toggleGoods()} className={details.goods ? 'text-brown-900 border-b-2 transition ease-in-out border-brown-900 font-bold cursor-pointer' : 'text-gray-400 cursor-pointer transition ease-in-out'}>Goods</p>
// <p onClick={() => toggleServices()} className={details.services ? 'text-brown-900 border-b-2 transition ease-in-out border-brown-900 font-bold cursor-pointer' : 'text-gray-400 cursor-pointer transition ease-in-out'}>Services</p>
// </div>
