import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useGetGoodsCategoriesQuery, useSingleGoodsCategoryQuery } from '../../../redux/apiSlice'
import Cover from '../child/Cover'
import HeadMarket from '../child/HeadMarket'
import MarketFooter from '../child/MarketFooter'
import MarketHeader from '../child/MarketHeader'
import AllGoodsCategory from './AllGoodsCategory'
import Sidebar from './child/Sidebar'

const GoodsCategory = () => {


  const route = useParams()
  

  const { data, isLoading, error } = useGetGoodsCategoriesQuery("product");

  if (isLoading) {
    
  }
  if (error) {
    
  }
  if (data) {
    
  }


  const { data: singleCategory, isLoading: singleCategoryLoading, error: singleError } = useSingleGoodsCategoryQuery(route.id)

  if (singleCategoryLoading) {
    
  }

  if (singleError) {
    
  }


  return (
    <>
      <HeadMarket />
      <div className='md:mt-8'>
        <div className='grid md:grid-cols-12 grid-cols-1 gap-5'>

          <div className='col-span-3'>

            <Sidebar categoryData={data && data.data} />
          </div>
          <div className='col-span-9 m-5'>

            <Outlet />
          </div>


        </div>
        <div className='mt-5'>
          <Cover />
        </div>
      </div>
      <MarketFooter />

    </>
  )
}

export default GoodsCategory;