import React from "react";
import HeadMarket from "../child/HeadMarket";
import Footer from "../../utils/Footer";
import { useSelector } from "react-redux";
import { Oval } from "react-loading-icons";
import { IoMdSquare } from "react-icons/io";
import { Link } from "react-router-dom";
import { Rating } from "@mui/material";
import { formatToCurrency } from "../../utils/currencyFormatter";
import { SearchOutlined } from "@material-ui/icons";

export default function SearchIndex() {
  const { isSearching, searchResult, searchTerm } = useSelector(
    (state) => state.search
  );

  console.log(searchResult)

  return (
    <div>
      <HeadMarket />

      {/* Search loading indicator */}
      <div className="flex justify-center items-center min-h-[400px]">
        {isSearching ? (
          <Oval stroke="#3D1F00" />
        ) : (
          <div>
            {searchResult && searchResult !== null && searchTerm !== "" ? (
              searchResult?.data?.length === 0 ? (
                <div className="w-80 whitespace-nowrap">
                  No result for <span>{`: `}</span>
                  <p className="font-bold text-lg pl-2">{` ${searchTerm}`}</p>
                </div>
              ) : (
                <>
                  <h1 className=" text-amber-900 my-4 flex items-center">
                    <span>Search result for</span> <span>{`: `}</span>
                    <p className="font-bold text-xl pl-2">{` ${searchTerm}`}</p>
                  </h1>
                  <div className="grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 grid-cols-2  gap-5 bg-white p-2">
                    {searchResult.data.map((detail, i) => {
                      return (
                        <div key={detail.id} className="space-y-2 block">
                          <Link to={`/market/goods/${detail.id}`}>
                            <div className="hover:bg-[#f8f8f8] bg-white  pb-2.5 cursor-pointer">
                              <div className="rounded-lg flex w-full">
                                <img
                                  src={detail.productImage}
                                  className="w-full h-40 rounded-t hover:border-[0.2px] object-cover object-top"
                                />
                              </div>
                              <div className="flex flex-col w-[90%] mx-auto py-2">
                                <p className="text-black text-[16px] capitalize truncate tracking-tight">
                                  {detail.name}
                                </p>
                                <Rating
                                  name="read-only"
                                  value={detail.ratings}
                                  precision={0.5}
                                  size="small"
                                  readOnly
                                  className="mt-1"
                                />

                                <div className="flex justify-between items-center">
                                  <p className="text-red-700 text-[18px] mt-2 font-semibold">
                                    {formatToCurrency(detail.price)}
                                  </p>
                                </div>
                                <p className="text-xs text-gray-400 text-[10px] mt-[0.5px]">
                                  {detail.hscode}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </>
              )
            ) : (
              <div className="flex flex-col items-center">
                <SearchOutlined style={{ fontSize: 80, color: "#b5b5b5" }} />
                <p className="text-[#b5b5b5]">Enter a search term!</p>
              </div>
            )}
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
}
