



import React from 'react'
import Header from '../../utils/Header'
import { Link } from 'react-router-dom'

export default function ResetPasswordSuccess() {
  return (
    <div>
        <>   
            <Header />
            <div className="font-sans">
                <div className="flex justify-center md:items-center md:h-[95vh] h-[95vh] md:pt-0 pt-20 relative px-10 overflow-hidden">
                    {/* floating vector */}
                    <div className="absolute md:-bottom-40 md:-left-10 bottom-0 left-0"> 
                        <div className="md:w-72 md:h-72 w-32 h-32">
                            <img src={"/images/login-vector.svg"} className="" />
                        </div>
                    </div>
                    <div className="flex flex-col items-center md:w-[320px] w-full md:mb-8">
                           <img src='/images/check-circle.png'  className='items-center justify-self-center'/>                    
                        <h3 className="text-center font-bold text-xl mb-10">Password Reset Success</h3>
                        <p className='text-gray-500 mb-6 text-center'>Your password has been successfully updated. You can go to the login page</p>
                        <button className='bg-brown-700 text-white py-2 px-5 rounded'>
                        <Link to="/login">
                  <span className="font-bold">Login</span>
                </Link>
                        </button>
                    </div>
                </div>
            </div>  
        </>
    </div>
  )
}
