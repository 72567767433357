import React from 'react';
import { IoIosMenu } from 'react-icons/io';
import {Link} from "react-router-dom";
function RegisterHeader(props) {
    return (
        <div className='flex justify-between items-center mb-4 h-20 shadow-md font-sans px-5'>
            <div className=''>
                <Link to='/'>
                    <img src={'images/logo.png'} height="41px" width="42px" alt='AFCFTA' />
                </Link>
            </div>

            <div>
                {/* <IoIosMenu className="text-3xl" /> */}
            </div>
            
        </div>
    );
}

export default RegisterHeader;