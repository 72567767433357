import React from 'react'
import HeadMarket from './child/HeadMarket';
import MarketFooter from './child/MarketFooter';
import MarketSlider from './child/MarketSlider';
import CategoryList from './goods/GoodsCategoryList';
import Cover from './child/Cover';
import ServiceCategoryList from './services/ServiceCategoryList';
import ServiceCategorySection from './services/CategorySection';
import GoodSections from './goods/GoodSections';
import { useSelector } from 'react-redux';

export default function GeneralMarket() {
  // const [details, setDetails] = useState({
  //   goods: true,
  //   services: false,
  // });


  const details = useSelector(state => state.market)
  const user = useSelector(state => state.auth)
  console.log(user)

  

  return (
    <div  className='bg-[#f9f9f9]'>
      <HeadMarket />
      <div className="">
        <MarketSlider />
      </div>
      <hr className='my-2 mx-5' />
      <div className="flex flex-col lg:mx-32 mx-5">
        {details.goods ? (
          <div>
            {/* product categories */}
            <CategoryList />
            <hr className='my-5 border-slate-200' />
            <>
              <GoodSections />
            </>
            <Cover />
          </div>
        ) : (
          <>
            <div>
              {/* service categories */}
              <ServiceCategoryList />
              <ServiceCategorySection
                name="Top Products"
                link="/market/single/service"
              />
              <div className="py-5 flex justify-center items-center">
                {/* <div className="W-2/5 mx-auto">
                  <button className="bg-brown-700 py-6 px-12 rounded-md text-white">
                    Start Shopping
                  </button>
                </div> */}
              </div>
            </div>
            <Cover />
          </>
        )}
      </div>
      <MarketFooter />
    </div>
  );
}
