import React from "react";
import BasicTable from "./Table";
import { useGetAllTransactionsQuery } from "../../../../../../redux/apiSlice";
import { Skeleton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function WalletHistory() {
  const { data: transactions, error } = useGetAllTransactionsQuery();



  return (
    <div className="w-full mx-auto p-3 rounded-md border border-gray-200">
      <div className="p-2 bg-white mb-2 rounded-sm">
        <h1 className="font-bold text-[16px] text-slate-500">
          Transaction History
        </h1>
      </div>

      {!transactions ? (
        <Skeleton />
      ) : (
        <div>
          {transactions.data.length === 0 ? (
            <div className="min-h-[50vh] bg-white rounded-md flex items-center justify-center">
              <h1>No transactions yet!</h1>
            </div>
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Transaction Type</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.data.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">
                        {row.transactionType}
                      </TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      )}
    </div>
  );
}
