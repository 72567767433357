import React from 'react';

function SectionSix(props) {
    return (
      <div className="md:h-screen pt-20 flex items-center justify-center">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:px-40 px-10">
          {/* Left Side */}
          <div className="flex items-center w-full md:w-5/6">
            <div className="mx-auto">
              <h3 className="text-4xl text-center md:text-left font-bold font-sans md:w-3/4 mb-3">
                More country, more trade
              </h3>
              <p className="text-center md:text-left text-lg">
                AFCFTA market gives complete access to individuals to carry out
                trade activities within more than 50 African countries, thereby
                making it one of the largest market in the whole world.
              </p>
            </div>
          </div>
          {/* Right side */}
          <div>
            <div className="mt-3">
              <img
                src={"images/country-flags.svg"}
                height={345}
                alt="Africa-nations-flag"
              />
            </div>
          </div>
        </div>
      </div>
    );
}

export default SectionSix;