import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { natureOfVendor } from '../../../../../../redux/classifySlice';
import RetailerModal from './RetailerModal';
import { useGetListOfCountriesQuery } from '../../../../../../redux/apiSlice';
import Manufacturer from './Manufacturer';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function ClassificationType({ open3, setOpen3, handleOpen3, handleClose3 }) {
    const token = JSON.parse(localStorage.getItem("TOKEN"))
    const [isManufacturer, setIsManufacturer] = React.useState(false)
    const [isRetailer, setIsRetailer] = React.useState(false)
    const [countries, setCountries] = React.useState()
    const [value, setValue] = React.useState('manufacturer')
    const [open, setOpen] = React.useState(false);
    
    
    const classifyDetails = useSelector(state => state.classify)
    
    
    const dispatch = useDispatch()
    
    // const dispatch = useDispatch()
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);


    const manufacturer = () => {
        dispatch(natureOfVendor({nature: "manufacturer"}))
        setIsManufacturer(true)
        setIsRetailer(false)
        setValue('maufacturer')
        // dispatch(natureOfVendor('manufacturer'))

    }
    const retailer = () => {
        dispatch(natureOfVendor({nature: "retailer"}))
        setIsRetailer(true)
        setIsManufacturer(false)
        setValue('retailer')
        // dispatch(natureOfVendor('retailer'))
    }
    
    const {data, error, isLoading} = useGetListOfCountriesQuery() 
    // console.log(data && data);

    const fetchCountries = async () => {
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `${token}`
        }
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}countries`, { headers: headers })
        console.log(res?.data?.data)
        setCountries(res?.data?.data)
    }
    const handleSubmit = async () => {
        setIsManufacturer(true)
        setIsRetailer(false)

        switch (isRetailer) {
            case true:
                handleClose3()
                handleOpen2()
                return
            case false:
                handleClose3()
                handleOpen()
                return
            default:
                break
        }


    }

    React.useEffect(() => {
        fetchCountries()
    }, [])


    return (
        <div>
            <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    <div className='flex justify-center items-center'>
                        <Stack spacing={2} className='flex flex-col items-center w-full'>
                            <img src="/images/vector123.png" className='w-20 items-center justify-self-center' />
                            <h2 className='text-2xl font-bold text-center'>Did you manufacture or produce this product?</h2>
                            {/* <p className='text-center text-sm'>Follow the steps to classify and get your product approved with an HS Code according to the AfCFTA Rules of origin agreement</p> */}
                        </Stack>
                    </div>
                    <div className='mt-4'>
                        <div className='flex mx-auto space-x-5  justify-center items-center'>
                            <button onClick={manufacturer} className={`border border-brown-300 rounded-sm ${isManufacturer ? "py-2 w-48  bg-[#5A3924] px-2 text-white cursor-pointer" : "py-2 w-48 px-2 cursor-pointer "}`}>
                                <p>I am a Manufacturer</p>
                            </button>
                            <button onClick={retailer} className={`border border-brown-300 rounded-sm ${isRetailer ? "py-2 w-48  bg-[#5A3924] px-2 text-white cursor-pointer" : "py-2 w-48 px-2 cursor-pointer "}`}>
                                <p>I am a Retailer</p>
                            </button>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <button onClick={handleSubmit} className='bg-[#5A3924] w-full text-white p-2 mt-6'>Proceed</button>
                    </div>
                </Box>
            </Modal>
            <Manufacturer open={open} setOpen={setOpen} handleOpen={handleOpen} handleClose={handleClose} countries={data && data.data} />
            <RetailerModal open2={open2} setOpen2={setOpen2} handleClose2={handleClose2} handleOpen2={handleOpen2} handleOpen3={handleOpen3} />
        </div>
    );
}
