import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'


// login thunk

// const userLogin = createAsyncThunk(
//   'users/login',
//   async 
// )

const initialState = {
  userDetails: {
    brandAddress: "",
    brandName: "",
    country: "",
    description: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    phoneNumber: "",
    resourceType: "goods",
    serviceType: "",
    state: "",
    vendorCategory: "",
  },
    userInfo: {},
    userToken: null,
    loading:false,
    error:false,
    success: false,
    userType: "",
    tokenActive: false 
}

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginStart:(state)=>{
        state.loading = true
    },
    updateUserDetails: (state, action) => {
      state.userDetails = action.payload;
    } ,
    userAccess: (state,action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // localStorage.setItem('token',action.payload)
      // console.log(action.payload)
      state.loading = false
      state.userInfo = action.payload.userDetails
      state.userToken = action.payload.token
      state.tokenActive = true
      state.userType = action.payload.userType
    },
    loginFailure: (state) => {
        // localStorage.removeItem('token')
        state.loading = false
      state.error = true
    } , 
    logout: (state) => {
        // localStorage.removeItem('token')
        state.loading = false
      state.error = false
      state.userToken = null
      state.userInfo =null
      state.tokenActive = false
    },
    setTokenActive: (state) => {
      state.tokenActive = true
    },
    setTokenInActive: (state )=> {
      state.tokenActive = false
    }
  },
})

// Action creators are generated for each case reducer function
export const { loginStart,logout,userAccess,loginFailure, setTokenActive,setTokenInActive} = authSlice.actions

export default authSlice.reducer;