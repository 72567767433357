import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { marketSlice } from "./apiSlice";

export const getUserOffer = createAsyncThunk(
  "offer/getOfferItem",
  async ({token}, thunkAPI) => {
    // const token = thunkAPI.getState().auth.userToken
    console.log(token)
    const res = axios
      .get(`${process.env.REACT_APP_BASE_URL}offers/customers`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((data) => {
        console.log("Offer data from backend",data.data);
        return data.data
      });
    return res;
  }
);

const initialState = {
  offerList: [],
  currentOffer: {},
  totalPrice: 0,
  loading: false,
  updateStatus: false,
};

export const offerSlice = createSlice({
  name: "cartState",
  initialState,
  reducers: {
    
    setCurrentOffer: (state, action) => {
      state.currentOffer = action.payload
    },
    resetCurrentOffer: (state) => {
      state.currentOffer = {}
    },
    
    // removeItem: (state, action) => {
    //   const removeItem = state.cart.cartItems.filter(
    //     (item) => item.product.id === action.payload.id
    //   );
    //   state.cart.cartItems = removeItem;
      
    // },
    updateTotal: (state) => {
      state.totalPrice  = state.cart.cartItems.reduce((prev, current) => (prev + current.product.price * current.quantity), 0)
    },
    // addTotal: (state) => {
    //   state.totalPrice += 100
    // },
    

  },
  extraReducers: {
    [getUserOffer.pending]: (state) => {
      state.loading = true;
    },
    [getUserOffer.fulfilled]: (state, action) => {
      state.loading = false;
      state.offerList = action.payload.data
    },
    [getUserOffer.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { setCurrentOffer, resetCurrentOffer } =
  offerSlice.actions

export default offerSlice.reducer;
