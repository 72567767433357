import { IconButton, Avatar } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Email, WhatsApp } from "@material-ui/icons";

import { IoLogoWhatsapp } from "react-icons/io";
import { phoneNumberCleaner } from "../../utils/phoneNumberCleaner";

export default function VendorInfo({ vendorDetail }) {
  console.log(vendorDetail);

  return (
    <div className="border-l pl-5">
      <div className="space-y-3 rounded-md m-2 md:mx-0 mt-10 md:mt-0 bg-white">
        <div className="">
          {/* <h1 className="text-sm">Seller Information</h1> */}
          <div className="w-full">
          <p className="text-sm">Personal details</p>
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2">
                {/* <h2 className="font-bold text-xl">{vendorDetail.firstName}</h2> */}
                <IconButton>
                  {vendorDetail.verified && (
                    <VerifiedIcon className="text-amber-500" />
                  )}
                </IconButton>
              </div>

              <IconButton>
                {/* <EditIcon className="text-blue-500" /> */}
              </IconButton>
            </div>
            <div className="flex flex-col space-y-1">
              <Avatar
                sx={{
                  width: 30,
                  height: 30,
                  backgroundColor: "#45361e",
                  marginBottom: 2.5
                }}
              >
                O
              </Avatar>
              <div className="">
                <h2 className="text-[14px]">{vendorDetail.firstName} {vendorDetail.lastName}</h2>
                {/* <p className="text-[12px] font-medium text-slate-500">
                  Logo-Brand Identity | Brand Designer
                </p> */}
                <p className="text-[14px] w-min text-amber-900 mt-1 rounded-sm">
                  {vendorDetail.country}
                </p>
                <p className="text-[12px] w-min text-amber-900 mt-1 rounded-sm">
                  {vendorDetail.state}
                </p>
              </div>
            </div>
            <hr className="my-3" />
            <p className="text-sm">Business details</p>
            <div className="my-2">
            <p className="text-[12px] w-min text-amber-900 mt-1 whitespace-nowrap">
                  {vendorDetail.businessInfo.brandName}
                </p>
            <p className="text-[12px] w-min text-amber-900 mt-1 whitespace-nowrap">
                  {vendorDetail.businessInfo.officeAddress }
                </p>
            </div>
            <div className="mt-2 py-1 flex items-center space-x-2">
            </div>
            <p className="text-sm">
              {vendorDetail.description !== ""
                ? vendorDetail.description
                : null}
            </p>
            
            {/* <p className="text-center">MEMBER SINCE: APRIL 16, 2020</p> */}
          </div>
        </div>
      </div>
      <h1 className="text-[9px] italic mb-2 text-gray-500"> Connect with vendor</h1>      
      <div className="flex items-center space-x-5">
        <a
          href={`https://wa.me/${phoneNumberCleaner(vendorDetail.phoneNumber)}`}
          target="_blank"
        >
          <WhatsApp />
        </a>

        <a href={`mailto:${vendorDetail.email}`} target="_blank">
          <Email />
        </a>
      </div>
    </div>
  );
}
