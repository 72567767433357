import { IconButton, Skeleton } from "@mui/material";
import React from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDashBoardDataQuery } from "../../../../../../redux/apiSlice";
export default function AccountSummary({ order, products, results }) {


  const {data, error, isLoading} = useDashBoardDataQuery()

  console.log("Vendors dashboard data", data)

  return (
    <div className="font-sans">
      <div className="grid md:grid-cols-3 grid-cols-1 gap-5 mt-5">
        {/* {dashboardValues.map((value, i) => ( */}
        <div>
          <div className="w-full h-40 bg-white drop-shadow-sm rounded flex flex-col justify-between px-5 py-3">
            <div className="flex justify-between items-center">
              <div className="space-x-1 flex flex-row items-center">
                <h3>Total Order</h3>
                <IconButton>
                  <ArrowDropDownIcon />
                </IconButton>
              </div>
              <IconButton>
                <MoreHorizIcon />
              </IconButton>
            </div>

            <div className=" flex justify-between items-start">
              <div className="space-y-1 p-2">
                <h4 className="text-gray-300">Goods</h4>
                {!data ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <p className="text-2xl font-bold">{data.data.totalOrders.goods}</p>}
                
              </div>
              <div className="space-y-1 p-2">
                <h4 className="text-gray-300">Services</h4>
                {!data ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <p className="text-2xl font-bold">{data.data.totalOrders.services}</p>}
              </div>
            </div>

          </div>
        </div>
        {/* {results ? results.totalSales : <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            } */}
        <div>
          <div className="w-full h-40 bg-white drop-shadow-sm rounded flex flex-col justify-between p-3">
            <div className="flex justify-between items-center">
              <div className="space-x-1 flex flex-row items-center">
                <h3>Total Sales</h3>
                <IconButton>
                  <ArrowDropDownIcon />
                </IconButton>
              </div>
              <IconButton>
                <MoreHorizIcon />
              </IconButton>
            </div>
            <div className="grid grid-cols-2 gap-3 items-start">
              <div className="space-y-1 p-2 ">
                <h4 className="text-gray-300">Goods</h4>
                {!data ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <p className="text-2xl font-bold">{data.data.totalSales.goods}</p>}
              </div>
              <div className="space-y-1 p-2">
                <h4 className="text-gray-300 ">Services</h4>
                {!data ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <p className="text-2xl font-bold">{data.data.totalSales.services}</p>}
              </div>
            </div>
          </div>
        </div>
        {/* <p className="text-3xl font-bold">{results ? results.totalProducts : <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            }</p> */}
        <div>
          <div className="w-full h-40 bg-white drop-shadow-sm rounded flex flex-col justify-between px-5 py-5">
            <div className="flex justify-between items-center">
              <div className="space-x-1 flex flex-row items-center">
                <h3>Total Inventory</h3>
                <IconButton>
                  <ArrowDropDownIcon />
                </IconButton>
              </div>
              <IconButton>
                <MoreHorizIcon />
              </IconButton>
            </div>
            <div className="grid grid-cols-2 gap-3 items-start">
              <div className="space-y-1 p-2">
                <h4 className="text-gray-300">Goods</h4>
                {!data ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <p className="text-2xl font-bold">{data.data.totalInventory.goods}</p>}
              </div>
              <div className="space-y-1 p-2">
                <h4 className="text-gray-300">Services</h4>
                {!data ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> : <p className="text-2xl font-bold">{data.data.totalInventory.services}</p>}
              </div>
            </div>
          </div>
        </div>
        {/* ))} */}
      </div>
    </div>
  );
}