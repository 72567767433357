


import React from 'react'
import { Link } from 'react-router-dom'

export default function ServiceOrder({services}) {
  return (
    <div>
        
                <div>
                  <div className='px-12'>
                    <div className='w-2/5 py-5'>
                      <div className='flex justify-between items-center'>
                        <h2 className='font-bold border-b-4 border-brown-900 cursor-pointer min-w-20 text-center'>All</h2>
                        <h2 className='font-bold text-gray-400 cursor-pointer w-20 text-center'>New Offer</h2>
                        <h2 className='font-bold text-gray-400 cursor-pointer w-20 text-center'>In Progress</h2>
                        <h2 className='font-bold text-gray-400 cursor-pointer w-20 text-center'>Completed</h2>
                      </div>
                    </div>
                    <div className='w-[92%] border-gray-200 border-2 rounded-md py-4 divide-y-2'>

                      <div className='w-[90%] mx-auto py-3 divide-y cursor-pointer'>
                        <Link to="/dashboard/order/service">
                          <div className='space-y-4'>
                            <h2 className='text-brown-900 font-bold'>Contract Title</h2>
                            <h5 className='text-gray-400'>We are looking for an experienced UX designer with attention to detail, creativity, and empathy towards the ....</h5>
                            <div className='flex justify-between items-center text-left w-full'>
                              <p className='flex-1'>Name of client</p>
                              <p className='flex-1'>Budget</p>
                              <p className='flex-1'>Contract period</p>
                              <p className='flex-1'>Status</p>
                            </div>
                            <div className='flex justify-between items-end text-left w-full'>
                              <p className='flex-1'>Leslie Alexandar</p>
                              <p className='flex-1'>$ 6,000</p>
                              <p className='flex-1'>July 25th - September 18th 2021 </p>
                              <p className='flex-1'>New Offer</p>
                            </div>
                          </div>
                        </Link>
                      </div>

                      <div className='w-[90%] mx-auto py-3 divide-y cursor-pointer'>
                        <Link to="/dashboard/order/service">
                          <div className='space-y-4'>
                            <h2 className='text-brown-900 font-bold'>Contract Title</h2>
                            <h5 className='text-gray-400'>We are looking for an experienced UX designer with attention to detail, creativity, and empathy towards the ....</h5>
                            <div className='flex justify-between items-center text-left w-full'>
                              <p className='flex-1'>Name of client</p>
                              <p className='flex-1'>Budget</p>
                              <p className='flex-1'>Contract period</p>
                              <p className='flex-1'>Status</p>
                            </div>
                            <div className='flex justify-between items-end text-left w-full'>
                              <p className='flex-1'>Leslie Alexandar</p>
                              <p className='flex-1'>$ 6,000</p>
                              <p className='flex-1'>July 25th - September 18th 2021 </p>
                              <p className='flex-1'>New Offer</p>
                            </div>
                          </div>
                        </Link>
                      </div>


                    </div>
                  </div>
                </div>
              
    </div>
  )
}
