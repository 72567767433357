import React from 'react';
import Slider from "react-slick";
import twinRibbon from "../../../../assets/images/twin-ribbon.png"
import overlayBackground from "../../../../assets/svg/about-background-overlay.svg";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "grey" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "grey" }}
        onClick={onClick}
      />
    );
  }

function SectionThree(props) {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 2000
  };
    
      const content = [
        {
          img: '/images/Group 1000001714.svg',
          text: 'Aid the movement of capital and people, facilitating investment',
          height: '95'
        },
        {
          img: '/images/Group 1000001715.svg',
          text: 'Creating a single market, to deepen the economic integration of the African continent'
        },
        {
          img: '/images/Group 1000001713.svg',
          text: 'Encourage industrial development through diversification and regional value chain development'
        },
        {
            img: '/images/Group 1000001716.svg',
            text: 'Resolve challenges of multiple and overlapping memberships'
        },
        {
          img: '/images/Group 1000001717.svg',
          text: 'Achieve sustainable and inclusive socioeconomic development, gender equality and structural transformations within member states'
        },
        {
          img: '/images/Group 1000001718.svg',
          text: 'Encourage industrial development through diversification and regional value chain development'
        },
      ]
    return (
      <div className="md:h-screen flex items-center relative py-20 md:py-0">
        {/* ribbon position */}
        <div className="absolute md:-top-[70px] -top-[50px]">
          <img src={twinRibbon} className="md:w-[150px] w-[110px]" />
        </div>

        {/* side overlay */}
        <div className="absolute right-0 top-16 hidden md:flex">
          <img src={overlayBackground} />
        </div>

        <div className="block w-full md:px-40 px-4">
          <div className="grid grid-cols-1 md:grid-cols-5">
            <div className="md:col-span-4 col-span-1">
              <div className="mb-4 md:w-3/4 w-full md:text-left text-center">
                <h3 className="font-black font-sans text-4xl mb-3">
                  Objectives of AFCFTA
                </h3>
                <h6 className="font-sans text-lg">
                  The African Continental Free Trade Area (AfCFTA)is a free
                  trade area founded in 2018, with trade commencing as of 1
                  January 2021
                </h6>
              </div>

              <div className="flex items-center h-[40vh] overflow-x-scroll scrol md:hidden hideScroll pt-10">
                <div className="">
                  <div className="flex space-x-5">
                    {content.map((item, i) => (
                      <>
                        <div className="w-[200px] rounded-lg px-5 py-2 flex flex-col justify-center items-center bg-brown-100">
                          <div className="mb-2">
                            <img
                              src={item.img}
                              alt="single-market"
                              className="object-contain"
                            />
                          </div>
                          <p className="text-sm font-sans text-center leading-tight">
                            {item.text}
                          </p>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>

              <div className="md:grid  md:grid-cols-4 md:gap-x-5 hidden">
                {content.map((item, i) => (
                  <>
                    <div className="w-full rounded-md px-2 py-2">
                      <div className="w-[70px] mb-2">
                        <img
                          src={item.img}
                          alt="single-market"
                          className="object-contain"
                        />
                      </div>
                      <p className="text-sm font-sans leading-tight">
                        {item.text}
                      </p>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    );
}

export default SectionThree;



<div className="mb-0 h-screen relative">
  <div className="container">
    <div className="block w-3/4 px-40">
      <div className="">
        
      </div>
      <div>
        <div className="grid grid-cols-4 gap-5">
          {/* {content.map((item, i) => ( */}
            <>
              <div className="bg-brown-500 rounded-md px-3 py-2">
                <div className="w-[80px]">
                  <img
                    src="images/Group 1000001715.svg"
                    alt="single-market"
                    className="object-contain"
                  />
                </div>
                <p className="text-sm">
                  Creating a single market, to deepen the economic integration
                  of the African continent
                </p>
              </div>
            </>
          {/* ))} */}
        </div>
      </div>
    </div>

    <div className="row row-header-4 big pb-4">
      <div className="col-md-7 ">
        <div className="row pt-3">
          <div className="col-md-4">
            <img
              src="images/Group 1000001715.svg"
              width={96}
              height={98}
              alt="single-market"
            />
            <p>
              Creating a single market, to deepen the economic integration of
              the African continent
            </p>
          </div>
          <div className="col-md-4">
            <img
              src="images/Group 1000001714.svg"
              height={80}
              width={80}
              alt="single-market"
            />
            <p>
              Aid the movement of capital and people, facilitating investment
            </p>
          </div>
          <div className="col-md-4">
            <img
              src="images/Group 1000001713.svg"
              width={85}
              height={88}
              alt="single-market"
            />
            <p>
              Encourage industrial development through diversification and
              regional value chain development
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4">
            <img
              src="images/Group 1000001716.svg"
              width={86}
              height={85}
              alt="single-market"
            />
            <p>Resolve challenges of multiple and overlapping memberships</p>
          </div>
          <div className="col-md-4">
            <img
              src="images/Group 1000001717.svg"
              width={94}
              height={94}
              alt="single-market"
            />
            <p>
              Achieve sustainable and inclusive socioeconomic development,
              gender equality and structural transformations within member
              states
            </p>
          </div>
          <div className="col-md-4">
            <img
              src="images/Group 1000001718.svg"
              width={95}
              height={95}
              alt="single-market"
            />
            <p>
              Encourage industrial development through diversification and
              regional value chain development
            </p>
          </div>
        </div>
      </div>
      <></>
    </div>

    {/* Samll screen  */}

    <div className="d-md-none " style={{ width: "500px", display: "block" }}>
      {/* <Slider {...settings}> */}
      {/* {content.map((x, i) => { */}
      return (
      <div key="{i} " className="card text-center pt-0">
        <center className="mt-5">
          {/* <img classname="img-card " src={x.img} height={95} /> */}
        </center>
        <div class="card-body  pt-1 mt-0">
          <div
            classname="card-text"
            style={{
              fontSize: "16px",
              color: "#000000",
              paddingBottom: "50px",
            }}
          >
            {/* {x.text} */}
          </div>
        </div>
      </div>
      );
      {/* })}
            </Slider> */}
    </div>
    {/* <img src={'images/Group 1000001719.svg'} className='ribbon-three'/> */}
  </div>
</div>;