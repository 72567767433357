import { Grid, Skeleton } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useGetServiceCategoriesQuery } from '../../../redux/apiSlice';
import DummySkeleton from '../goods/child/DummySkeleton';
import { Service } from './child/data';


const ServiceCategoryList = () => {


  // const [data, setData] = useState()
  const [category, setCategory] = useState()
  const [loading, setLoading] = useState(false)


  const { data, isLoading, error } = useGetServiceCategoriesQuery()

  if (error) {
    console.log("loading error", data)
  }

  return (
    <div className="block  my-5">
      <div className="flex justify-between items-center">
        <h1 className="font-bold text-xl my-7">Browse Categories</h1>
        <Link to="/market/service/category">
          <p className="text-amber-800 text-sm hidden">View all</p>
        </Link>
      </div>
      <div>
        {!data ? <Link to="/market/service/3"><DummySkeleton /></Link> :  (
          <div className="grid md:grid-cols-4 grid-cols-2 gap-3">
          {data.data.map((data) => (
          <Grid key={data.id} item xs={6} md={4}>
            <Link to={`/market/service/category/${data.id}`}>
              <div className="flex justify-between items-center rounded-md drop-shadow bg-white md:h-20 h-14">
                <img
                  src={data.image}
                  className="md:w-20 w-14 md:h-20 h-14 rounded-l-lg object-cover"
                />
                <div className="bg-white flex-1">
                  <div className="space-y-3 ml-3">
                    <p className="capitalize text-sm leading-tight">{data.name}</p>
                    {/* <p className="text-gray-300 text-sm">
                      {data.productCount}{" "}
                      {data.productCount > 1 ? "Skills" : "Skill"}
                    </p> */}
                  </div>
                </div>
              </div>
            </Link>
          </Grid>
        ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default ServiceCategoryList;