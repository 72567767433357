import React, { useEffect, useState } from "react";
import Oval from "react-loading-icons/dist/esm/components/oval";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useCompleteCheckoutMutation,
  useCompleteServiceOrderMutation,
} from "../../../../../redux/apiSlice";
import { openSnackBar } from "../../../../../redux/snackSlice";

export default function FinalizeCheckout() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const currentOffer = useSelector((state) => state.offer.currentOffer);
  const type = useSelector((state) => state.checkout.type);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const reference = queryParams.get("reference");
  // console.log(reference, type)

  //  data
  const [completeCheckout, { isLoading, isError, error }] =
    useCompleteCheckoutMutation();
  const [createServiceOrder] = useCompleteServiceOrderMutation();

  // const userToken = useSelector((state) => state.auth.userToken)

  // Effect
  // useEffect(() => {
  //   if (type === "services") {
  //     finalizeServiceCheckout();
  //     console.log("type is", type);
  //   }

  //   if (type === "goods") {
  //     finalizeGoodsCheckout();
  //     console.log("type is", type);
  //   }

  //   // const user = userToken
  //   // return () => {

  //   // }
  // }, []);

  function finalizeServiceCheckout() {
    console.log(currentOffer.id, reference, currentOffer.service.id)
    try {
      createServiceOrder({
        offerId: currentOffer.id,
        reference: reference,
        serviceId: currentOffer.service.id,
      })
        .unwrap()
        .then((data) => {
          console.log(data);
          dispatch(openSnackBar({ message: "Order created successfully" }));
          navigate("/account/order");
        });
    } catch (err) {}
  }

  function finalizeGoodsCheckout() {
    try {
      setLoading(true);
      completeCheckout({
        paymentReference: reference,
      })
        .unwrap()
        .then((data) => {
          console.log(data);
          dispatch(openSnackBar({ message: data.message }));
          navigate("/account/order");
        });
    } catch (err) {
      console.log(err);
    }
  }

  function gotoMarket(params) {
    navigate("/market")
  }

  return (
    <>
      <div className="px-5 py-2 bg-red-600 text-white flex justify-center">
        <p className="text-lg">Please do not close this page!</p>
      </div>
      <div className="flex justify-center w-full mt-10">
        <div className="w-20 h-20 relative">
          <img src="/images/logo.png" alt="" className="object-cover overflow-hidden" />
        </div>
      </div>
      <div className="m-5 md:p-20 flex flex-col items-center justify-center space-x-10">
        <div className="w-40 h-40 relative">
          <img src="/images/payment.png" alt="" className="object-cover overflow-hidden" />
        </div>
        {/* <h1 className="text-xl mb-20">Checkout Update</h1> */}
        <div className="">
          <h3 className="text-2xl text-[#278d3d]">Payment successful</h3>
        </div>
        <div className="h-32 flex items-center space-x-10">
          <button className="text-2xl  px-5 py-3 underline" onClick={gotoMarket}>Continue Shopping</button>
          
        </div>
      </div>
    </>
  );
}
