import { Grid, Skeleton } from "@mui/material";
import React from "react";
import { IoIosBasket } from "react-icons/io";
import { useLocation, useParams } from "react-router-dom";
import {
  useGetProductByCategoryIdQuery,
  useGetSubCategoriesQuery,
} from "../../../redux/apiSlice";
import GoodsSubCategoryParent from "./child/GoodsSubCategoryParent";

export default function ParentGoodsCategory() {
  const route = useParams();
  const location = useLocation();

  // console.log(location)

  // parent category
  const {
    data: parentProduct,
    isLoading: parentLoading,
    error: parentError,
  } = useGetProductByCategoryIdQuery(route.id);

  const { data, isLoading, error } = useGetSubCategoriesQuery(route.id);
  // console.log("Error", error)

  // if (data) {
  //     console.log("data", data)
  // }

  return (
    <>
      <div className="mt-8">
        <h1 className="text-xl ">
          Showing results for{" "}
          <span className="font-semibold">{location.state.categoryName}</span>
        </h1>
        <div className="w-full bg-amber-800 rounded h-1.5 my-5" />
        {/* Parent products */}
        {!parentProduct ? (
          <div className="grid md:grid-cols-5 grid-cols-2 gap-5">
            {testNum.slice(0, 5).map((dum, i) => (
              <>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{ height: 150, width: "100%", borderRadius: 3 }}
                />
              </>
            ))}
          </div>
        ) : (
          <div>
            {parentProduct.data.length === 0 ? (
              <div className="px-5 py-10 bg-slate-100">
                <div className="flex flex-col justify-center items-center">
                  <IoIosBasket fontSize={70} color="#9f9f9f" />
                  <p className="text-[#9f9f9f]">
                    No product in this category yet!
                  </p>
                </div>
              </div>
            ) : (
              <GoodsSubCategoryParent
                name={location.state.categoryName}
                id={route.id}
                products={parentProduct && parentProduct}
              />
            )}
          </div>
        )}

        {/* sub category products */}

        {/* {!data ? (
                    <div className="grid grid-cols-5 gap-5">
                        {testNum.slice(0, 5).map((dum, i) => (
                            <>
                                <Skeleton variant="rectangular" animation="wave" sx={{ height: 50, width: "100%", borderRadius: 3 }} />
                            </>
                        ))}
                    </div>
                ) : (
                    <>
                        {data.data === undefined ? "Undefined" : "Posible"}
                    </>)} */}
      </div>
    </>
  );
}
const testNum = [1, 2, 3, 4, 5, 6, 7, 8];
