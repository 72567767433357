import { IconButton, Rating } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import {
  useNewArrivalsQuery,
  usePopularProductsQuery,
  useRecommendedProductsQuery,
} from "../../../../redux/apiSlice";
import DummySkeleton from "./DummySkeleton";
import StarIcon from "@mui/icons-material/Star";
import { IoMdSquare } from "react-icons/io";
import { formatToCurrency } from "../../../../component/utils/currencyFormatter";

export default function RecommendedProducts({ name }) {
  const { data, isLoading, error } = useRecommendedProductsQuery();

  console.log(data && data);

  return (
    <div>
      {!data ? (
        <>
          <h1 className="text-xl font-semibold text-amber-900 my-4 flex items-center">
            {name}
          </h1>
          <DummySkeleton />
        </>
      ) : (
        <div>
          {data.length === 0 ? null : (
            <>
              <h1 className="text-xl font-semibold text-amber-900 my-4 flex items-center">
                <IoMdSquare /> {name}
              </h1>
              <div className="grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 grid-cols-2  gap-5 bg-white p-2">
                {data.data.map((detail, i) => {
                  return (
                    <div key={detail.id} className="space-y-2 block">
                      <Link to={`/market/goods/${detail.id}`}>
                        <div className=" hover:bg-[#f8f8f8] bg-white  pb-2.5 cursor-pointer">
                          <div className="rounded-lg flex w-full">
                            <img
                              src={detail.productImage}
                              className="w-full h-40 rounded-t hover:border-[0.2px]  object-cover object-top"
                            />
                          </div>
                          <div className="flex flex-col w-[90%] mx-auto py-1.5">
                            <p className="text-lg font-medium truncate">
                              {detail.name}
                            </p>
                            <div className="flex space-x-2 items-center">
                              <Rating
                                name="read-only"
                                value={detail.ratings}
                                precision={0.5}
                                size="small"
                                readOnly
                                className=""
                              />
                              <p className="text-xs text-gray-400 text-[10px] mt-[0.5px]">
                                ({detail.ratingsCount})
                              </p>
                            </div>
                            <div className="my-0.5" />
                            <p className="text-xs text-gray-400 text-[10px]">
                              {detail.hscode}
                            </p>

                            <div className="my-0.5" />
                            <p className="text-slate-700 text-lg font-bold font-sans">
                              {formatToCurrency(detail.price)}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
