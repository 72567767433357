import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetAllTransactionQuery } from "../../../../../redux/apiSlice";
import { Oval } from "react-loading-icons";
import { formatToCurrency } from "../../../../utils/currencyFormatter";
import { Box, Modal } from "@mui/material";
import SingleUserTransaction from "./SingleUserTransaction";
import { useNavigate } from "react-router-dom";

export default function TransactionList() {

    const navigate = useNavigate()


    const [openModal, setOpenModal] = useState(false)
  const user = useSelector((state) => state.auth);
  console.log(user);

  const { data: transactions } = useGetAllTransactionQuery();
  
  function handleCloseModal() {
    setOpenModal(false)
  }
  function handleOpenModal(ref) {
    setOpenModal(true)
    navigate(`/account/transaction?ref=${ref}`, {
        
    })
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 10,
    p: 4,
  };

  return (
    <div>
      <h2>Transactions for {user.userInfo.firstName}</h2>
      <div>
        {/* {filteredTransactions && filteredTransactions.length === 0 && <p>No transactions found.</p>} */}
        {!transactions ? (
          <Oval />
        ) : (
          <div className="flex flex-col space-y-2">
            {transactions.data.map((transaction) => (
              <div
                key={transaction.id}
                className="px-3 py-2 border rounded flex justify-between items-center hover:bg-brown-100"
                onClick={() => handleOpenModal(transaction.charge.reference)}
              >
                <div>
                <p className="font-bold">{transaction.description}</p>
                  <p className="text-sm">{new Date(transaction.date).toLocaleDateString()}</p>
                </div>
                <p>{formatToCurrency(transaction.charge.amount)}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box style={style} className="">
            <SingleUserTransaction />
        </Box>
      </Modal>
    </div>
  );
}
