import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-step-progress-bar/styles.css";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch } from "react-redux";
import FormStepper from "../../registration/controller/FormStepper";
import PersonalDetails from "../../registration/registration-pages/PersonalDetails";
import AgencyInfo from "../../registration/registration-pages/AgencyInfo";
import { useSignupVendorMutation } from "../../../../redux/apiSlice";
import { openSnackBar } from "../../../../redux/snackSlice";
import Confirmation from "../../registration/registration-pages/Confirmation";

function VendorRegistration(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [progress, setProgress] = useState(30);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState({
    brandAddress: "",
    brandName: "",
    country: "",
    description: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    phoneNumber: "",
    resourceType: "goods",
    serviceType: "",
    state: "",
    vendorCategory: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value)
    setData({ ...data, [name]: value });
  };

  const registerVendor = async () => {
    // console.log(data);
    console.log("initializing");
    setLoading(true);
    //  const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/vendors/signup`, data)
    //   console.log(res.data)
    signupVendor(data)
      .unwrap()
      .then((data) => {
        setLoading(false);
        navigate("/confirm-email", {
          state: {
            name: data.data.firstName,
            email: data.data.email,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(openSnackBar({ message: err.data.message }));
        setLoading(false);
      });
  };

  // reg api
  const [signupVendor, { isLoading }] = useSignupVendorMutation();

  // Formik
  const initialValues = {
    brandAddress: "",
    brandName: "",
    country: "",
    description: "",
    email: "",
    firstName: "Wester",
    lastName: "Linke",
    password: "",
    phoneNumber: "",
    resourceType: "",
    serviceType: "",
    state: "",
    vendorCategory: "",
  };

  const signupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Enter your email address"),
    password: Yup.string()
      .min(6, "Password should be a minimum of six characters")
      .required("Enter your password"),
    country: Yup.string().required("Enter your phone number"),
    firstName: Yup.string().required("Enter your first name"),
    lastName: Yup.string().required("Enter your last name"),
    phoneNumber: Yup.number().required("Enter your phone number"),
    brandName: Yup.string().required("Enter your business name"),
    description: Yup.string().required(
      "Describe your business in a short sentence"
    ),
    brandAddress: Yup.string().required("State your business address"),
    // resourceType: Yup.string().required("State your your type of offering"),
    serviceType: Yup.string()
      .required("Select your type of business ")
      .oneOf(["individual", "agency"]),
    state: Yup.string().required("Enter your state of origin"),
    vendorCategory: Yup.string()
      .required("Select your size of vendor")
      .oneOf(["highend", "minimist"]),
  });

  return (
    <div className="font-sans">
      <div className="">
        <div className="grid lg:grid-cols-5 grid-cols-1 mt-0 pt-0 h-screen">
          <div
            className="lg:col-span-3 bg-brown-900 bg-blend-overlay hidden lg:block w-full"
            style={{
              backgroundImage: `url("/images/red-pepper.png")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="flex flex-col justify-center p-20 h-full text-white">
              <h1 className="text-6xl font-bold mb-2">One market for all</h1>
              <p className="text-2xl font-light">
                The main objectives of the AfCFTA are to, inter alia, create a
                single continental market with free movement of goods and
                services
              </p>
            </div>
          </div>
          <div className="col-span-1 lg:col-span-2">
            {/* <RegisterHeader /> */}
            <div className="flex flex-col justify-center mt-5 px-5 mx-10">
              <div className="mt-5 flex flex-col items-center bg-slate-50 rounded-lg py-4">
                <h1 className="text-2xl md:text-3xl font-bold lg:text3xl">
                  Create your account
                </h1>
                <h1 className="text-base lg:text3xl text-brown-600">
                  Start enjoying all the benefits of Intra-African trade
                </h1>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={signupSchema}
              >
                {({ errors, touched }) => (
                  <>
                    <FormStepper
                      loading={loading}
                      errors={errors}
                      touched={touched}
                      handleInputChange={handleInputChange}
                      registerVendor={registerVendor}
                    >
                      <PersonalDetails
                        label={"Personal Details"}
                        errors={errors}
                        touched={touched}
                      />
                      <AgencyInfo
                        label={"Business Information"}
                        errors={errors}
                        touched={touched}
                      />
                      <Confirmation label={"Confirmation"} />
                    </FormStepper>
                    {errors.email ||
                            errors.password ||
                            errors.phoneNumber ||
                            errors.firstName ||
                            errors.lastName ||
                            errors.brandName ||
                            errors.brandAddress ||
                            errors.description ||
                            errors.serviceType ||
                            errors.state ||
                            errors.vendorCategory ? (
                      <div className="mt-10 border bg-red-100 text-gray-400 px-5 py-2 w-[400px] hidden">
                        <p>
                          {errors.email ||
                            errors.password ||
                            errors.phoneNumber ||
                            errors.firstName ||
                            errors.lastName ||
                            errors.brandName ||
                            errors.brandAddress ||
                            errors.description ||
                            errors.serviceType ||
                            errors.state ||
                            errors.vendorCategory}
                        </p>
                      </div>
                    ) : null}
                  </>
                )}
              </Formik>

              <div className="mt-5 mb-20">
                Already have an account?{" "}
                <span className="pl-2 font-bold text-brown-600">
                  {" "}
                  <Link to="/login">Log in</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorRegistration;
