import React, {useState, useEffect} from 'react';
import { FirstSlide, SecondSlide, ThirdSlide } from './Sliders';

function renderSlide({step, prevStep, nextStep}) {
    const renderSlide = (param) => {
        switch (param) {
          case 1: 
          return(
            <FirstSlide 
            prevStep={prevStep}
            nextStep={nextStep}
            step={step}/>
          )
            
          case 2: 
            return(
              <SecondSlide  
              prevStep={prevStep}
              nextStep={nextStep}
              step={step}
              />
              )
            
          case 3: 
            return(
              <ThirdSlide
              prevStep={prevStep}
              nextStep={nextStep}
              step={step}
              />
              
              )
          
          default:
              return(
            <FirstSlide prevStep={prevStep}
              nextStep={nextStep} step={step}/>
          )
             
          }
      }
    return (
        <div>
            {renderSlide(step)}
        </div>
    );
}

export default renderSlide;