import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import axios from "axios";

const SlideOne = () => {
  return (
    <div className="flex justify=between items-center w-full h-[450px] bg-brown-800 px-5">
      <div className="mx-auto flex justify-between items-center">
        <Stack direction="column" spacing={1}>
          <h1 className="text-brown-500 font-bold md:text-3xl">
            Experience the One African Market
          </h1>
          <h5 className="text-brown-200 text-xs md:text-lg">
            Browse thousands of locally made african products
          </h5>
        </Stack>
        <Stack direction="column" >
          <img src="/images/africa-woman.png" className="w-3/4 md:w-full" />
        </Stack>
      </div>
    </div>
  );
};
const SlideTwo = () => {
  return (
    <div className="flex justify=between items-center w-full h-[450px] bg-yellow-200 px-5">
      <div className="mx-auto flex justify-center items-center">
        <Stack direction="column" spacing={1}>
          <h1 className="text-brown-800 font-bold md:text-3xl w-3/4">
            Shop from different categories of Goods and Services
          </h1>
          <h5 className="text-brown-700 text-xs md:text-lg">
            Shop from across the African market
          </h5>
        </Stack>
        <Stack direction="column" >
          <img src="/images/chips.png" className="w-3/4 md:w-full" />
        </Stack>
      </div>
    </div>
  );
};
const SlideThree = () => {
  return (
    <div className="flex justify=between items-center w-full h-[450px] bg-gray-100 px-5">
      <div className="mx-auto flex justify-between items-center">
        <Stack direction="column" spacing={1}>
          <h1 className="text-brown-900 font-bold md:text-3xl">
            Enjoy easy logistic support across border
          </h1>
          <h5 className="text-gray-500 text-xs md:text-lg">
            You can easily get your goods and services delivered
          </h5>
        </Stack>
        <Stack direction="column" >
          <img src="/images/box.png" className="w-3/4 md:w-full" />
        </Stack>
      </div>
    </div>
  );
};

export default function MarketSlider() {
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      // navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      // className="mySwiper"
    >
      <SwiperSlide>
        <SlideOne />
      </SwiperSlide>
      <SwiperSlide>
        <SlideTwo />
      </SwiperSlide>
      <SwiperSlide>
        <SlideThree />
      </SwiperSlide>
    </Swiper>
  );
}
