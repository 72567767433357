import { configureStore, combineReducers } from '@reduxjs/toolkit'
import authReducer from './AuthSlice'
import ingredientReducer from './ingredients'
import orderReducer from './Order'
import customerReducer from './CustomerOrder';
import goodsReducer from './SingleGoods';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import { marketSlice } from './apiSlice';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { serviceReducer } from './serviceSlice';
import { marketReducer } from './market';
import checkoutSlice from './checkoutSlice';
import { snackReducer } from './snackSlice';
import { cartSlice } from './cartSlice';
import { classifyReducer } from './classifySlice';
import {offerSlice} from './offerSlice';
import { searchReducer } from './searchSlice';



const persistConfig = {
  key: "root",
  storage,
  // whitelist: ['auth', 'cart', 'offer', 'checkout']
  blacklist: ['api']
};

const rootReducer = combineReducers({
  auth: authReducer,
  classify: classifyReducer.reducer,
  ingredients: ingredientReducer,
  order: orderReducer,
  customer: customerReducer,
  goods: goodsReducer,
  service: serviceReducer.reducer,
  market: marketReducer.reducer,
  cart: cartSlice.reducer,
  offer: offerSlice.reducer,
  checkout: checkoutSlice,
  snack: snackReducer.reducer,
  search: searchReducer.reducer,
  api: marketSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }, 
    }).concat(marketSlice.middleware),
})

setupListeners(store.dispatch)
export const persistor = persistStore(store)