

import React from 'react'
import { IoIosCheckmarkCircle, IoIosInformationCircle, IoIosPerson } from 'react-icons/io'

export default function CompletedOnboardingStep() {
  return (
    <div className="">
      <div className="grid grid-cols-1 mt-10 px-10">
        <div className="flex flex-col px-20 font-sans space-y-3">
          {/* Heading */}
          <h1 className="text-4xl font-bold font-sansextra tracking-tight">
            Welcome Solomon,
          </h1>
          {/* icon and completed heading */}
          <div className="md:w-3/4 w-full flex items-center px-10 py-3 justify-between bg-white rounded">
            <div className="flex items-center space-x-5">
              <i className="w-7 h-7 flex justify-center items-center rounded-full bg-brown-200">
                <IoIosPerson />
              </i>
              <p>Step 2 of 3 Completed</p>
            </div>
            {/* Completed level indicator */}
            <div className="flex items-center space-x-2 text-2xl">
              <i>
                <IoIosCheckmarkCircle className="text-green-600" />
              </i>
              <i>
                <IoIosCheckmarkCircle className="text-green-600" />
              </i>

              <i>
                <IoIosCheckmarkCircle className="text-slate-400" />
              </i>
            </div>
          </div>
          {/* Onboarding Tasks */}
          <div className="block px-10 py-5 bg-white md:w-3/4 w-full mb-3">
            {/* Info */}
            <div className="flex items-center space-x-5 mb-5">
              <i className="w-9 h-9 flex justify-center items-center rounded-full bg-sky-200 text-sky-600 text-xl">
                <IoIosInformationCircle />
              </i>
              <p className="text-lg">
                Complete the following details to fully unlock all the features
                on your profile
              </p>
            </div>
            {/* Tasks */}
            <div className="block space-y-3">
              <button className="flex justify-between items-center w-full bg-green-600 text-white px-4 py-2.5 rounded-lg">
                <p>Complete personal Details</p>
                <i>
                  <IoIosCheckmarkCircle />
                </i>
              </button>
              <button className="flex justify-between items-center w-full bg-green-600 text-white px-4 py-2.5 rounded-lg">
                <p>Update Business Profile</p>
                <i>
                  <IoIosCheckmarkCircle />
                </i>
              </button>
              <button className="flex justify-between items-center w-full bg-slate-300 text-slate-600 px-4 py-2.5 rounded-lg hover:bg-slate-700 hover:text-slate-200">
                <p>Submit Official Documents</p>
                <i>
                  <IoIosCheckmarkCircle />
                </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}





