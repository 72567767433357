import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import Sidebar from './Sidebar'
import { Outlet } from 'react-router-dom'
import HeadMarket from '../../../../MarketPlace/child/HeadMarket'
const Layout = ({ children }) => {
    return (
        <>
            <HeadMarket />
            <div className='mx-auto bg-slate-100'>
                <div className='py-5'>
                    <div className='grid md:grid-cols-4 grid-cols-1 '>
                        <div className='hidden md:block'>
                            <Sidebar />
                        </div>
                        <div className='md:col-span-3 mx-5'>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Layout