import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import DateNow from './DateNow';

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isWeekend' && prop !== 'isWithinRange',
})(({ theme, isWeekend, isWithinRange }) => ({
  ...(isWeekend && {
    pointerEvents: 'none',
    color: theme.palette.text.disabled,
    '& .MuiIconButton-label': {
      color: theme.palette.text.disabled,
    },
  }),
  ...(isWithinRange && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
}));

function Day(props) {
  const { day, selectedDay, ...other } = props;
  const isWeekend = dayjs(day).day() === 0 || dayjs(day).day() === 6;

  if (isWeekend) {
    return (
      <CustomPickersDay
        {...other}
        day={day}
        disableMargin
        isWeekend={isWeekend}
        disabled
      />
    );
  }

  const today = dayjs();
  const end = today.add(9, 'day');
  const isWithinRange = dayjs(day).isBetween(today, end, null, '[]');

  return (
    <CustomPickersDay
      {...other}
      day={day}
      disableMargin
      isWithinRange={isWithinRange}
      selected={dayjs(selectedDay).isSame(day, 'day')}
    />
  );
}

Day.propTypes = {
  /**
   * The date to show.
   */
  day: PropTypes.object.isRequired,
  selectedDay: PropTypes.string,
};

export default function ShippingMentStarter({handleCloseShipmentModal}) {
  const [selectedDay, setSelectedDay] = React.useState(dayjs().format('YYYY-MM-DDThh-mm-ss'));

  const handleDateChange = (date) => {
    setSelectedDay(date.format('YYYY-MM-DD'));
  };

  return (
   <div>
    <DateNow handleCloseShipmentModal={handleCloseShipmentModal} />
   </div>
  );
}
