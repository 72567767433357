import { Snackbar } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar } from "../../redux/snackSlice";

export default function SnackBar() {
  
    const snackState = useSelector(state => state.snack)
    console.log(snackState);

    const dispatch = useDispatch()
    function handleSnackbarClose () {
        dispatch(closeSnackbar())
    }
  
    return (
    <div>
      <Snackbar
        open={snackState.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackState.message}
        // action={action}
      />
    </div>
  );
}
