import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAddNewShippingAddressMutation, useGetListOfCountriesQuery } from "../../../../redux/apiSlice";

export default function AddressModal({
  handleClickOpen,
  open,
  setOpen,
  handleClose,
}) {
  const [loading, setLoading] = React.useState(false);
  const token = JSON.parse(localStorage.getItem("TOKEN"));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState();
  const [country, setCountry] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [state, setState] = React.useState("");
  const [contactName, setContactName] = React.useState("");

  const [addNewAddress, { isLoading }] = useAddNewShippingAddressMutation();
  const { data: countryData, error: countryDataError } =
    useGetListOfCountriesQuery();

  const handleSubmit = async (e) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };
    e.preventDefault();
    setLoading(true);

    try {
      addNewAddress({
        address: address,
        city: city,
        companyName: "Test company Name",
        contactName: contactName,
        country: country,
        phoneNumber: phoneNumber,
        postalCode: postalCode,
        state: state,
      })
        .unwrap()
        .then((data) => {
          console.log(data);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div id="responsive-dialog-title" className="text-2xl m-5">
          <h2 className="text-2xl">Add Address</h2>
        </div>
        <DialogContent className="example">
          <div>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <div className="py-2">
                    <h3>Contact Name</h3>
                  </div>
                  <TextField
                    placeholder="Enter Name"
                    required
                    className="w-full"
                    onChange={(e) => setContactName(e.target.value)}
                  />
                </Grid>

                <FormControl className="w-full">
                        <InputLabel id="demo-simple-select-label">
                          Country
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={country}
                          label="Category"
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          {countryData &&
                            countryData.data.map((cat, i) => (
                              <MenuItem key={i} value={cat.alpha2Abbr}>
                                {cat.fullName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                <Grid item xs={12} md={6}>
                  <div className="py-2">
                    <h3>State</h3>
                  </div>
                  <TextField
                    placeholder="Select State"
                    required
                    className="w-full"
                    onChange={(e) => setState(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="py-2">
                    <h3>City</h3>
                  </div>
                  <TextField
                    placeholder="City"
                    required
                    className="w-full"
                    onChange={(e) => setCity(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="py-2">
                    <h3>Address</h3>
                  </div>
                  <TextField
                    placeholder="Enter Address"
                    required
                    className="w-full"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="py-2">
                    <h3>Postal Code</h3>
                  </div>
                  <TextField
                    placeholder="Enter Postal Code"
                    required
                    className="w-full"
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className="py-2">
                    <h3>Phone Number</h3>
                  </div>
                  <TextField
                    placeholder="Phone Number"
                    required
                    className="w-full"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Grid>
              </Grid>
              <div className="py-4">
                <button className="bg-brown-700 rounded-md py-4 px-4 text-white">
                  {" "}
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <p>Confirm Address</p>
                  )}
                </button>
              </div>
            </form>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
