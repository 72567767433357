import { Dialog, Grid, Modal, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import { closeAddService } from '../../../../../redux/serviceSlice';




function Services() {

    const dispatch = useDispatch();
    const handleClose = () => dispatch(closeAddService());
    const [item, setItem] = useState(false);


    


    // Return false states
    useEffect(() => {
        return () => {
            dispatch(closeAddService());
        };
    }, []);


    return (
        <div className='w-full min-h-screen'>
            <div className=''>
                <div className="mx-auto bg-white p-3">
                    <div className="flex justify-between mb-5">
                    </div>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Services;


{/* <button className='bg-brown-900 rounded-md px-3 py-2 text-white' onClick={handleOpen}>Add New Services</button> */}