import {
  CircularProgress,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import {
  useAddNewShippingAddressMutation,
  useGetListOfCountriesQuery,
} from "../../../../../../redux/apiSlice";

export default function AddPickupAddress({handleInputChange, handleCloseAddress}) {
  const [loading, setLoading] = React.useState(false);
  const token = JSON.parse(localStorage.getItem("TOKEN"));
  
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { data: countryData, error: countryDataError } =
    useGetListOfCountriesQuery();
  const [addNewAddress, { isLoading }] = useAddNewShippingAddressMutation();



  return (
    <div className="p-5">
      <div id="responsive-dialog-title" className="text-2xl">
        <h2 className="text-2xl">Add Pickup Requests</h2>
      </div>
      <hr className="my-5" />
      <div className="example">
        <div>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <div className="py-2">
                  <h3>Do you have any special instructions</h3>
                </div>
                <TextareaAutosize 
                minRows={4}
                name="specialInstructions"
                placeholder="Enter instructions"
                style={{
                    width: '100%',
                    padding: '5px',
                    border: '2px solid #efefef',
                    outline: 'none'
                }}
                onChange={(e) => handleInputChange(e)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="py-2">
                  <h3>Contact Name</h3>
                </div>
                <TextField
                  placeholder="Enter Name"
                  className="w-full"
                  name="contactName"
                  onChange={(e) => handleInputChange(e)}
                />
              </Grid>
              
              <Grid item xs={12} md={12}>
                <div className="py-2">
                  <h3>Address</h3>
                </div>
                <TextField
                  placeholder="Enter Pickup Address"
                  className="w-full"
                  name="address"
                  onChange={(e) => handleInputChange(e)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="py-2">
                  <h3>City</h3>
                </div>
                <TextField
                  placeholder="Enter City"
                  className="w-full"
                  name="city"
                  onChange={(e) => handleInputChange(e)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="py-2">
                  <h3>Company Name</h3>
                </div>
                <TextField
                  placeholder="i.e Heirs Holdings"
                  className="w-full"
                  name="companyName"
                  onChange={(e) => handleInputChange(e)}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <div className="py-2">
                  <h3>Phone Number</h3>
                </div>
                <TextField
                  placeholder="Enter Phone Number"
                  className="w-full"
                  name="phoneNumber"
                  onChange={(e) => handleInputChange(e)}
                />
              </Grid>


              <Grid item xs={12} md={12}>
                {/* <div className='py-2'>
                                        <h3>Country</h3>
                                    </div>
                                    // <TextField placeholder='Country' className='w-full' onChange=
                                    {(e) => setCountry(e.target.value)} /> */}
                <FormControl className="w-full">
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="country"
                    label="Country"
                    onChange={(e) => handleInputChange(e)}
                  >
                    {countryData &&
                      countryData.data.map((cat, i) => (
                        <MenuItem key={i} value={cat.alpha2Abbr}>
                          {cat.fullName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="py-2">
                  <h3>State</h3>
                </div>
                <TextField
                  placeholder="Select State"
                  className="w-full"
                  name="state"
                  onChange={(e) => handleInputChange(e)}
                />
              </Grid>
              
              
              <Grid item xs={12} md={6}>
                <div className="py-2">
                  <h3>Postal Code</h3>
                </div>
                <TextField
                  placeholder="Postal Code"
                  className="w-full"
                  name="postalCode"
                  onChange={(e) => handleInputChange(e)}
                />
              </Grid>
              
              
            </Grid>
            <div className="py-4">
              <button
                className="bg-brown-700 rounded-md py-4 px-4 text-white w-full"
                onClick={handleCloseAddress}
              >
                Save Request
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
