import { Avatar, IconButton, Stack } from "@mui/material";
import DummySkeleton from "../../../../../component/MarketPlace/goods/child/DummySkeleton";
import React from "react";
import { Link } from "react-router-dom";
import { useGetAllServiceForVendorQuery } from "../../../../../redux/apiSlice";
import StarIcon from "@mui/icons-material/Star";
import { useDispatch, useSelector } from "react-redux";
import AddNewService from "./AddNewService";
import {
  closeAddService,
  openAddService,
} from "../../../../../redux/serviceSlice";
import { AiOutlineUser } from "react-icons/ai";

export default function ServiceIndex() {
  const { data, error, isLoading } = useGetAllServiceForVendorQuery();

  console.log(data && data);

  const dispatch = useDispatch();
  const service = useSelector((state) => state.service);

  function addNewService() {
    console.log("open");
    dispatch(openAddService());
  }

  function handleCloseModal() {
    dispatch(closeAddService());
  }

  return (
    <div>
      <div className="flex items-center justify-between mb-10">
        <h2 className="font-bold text-2xl">Services</h2>
        <button
          className="bg-brown-700 text-amber-100 py-2 px-4 rounded-md"
          onClick={addNewService}
        >
          Add Service
        </button>
      </div>
      {!data ? (
        <DummySkeleton />
      ) : (
        <>
          {data.data.length === 0 ? (
            <div className="h-[60vh] flex justify-center items-center">
              <Stack
                spacing={2}
                justifyContent="center"
                className="text-center"
              >
                <div>
                  <img src="/images/services.png" className="w-4/5 mx-auto" />
                </div>
                <h2 className="text-gray-800 text-xl font-bold">
                  You don't have any service yet!{" "}
                </h2>
                <h2 className="text-gray-400">
                  Would you like to add one now?
                </h2>
                <div className="">
                  <button
                    className="bg-brown-900 rounded-md px-3 py-2  text-white"
                    onClick={addNewService}
                  >
                    Add Service
                  </button>
                </div>
              </Stack>
            </div>
          ) : (
            <div className="grid grid-cols-2 md:grid-cols-3 gap-5">
              {data.data.map((service, i) => (
                <Link to={`${service.id}`}>
                  <div className="hover:bg-sky-100 rounded-lg p-3 border">
                    <div className="">
                      <img
                        src={service.productImage}
                        className="w-full h-36 rounded-t cursor-pointer object-cover "
                      />
                    </div>
                    <div className="flex flex-col w-full mx-auto py-1">
                      <div className="py-1 space-y-2">
                        <div className="my-2" />
                        <div className="flex items-center space-x-2">
                          <Avatar sx={{ width: 15, height: 15 }}>
                            <AiOutlineUser />
                          </Avatar>
                          <p className="text-[12px]">
                            {service.vendor.firstName}
                          </p>
                        </div>
                        <p className="text-black font-medium capitalize">
                          {service.category.name}
                        </p>
                        <div className="flex flex-wrap items-center gap-2">
                          {service.tags.map((tag, i) => (
                            <div
                              className="bg-brown-100 text-brown-800 p-1 rounded-md"
                              key={tag.id}
                            >
                              <p className="text-[8px]  text-gray-400">
                                {tag.tagName}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                      <p className="text-slate-700 text-xl font-bold font-sans">
                          ${service.price}
                        </p>
                        {service.ratings !== 0 && (
                          <div className="flex items-center space-x-1">
                            <IconButton>
                              <StarIcon className="text-yellow-500" />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </>
      )}
      <AddNewService open={service.addService} handleClose={handleCloseModal} />
    </div>
  );
}
