import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, TextareaAutosize } from '@mui/material';





export default function ProfileSettings() {
  
  const [brandName, setBrandName] = useState("")
  const [businessPhoneNo, setBusinessPhoneNo] = useState("")
  const [companyEmail, setCompanyEmail] = useState("")
  const [nationality, setNationality] = useState("")
  const [officeAddress, setOfficeAdrress] = useState("")
  const [state, setState] = useState("")
  

  const currentUser = useSelector((state) => state.auth.userInfo)
  console.log(currentUser)

  
  const handleSubmit = async (e) => {
    e.preventDefault()
    // console.log(token)
    console.log("processing ..........")
    const headers = {
      'Content-Type': 'application/json',
      // 'Authorization': `${token}`
    }
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}vendors/business/addInfo`, { brandName, businessPhoneNo, state, officeAddress, nationality, companyEmail }, { headers: headers })
      await console.log(res?.data)
      console.log('Done.....')
      console.log(state)
      setBrandName('')
      setBusinessPhoneNo('')
      setCompanyEmail('')
      setNationality('')
      setOfficeAdrress('')
      setState('')
      toast.success('Profile Updated', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // console.log(token)
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.errors.summary, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  

  return (
    <>
      <div className="bg-white my-10 m-5">
        <h2 className="p-5 font-bold">Personal Details</h2>

        <div className="flex flex-col justify-center items-center my-5">
          <div className="w-32 h-32 bg-slate-600 rounded-full">
            <img src="" alt='' className="object-cover" />
          </div>
          <h4 className="text-2xl font-bold mt-3">{currentUser.firstName}</h4>
          <p className="text-base">{currentUser.category} Vendor</p>
        </div>
        <hr className="my-3" />
        <div className="py-3 mt-6"></div>
        <div className="w-[95%] mx-auto">
          <form className="w-full block font-sans">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div className="">
                  <label className="text-left">
                    <div>First Name</div>
                  </label>
                  <input
                    type="text"
                    id="firstname"
                    name="firstName"
                    placeholder={currentUser.firstName}
                    value={currentUser.firstName}

                    className="px-2 py-2.5 w-full text-gray-300 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                    required
                    readOnly
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="col-6">
                  <label className="sd"> Last Name</label>
                  <input
                    type="text"
                    id="lastname"
                    name="lastName"
                    placeholder={currentUser.lastName}
                    value={currentUser.lastName}

                    className="px-2 py-2.5 w-full text-gray-300 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                    required
                    readOnly
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="">
                  <label className="text-left">
                    <div>Email</div>
                  </label>
                  <input
                    type="text"
                    id="firstname"
                    name="firstName"
                    placeholder={currentUser.email}
                    value={currentUser.email}

                    className="px-2 py-2.5 w-full text-gray-300 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                    required
                    readOnly
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="col-6">
                  <label className="sd">Phone Number</label>
                  <input
                    type="text"
                    id="lastname"
                    name="lastName"
                    placeholder={currentUser.phoneNumber}
                    value={currentUser.phoneNumber}
                    className="px-2 py-2.5 w-full text-gray-300 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                    required
                    readOnly
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="">
                  <label className="text-left">
                    <div>Nationality</div>
                  </label>
                  <input
                    type="text"
                    id="firstname"
                    name="firstName"
                    placeholder={currentUser.country}
                    value={currentUser.country}

                    className="px-2 py-2.5 w-full text-gray-300 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                    required
                    readOnly
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="col-6">
                  <label className="sd"> State</label>
                  <input
                    type="text"
                    id="lastname"
                    name="lastName"
                    placeholder={currentUser.state}
                    value={currentUser.state}

                    className="px-2 py-2.5 w-full text-gray-300 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                    required
                    readOnly
                  />
                </div>
              </Grid>
            </Grid>
            <div className='w-full py-6 flex justify-end'>
              <button className='bg-blue-300 font-white font-bold py-2 px-4 text-white rounded-md'>Save</button>
            </div>
          </form>
        </div>
      </div>
      <div className='bg-white py-5 shadow-md my-10 mr-20'>
        <div className='px-5'>
          <h2 className='text-lg font-bold'>Agency Details</h2>
        </div>
        <div className="py-5 w-[95%] mx-auto">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div className="col-6">
                <label className="sd">Agency Name</label>
                <input
                  type="text"
                  id="lastname"
                  name="lastName"
                  placeholder='Jallem Enterprise'
                  className="px-2 py-2.5 w-full text-gray-300 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                  required
                  readOnly
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>

            </Grid>
            <Grid item xs={12} md={12}>
              <div className="col-6">
                <label className="sd">Office Address</label>
                <input
                  type="text"
                  id="lastname"
                  name="lastName"
                  placeholder='House 3, Bronsville Avenue, Lekki, Lagos'
                  className="px-2 py-2.5 w-full ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                  required
                  readOnly
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="col-6">
                <label className="sd">Agency Email Address</label>
                <input
                  type="text"
                  id="lastname"
                  name="lastName"
                  placeholder='Input'
                  className="px-2 py-2.5 w-full ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                  required
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="col-6">
                <label className="sd">Agency phone number</label>
                <input
                  type="text"
                  id="lastname"
                  name="lastName"
                  placeholder='+235704593885'
                  className="px-2 py-2.5 w-full ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                  required
                />
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="col-6">
                <label className="sd">Description</label>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={8}
                  placeholder="Minimum 3 rows"
                  className='w-full ring-gray-200 border-2 border-gray-200 bg-gray-100 p-3'
                />
              </div>
            </Grid>
          </Grid>
          <div className='w-full py-6 flex justify-end'>
            <button className='bg-blue-300 font-white font-bold py-2 px-4 text-white rounded-md'>Save</button>
          </div>
        </div>
      </div>
      <div className='bg-white py-5 shadow-md my-10 mr-20'>
        <div className="w-[95%] mx-auto">
          <div>
            <h1 className="text-lg font-bold">Categories</h1>
            <div className='py-4'>
              <h2 className="text-lg">Design & Creatives</h2>
              <div className='py-4 flex space-x-4 items-center'>
                <span className="bg-blue-200 rounded-md text-white py-2 px-4">Product Designer</span>
                <span className="bg-blue-200 rounded-md text-white py-2 px-4">UI/UX Designer</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white py-5 shadow-md my-10 mr-20 hidden'>
        <div className="mx-auto w-[95%  ">
          <div >
            <h1 className='text-lg font-bold'>Change Password</h1>
          </div>
          <div className='py-5'>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div className="col-6">
                  <label className="sd">Current Password</label>
                  <input
                    type="password"
                    id="lastname"
                    name="lastName"
                    placeholder='*****'
                    className="px-2 py-2.5 w-full text-gray-300 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                    required
                    readOnly
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>

              </Grid>
              <Grid item xs={12} md={6}>
                <div className="col-6">
                  <label className="sd">New Password</label>
                  <input
                    type="password"
                    id="lastname"
                    name="lastName"
                    placeholder='*********'
                    className="px-2 py-2.5 w-full text-gray-300 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                    required
                    readOnly
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="col-6">
                  <label className="sd">Retype New Password</label>
                  <input
                    type="password"
                    id="lastname"
                    name="lastName"
                    placeholder='*********'
                    className="px-2 py-2.5 w-full text-gray-300 ring-slate-300 focus:ring-brown-800 ring-[1px] focus:ring-[2px] rounded outline-none mt-2"
                    required
                    readOnly
                  />
                </div>
              </Grid>
              <div className='w-full py-6 flex justify-end'>
                <button className='bg-blue-300 font-white font-bold py-2 px-4 text-white rounded-md'>Save</button>
              </div>
            </Grid>
          </div>
        </div>
      </div>
    </>

  );
}
