
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAcceptVendorOrderMutation,
  useGetSingleVendorOrderQuery,
  useStartOrderShipmentMutation,
} from "../../../../../redux/apiSlice";
import { Oval } from "react-loading-icons";
import { formatToCurrency } from "../../../../utils/currencyFormatter";
import moment from "moment";
import { Button, Dialog, Menu, MenuItem, Modal } from "@mui/material";
import ShippingMentStarter from "./child/ShippingMentStarter";

export default function SingleOrder() {
  // const state = useSelector((state) => state.order);
  const [openModal, setOpenModal] = useState(false);
  const [shipmentModal, setShipmentModal] = useState(false)

  const navigate = useNavigate();
  const location = useLocation()

  const route = useParams();
  const { data: orderDetails } = useGetSingleVendorOrderQuery(route.id);
  
  const [acceptVendorOrder, { isLoading: acceptOrderLoading }] =
    useAcceptVendorOrderMutation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  function handleCloseShipmentModal() {
    setShipmentModal(false)
  }

  function launchShipment () {
    setShipmentModal(true)
  }

  

  function acceptOrder(id) {
    console.log(id)
    setOpenModal(true);
    acceptVendorOrder(id)
      .unwrap()
      .then((data) => {
        console.log(data);
        setAnchorEl(null);
        handleCloseModal();
        
      })
      .catch((err) => {
        console.log(err);
        setAnchorEl(null);
        setOpenModal(false)
      });
  }

  console.log(orderDetails && orderDetails);
  console.log(location)

  return (
    <div className="my-10 h-full p-5 overflow-hidden">
      <button onClick={() => navigate(-1)} className="mb-3">
        Go back
      </button>
      <div className="flex justify-between items-center">
        <h2 className="text-4xl font-bold mb-5">
          Order Details <span className="text-red-600">#</span>
        </h2>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          variant="contained"
          sx={{
            color: "#fff",
            backgroundColor: "#0b3e90",
          }}
        >
          Order Options
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {orderDetails &&
          orderDetails.data.orderAcceptance.acceptanceStatus === "ACCEPTED" ? (
            <MenuItem onClick={()=> launchShipment()}>
              Start Shipment
            </MenuItem>
          ) : (
            <MenuItem onClick={() => acceptOrder(orderDetails.data.id)}>Accept Order</MenuItem>
          )}
          {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
        </Menu>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        {/* Item summary */}
        {!orderDetails ? (
          <div className="w-full flex h-screen justify-center items-center">
            <Oval />
          </div>
        ) : (
          <>
            <div>
              <table className="table-auto w-full rounded-md bg-white text-lg">
                <thead className="h-10">
                  <tr className="bg-brown-800 text-white">
                    <th>Item Name</th>
                    <th>Item Id</th>
                    <th>QTY</th>
                    <th>Price</th>
                    {/* <th>Total Price</th> */}
                  </tr>
                </thead>
                <tbody className="text-center">
                  {orderDetails.data.orderItems.map((data, i) => (
                    <tr>
                      <td>{data.product.name}</td>
                      <td>{data.id}</td>
                      <td>x{data.quantity}</td>
                      <td>{formatToCurrency(data.price)}</td>
                      {/* <td>$45</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* <SingleTable/>
            </div>

            {/* Pricing */}
            <div className="bg-white rounded-md flex flex-col space-y-3 p-5">
              <div className="flex justify-between items-center px-2">
                <h2 className="font-bold">Order summary</h2>
                <div className="px-3 py-1 bg-yellow-50 text-yellow-700">
                  {/* {state.data.tripStatus} */}
                </div>
              </div>
              {/* {new Date().getDate()} */}
              <div className="flex justify-between items-center px-2">
                <p>Order Status</p>
                <p className="font-bold">
                  {orderDetails.data.orderAcceptance.acceptanceStatus}
                </p>
              </div>
              <div className="flex justify-between items-center px-2">
                <p>Order Id</p>
                <p>{orderDetails.data.logistics.uniqueId}</p>
              </div>
              <div className="flex justify-between items-center px-2">
                <p>Acceptance Date</p>
                <p>
                  {moment(
                    orderDetails.data.orderAcceptance.acceptanceDate
                  ).format("ddd DD MMMM, yyyy")}
                </p>
              </div>
              <div className="flex justify-between items-center px-2">
                <p>Delivery Fee</p>
                <p>
                  {formatToCurrency(orderDetails.data.logistics.totalPrice)}
                </p>
              </div>
            </div>

            {/* Customer + order details */}
            <div className="bg-white rounded-md flex flex-col space-y-3 p-5">
              <div className="flex justify-between items-center px-2">
                <h2 className="font-bold">Customer and Order details</h2>
                <div className="px-3 py-1 bg-yellow-50 text-yellow-700"></div>
              </div>
              <div className="flex justify-between items-center px-2">
                <p>Customer Name</p>
                <p>
                  {orderDetails.data.customer.firstName}{" "}
                  {orderDetails.data.customer.lastName}
                </p>
              </div>
              <div className="flex justify-between items-center px-2">
                <p>Phone Number</p>
                {/* <p>{state.data.customer.phoneNumber}</p> */}
              </div>
              <div className="flex justify-between items-center px-2">
                <p>Country</p>
                <p>Nigeria</p>
              </div>
              <div className="flex justify-between items-center px-2">
                <p></p>
                <p></p>
              </div>
            </div>

            {/* Delivery Details */}
            <div className="bg-white rounded-md flex flex-col space-y-3 p-5">
              <div className="flex justify-between items-center px-2">
                <h2 className="font-bold">Delivery details</h2>
                <div className="px-3 py-1 bg-yellow-50 text-yellow-700"></div>
              </div>
              <div className="flex justify-between items-center px-2">
                <p>Contact Name</p>
                <p>
                  {
                    orderDetails.data.logistics.customerShippingAddress
                      .contactName
                  }
                </p>
              </div>
              <div className="flex justify-between items-center px-2">
                <p>Phone Number</p>
                <p>
                  {
                    orderDetails.data.logistics.customerShippingAddress
                      .phoneNumber
                  }
                </p>
              </div>
              <div className="flex justify-between items-center px-2">
                <p>Address</p>
                <p>
                  {orderDetails.data.logistics.customerShippingAddress.address}
                </p>
              </div>
              <div className="flex justify-between items-center px-2">
                <p>Country</p>
                <p>
                  {orderDetails.data.logistics.customerShippingAddress.country}
                </p>
              </div>
              <div className="flex justify-between items-center px-2">
                <p>Nationality</p>
                <p>
                  {
                    orderDetails.data.logistics.customerShippingAddress
                      .nationality
                  }
                </p>
              </div>
              <div className="flex justify-between items-center px-2">
                <p>Address ID</p>
                <p>
                  {
                    orderDetails.data.logistics.customerShippingAddress
                      .addressId
                  }
                </p>
              </div>
              <div className="flex justify-between items-center px-2">
                <p>State</p>
                <p>
                  {orderDetails.data.logistics.customerShippingAddress.state}
                </p>
              </div>
              <div className="flex justify-between items-center px-2">
                <p>City</p>
                <p>
                  {orderDetails.data.logistics.customerShippingAddress.city}
                </p>
              </div>
              <div className="flex justify-between items-center px-2">
                <p>Postal Code</p>
                <p>
                  {
                    orderDetails.data.logistics.customerShippingAddress
                      .postalCode
                  }
                </p>
              </div>
              <div className="flex justify-between items-center px-2">
                <p>Company Name</p>
                <p>
                  {
                    orderDetails.data.logistics.customerShippingAddress
                      .companyName
                  }
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      <Dialog open={shipmentModal}  onClose={handleCloseShipmentModal}>
        <div className="w-[350px] md:w-[500px] p-10">
          <ShippingMentStarter handleCloseShipmentModal={handleCloseShipmentModal} />
        </div>
      </Dialog>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <div className="w-[100px] h-[100px] rounded flex justify-center items-center">
          <Oval stroke="#febc23" />
        </div>
      </Dialog>
    </div>
  );
}
