import React, { Component } from "react";
import "./App.css";
import "./index.css"
import MainComponent from "./component/controller/MainComponent";

const App = () => {
  return <MainComponent className="" />;
};

export default App;
