



import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function SettingsMenu() {

    const location = useLocation()
    console.log(location.pathname)


    return (
      <div>
        <div className="grid grid-cols-1 mt-10 ml-5">
          <aside className="block w-full bg-white  py-10 rounded px-5 space-y-5">
            {settingsMenu.map((menu, i) => (
              <div>
                <Link to={`/dashboard/settings/${menu.to}`}>
                  <p>{menu.name}</p>
                </Link>
              </div>
            ))}
          </aside>
        </div>
      </div>
    );
}



const settingsMenu = [
    {
        name: "Profile",
        to: ""
    },
    // {
    //     name: "Payment",
    //     to: "payment"
    // },
    // {
    //   name:'Security',
    //   to:'security'
    // }
]