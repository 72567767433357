import { Stack } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const MarketFooter = () => {
   return (
      <>
         <div className='bg-[#281408] flex justify-center items-center p-6 h-[250px]'>
            
               <div className='flex flex-col items-center space-y-2'>
               <img src={"images/logo.png"} alt="" className="w-20" />
                  <div className='flex gap-5 items-center text-amber-500'>
                     <Link to="/about">
                        <p className='cursor-pointer whitespace-nowrap hover:underline underline-offset-4'>About Us</p>
                     </Link>
                     <Link to="/">
                        <p className='cursor-pointer whitespace-nowrap hover:underline underline-offset-4'>Trades</p>
                     </Link>
                  </div>
                  <p className='text-center text-white'>© 2022 AFCFTA, Inc. All rights reserved</p>
               </div>
            
         </div>
      </>
   )
}

export default MarketFooter