import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Modal.css";
import axios from "axios";
import {
  useAddNewShippingAddressMutation,
  useGetAllShippingAddressQuery,
  useGetListOfCountriesQuery,
  useSetDefaultShippingAddressMutation,
} from "../../../../redux/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import { CloseRounded } from "@material-ui/icons";
import { openSnackBar } from "../../../../redux/snackSlice";
import { setCheckoutAddress } from "../../../../redux/checkoutSlice";

export default function SelectAddressModal({
  handleClickOpen,
  open,
  setOpen,
  handleClose,
}) {
  const token = JSON.parse(localStorage.getItem("TOKEN"));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [newAddress, setNewAddress] = React.useState(false);
  const [newAddressId, setNewAddressId] = React.useState(null);

  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState();
  const [country, setCountry] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [state, setState] = React.useState("");
  const [contactName, setContactName] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  // Hooks
  const dispatch = useDispatch();

  const { data, isLoading, error } = useGetAllShippingAddressQuery();
  const [addNewAddress, { isLoading: newAddressLoading }] =
    useAddNewShippingAddressMutation();
  const [setAsDefault, { isLoading: defaultSetLoading }] =
    useSetDefaultShippingAddressMutation();
  const { data: countryData, error: countryDataError } =
    useGetListOfCountriesQuery();

  // console.log(countryData);

  const shipping = useSelector((i) => i.checkout);
  // console.log(shipping);

  // Methods
  function pickShippingAddress(value) {
    console.log(value);
    dispatch(setCheckoutAddress(value))
    handleClose()
  }

  function openAddNewAddress() {
    setNewAddress(true);
  }

  function closeAddNewAddress() {
    setNewAddress(false);
  }

  // Submit address
  const handleSubmitAddress = async (e) => {
    e.preventDefault();

    
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };
    e.preventDefault();
    setLoading(true);

    try {
      addNewAddress({
        address: address,
        city: city,
        companyName: "Test company Name",
        contactName: contactName,
        country: country,
        phoneNumber: phoneNumber,
        postalCode: postalCode,
        state: state,
      })
        .unwrap()
        .then((data) => {
          console.log(data);
          setLoading(false);
          setNewAddressId(data.id);
          setNewAddress(false)
        });
    } catch (err) {
      
    }
  };

  function setAddressDefault() {
    try {
      setAsDefault(newAddressId)
        .unwrap()
        .then((data) => {
        
          dispatch(
            openSnackBar({ message: "New default shipping address added!" })
          );
          setNewAddress(false);
        });
    } catch (err) {
      
    }
  }

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div
          id="responsive-dialog-title"
          className="py-4 px-3 flex justify-between items-center"
        >
          <h2 className="text-[20px] font-bold">Select Address</h2>
          {newAddress ? (
            <button
              className="bg-slate-50 rounded-md py-2 px-3"
              onClick={closeAddNewAddress}
            >
              <span>
                <CloseRounded />
              </span>{" "}
              Close
            </button>
          ) : (
            <button
              className="bg-brown-800 text-amber-300 rounded-md py-2 px-3"
              onClick={openAddNewAddress}
            >
              <span className="mr-1.5">
                <AddCircleIcon />
              </span>
              New Address
            </button>
          )}
        </div>
        <DialogContent className="">
          <div className="md:w-[500px] w-[400px] overflow-hidden">
            {/* adding new address ? */}
            {newAddress ? (
              <>
                <form onSubmit={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className="py-2">
                        <h3>Name</h3>
                      </div>
                      <TextField
                        placeholder="Enter Name"
                        className="w-full"
                        onChange={(e) => setContactName(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      {/* <div className='py-2'>
                                        <h3>Country</h3>
                                    </div>
                                    <TextField placeholder='Country' className='w-full' onChange=
                                    {(e) => setCountry(e.target.value)} /> */}
                      <FormControl className="w-full">
                        <InputLabel id="demo-simple-select-label">
                          Country
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={country}
                          label="Category"
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          {countryData &&
                            countryData.data.map((cat, i) => (
                              <MenuItem key={i} value={cat.alpha2Abbr}>
                                {cat.fullName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="py-2">
                        <h3>State</h3>
                      </div>
                      <TextField
                        placeholder="Select State"
                        className="w-full"
                        onChange={(e) => setState(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="py-2">
                        <h3>City</h3>
                      </div>
                      <TextField
                        placeholder="City"
                        className="w-full"
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="py-2">
                        <h3>Address</h3>
                      </div>
                      <TextField
                        placeholder="Enter Address"
                        className="w-full"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="py-2">
                        <h3>Postal Code</h3>
                      </div>
                      <TextField
                        placeholder="Postal Code"
                        className="w-full"
                        onChange={(e) => setPostalCode(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="py-2">
                        <h3>Phone Number</h3>
                      </div>
                      <TextField
                        placeholder="Phone Number"
                        className="w-full"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <div className="py-4">
                    <button
                      className="bg-brown-700 rounded-md py-4 px-4 text-white w-full"
                      onClick={handleSubmitAddress}
                    >
                      {newAddressLoading ? (
                        <div className="flex items-center justify-center">
                          <CircularProgress />
                        </div>
                      ) : (
                        <p>Save Address</p>
                      )}
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <div>
                {!data ? (
                  <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </>
                ) : (
                  <>
                    {data.data.length === 0 ? (
                      <div>
                        <p>You have no address yet!</p>
                        <p className="text-xs text-slate-400">
                          Add new shipping address by clicking the button above
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div className="space-y-1">
                          {data.data.map((info, index) => (
                            <div
                              className="bg-white hover:bg-slate-100 rounded-md p-3"
                              key={index}
                              onClick={() => pickShippingAddress(info)}
                            >
                              <div className="">
                                <h1 className="font-light text-slate-500 text-[16px]">
                                  {info.contactName}
                                </h1>
                                <h1 className="text-lg font-bold">
                                  {info.address}
                                </h1>
                                <div className="flex items-center justify-between">
                                  <p className="text-[14px] text-slate-600 mt-2">
                                    {info.phoneNumber}
                                  </p>
                                  <div>
                                    {/* {index === 0 && (
                                      <div className="flex justify-end">
                                        <button className="bg-blue-200 px-2 rounded-md text-[14px] font-bold text-blue-500">
                                          Default
                                        </button>
                                      </div>
                                    )} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

{
  /* <DialogContentText>
                        <form onSubmit={1}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <div className='py-2'>
                                        <h3>Name</h3>
                                    </div>
                                    <TextField placeholder='Enter Name' className='w-full' onChange={(e) => setContactName(e.target.value)} />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <div className='py-2'>
                                        <h3>Country</h3>
                                    </div>
                                    <TextField placeholder='Country' className='w-full' onChange={(e) => setCountry(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className='py-2'>
                                        <h3>State</h3>
                                    </div>
                                    <TextField placeholder='Select State' className='w-full' onChange={(e) => setState(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className='py-2'>
                                        <h3>City</h3>
                                    </div>
                                    <TextField placeholder='City' className='w-full' onChange={(e) => setCity(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className='py-2'>
                                        <h3>Address</h3>
                                    </div>
                                    <TextField placeholder='Enter Address' className='w-full' onChange={(e) => setAddress(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className='py-2'>
                                        <h3>Postal Code</h3>
                                    </div>
                                    <TextField placeholder='Enter Name' className='w-full' onChange={(e) => setPostalCode(e.target.value)} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <div className='py-2'>
                                        <h3>Phone Number</h3>
                                    </div>
                                    <TextField placeholder='Phone Number' className='w-full' onChange={(e) => setPhoneNumber(e.target.value)} />
                                </Grid>
                            </Grid>
                            <div className='py-4'>
                                <button className="bg-brown-700 rounded-md py-4 px-4 text-white">{loading ? (
                                    <div className='flex items-center justify-center'>
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <p>Change Address</p>
                                )}</button>
                            </div>
                        </form>
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />

                    </DialogContentText> */
}
