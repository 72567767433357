import HeadMarket from '../../../../component/MarketPlace/child/HeadMarket'
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserCart } from '../../../../redux/cartSlice'
import CartItems from './subcomponents/CartItems'

const CartHome = () => {

    const dispatch = useDispatch()
    const navigate = useDispatch()

    const userToken = useSelector(state => state.auth.userToken)

    useEffect(() => {
        
      dispatch(getUserCart({token: userToken}))

      
    }, [])
    
    
    

    return (
        <>
            <HeadMarket />
            <div className='mx-5'>
                <CartItems />
            </div>
            {/* <MarketFooter /> */}

        </>
    )
}

export default CartHome