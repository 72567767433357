import React from 'react'
import DisputeTable from './DisputeTable'

const Disputes = () => {
    return (
        <>
            <div className='bg-white py-5 md:py-5'>
                <div className='px-6'>
                    <h1 className='text-xl font-bold'>Disputes</h1>
                    <div className='flex space-x-3 justify-center items-center py-8'>
                        <button className='bg-brown-900 py-2 px-8 rounded-md text-white'>Goods</button>
                        <button className='bg-brown-900 py-2 px-8 rounded-md text-white'>Services</button>
                    </div>
                    {/* <div className='flex justify-center flex-col space-y-3 h-[40vh] items-center py-8'>
                        <img src="/images/customersuport.png" />
                        <p className='text-center text-gray-300'>You don’t have order yet!</p>
                        <p className='text-center text-gray-300'>When someone makes an order it’ll appear here</p>
                    </div> */}
                    <div className='w-4/5 mx-auto'>
                        <div className='py-4'>
                            <div className="space-x-4 flex items-center">
                                <h2 className='font-bold cursor-pointer border-b-2 border-brown-900'>Open</h2>
                                <h2 className='font-bold cursor-pointer'>Closed</h2>
                            </div>
                            <div className='py-3'>
                                <DisputeTable />
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Disputes