import React from 'react';
import { useNavigate } from 'react-router-dom';
import exploreBackground from "../../../../assets/images/explore-lady.png";

function SectionFive(props) {


  const navigate = useNavigate()

  const gotoVendor = () => {
    navigate("/select-user");
  };

  const gotoMarket = () => {
    navigate("/market");
  };
  

    return (
      <div
        className="h-screen flex items-center justify-center bg-brown-800 bg-blend-overlay"
        style={{
          backgroundImage: `url(${exploreBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-col space-y-14 md:space-y-2 justify-center items-center text-white text-center px-10 md:px-0 relative">
          <h1 className="text-4xl font-bold font-sans">Explore the market</h1>
          <p className="w-3/4 md:w-full text-lg">
            Check the latest products developed by Africans for africans
          </p>
          <div className="w-5 h-5 rounded-full bg-white md:hidden" />
          <div className="grid md:grid-cols-2 items-center gap-3 mt-1.5 w-full">
            <button
              className="py-3.5 rounded-md bg-white text-brown-900 text-sm flex items-center justify-center"
              onClick={gotoMarket}
            >
              Explore Market
              <span>
                <i class="fa fa-arrow-right ml-3"></i>
              </span>
            </button>
            <button
              className="py-3.5 rounded-md bg-brown-500 text-white text-sm flex items-center justify-center"
              onClick={gotoVendor}
            >
              Become a vendor
              <span>
                <i class=" ml-2 mr-2 fa fa-arrow-right"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    );
}

export default SectionFive;



<>
            <div className='image-overlay'>
            <div className='jumbotron section-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-8 mx-auto pl-5'>
                            <h3 className='text-white'>
                                Explore the market
                            </h3>
                            <p className='mt-3' >
                                Check the latest products developed by Africans for Africans
                            </p>
                            <div className='small'>
                                <img src={'images/Ellipse 986.svg'} alt= 'eclipse'/>
                            </div>
                                
                            <button className='btn btn-3 mt-3 btn-md'>
                                Explore Market <span><i class="fa fa-arrow-right ml-4"></i></span>
                            </button>
                            <button className='btn btn-md mt-3 text-white btn-4 ml-3'>
                                Become a vendor <span><i class=" ml-4 mr-2 fa fa-arrow-right"></i></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>