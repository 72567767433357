import React, { useContext, useEffect } from "react";
import DashHeader from "./DashHeader";
import Content from "./pages/Content";
import Sidebar from "./pages/Sidebar";
import styled from "styled-components";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Dashboard(props) {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth);
  

  

  useEffect(() => {

    if (currentUser.userInfo.onboardingStage === "REGISTERED") {
      // navigate("/vendor/onboarding")
    }
    // if (currentUser.userToken === null) {
    //   navigate("/login")
    // }
    // return () => {
    // }
  }, []);

  return (
    <>
      <div>
        <div className="grid grid-cols-1 md:grid-cols-12">
          <div className="col-span-2 hidden md:block">
            <div className="fixed w-[17%] z-10 bg-white">
              <Sidebar />
            </div>
          </div>
          <div className="col-span-10 bg-grey_dashboard-100 min-h-[100vh]">
            <div className="w-full">
              <div className="fixed w-[100%] top-0">
                <DashHeader />
              </div>
              <div className="md:mt-10 md:p-10">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
