import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import ClassificationType from './ClassificationType';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 10,
}



export default function Classify({ open2, setOpen2, handleOpen2, handleClose2 }) {

    const [open3, setOpen3] = React.useState(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);


    const classifyDetail = useSelector(state => state.classify)
    console.log(classifyDetail)

    const handleClick = () => {
        handleClose2()
        handleOpen3()
    }

    return (
        <>
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open2}
                    onClose={handleClose2}
                    closeAfterTransition
                >
                    <Fade in={open2}>
                        <Box sx={style}>
                            <div className='flex justify-center items-center'>
                                <Stack spacing={2} className='flex flex-col items-center w-full'>
                                    <img src="/images/vector123.png" className='w-20 items-center justify-self-center' />
                                    <h2 className='text-2xl font-bold text-center'>Classify products for rules of origin</h2>
                                    <p className='text-center text-sm'>Follow the steps to classify and get your product approved with an HS Code according to the AfCFTA Rules of origin agreement</p>
                                </Stack>
                            </div>
                            <div className='mt-4'>
                                <button onClick={handleClick} className='bg-[#5A3924] w-full text-white p-2 mt-6'>Proceed</button>
                            </div>

                        </Box>
                    </Fade>
                </Modal>
                <ClassificationType open3={open3} setOpen3={setOpen3} handleOpen3={handleOpen3} handleClose3={handleClose3} />
            </div>
        </>

    );
}
