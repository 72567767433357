import axios from "axios";
import { createContext, useEffect, useReducer, useState } from "react";
import { CartReducer } from "./CartReducer";

export const CartContext = createContext();

const data = JSON.parse(localStorage.getItem("CART"));

const INITIAL_STATE = data ? data : [];

const CartProvider = ({ children }) => {
  const token = JSON.parse(localStorage.getItem("TOKEN"));
  const [cartItems, setCartItems] = useState([]);
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };
    const getCartItems = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}carts`,
        { headers: headers }
      );
      // console.log(response.data.data.cartItems)
      // console.log(data)
      setCartItems(response.data.data.cartItems);
      window.localStorage.setItem(
        "CART",
        JSON.stringify(response.data.data.cartItems)
      );
    };
    getCartItems();
  }, []);
  const [state, dispatch] = useReducer(CartReducer, cartItems ? cartItems : []);
  return (
    <CartContext.Provider value={{ dispatch, state }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
