import axios from "axios";
import React, { useEffect } from "react";
import WalletHistory from "./child/WalletHistory";
import { useGetVendorWalletSummaryQuery } from "../../../../../redux/apiSlice";
import { formatToCurrency } from "../../../../../component/utils/currencyFormatter";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function WalletSummary() {

  const { data, error } = useGetVendorWalletSummaryQuery();
const navigate = useNavigate()
  function handleInitiateWithdrawal () {
    navigate('/dashboard/wallet/withdraw')
  }

  return (
    <div className="w-4/5 mx-auto">
      <div className="font-sans">
        <div className="grid md:grid-cols-3 grid-cols-1 gap-5 mt-5">
          {/* {dashboardValues.map((value, i) => ( */}
          <div>
            <div className="w-full h-44 bg-white drop-shadow rounded-md flex flex-col justify-between p-5">
              <h3>Legder Balance</h3>
              <div className="flex items-center w-full h-full">
                {!data ? (
                  <Skeleton width={10} height={25} animation="wave" />
                ) : (
                  <>
                    <p className="text-3xl font-bold">
                      {formatToCurrency(data.data.ledger.amount)}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="w-full h-44 bg-white drop-shadow rounded-md flex flex-col justify-between p-5">
              <h3>Available Balance</h3>
              <div className="flex items-center w-full h-full">
              {!data ? (
                  <Skeleton width={10} height={25} animation="wave" />
                ) : (
                  <>
                    <p className="text-3xl font-bold">
                      {formatToCurrency(data.data.available.amount)}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="w-full h-44 bg-white drop-shadow rounded-md flex flex-col justify-between p-5">
              <h3>Total Balance</h3>
              {!data ? (
                  <Skeleton width={10} height={25} animation="wave" />
                ) : (
                  <>
                    <p className="text-3xl font-bold">
                      {formatToCurrency(data.data.ledger.amount + data.data.available.amount)}
                    </p>
                  </>
                )}
              <div className="mx-auto flex space-x-2 items-center">
                {/* <button className="py-2 px-4 rounded-[50px] bg-brown-500 text-white">
                  Deposit
                </button> */}
                <button className="py-2 px-4 rounded-[50px] bg-gray-200" onClick={handleInitiateWithdrawal}>
                  Withdraw
                </button>
              </div>
            </div>
          </div>
          {/* ))} */}
        </div>
        <div className="my-5">
          <WalletHistory />
        </div>
      </div>
    </div>
  );
}

