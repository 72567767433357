



import React from 'react'
import NewArrivals from './child/NewArrivals'
import PopularProducts from './child/PopularProducts'
import RecommendedProducts from './child/RecommendedProduct'
import TopSelling from './child/TopSelling'

export default function GoodSections() {
  return (
    <div className='space-y-10'>
        <NewArrivals />
        <hr className='my-2' />
        {/* <TopSelling /> */}
        {/* <PopularProducts /> */}
        {/* <RecommendedProducts name={"New Arrivals"} /> */}
        {/* <hr className='my-2' />
        <RecommendedProducts name={"Fashion"} /> */}
        <hr className='my-2' />
        <RecommendedProducts name={"Near you"} />
    </div>
  )
}
