import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Grid, IconButton } from '@mui/material'
import Person2Icon from '@mui/icons-material/Person2';
import AddCardIcon from '@mui/icons-material/AddCard';
import InventoryIcon from '@mui/icons-material/Inventory';
const VendorHome = () => {
  const currentUser = useSelector((state) => state.auth)
  

  useEffect(() => {
    console.log(currentUser)
  }, [])
  return (
    <>
      <main className='max-h-screen '>
        {/* <Header /> */}
        <div className='min-h-[75vh] w-[80%] mx-auto'>
          <div className='p-2 w-full mx-auto flex justify-between items-center'>
            {/* <Link to="/vendor/onboarding/business">
        </Link> */}
            <Link to="/">
              <img src="/images/logo.png" />
            </Link>
            <Link to="/vendor/onboarding/business">
              <IconButton className='cursor-pointer'>
                <NavigateNextIcon className="text-xl" />
              </IconButton>
            </Link>
          </div>
          <div className='py-4'>
            {/* <div className="flex justify-between items-center">
              <img src="/images/beautiful.png" className='h-4/5'/>
            </div> */}
            <Grid container spacing={2} alignItems="start">
              <Grid item xs={12} md={6}>
                <img src="/images/beautiful.png" className='h-[500px] w-4/5 object-cover' />
              </Grid>
              <Grid item xs={12} md={6}>
                <div className='space-y-4 py-8'>
                  <h1 className='font-bold text-4xl'>{`Hello ${currentUser.userInfo.firstName}, ready to seize AfCFTA opportunity?`}</h1>
                  <h4>Information takes about 5-10 minutes to fill. Your answers will be saved automatically as you proceed</h4>
                  <div className='space-y-6'>
                    <div className="flex items-center space-x-3">
                      <IconButton>
                        <Person2Icon className="text-brown-700" />
                      </IconButton>
                      <h2>Build your profile</h2>
                    </div>
                    <div className="flex items-center space-x-3">
                      <IconButton>
                        <InventoryIcon className="text-brown-700" />
                      </IconButton>
                      <h2>Offer goods and services</h2>
                    </div>
                    <div className="flex items-center space-x-3">
                      <IconButton>
                        <AddCardIcon className="text-brown-700" />
                      </IconButton>
                      <h2>Get paid for what you offer</h2>
                    </div>


                  </div>

                </div>
                <Link to="/vendor/onboarding/personal">
                  <button className='bg-brown-900 py-3 px-6 rounded-md text-white w-4/5'>Get Started</button>
                </Link>

              </Grid>
            </Grid>

          </div>
          {/* <Personal /> */}
          {/* <Stepperr /> */}
          {/* <Business/> */}
          {/* <Upload/> */}
        </div>
        {/* <Stepper/> */}

      </main>
    </>
  )
}

export default VendorHome;