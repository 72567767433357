import DummySkeleton from "../../../../../component/MarketPlace/goods/child/DummySkeleton";
import React, { useState } from "react";
import { MdHourglassEmpty } from "react-icons/md";
import { Link } from "react-router-dom";
import { useGetAllCustomerOrderQuery } from "../../../../../redux/apiSlice";

export default function OrderIndex() {


  const [goods, setGoods] = useState(true);
  const [services, setServices] = useState(false);


  const { data, isLoading, error } = useGetAllCustomerOrderQuery();
  

  // Methods
  const onGoods = () => {
    setGoods(true);
    setServices(false);
  };
  const onServices = () => {
    setServices(true);
    setGoods(false);
  };

  return (
    <div>
      {/* List all the orders */}
      <div className="mt-10 w-full">
        {!data ? (
          <DummySkeleton />
        ) : (
          <>
          <div className="flex items-center space-x-1 mb-5">
        <button
          onClick={onGoods}
          className={` relative
            ${
              goods
                ? "border bg-brown-900 text-white py-2 px-10 "
                : "border-brown-900 py-2 px-10 border text-black"
            }
          `}
        >
          Goods
          <span className="w-[18px] h-[18px] rounded-lg bg-red-600 text-white absolute right-5 top-2 flex items-center justify-center">
            <p className="text-[8px]">{data && data.data.length > 0 ? data.data.length : 0}</p>
          </span>
        </button>
        <button
          onClick={onServices}
          className={` relative
            ${
              services
                ? "bg-brown-900 text-white py-2 px-10 "
                : "border-brown-900 py-2 px-10 text-black border"
            }
          `}
        >
          Services
          <span className="w-[18px] h-[18px] rounded-lg bg-red-600 text-white absolute right-5 top-2 flex items-center justify-center">
            <p className="text-[8px]">{data && data.data.filter(item => item.orderType === "SERVICE").length > 0 ? data.data.filter(item => item.orderType === "SERVICE").length : 0}</p>
          </span>
        </button>
      </div>
          
          {goods ? <div className="flex flex-col space-y-5">
            {data.data.length === 0 ? <div className="w-full bg-white h-[400px] flex flex-col justify-center items-center">
              <div className="flex flex-col items-center">
                <MdHourglassEmpty fontSize={30} color="#dfdfef" />
              <p className="text-slate-400">No item in your order</p>
              </div>
            </div> : <>{data.data.map((order, i) => (
              <>
                <Link to={`${order.id}`}>
                  <div className="flex justify-between items-center bg-white cursor-pointer p-2 hover:bg-blue-100">
                    <div className="flex items-center space-x-5">
                      <div className="bg-red-500 rounded w-24 h-24 items-center">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/5234/5234583.png"
                          className="object-cover w-24 h-24"
                        />

                        {/* Some order details */}
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm text-slate-400">
                          Number of Item:{" "}
                          <span className="text-md text-slate-700">
                          {/* {order.orderItems[0].quantity !== 0
                            ? order.orderItems[0].quantity
                            : null} */}
                          </span>
                        </p>
                        <h1 className="text-xs">
                          Order Status:{" "}
                          <span className="bg-green-600 text-white px-2 py-1 rounded-md">Active</span>
                        </h1>
                        
                      </div>
                    </div>

                    <div>
                        <p className="font-bold text-xs text-slate-400">Total amount</p>
                        <p className="font-bold text-xl">$ {order.totalPrice}</p>
                      
                    </div>
                  </div>
                </Link>
              </>
            ))}</>}
          </div> : null}

          {/* Services orders */}
          {services ? <div className="flex flex-col space-y-5">
            {data.data.length === 0 ? <div className="w-full bg-white h-[400px] flex flex-col justify-center items-center">
              <div className="flex flex-col items-center">
                <MdHourglassEmpty fontSize={30} color="#dfdfef" />
              <p className="text-slate-400">No item in your order</p>
              </div>
            </div> : <>{data.data.filter(item => item.orderType === "SERVICE").map((order, i) => (
              <>
                <Link to={`${order.id}`}>
                  <div className="flex justify-between items-center bg-white cursor-pointer p-2 hover:bg-blue-100">
                    <div className="flex items-center space-x-5">
                      <div className="bg-red-500 rounded w-24 h-24 items-center">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/5234/5234583.png"
                          className="object-cover w-24 h-24"
                        />

                        {/* Some order details */}
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm text-slate-400">
                          Number of Item:{" "}
                          <span className="text-md text-slate-700">
                          {/* {order.orderItems[0].quantity !== 0
                            ? order.orderItems[0].quantity
                            : null} */}
                          </span>
                        </p>
                        <h1 className="text-xs">
                          Order Status:{" "}
                          <span className="bg-green-600 text-white px-2 py-1 rounded-md">Active</span>
                        </h1>
                        
                      </div>
                    </div>

                    <div>
                        <p className="font-bold text-xs text-slate-400">Total amount</p>
                        <p className="font-bold text-xl">$ {order.totalPrice}</p>
                      
                    </div>
                  </div>
                </Link>
              </>
            ))}</>}
          </div> : null}
        
        </>)}
      </div>
    </div>
  );
}
