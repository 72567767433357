import {
  Avatar,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Footer from "../../utils/Footer";
import VendorInfo from "./child/VendorInfo";
import {
  useCreateOfferMutation,
  useGetCustomerOffersQuery,
  useGetSingleServiceQuery,
} from "../../../redux/apiSlice";
import { useNavigate, useParams } from "react-router-dom";
import MoreServiceFromVendor from "./child/MoreServiceFromVendor";
import { useDispatch, useSelector } from "react-redux";
import CreateOffer from "./child/CreateOffer";
import { closeModal, openModal } from "../../../redux/serviceSlice";
import HeadMarket from "../child/HeadMarket";
import DummySkeleton from "../goods/child/DummySkeleton";
import AuthenticationPrompt from "../child/AuthenticationPrompt";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const SingleServiceDetail = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useParams();

  const [hideOfferButton, setHideOfferButton] = useState(false);

  const modal = useSelector((state) => state.service);

  // current user
  const user = useSelector((state) => state.auth);

  // Queries
  const [{ isLoading: createOfferLoading }] = useCreateOfferMutation();
  const { data: offerList } = useGetCustomerOffersQuery();
  const { data, isLoading, error } = useGetSingleServiceQuery(route.id);

  // useEffect(() => {
  //   // Check if the current service is already in the users list of service offers

  //   if (offerExist) {
  //     setHideOfferButton(true);
  //   }

  //   return () => {};
  // }, []);

  function handleOpenOfferModal() {
    dispatch(openModal());
  }
  function handleCheckOffer() {
    navigate("/account/offer");
  }
  const handleCloseDialog = () => {
    dispatch(closeModal());
  };

  // Save service to favourite
  const handleOpenSaveServiceModal = () => {};

  const serviceId = data && data.data.id;
  const offerExist =
    offerList && offerList.data.find((item) => item.service.id === serviceId);

  return (
    <>
      <HeadMarket />

      {!data ? (
        <div className="py-8 px-4">
          <div className="">
            <div className="grid grid-cols-3 gap-5">
              <div className="col-span-2">
                <Skeleton
                  variant="rectangular"
                  animation="pulse"
                  sx={{ height: 400, borderRadius: 3 }}
                />
              </div>
              <div className="space-y-5">
                <Skeleton
                  variant="rectangular"
                  animation="pulse"
                  sx={{ height: 80, borderRadius: 3 }}
                />
                <Skeleton
                  variant="rectangular"
                  animation="pulse"
                  sx={{ height: 150, borderRadius: 3 }}
                />
                <div className="block space-y-2 sticky mt-10">
                  <button
                    className="py-4 w-full border rounded-md border-brown-900"
                    onClick={handleOpenSaveServiceModal}
                  >
                    Save work
                  </button>
                  <button
                    className="py-4 w-full text-white rounded-md bg-brown-900"
                    onClick={handleOpenOfferModal}
                  >
                    Hire Me
                  </button>
                </div>
              </div>
            </div>
            <div className="space-y-2 mt-5">
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ height: 20, width: 200 }}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ height: 10 }}
              />
            </div>
          </div>
          <div className="mt-10">
            <DummySkeleton />
          </div>
        </div>
      ) : (
        <>
          <div className="py-8 px-4">
            <div className="mx-auto">
              <div className="grid md:grid-cols-3 grid-cols-1">
                <div className="col-span-2">
                  <div className="py-5 md:px-10">
                    <h2 className="text-2xl font-bold">
                      {data && data.data.name}
                    </h2>
                    <div>
                      <Grid
                        container
                        justifyContent=""
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item xs={12} md={12}>
                          <div className="py-4">
                            <img
                              src={data && data.data.productImage}
                              className="w-full min-h-[400px] max-h-[350px] rounded-md object-cover"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="mt-3">
                      <hr className="" />
                    </div>
                    <div className="py-4 space-y-4">
                      <h2 className="font-bold">Project Details</h2>
                      <p>{data && data.data.description}</p>
                      <p className="text-blue-400">
                        {data && data.data.projectUrl}
                      </p>
                    </div>
                    <div className="mt-3">
                      <hr className="" />
                    </div>

                    <div className="py-3 mt-3">
                      <h2 className="font-bold">Tags</h2>

                      <div className="flex flex-wrap items-center gap-3 py-3">
                        {data &&
                          data.data.tags.map((item, i) => (
                            <button
                              key={i}
                              className="py-2 px-3 whitespace-nowrap border border-gray-200 rounded-lg hover:bg-brown-900 hover:text-white"
                            >
                              {item.tagName}
                            </button>
                          ))}
                      </div>
                    </div>
                    <div className="py-3 mt-3 w-full">
                      <h2 className="font-bold">Reviews</h2>
                      <div className="flex justify-center items-center">
                        <div className="py-8">
                          <h2 className="text-gray-400">
                            You have no reviews yet
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* User card */}
                <div className="md:mx-10">
                  <VendorInfo vendorInfo={data && data.data.vendor} />
                  <div className="block space-y-2 sticky mt-10">
                    {/* <button className="py-4 w-full border rounded-md border-brown-900">
                      Save work
                    </button> */}
                    {offerExist !== undefined ? (
                      <button
                        className="py-4 w-full text-white rounded-md bg-blue-600"
                        onClick={handleCheckOffer}
                      >
                        Check Offer Status
                      </button>
                    ) : (
                      <button
                        className="py-4 w-full text-white rounded-md bg-brown-900"
                        onClick={handleOpenOfferModal}
                      >
                        Create Offer
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className=" mt-10">
              <MoreServiceFromVendor vendorId={data && data.data.vendor.id} />
            </div>
          </div>
        </>
      )}
      {/* <CreateOffer open={modal.modal}  /> */}
      {/* Create a dialog that first check if the user is currently authenticated, if yes, show create offer, otherwise will show the login/signup pop-up */}
      <Dialog
        // fullScreen={fullScreen}
        open={modal.modal}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
        className="example"
      >
        {user.userToken === null ? (
          <AuthenticationPrompt />
        ) : (
          <>
            <CreateOffer data={data ? data.data : {}} />
          </>
        )}
      </Dialog>
      <Footer />
    </>
  );
};

export default SingleServiceDetail;

const textName = [
  "Creative Design",
  "Product Design",
  "Case Study",
  "Ecommerce",
  "Web App",
  "Mobile App",
  "Artificial Intelligence",
];
