

import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function EmptyOffer() {

    const navigate = useNavigate()

    function gotoService() {
        navigate('/market')
    }

  return (
    <div className='flex justify-center items-center min-h-[500px] bg-white rounded-lg'>
        <div className='flex flex-col justify-center'>
            
        <h1 className='text-sm text-slate-500 my-4'>You don't have any offer yet</h1>
        <button className='text-amber-400 bg-brown-800 rounded-md min-w-[100px] py-1.5' onClick={gotoService}>
            Create Offer
        </button>
        </div>
    </div>
  )
}
