import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: "",
    constituents: [],
    countryOfVendor: "",
    natureOfVendor: "",
  }

export const classifyReducer = createSlice({
    name: 'classify',
    initialState,
    reducers: {
        addId: (state,action) => {
            state.id = action.payload.id
        },
        countryOfVendor: (state, action) => {
            state.countryOfVendor = action.payload.country
        },
        natureOfVendor: (state,action) => {
            state.natureOfVendor = action.payload.nature
        },
        updateClassificationDetails: (state, action) => {
            state.constituents.push(action.payload.ingredient) 
        },
    },
})

// Action creators are generated for each case reducer function
export const { addId, natureOfVendor, updateClassificationDetails, countryOfVendor } = classifyReducer.actions

export default classifyReducer.reducer