import React from 'react';
import overlayBackground from "../../../../assets/svg/about-background-overlay.svg";

function SectionOne(props) {
    return (
      <div className="mb-0 bg-brown-900 h-screen relative flex items-center">
        {/* overlay background vector */}
        <div className="absolute right-0 top-10">
          <img
            src={overlayBackground}
            className="object-cover mix-blend-overlay"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-24 mx-auto px-3 md:px-40 overflow-hidden bg-brown-900 py-5">
          <div className="w-full flex items-center text-white">
            <div>
              <h3 className="font-bold font-sans text-3xl md:text-5xl text-center md:text-left mb-4">
                What is AFCFTA?
              </h3>
              <h6 className="mb-4 text-center md:text-left text-lg">
                The African Continental Free Trade Area (AfCFTA)is a free trade
                area founded in 2018, with trade commencing as of 1 January
                2021.
              </h6>
              <p className="text-center md:text-left mb-4 text-lg">
                It was created by the African Continental Free Trade Agreement
                among 54 of the 55 African Union nations. The free-trade area is
                the largest in the world in terms of the number of participating
                countries since the formation of the World Trade Organization.
              </p>
              
            </div>
          </div>
          <div className="w-full flex items-center md:justify-end justify-center order-first md:order-last">
            <div className="">
              <img
                src={"images/image 4.png"}
                alt={"AFCFTA-logo"}
                className="w-[300px]"
              />
            </div>
          </div>
        </div>
      </div>
    );
}

export default SectionOne;