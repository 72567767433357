import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    type: "",
    checkoutAddress: {},
    deliveryRate: "",
    items: [],
    cartTotalPrice: 0,
    newAddressModal: false

}


export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setCheckoutType: (state, action) => {
            state.type = action.payload.type
        } ,
        setCheckoutAddress: (state, action) => {
            state.checkoutAddress = action.payload
        },
        addCheckoutItems: (state, action) => {
            state.items = action.payload.cartItems
            state.cartTotalPrice = action.payload.cartTotalPrice
        },
        updateCartTotalPrice: (state, action) => {
            state.cartTotalPrice = action.payload
        },
        openNewAddressModal: (state) => {
            state.newAddressModal = true
        },
        closeNewAddressModal: (state) => {
            state.newAddressModal = false
        }
    }
})


export const {setCheckoutType, setCheckoutAddress, addCheckoutItems, openNewAddressModal, closeNewAddressModal} = checkoutSlice.actions

export default checkoutSlice.reducer