import axios from "axios";
import React, { useState } from "react";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import {
  IoIosCard,
  IoIosClose,
  IoIosCompass,
  IoIosHome,
  IoIosMenu,
  IoIosPie,
  IoIosSettings,
  IoIosWarning,
  IoMdArrowDropdown,
} from "react-icons/io";
import { useDispatch } from "react-redux";
import {
  NavLink,
  useHistory,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";
import { logout } from "../../../redux/AuthSlice";
// import axios from 'axios'

function DashHeader(props) {
  const [user, setUser] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [active, setActive] = useState(0);
  const token = JSON.parse(localStorage.getItem("TOKEN"));
  // navigate
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleDashMenu = () => {
    setOpenMenu((prev) => !prev);
  };

  // Click action
  const handleClickMenu = (id, link) => {
    // e.preventDefault()
    setActive(id);
    navigate(`/dashboard/${link}`);
    setOpenMenu(false);
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    console.log("processing.......");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };
    // console.log(token)
    // Check LogOut Function Again
    localStorage.removeItem("TOKEN");
    // const res = await axios.post(`${process.env.REACT_APP_BASE_URL}users/logout`,{headers:headers})
    // console.log(res?.data)
    console.log("done.......");

    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="relative hidden">
      <div className="w-full bg-white h-[55px] fixed top-0 shadow-md flex justify-between items-center px-10 md:px-8 z-50">
        <div>
          <img src={"images/logo.png"} alt="" className="w-10" />
        </div>
        {/* Menu */}
        <div>
          {/* <div className="flex items-center space-x-3.5">
            <div className="w-10 h-10 bg-brown-700 rounded-full" />
            <div className="text-center" to="/login">
              Olamide Mbenko
            </div>
            <div>
              <IoMdArrowDropdown />
            </div>
          </div> */}
          <div className="md:hidden block" onClick={toggleDashMenu}>
            {openMenu ? (
              <IoIosClose className="text-5xl pointer-events-none" />
            ) : (
              <IoIosMenu className="text-3xl pointer-events-none" />
            )}
          </div>
        </div>
      </div>

      {/* {openMenu && (
        <div className='md:hidden block'>
          <div className="absolute bg-slate-800 w-full h-screen bg-blend-overlay opacity-40 duration-700 ease-in-out transition-transform" onClick={() => setOpenMenu(false)}></div>
          <div className="absolute left-0 top-4 w-3/5 h-screen bg-white">
            <div className="p-5">
              <div className="flex flex-col justify-between h-[80vh]">
                <div className="">
                  {sideMenu.map((menu, i) => (
                    <div
                      className="w-full"
                      onClick={() => handleClickMenu(i, menu.to)}
                    >
                      <div
                        className={`px-5 py-3 ${active === i
                            ? "text-brown-900 bg-brown-200 font-bold"
                            : "text-slate-500"
                          } flex items-center space-x-3.5 hover:bg-slate-100`}
                      >
                        <p className="">
                          {menu.icon}
                        </p>
                        <span>{menu.name}</span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-5 ml-3 mb-5 text-center">
                  <button
                    className="w-full py-4 bg-red-600 text-white rounded "
                    onClick={handleLogout}
                  >
                    <i class="fas fa-sign-out mr-2"></i>Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
}

export default DashHeader;

const sideMenu = [
  {
    name: "Dashboard",
    to: "",
    icon: <IoIosHome />,
  },
  {
    name: "Shop",
    to: "shop",
    icon: <IoIosCompass />,
  },
  {
    name: "Orders",
    to: "orders",
    icon: <IoIosPie />,
  },
  {
    name: "Wallet",
    to: "wallet",
    icon: <IoIosCard />,
  },
  {
    name: "Disputes",
    to: "disputes",
    icon: <IoIosWarning />,
  },
  {
    name: "Settings",
    to: "settings",
    icon: <IoIosSettings />,
  },
];

<Navbar collapseOnSelect expand="" className="" variant="dark">
  <div className="container-fluid">
    <Navbar.Toggle
      aria-controls="responsive-navbar-nav"
      id="collapse-fontawesome"
    />
    <Navbar.Brand href="/">
      <img src={"/images/logo.png"} height="41px" width="41px" alt="AFCFTA" />
    </Navbar.Brand>
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="align-self-center ml-auto text-right">
        <NavItem>
          <NavLink className="nav-link mt-3 ml-3 text-center" to="/login">
            Olamide Mbenko
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link mt-2 ml-3 text-center" to="/vendor">
            <img
              src={"/images/Ellipse 970.png"}
              height="36px"
              width="36px"
              alt="profile-picture"
            />
          </NavLink>
        </NavItem>
        <hr />
      </Nav>
    </Navbar.Collapse>
  </div>
</Navbar>;
{
  /* Small screen */
}
<Navbar
  collapseOnSelect
  expand="lg"
  className="nav-small pt-4 small fixed-top head"
  variant="dark"
>
  <div className="container">
    <Navbar.Toggle aria-controls="responsive-navbar-nav">
      <span>
        <i className="fa fa-bars"></i>
      </span>
    </Navbar.Toggle>
    <img
      src={"/images/Ellipse 970.png"}
      height="36px"
      width="36px"
      alt="profile-picture"
    />

    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="align-self-center ml-auto text-right">
        <NavItem>
          <NavLink className="nav-link text-center pt-4 " to="/login">
            Olamide Mbenko
          </NavLink>
        </NavItem>
        <hr className="bg-white" />
        <NavItem>
          <NavLink className="nav-link pt-4 ml-3" to="#">
            <span>
              <i class="fas fa-home-alt mr-4"></i>Dashboard
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link pt-4 ml-3" to="#">
            <span>
              <i class="fas fa-map-marker-alt mr-4"></i>Marketplace
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link pt-4 ml-3" to="#">
            <span>
              <i class="fas fa-chart-pie-alt mr-4"></i>Shop
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link pt-4 ml-3" to="#">
            <span>
              <i class="fas fa-credit-card mr-4"></i>Orders
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link pt-4 ml-3" to="#">
            <span>
              <i class="fas fa-credit-card mr-4"></i>Wallet
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link pt-4 ml-3" to="#">
            <span>
              <i class="fas fa-credit-card mr-4"></i>Disputes
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link pt-4 ml-3" to="#">
            <span>
              <i class="fas fa-bolt mr-4"></i>Logistics
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link pt-4 ml-3" to="#">
            <span>
              <i class="fas fa-unlock mr-4"></i>Insurance
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link pt-4 ml-3" to="#">
            <span>
              <i class="fas fa-cog mr-4"></i>Settings
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link pt-4 pb-5 ml-3" to="#">
            <button className="btn btn-lg btn-logout">
              <i class="fas fa-sign-out mr-2"></i>Logout
            </button>
          </NavLink>
        </NavItem>
      </Nav>
    </Navbar.Collapse>
  </div>
</Navbar>;
