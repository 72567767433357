import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress, IconButton, Stack, TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import { IngredientContext } from "../../../../../../context/IngedientContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessModal from "./Success";
import { useSelector } from "react-redux";
import { useClassifyRetailerMutation } from "../../../../../../redux/apiSlice";
import { openSnackBar } from "../../../../../../redux/snackSlice";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  minHeight: "70vh",
  overflowY: "scroll",
  borderRadius: 2,
  p: 6,
};

export default function RetailerModal({
  open2,
  setOpen2,
  handleClose2,
  handleOpen2,
  handleOpen3,
}) {
  const token = JSON.parse(localStorage.getItem("TOKEN"));
  const [companyName, setCompanyName] = React.useState();
  const [retailerBatchId, setRetailerBatchId] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const { state, dispatch } = React.useContext(IngredientContext);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const Closer = async () => {
    handleClose2();
    handleOpen3();
  };

  const product = useSelector((state) => state.classify);
  const user = useSelector((i) => i.auth.userToken);
  console.log("user", product.id);

  // data
  const [classifyRetailer, { isLoading }] = useClassifyRetailerMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    };
    try {
      classifyRetailer({
        id: product.id,
        data: {
          companyName: companyName,
          retailerBatchId: retailerBatchId,
        },
      }).unwrap().then(data => {
        handleClose2()
        dispatch(openSnackBar({message: "Classification request sent!"}))
      });

      // const res = await axios.post(`${process.env.REACT_APP_BASE_URL}products/classifications/retailer/${state.id}`, { companyName, retailerBatchId }, { headers: headers })
      // console.log(res.data.data)
      // toast.success('Products Successfully Classified Check Your Mail for further Enquiry', {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      // setLoading(false)
      // setTimeout(() => {
      //   handleClose2()
      //   handleOpen()
      // }, 1000)
    } catch (error) {
      console.log(error);
      // toast.error('An Error occured', {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      // setLoading(false)
    }
  };
  return (
    <div>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-start flex-col items-center space-y-4 min-w-full">
            <IconButton className="self-start" onClick={Closer}>
              <ArrowBackIcon />
            </IconButton>
            <form onSubmit={handleSubmit} className="w-full">
              <Stack spacing={2} className="w-full">
                <div className="space-y-3 w-full">
                  <h3 className="font-semibold">Company Name</h3>
                  <TextField
                    variant="outlined"
                    onChange={(e) => setCompanyName(e.target.value)}
                    className="w-full"
                    placeholder="Dangote Cement PLC"
                  />
                </div>
                <div className="space-y-3">
                  <h3 className="font-semibold">Retailer Batch ID</h3>
                  <TextField
                    variant="outlined"
                    onChange={(e) => setRetailerBatchId(e.target.value)}
                    className="w-full"
                    placeholder="DNG45002901"
                  />
                </div>
                <div className="py-3 mt-3">
                  <button className="bg-brown-800 py-2 w-full rounded-md text-white">
                    {isLoading ? (
                      <div className="flex items-center justify-center">
                        <CircularProgress />
                      </div>
                    ) : (
                      <p>Proceed</p>
                    )}
                  </button>
                </div>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </Stack>
            </form>
          </div>
        </Box>
      </Modal>
      <SuccessModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        handleOpen={handleOpen}
      />
    </div>
  );
}
