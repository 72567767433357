import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    //   border: '2px solid #000',
    // boxShadow: 24,

    p: 4,
    borderRadius:2
};

export default function DisputeModal({ open, handleOpen, setOpen, handleClose }) {


    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='px-5'>
                        <h2 className='text-xl'>Order #2141</h2>
                        <div className='py-2'>
                            <hr />
                        </div>
                        <div className='py-2 flex items-center space-x-6'>
                            <div>
                                <h2>Status : Payment</h2>
                            </div>
                            <div>
                                <h2>Dispute Date : 16th Sept,2022,11:13am</h2>
                            </div>
                        </div>
                        <div className='py-1 rounded-md mx-auto bg-blue-100'>
                            <div className='py-1 w-[90%] mx-auto flex justify-between items-center '>
                                <h2>Order Summary</h2>
                                <h2>Total ; $7000</h2>
                            </div>
                        </div>
                        <div className='py-3'>
                            <div className='flex justify-between items-center'>
                                <div className='flex items-center space-x-4'>
                                    <img src="/images/red-pepper.png" className="w-16" />
                                    <div>
                                        <h3>Red Pepper</h3>
                                        <p className="text-gray-400">xl</p>
                                    </div>

                                </div>
                                <div>
                                    <h1>$3,500</h1>
                                </div>
                            </div>
                        </div>
                        <div className='py-3'>
                            <div className='flex justify-between items-center'>
                                <div className='flex items-center space-x-4'>
                                    <img src="/images/red-pepper.png" className="w-16" />
                                    <div>
                                        <h3>Red Pepper</h3>
                                        <p className="text-gray-400">xl</p>
                                    </div>

                                </div>
                                <div>
                                    <h1>$3,500</h1>
                                </div>
                            </div>
                        </div>
                        <div className='py-1 rounded-md mx-auto bg-blue-100'>
                            <div className='py-1 w-[90%] mx-auto flex justify-between items-center '>
                                <h2>Comment Details</h2>       
                            </div>
                        </div>
                        <div className="py-2">
                            <div className='flex justify-between items-center py-2'>
                                 <p>Date:</p>
                                 <p>24/10/10</p>
                            </div>
                            <div className='flex justify-between items-center py-2'>
                                 <p>Customer:</p>
                                 <p>Charles Tony</p>
                            </div>
                            <div className='flex justify-between items-center py-2'>
                                 <p>Dispute ID:</p>
                                 <p>#309604uh2</p>
                            </div>
                            <div className='flex justify-between items-center py-2'>
                                 <p>Company ID:</p>
                                 <p>048u63025</p>
                            </div>
                            <div className='flex justify-between items-center py-2'>
                                 <p>Amount paid:</p>
                                 <p>$4,000</p>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
