import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setIsSearching,
  setSearchResult,
  setSearchTerm,
} from "../../../../redux/searchSlice";
import axios from "axios";

export default function SearchInit() {
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const location = useLocation()

  const dispatch = useDispatch();
  const searchTerm = useSelector((state) => state.search.searchTerm);
  const searchResult = useSelector((state) => state.search.searchResult);

  const initSearch = () => {
    let timeoutId;

    // // only make request if input character count is greater than 5
    // if (searchTerm.length === 4) {
    //   dispatch(setIsSearching(true));

    //   // debounce the request

    //   axios
    //     .get(
    //       `${process.env.REACT_APP_BASE_URL}products/search?query=${searchTerm}&size=100&page=1`
    //     )
    //     .then((response) => {
    //       console.log(response);
    //       dispatch(setSearchResult({ result: response.data }));
    //       dispatch(setIsSearching(false));
    //       navigate("/market/search");
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       dispatch(setIsSearching(false));
    //     });
    // }
  };

  function handleInputChange(e) {
    dispatch(setSearchTerm({ term: e.target.value }));
    // only make request if input character count is greater than 5
    if (searchTerm !== null && searchTerm.length >= 3) {
      dispatch(setIsSearching(true));

      // debounce the request
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}products/search?query=${searchTerm}&size=100&page=1`
        )
        .then((response) => {
          console.log(response);
          dispatch(setSearchResult({ result: response.data }));
          dispatch(setIsSearching(false));
          if (location.pathname !== "/market/search") {
            navigate("/market/search");
          }
        })
        .catch((error) => {
          console.error(error);
          dispatch(setIsSearching(false));
        });
    } else if (searchTerm === "") {
      dispatch(setSearchResult({result: null}))
    }
  }

  function handleRouteSearch(value) {
    navigate("/market/search");
  }

  

  

  return (
    <div className="flex justify-between items-center">
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={handleInputChange}
        className="min-w-[400px] p-2 rounded-l-md border outline-none"
      />
      <button
        className="p-2 bg-brown-700 text-white border border-brown-700 rounded-r-md"
        onClick={handleRouteSearch}
      >
        Submit
      </button>
    </div>
  );
}
