import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextareaAutosize, TextField, Backdrop, Fade, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateClassificationDetails } from '../../../../../../redux/classifySlice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    //   border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Ingredient({ opener, setOpener, handleOpener, handleCloser, countries }) {
    const [name, setName] = React.useState()
    const [countrySourced, setCountry] = React.useState()
    const [percent, setPercent] = React.useState()
    //  const dispatch = useDispatch()
    // const { dispatch } = React.useContext(IngredientContext)

    const dispatch = useDispatch()
    const handleAddIngredient = async () => {
        // dispatch(add({countrySourced,percent,name}))
        dispatch(updateClassificationDetails({ingredient: {
            name: name,
            countrySourced: countrySourced,
            percent: percent
        }}))
        handleCloser()
    }
    return (
        <div>
            <Modal
                open={opener}
                onClose={handleCloser}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="mt-3">
                        <div className="py-2">
                            <h2 className="text-xl font-bold">Add Raw Materials Information</h2>
                        </div>

                        <div className="flex justify-betweeen items-center w-full">
                            <div className="space-y-3 flex-1">
                                <p>Name</p>
                                <TextField className="w-4/5" id="outlined-basic" label="Constituents" onChange={(e) => setName(e.target.value)} required variant="outlined" />
                            </div>
                            <div className="space-y-3 flex-1">
                                <p>Country Sourced</p>
                                {countries && (
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            // value={productOrigin}
                                            label="Age"
                                            required
                                            onChange={(e) => setCountry(e.target.value)}
                                        // onChange={(e) => setProductOrigin(e.target.value)}
                                        >
                                            {countries.map((country) => (
                                                <MenuItem value={country?.fullName}>{country?.fullName}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </div>
                            <div className="space-y-3 flex-1 text-center">
                                <p>% In Constituient</p>
                                <TextField className="w-4/5" id="outlined-basic" label="Ingredients" onChange={(e) => setPercent(e.target.value)} variant="outlined" />
                            </div>
                        </div>
                        <div className='py-2'>
                            <button className='bg-[#5A3924] w-full text-white p-2 mt-6' onClick={handleAddIngredient}>Add</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
