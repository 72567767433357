import { Avatar, Divider, Grid, IconButton, Skeleton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Service } from "./child/data";
import StarIcon from "@mui/icons-material/Star";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useGetAllServicesQuery } from "../../../redux/apiSlice";
import DummySkeleton from "../goods/child/DummySkeleton";




const ServiceCategorySection = () => {

  const { data, isLoading, error } = useGetAllServicesQuery()

  

  return (
    <>
      <div className="block">
        <div className="flex justify-between items-center">
          <h1 className="font-bold text-2xl my-5 capitalize">Top Services</h1>
          {/* <div className="flex items-center space-x-1">
            <IconButton>
              <ArrowBackIosIcon fontSize="small" className="text-blue-400" />
            </IconButton>
            <IconButton>
              <ArrowForwardIosIcon fontSize="small" className="text-blue-400" />
            </IconButton>
          </div> */}
        </div>
        {!data ? <DummySkeleton /> : (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-5" container spacing={5}>
            {data && data.data.map((service) => {
              return (
                <Grid
                  key={service.id}
                  item
                  xs={6}
                  md={3}
                  lg={3}
                  className="space-y-2"
                >
                  <Link to={`/market/service/${service.id}`}>
                    <div className="hover:border-brown-400 hover:drop-shadow rounded-lg pb-1.5">
                      <div className="rounded">
                        <img
                          src={service.productImage}
                          className="w-full h-36 rounded-t cursor-pointer object-cover"
                        />
                      </div>
                      <div className="flex flex-col w-[90%] mx-auto py-1">
                        <div className="py-1 space-y-3">
                          <div className="flex items-center space-x-2">
                            <Avatar sx={{ width: 35, height: 35 }}>P</Avatar>
                            <p className="text-sm">{service.vendor.firstName}</p>
                          </div>
                          <p className="text-black font-normal capitalize">
                            {service.category.name}
                          </p>
                          {/* <p className="text-xs  text-gray-400">
                            {service.tag}
                          </p> */}
                        </div>
                        <div className="flex justify-between items-center">
                          <p className="text-red-800 font-bold">
                            ${service.price}
                          </p>
                          {service.ratings !== 0 && (
                            <div className="flex items-center space-x-1">
                              <IconButton>
                                <StarIcon className="text-yellow-500" />
                              </IconButton>
                              {/* <p className="font-bold">{service.ratings}</p> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Grid>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default ServiceCategorySection;
